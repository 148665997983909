import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_GENRE, ADD_NEW_GENRE, DELETE_GENRE, UPDATE_GENRE, UPDATE_GENRE_IMAGE, EDIT_GENRE } from "./actionTypes"

import {
  getGenreSuccess,
  getGenreFail,
  addGenreFail,
  addGenreSuccess,
  updateGenreimage,
  updateGenreImageSuccess,
  updateGenreImageFail,
  updateGenreSuccess,
  updateGenreFail,
  deleteGenreSuccess,
  deleteGenreFail,
  editGenreSuccess,
  editGenreFail,
} from "./actions"

//Include Both Helper File with needed methods
import { getGenre, addNewGenre, updateGenre, deleteGenre, updateGenreImage, editGenre } from "../../helpers/fakebackend_helper"
import { stopLoader } from "../loaderState/actions"

function* fetchGenre({ payload: page}) {
  try {
  const response = yield call(getGenre,page.page,page.isActive)
    yield put(getGenreSuccess(response))
    yield put(stopLoader())
  } catch (error) {
    yield put(getGenreFail(error))
  }
}

function* onEditGenre({ payload:genre }) {
  try {

    const response = yield call(editGenre,genre)
    yield put(editGenreSuccess(response))
  } catch (error) {
    yield put(editGenreFail(error))
  }
}
function* onUpdateGenre({ payload: {genre, history } }) {
  try {
    const name =genre.name;
    const id =genre.id;
    const description =genre.description;
    const sort = genre.sort;
    const isActive = genre.isActive;
    const response = yield call(updateGenre, id,{ name, description, isActive,sort })
    yield put(updateGenreSuccess(response.data))
    const catId = response.data.id;
    const image =genre.imageNew;
    if(genre.imageNew != undefined){
      yield put(updateGenreimage(catId, genre.imageNew))
    }
    history.push("/genre");
    
  } catch (error) {
    yield put(updateGenreFail(error))
  }
}

function* onDeleteGenre({ payload: {genre, history } }) {
  try {
    const response = yield call(deleteGenre,genre)

    yield put(deleteGenreSuccess(genre))
    history.push("/genre");

  } catch (error) {
    yield put(deleteGenreFail(error))
  }
}

function* onAddNewGenre({ payload: {genre, history } }) {

  try {
    const name =genre.name;
    const description =genre.description;
    const image =genre.image;
    const isActive =genre.isActive == 1 ? true : false
    const response = yield call(addNewGenre, { name, description, isActive })

    yield put(addGenreSuccess(response.data))
    const catId = response.data.id;
    if(image != undefined){
      yield put(updateGenreimage(catId, image))
    }
    history.push("/genre");
  } catch (error) {

    yield put(addGenreFail(error))
  }
}

function* onUpdateGenreImage({ payload: { id, image } }) {

  try {
    const formData = new FormData()
    formData.append('image', image)
    const response = yield call(updateGenreImage, id, { image })
    yield put(updateGenreImageSuccess(response.data))
  } catch (error) {

    yield put(updateGenreImageFail(error))
  }
}

function* genreSaga() {
  yield takeEvery(GET_GENRE, fetchGenre)
  yield takeEvery(ADD_NEW_GENRE, onAddNewGenre)
  yield takeEvery(UPDATE_GENRE, onUpdateGenre)
  yield takeEvery(DELETE_GENRE, onDeleteGenre)
  yield takeEvery(EDIT_GENRE, onEditGenre)
  yield takeEvery(UPDATE_GENRE_IMAGE, onUpdateGenreImage)
}

export default genreSaga;
