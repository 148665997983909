import React, { useEffect, useMemo, useState } from "react";
import { cilPencil, cilTrash } from "@coreui/icons";
import "./index.css"
import { CCard, CCardBody, CCardGroup } from "@coreui/react";
import DataTable from "react-data-table-component";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import CIcon from "@coreui/icons-react";
import { useHistory, Link } from "react-router-dom";
import { toastConfirm } from "../../../common/toast";
import { useDispatch, useSelector } from "react-redux";
import { deleteUser, getUser } from "../../../store/actions";
import { setTokenHeader } from "../../../helpers/api_helper";
import FullPageLoader from "../../../components/Common/FullPageLoader";
import { startLoader } from "../../../store/loaderState/actions";
import { countries } from "country-data";
import {
  Col,
  DropdownMenu,
  DropdownToggle,
  Row,
  Dropdown,
  DropdownItem,
} from "reactstrap";
import { updateUserType } from "../../../store/user/toolkit/slice";

const EditUser = () => {
  document.title = "Moviez Customer";
  const { loadingState } = useSelector((state) => state.loadingState);
  const { type } = useSelector((state) => state.UserSlice);
  const history = useHistory();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const { user } = useSelector((state) => state.user);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [filterValue, setFilterValue] = useState(type);
  const [searched,setSearched] = useState(true)
  const [originalData, setOriginalData] = useState(null); // State to hold the original data
  const [filteredData, setFilteredData] = useState(null); // State to hold the filtered data
  const [searchInput, setSearchInput] = useState(""); // State to hold the search input value
  const [menuOpen, setMenuOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [email, setEmail] = useState("");
  const [userId, setuserId] = useState("");

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  // const filterData =  () => {
  //     if (searchInput.trim() === "" ||  selectedItem === "") {
  //       // If search value is empty, don't filter
  //       setFilteredData((prevState) => ({
  //         ...prevState,
  //         results: originalData.results,
  //         totalResults: originalData.totalResults,
  //         totalPages: originalData.totalPages,
  //         page: originalData.page,
  //       }));
  //       return;
  //     }
  //     const { results, totalResults, totalPages, page } = originalData;
  
  //     let filtered = results;
  //     if (selectedItem === "Email") {
  //       filtered = results.filter((row) =>
  //         row.email.toLowerCase().includes(searchInput.toLowerCase())
  //       );
  //     }else if (selectedItem === "UserId") {
  //       // Check if the search value is a valid ObjectId
  //       const isValidObjectId = /^[0-9a-fA-F]{24}$/.test(searchInput);
  
  //       if (isValidObjectId) {
  //         // Filter only if the search value is a valid ObjectId
  //         filtered = results.filter((row) =>
  //           row.id == searchInput
  //         );
  //       }
  //     }
  //     const filteredTotalPages = Math.ceil(filtered.length / originalData.limit);

  //     setFilteredData((prevState) => ({
  //       ...prevState,
  //       results: filtered,
  //       totalPages: filteredTotalPages,
  //       totalResults: filtered.length,
  //     }));
  //  };

  


    const filterData =  () => {
      if (searchInput.trim() === "" ||  selectedItem === "") {
        return
      }
      if (selectedItem === "Email") {
        console.log("email",email)
        setuserId("")
        setEmail(searchInput)
        setSearched(!searched)
      }else if (selectedItem === "UserId") {
        setuserId(searchInput)
        setEmail("")
        setSearched(!searched)
      }
   };

  

  const toggleDropdown = () => {
    setDropdownOpen((prevState) => !prevState);
  };

  const handleFilterChange = (value) => {
    setFilterValue(value);
    dispatch(updateUserType({ type: value }));
  };

  const deleteUserRecord = async (id) => {
    const response = await (
      await toastConfirm("Are you sure you want to delete this?")
    ).fire();
    if (response.isConfirmed) {
      dispatch(deleteUser(id, history));
    }
  };

  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem("authUser"));

    setTokenHeader(data.access_token);
    dispatch(startLoader());
    dispatch(getUser(page, filterValue,email,userId));
  }, []);

  // useEffect(() => {
  //   if (user) {
  //     setOriginalData(user);
  //     setFilteredData(user);
  //   }
  // }, [user]);
  
  useEffect(() => {
    dispatch(startLoader());
    console.log("check data 1  ssss===>",page, filterValue,email,userId)
  
    dispatch(getUser(page, filterValue,email,userId));
  }, [page, filterValue,searched]);

  var rowCounter = 1;
  const columns = [
    {
      name: "Sr. no",
      selector: (row) => rowCounter++,
      sortable: true,
      maxWidth: "80px",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      width: "300px",
      maxWidth: "300px",
    },
    {
      name: "User Name",
      selector: (row) => row.name || "N/A",
      sortable: true,
    },
    {
      name: "Country",
      selector: (row) => countries[row.country]?.name || row.country || "N/A",
      sortable: true,
    },
    {
      name: "Email Verified",
      selector: (row) => (row.isEmailVerified == true ? "Yes" : "No"),
      sortable: true,
      width: "200px",
      maxWidth: "120px",
    },
    {
      name: "Subscription",
      selector: (row) => (row.isSubscribed == true ? "Active" : "Inactive"),
      sortable: true,
      maxWidth: "150px",
    },
    {
      name: "Actions",
      maxWidth: "100px",
      selector: (row) => (
        <>
          <Link to={`user/${row.id}/edit`}>
            <CIcon
              icon={cilPencil}
              className="text-warning hand me-2"
              onClick={() => {
                // toggleViewModal()
                // setEdit(row);
              }}
            />
          </Link>
          <CIcon
            icon={cilTrash}
            className="text-danger hand"
            onClick={() => {
              deleteUserRecord(row.id);
            }}
          />
        </>
      ),
    },
  ];

  if (!user?.results) {
    return <FullPageLoader />;
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Registered Users"
            breadcrumbItem="Manage Customers"
          />

          <Row>
            <Col xl={12}>
              
              <div className="mb-2">
                <Link
                  to={"/registered-users/add"}
                  className="btn btn-primary w-md float-end"
                >
                  Add User
                </Link>
              </div>
              
            </Col>

         

          
            <div className="clearfix mt-3">
                <div className="float-start">
                <div className="d-flex ">
                <Dropdown
                isOpen={menuOpen}
                toggle={toggleMenu}
                className="d-inline-block"
                style={{
                  marginBottom: "0px",
                }}
              >
                <DropdownToggle caret className="dropdown_after">
                  {selectedItem || "Filter By"}   <i className="fa fa-caret-down"></i>
                 
                </DropdownToggle>

                <DropdownMenu className="dropdown-menu-end hlo" direction="start">
                  <DropdownItem
                    onClick={() => handleItemClick("Email")}
                    style={{
                      background:
                        selectedItem === "Email" ? "lightgray" : "white",
                    }}
                  >
                    Email 
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => handleItemClick("UserId")}
                    style={{
                      background:
                        selectedItem === "UserId" ? "lightgray" : "white",
                    }}
                  >
                    User Id
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
              <input
                type="text"
                value={searchInput}
                className="form-control ms-4 border-end-0 border_es"
                style={{
                  maxWidth: "300px",
                  padding: "8px 12px",
                  border: "1px solid #ced4da",
                  boxShadow: "none",
                  transition:
                    "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
                }}
                onChange={(e)=>{
                  setSearchInput(e.target.value)
                }}
                placeholder="Search"
              />
              <button onClick={filterData} className="btn btn-secondary mr-search">Search</button>
            
            </div>
                </div>
                <div className="float-end">
                <div className="d-flex align-items-center justify-content-end">
              <Dropdown
                isOpen={dropdownOpen}
                toggle={toggleDropdown}
                style={{
                  marginLeft: "5px",
                  marginBottom: "0px",
                }}
              >
                <DropdownToggle caret>{filterValue || "Filter"} <i className="fa fa-caret-down"></i></DropdownToggle>
                <DropdownMenu >
                  <DropdownItem onClick={() => handleFilterChange("Live")}>
                    Live
                  </DropdownItem>
                  <DropdownItem onClick={() => handleFilterChange("Test")}>
                    Test
                  </DropdownItem>
                  <DropdownItem onClick={() => handleFilterChange("")}>
                    All
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
                </div>
              </div>

            <Col xl={12}>
              <CCardGroup>
                <CCard>
                  <CCardBody>
                    <DataTable
                      striped
                      columns={columns}
                      data={user?.results}
                      highlightOnHover
                      pagination
                      paginationServer
                      paginationTotalRows={user?.totalResults}
                      paginationPerPage={user?.limit}
                      paginationComponentOptions={{
                        noRowsPerPage: true,
                      }}
                      onChangePage={(page, totalRows) => {
                        setPage(page);
                      }}
                    />
                  </CCardBody>
                </CCard>
              </CCardGroup>
              <FullPageLoader loading={loadingState} />
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EditUser;
