import { createAsyncThunk } from "@reduxjs/toolkit";
const baseUrl = `${process.env.REACT_APP_ZFLIX_URL}/v1/`;


const getTransactionData = createAsyncThunk(
    'transaction',
    async (action) => {
        try {
            let url = `${baseUrl}users/subscription/details?all=true`;
            const token = JSON.parse(sessionStorage.getItem("authUser")).access_token
            let option = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':`Bearer ${token}`,
                },
            }
            const response = await (await fetch(url, option)).json();

            if (response.status === true) {
                return [true, response];
            } else if (response.status === false) {
                return [false, response.message];
            }
        } catch (error) {
            console.log(error)
            return [false];
        }
    }
)

export { getTransactionData }
