/* CATEGORIES */
export const GET_TITLE = "GET_TITLE"
export const GET_TITLE_SUCCESS = "GET_TITLE_SUCCESS"
export const GET_TITLE_FAIL = "GET_TITLE_FAIL"

/**
 * add TITLE
 */
 export const ADD_NEW_TITLE = "ADD_NEW_TITLE"
 export const ADD_TITLE_SUCCESS = "ADD_TITLE_SUCCESS"
 export const ADD_TITLE_FAIL = "ADD_TITLE_FAIL"

/**
 * UPDATE TITLE IMAGE
 */
 export const UPDATE_TITLE_IMAGE = "UPDATE_TITLE_IMAGE"
 export const UPDATE_TITLE_IMAGE_SUCCESS = "UPDATE_TITLE_IMAGE_SUCCESS"
 export const UPDATE_TITLE_POSTER_SUCCESS = "UPDATE_TITLE_POSTER_SUCCESS"
 export const UPDATE_TITLE_IMAGE_FAIL = "UPDATE_TITLE_IMAGE_FAIL"
  

 /**
 * UPDATE TITLE VIDEO
 */
  export const UPDATE_TITLE_VIDEO = "UPDATE_TITLE_VIDEO"
  export const UPDATE_TITLE_VIDEO_SUCCESS = "UPDATE_TITLE_VIDEO_SUCCESS"
  export const UPDATE_TITLE_VIDEO_FAIL = "UPDATE_TITLE_VIDEO_FAIL"
 /**
 * IMPORT TITLE VIDEO
 */
  export const IMPORT_TITLE_VIDEO = "IMPORT_TITLE_VIDEO"
 /**
 * UPDATE TRAILER VIDEO
 */
  export const UPDATE_TRAILER_VIDEO = "UPDATE_TRAILER_VIDEO"
  export const UPDATE_TRAILER_VIDEO_SUCCESS = "UPDATE_TRAILER_VIDEO_SUCCESS"
  export const UPDATE_TRAILER_VIDEO_FAIL = "UPDATE_TRAILER_VIDEO_FAIL"
  /**
  * UPLOAD TITLE VIDEO
  */
   export const UPLOAD_TITLE_VIDEO = "UPLOAD_TITLE_VIDEO"
   export const UPLOAD_TITLE_VIDEO_SUCCESS = "UPLOAD_TITLE_VIDEO_SUCCESS"
   export const UPLOAD_TITLE_VIDEO_FAIL = "UPLOAD_TITLE_VIDEO_FAIL"
  /**
  * UPLOAD SERIES VIDEO
  */
   export const UPDATE_SERIES_VIDEO = "UPDATE_SERIES_VIDEO"
   export const UPDATE_SERIES_VIDEO_SUCCESS = "UPDATE_SERIES_VIDEO_SUCCESS"
   export const UPDATE_SERIES_VIDEO_FAIL = "UPDATE_SERIES_VIDEO_FAIL"
  
  
/**
 * Edit TITLE
 */
export const EDIT_TITLE = "EDIT_TITLE"
export const EDIT_TITLE_SUCCESS = "EDIT_TITLE_SUCCESS"
export const EDIT_TITLE_FAIL = "EDIT_TITLE_FAIL"


/**
 * Edit TITLE
 */
 export const UPDATE_TITLE = "UPDATE_TITLE"
 export const UPDATE_TITLE_SUCCESS = "UPDATE_TITLE_SUCCESS"
 export const UPDATE_TITLE_FAIL = "UPDATE_TITLE_FAIL"

/**
 * Delete TITLE
 */
export const DELETE_TITLE = "DELETE_TITLE"
export const DELETE_TITLE_SUCCESS = "DELETE_TITLE_SUCCESS"
export const DELETE_TITLE_FAIL = "DELETE_TITLE_FAIL"

export const UPDATE_CATEGORY_TITLE = "UPDATE_CATEGORY_TITLE"
