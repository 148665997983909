import { createSlice } from "@reduxjs/toolkit";
import { getFAQ, getFAQById, deleteFAQById } from "./action";

const FAQSlice = createSlice({
    name: 'FAQSlice',
    initialState: {
        faqData: [],
        loadingState: true,
        faqEditData: {}
    },
    reducers: {

    },
    extraReducers: (builder) => {

        // handle  get all faq
        builder.addCase(getFAQ.pending, (state) => {
            return { ...state, faqData: [], loadingState: false };
        });
        builder.addCase(getFAQ.fulfilled, (state, action) => {
            if (action.payload[0])
                return {
                    ...state,
                    faqData: action.payload[1].data,
                    loadingState: false
                }
            else
                return { ...state, faqData: [], errors: action.payload[1], loadingState: false }
        });
        builder.addCase(getFAQ.rejected, (state) => {
            return { ...state, faqData: [] }
        });

        // handle  get all faq by id
        builder.addCase(getFAQById.pending, (state) => {
            return { ...state, faqEditData: [], loadingState: false };
        });
        builder.addCase(getFAQById.fulfilled, (state, action) => {
            if (action.payload[0])
                return {
                    ...state,
                    faqEditData: action.payload[1].data,
                    loadingState: false
                }
            else
                return { ...state, faqEditData: [], errors: action.payload[1], loadingState: false }
        });
        builder.addCase(getFAQById.rejected, (state) => {
            return { ...state, faqEditData: [], loadingState: false }
        });

         // handle delete by id faq
         builder.addCase(deleteFAQById.pending, (state) => {
            return { ...state, faqData: [], loadingState: false };
        });
        builder.addCase(deleteFAQById.fulfilled, (state, action) => {
            if (action.payload[0])
                return {
                    ...state,
                    faqData: action.payload[1].data,
                    loadingState: false
                }
            else
                return { ...state, faqData: [], errors: action.payload[1], loadingState: false }
        });
        builder.addCase(deleteFAQById.rejected, (state) => {
            return { ...state, faqData: [] }
        });

        
    }
})

export const { } = FAQSlice.actions;


export default FAQSlice.reducer;