import axios from "axios"
import { del, get, post, put, patch } from "./api_helper"
import * as url from "./url_helper"

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

// Register Method
const postFakeRegister = data => {
  return axios
    .post(url.POST_FAKE_REGISTER, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      let message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

// Login Method
const postLogin = data => post(url.POST_LOGIN, data)

// get categories

// postForgetPwd
const postFakeForgetPwd = data => post(url.POST_FAKE_PASSWORD_FORGET, data)

// Edit profile
const postJwtProfile = data => post(url.POST_EDIT_JWT_PROFILE, data)

const postFakeProfile = data => post(url.POST_EDIT_PROFILE, data)

// Register Method
const postJwtRegister = (url, data) => {
  return axios
    .post(url, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      var message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

// Login Method
const postJwtLogin = data => post(url.POST_FAKE_JWT_LOGIN, data)

// postForgetPwd
const postJwtForgetPwd = data => post(url.POST_FAKE_JWT_PASSWORD_FORGET, data)

// postSocialLogin
export const postSocialLogin = data => post(url.SOCIAL_LOGIN, data)

// get Products
export const getProducts = () => get(url.GET_PRODUCTS)

// get Product detail
export const getProductDetail = id =>
  get(`${url.GET_PRODUCTS_DETAIL}/${id}`, { params: { id } })

// get Events
export const getEvents = () => get(url.GET_EVENTS)

// add Events
export const addNewEvent = event => post(url.ADD_NEW_EVENT, event)

// update Event
export const updateEvent = event => put(url.UPDATE_EVENT, event)

// delete Event
export const deleteEvent = event =>
  del(url.DELETE_EVENT, { headers: { event } })

//  Category urls

export const getCategories = () => get(url.GET_CATEGORIES)
export const getCategoriesProfile = () => get(url.GET_CATEGORY_PROFILE)

export const addNewCategory = category => post(url.ADD_NEW_CATEGORY, category)
export const updateCategory = (id, data) =>
  patch(`${url.ADD_NEW_CATEGORY}/${id}`, data)
export const deleteCategory = id => del(`${url.DELETE_CATEGORY}/${id}`)
export const updateCategoryImage = (id, data) =>
  post(`${url.UPDATE_CATEGORY_IMAGE}/${id}`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  })

// get Genre
export const getGenre = (page,isActive) =>  get(`${url.GET_GENRE}?page=${page}&isActive=${isActive}`)
export const addNewGenre = genre => post(url.ADD_NEW_GENRE, genre)
export const deleteGenre = id => del(`${url.DELETE_GENRE}/${id}`)
export const updateGenreImage = (id, data) =>
  post(`${url.UPDATE_GENRE_IMAGE}/${id}`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  })
export const editGenre = id => get(`${url.EDIT_GENRE}/${id}`)
export const updateGenre = (id, data) =>
  patch(`${url.UPDATE_GENRE}/${id}`, data)

// get Cast
export const getCast = page => get(`${url.GET_CAST}?page=${page}`)
export const addNewCast = cast => post(url.ADD_NEW_CAST, cast)
export const deleteCast = id => del(`${url.DELETE_CAST}/${id}`)
export const updateCastImage = (id, data) =>
  post(`${url.UPDATE_CAST_IMAGE}/${id}`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  })
export const editCast = id => get(`${url.EDIT_CAST}/${id}`)
export const updateCast = (id, data) => patch(`${url.UPDATE_CAST}/${id}`, data)

// get Title
export const getTitle = (page,countries,language,sortKey,sortType) => get(`${url.GET_TITLE}?page=${page}&country=${countries}&language=${language}&sortKey=${sortKey}&sortType=${sortType}`)
export const addNewTitle = title => post(url.ADD_NEW_TITLE, title)
export const deleteTitle = id => del(`${url.DELETE_TITLE}/${id}`)
export const updateTitleImage = (id, data) =>
  post(`${url.UPDATE_TITLE_IMAGE}/${id}`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  })
export const updateTitlePoster = (id, data) =>
  post(`${url.UPDATE_TITLE_POSTER}/${id}`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  })
export const editTitle = id => get(`${url.EDIT_TITLE}/${id}`)
export const updateTitle = (id, data) =>
   patch(`${url.UPDATE_TITLE}/${id}`, data)
 
export const importTitleVideo = data =>
  post(`${url.IMPORT_TITLE_VIDEO}`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  })
export const updateTitleVideo = (id, data) =>
  patch(`${url.UPDATE_TITLE_VIDEO}/${id}`, data)
export const uploadTitleVideo = (id, data) =>
  post(`${url.UPDATE_TITLE_VIDEO}/${id}`, data)
export const updateTrailerVideo = (id, data) =>
  post(`${url.UPDATE_TRAILER_VIDEO}/${id}`, data)
export const updateSeriesVideo = (id, data) =>
  patch(`${url.UPDATE_SERIES_VIDEO}/${id}`, data)
export const uploadSeriesVideo = (id, data) =>
  post(`${url.UPDATE_SERIES_VIDEO}/${id}`, data)
  export const updateCategoryTitle = data => put(url.UPDATE_CATEGORY_TITLE, data)

// get users
export const getUser = (page,type,email,userId) => get(`${url.GET_USER}?page=${page}&type=${type}&email=${email}&userId=${userId}`)
export const addNewUser = user => post(url.ADD_NEW_USER, user)
export const deleteUser = id => del(`${url.DELETE_USER}/${id}`)
export const editUser = id => get(`${url.DELETE_USER}/${id}`)
export const updateUser = (id, user) => patch(`${url.UPDATE_USER}/${id}`, user)

// get Plans
export const getPlan = page => get(`${url.GET_PLAN}?page=${page}`)
// export const addNewPlan = plan => post(url.ADD_NEW_PLAN, plan)
// export const deletePlan = id => del(`${url.DELETE_USER}/${id}`)
// export const editPlan = id => get(`${url.DELETE_USER}/${id}`)
// export const updatePlan = (id, user) => patch(`${url.UPDATE_USER}/${id}`, user)

// get chats
export const getChats = () => get(url.GET_CHATS)

// get groups
export const getGroups = () => get(url.GET_GROUPS)

// get Contacts
export const getContacts = () => get(url.GET_CONTACTS)

// get messages
export const getMessages = (roomId = "") =>
  get(`${url.GET_MESSAGES}/${roomId}`, { params: { roomId } })

// post messages
export const addMessage = message => post(url.ADD_MESSAGE, message)

// get orders
export const getOrders = () => get(url.GET_ORDERS)

// add order
export const addNewOrder = order => post(url.ADD_NEW_ORDER, order)

// update order
export const updateOrder = order => put(url.UPDATE_ORDER, order)

// delete order
export const deleteOrder = order =>
  del(url.DELETE_ORDER, { headers: { order } })

// get cart data
export const getCartData = () => get(url.GET_CART_DATA)

// get customers
export const getCustomers = () => get(url.GET_CUSTOMERS)

// add CUSTOMER
export const addNewCustomer = customer => post(url.ADD_NEW_CUSTOMER, customer)

// update CUSTOMER
export const updateCustomer = customer => put(url.UPDATE_CUSTOMER, customer)

// delete CUSTOMER
export const deleteCustomer = customer =>
  del(url.DELETE_CUSTOMER, { headers: { customer } })

// get shops
export const getShops = () => get(url.GET_SHOPS)

// get wallet
export const getWallet = () => get(url.GET_WALLET)

// get crypto order
export const getCryptoOrder = () => get(url.GET_CRYPTO_ORDERS)

// get invoices
export const getInvoices = () => get(url.GET_INVOICES)

// get invoice details
export const getInvoiceDetail = id =>
  get(`${url.GET_INVOICE_DETAIL}/${id}`, { params: { id } })

// get jobs
export const getJobList = () => get(url.GET_JOB_LIST)

// get Apply Jobs
export const getApplyJob = () => get(url.GET_APPLY_JOB)

// get project
export const getProjects = () => get(url.GET_PROJECTS)

// get project details
export const getProjectsDetails = id =>
  get(`${url.GET_PROJECT_DETAIL}/${id}`, { params: { id } })

// get tasks
export const getTasks = () => get(url.GET_TASKS)

// get contacts
// export const getUsers = () => get(url.GET_USERS);

// add user
// export const addNewUser = user => post(url.ADD_NEW_USER, user);

// delete user
// export const deleteUser = user => del(url.DELETE_USER, { headers: { user } });

// add jobs
export const addNewJobList = job => post(url.ADD_NEW_JOB_LIST, job)

// update jobs
export const updateJobList = job => put(url.UPDATE_JOB_LIST, job)

// delete jobs
export const deleteJobList = job =>
  del(url.DELETE_JOB_LIST, { headers: { job } })

// Delete Apply Jobs
export const deleteApplyJob = data =>
  del(url.DELETE_APPLY_JOB, { headers: { data } })

/** PROJECT */
// add user
export const addNewProject = project => post(url.ADD_NEW_PROJECT, project)

// update user
export const updateProject = project => put(url.UPDATE_PROJECT, project)

// delete user
export const deleteProject = project =>
  del(url.DELETE_PROJECT, { headers: { project } })

// export const getUserProfile = () => get(url.GET_USER_PROFILE);

// get inboxmail
export const getInboxMails = () => get(url.GET_INBOX_MAILS)

// add inboxmail
export const addNewInboxMail = inboxmail =>
  post(url.ADD_NEW_INBOX_MAIL, inboxmail)

// delete inboxmail
export const deleteInboxMail = inboxmail =>
  del(url.DELETE_INBOX_MAIL, { headers: { inboxmail } })

// get starredmail
export const getStarredMails = () => get(url.GET_STARRED_MAILS)

// get importantmail
export const getImportantMails = () => get(url.GET_IMPORTANT_MAILS)

// get sent mail
export const getSentMails = () => get(url.GET_SENT_MAILS)

// get trash mail
export const getTrashMails = () => get(url.GET_TRASH_MAILS)

// get starredmail
export const getDraftMails = () => get(url.GET_DRAFT_MAILS)

// get dashboard charts data
export const getWeeklyData = () => get(url.GET_WEEKLY_DATA)
export const getYearlyData = () => get(url.GET_YEARLY_DATA)
export const getMonthlyData = () => get(url.GET_MONTHLY_DATA)

export const topSellingData = month =>
  get(`${url.TOP_SELLING_DATA}/${month}`, { params: { month } })

export const getEarningChartsData = month =>
  get(`${url.GET_EARNING_DATA}/${month}`, { params: { month } })

  // get SearchData
export const getSearchData = page => get(`${url.GET_SEARCHDATA}?page=${page}`)

const getProductComents = () => get(url.GET_PRODUCT_COMMENTS)

const onLikeComment = (commentId, productId) => {
  return post(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}`, {
    params: { commentId, productId },
  })
}
const onLikeReply = (commentId, productId, replyId) => {
  return post(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}/${replyId}`, {
    params: { commentId, productId, replyId },
  })
}

const onAddReply = (commentId, productId, replyText) => {
  return post(`${url.ON_ADD_REPLY}/${productId}/${commentId}`, {
    params: { commentId, productId, replyText },
  })
}

const onAddComment = (productId, commentText) => {
  return post(`${url.ON_ADD_COMMENT}/${productId}`, {
    params: { productId, commentText },
  })
}


export {
  getLoggedInUser,
  isUserAuthenticated,
  postFakeRegister,
  postLogin,
  postFakeProfile,
  postFakeForgetPwd,
  postJwtRegister,
  postJwtLogin,
  postJwtForgetPwd,
  postJwtProfile,
  getProductComents,
  onLikeComment,
  onLikeReply,
  onAddReply,
  onAddComment,
}
