import React, { useEffect, useState } from "react"
import { Card, Col, Container, Row, CardBody, Label } from "reactstrap"
import "./index.css"
import { useForm, useFieldArray, Controller } from "react-hook-form"
import * as Yup from "yup"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { toast } from "react-toastify"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { setTokenHeader } from "../../../helpers/api_helper"
import { useHistory } from "react-router-dom"
import { getFAQById, updateFAQ } from "../../../store/faq/action"
import FullPageLoader from "../../../components/Common/FullPageLoader"

const CastEdit = props => {
  document.title = "Edit FAQ"
  const params = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const { faqEditData, loadingState } = useSelector(state => state.faq)
  const [editData, setEditData] = useState(faqEditData ? faqEditData : "")
  const { control, handleSubmit, setValue, getValues, register, errors } =
    useForm({
      defaultValues: {
        faq_content: (editData && editData.length > 0?.faq_content) || [],
      },
    })

  useEffect(() => {
    const sessionsData = JSON.parse(sessionStorage.getItem("authUser"))
    setTokenHeader(sessionsData.access_token)
    dispatch(getFAQById(params.id))
  }, [])

  const { fields, append, remove } = useFieldArray({
    control,
    name: "faq_content",
  })

  useEffect(() => {
    if (faqEditData) {
      setEditData(faqEditData)
      setValue("faq_content", faqEditData?.faq_content)
      setValue("title", faqEditData?.title)
      setValue("onHomePage", faqEditData?.onHomePage)
    }
  }, [faqEditData])

  // Handle form submission
  const onSubmit = data => {
    const filteredData = data.faq_content.map(faq => {
      const { _id, ...filteredFaq } = faq
      return filteredFaq
    })
    const updatedata = {
      id: params.id,
      title: editData.title,
      faq_content: filteredData,
      onHomePage:editData.onHomePage
    }
    dispatch(updateFAQ(updatedata)).then(v => {
      console.log("Updated status", v)
      if (v.payload[0] === true) {
        toast.success("Updated Successfully!!")
        history.push("/faq")
      }
      if (v.payload[0] === false) {
        toast.error(v.payload[1])
      }
    })
  }

  const handleShowHomePage = value => {
    const newValue = value === "true" ? true : false
    setEditData({ ...editData, onHomePage: newValue })
  }

  if (editData.length <= 0) {
    return <FullPageLoader loading={"loading"} />
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Manage FAQ" breadcrumbItem="Edit FAQ" />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col xl={12}>
                <Card>
                  <CardBody>
                    <Row>
                      <Col xl={12}>
                        <button
                          type="button"
                          className="btn btn-sm btn-primary m-2"
                          style={{ float: "right" }}
                          onClick={() => append({ question: "", answer: "" })}
                        >
                          Add FAQ
                        </button>
                      </Col>
                    </Row>
                    <div className="mb-3">
                      <div className="row">
                        <div className="col-sm-8">
                          <Label htmlFor={`title`}>FAQ Title</Label>
                          <input
                            type="text"
                            className="form-control"
                            name={`title`}
                            defaultValue={editData?.title || ""}
                            {...register(`title`)}
                          />
                        </div>
                        <div className="col-sm-4 d-relative">
                          <Label htmlFor={`title`}>Show On Home Page</Label>
                          <div className="d-flex justify-content-start ">
                            <div className="mr-2 onHomePage">
                              <input
                                type="radio"
                                id="true"
                                name="onHomePage"
                                checked={editData.onHomePage === true}
                                value="true"
                                onClick={e =>
                                  handleShowHomePage(e.target.value)
                                }
                              />{" "}
                              <label htmlFor="true"> YES</label>
                            </div>
                            <div className="ml-2 onHomePage">
                              <input
                                type="radio"
                                id="false"
                                checked={editData.onHomePage === false}
                                name="onHomePage"
                                value="false"
                                onClick={e =>
                                  handleShowHomePage(e.target.value)
                                }
                              />{" "}
                              <label htmlFor="false"> NO</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {fields.map((field, index) => (
                      <div key={index} className="mb-3">
                        <div className="mb-3">
                          <Label htmlFor={`faq_content.${index}.question`}>
                            {index + 1}. Question
                          </Label>
                          <input
                            type="text"
                            className="form-control"
                            name={`faq_content[${index}].question`}
                            defaultValue={field.question}
                            {...register(`faq_content[${index}].question`)}
                          />
                        </div>
                        <div className="mb-3">
                          <Label htmlFor={`faq_content.${index}.answer`}>
                            Answer
                          </Label>
                          <textarea
                            className="form-control"
                            name={`faq_content[${index}].answer`}
                            defaultValue={field.answer}
                            {...register(`faq_content[${index}].answer`)}
                            rows="5"
                          />
                        </div>
                        <button
                          type="button"
                          className="btn btn-sm btn-danger mb-3"
                          style={{ float: "right" }}
                          onClick={() => remove(index)}
                        >
                          Delete
                        </button>
                      </div>
                    ))}
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <button type="submit" className="btn btn-sm btn-success m-2">
              Submit
            </button>
          </form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CastEdit
