import PropTypes from "prop-types"
import React, {useState,useEffect} from "react"
import { ToastContainer } from "react-toastify"
import {
  Row,
  Container,
} from "reactstrap"
import axios from "axios"
//redux
import { withRouter, } from "react-router-dom"
import FullPageLoader from "../../components/Common/FullPageLoader"

//Import config
const TermsConditions = props => {
  const API_URL = `${process.env.REACT_APP_ZFLIX_URL}/v1`
  document.title = "Privacy Policy | Moviez : Stream Movie Channel"
  const [text, setText] = useState("")


  useEffect(() => {
    getCms()
    document.body.style.backgroundColor = "rgb(26, 32, 50)";
    return (()=>{
      document.body.style.backgroundColor= ""
    })
  }, [])

  const getCms = () => {
    axios
      .get(API_URL + "/cms/privacy-policy?type=terms-conditions")
      .then(response => {
        setText(response.data?.data[0]?.Content)
      })
      .catch(error => console.log(error))
  }

  if (!text) {
    return <FullPageLoader/>
  }

  return (
    <React.Fragment>
      <div
        className="account-pages py-3"
        style={{ background: "#1A2032", color: "#FFF" }}
      >
        <Container>
          <div className="content py-2 mb-3">
            <div className="container">
              <Row>
              <div dangerouslySetInnerHTML={{ __html: text }} />
              </Row>
            </div>
          </div>
          <ToastContainer />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(TermsConditions)

TermsConditions.propTypes = {
  history: PropTypes.object,
}
