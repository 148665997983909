import { call, put, select, takeEvery } from "redux-saga/effects"
import { toast } from "react-toastify"
// Crypto Redux States
import {
  GET_TITLE,
  ADD_NEW_TITLE,
  DELETE_TITLE,
  UPDATE_TITLE,
  UPDATE_TITLE_IMAGE,
  UPDATE_TITLE_VIDEO,
  UPLOAD_TITLE_VIDEO,
  EDIT_TITLE,
  UPDATE_SERIES_VIDEO,
  UPDATE_TRAILER_VIDEO,
  IMPORT_TITLE_VIDEO,
  UPDATE_CATEGORY_TITLE
} from "./actionTypes"

import {
  getTitleSuccess,
  getTitleFail,
  addTitleSuccess,
  updateTitleimage,
  updateCategoryTitles,
  updateTitleImageSuccess,
  updateTitleImageFail,
  updateTitleVideoSuccess,
  updateTitleVideoFail,
  uploadTitleVideoSuccess,
  uploadTitleVideoFail,
  updateTitleSuccess,
  updateTitleFail,
  deleteTitleSuccess,
  deleteTitleFail,
  editTitleSuccess,
  editTitleFail,
  updateTitlePosterSuccess,
  updateTrailerVideoFail,
  updateTrailerVideoSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getTitle,
  addNewTitle,
  updateTitle,
  deleteTitle,
  updateTitleImage,
  updateTitleVideo,
  updateCategoryTitle,
  uploadTitleVideo,
  importTitleVideo,
  editTitle,
  updateSeriesVideo,
  uploadSeriesVideo,
  updateTitlePoster,
  updateTrailerVideo,
} from "../../helpers/fakebackend_helper"
import { stopLoader } from "../loaderState/actions"
import { checkArray, checkNull } from "../../helpers/basicFunctions"

function* fetchTitle({ payload }) {
  try {
    const response = yield call(getTitle, payload.page, payload.countries, payload.language, payload.sortKey, payload.sortType)
    yield put(getTitleSuccess(response))
    yield put(stopLoader())
  } catch (error) {
    yield put(getTitleFail(error))
  }
}

function* onEditTitle({ payload: title }) {
  try {
    const response = yield call(editTitle, title)
    yield put(editTitleSuccess(response))
    yield put(stopLoader())
  } catch (error) {
    yield put(editTitleFail(error))
  }
}

function* onUpdateTitle({ payload: { titles, history } }) {
  try {
    const title = titles.title
    const type = titles.type
    const genres = titles.genres.map(genre => genre.value)
    const categories = titles.categories
    const tags = titles.tags
    const year = titles.year
    const description = titles.description
    const casts = titles.casts.map(cast => cast.value)
    const tagline = titles.tagline
    const running_time = titles.running_time
    const country_of_origin = titles.country_of_origin
    const language = titles.language
    const copyright = titles.copyright
    const studio = titles.studio
    const sku = titles.sku
    const imdb = titles.imdb
    const included_countries = titles.included_countries.map(
      included_country => included_country.value
    )
    const excluded_countries = titles.excluded_countries.map(
      excluded_country => excluded_country.value
    )
    const isActive = titles.isActive == 1 ? true : false
    const isAgeRestriction = titles.isAgeRestriction

    const addToDashboard = titles.addToDashboard
    const isDefaultSearchInclude = titles.isDefaultSearchInclude

    const rankTitle = titles.rankTitle

    const image = titles.imageNew
    const poster = titles.poster
    const response = yield call(updateTitle, titles.id, {
      title,
      type,
      genres,
      categories,
      tags,
      year,
      description,
      casts,
      tagline,
      running_time,
      country_of_origin,
      language,
      copyright,
      studio,
      sku,
      imdb,
      included_countries,
      excluded_countries,
      isActive,
      isAgeRestriction,
      addToDashboard,
      isDefaultSearchInclude,
      rankTitle
    })
    let categoryData = response.data.categories.map((v) => v.id);
    yield put(updateCategoryTitles(categoryData, response.data.id))
    yield put(updateTitleSuccess(titles))

    const catId = titles.id
    if (checkNull(image, false)) {
      yield put(updateTitleimage(catId, image))
    }
    if (checkNull(poster, false) && typeof poster === "object") {
      yield put(updateTitleimage(catId, poster, "poster"))
    }
    toast.success(response.message, {
      position: toast.POSITION.TOP_RIGHT,
    })
    history.push("/titles")
  } catch (error) {
    toast.error(error.message, {
      position: toast.POSITION.TOP_LEFT,
    })
    yield put(updateTitleFail(error))
  }
}

function* onDeleteTitle({ payload: { title, history } }) {
  try {
    const response = yield call(deleteTitle, title)
    if (response.status) {
      toast.success("Title Delete Successfully")
    }
    yield put(deleteTitleSuccess(title))
    history.push("/titles")
  } catch (error) {
    yield put(deleteTitleFail(error))
  }
}

function* onAddNewTitle({ payload: { titles, history } }) {
  try {
    const title = titles.title
    const type = titles.type
    const genres = titles.genres.map(genre => genre.value)
    const tags = titles.tags
    const year = titles.year
    const description = titles.description
    const casts = titles.casts.map(cast => cast.value)
    const tagline = titles.tagline
    const running_time = titles.running_time
    const country_of_origin = titles.country_of_origin
    const language = titles.language
    const copyright = titles.copyright
    const studio = titles.studio
    const sku = titles.sku
    const imdb = titles.imdb
    const included_countries = titles.included_countries.map(
      included_country => included_country.value
    )
    const excluded_countries = titles.excluded_countries.map(
      excluded_country => excluded_country.value
    )
    const isActive = titles.isActive == 1 ? true : false
    const isAgeRestriction = titles.isAgeRestriction
    const addToDashboard = titles.addToDashboard
    const isDefaultSearchInclude = titles.isDefaultSearchInclude


    const image = titles.imageNew
    const poster = titles.poster
    const categories = titles.categories
    const response = yield call(addNewTitle, {
      title,
      type,
      genres,
      categories,
      tags,
      year,
      description,
      casts,
      tagline,
      running_time,
      country_of_origin,
      language,
      copyright,
      studio,
      sku,
      imdb,
      included_countries,
      excluded_countries,
      isActive,
      isAgeRestriction,
      addToDashboard,
      isDefaultSearchInclude
    })
    yield put(updateCategoryTitles(response.data.data.categories, response.data.data.id))
    // yield put(addTitleSuccess(titles))
    const catId = response.data.data.id
    if (checkNull(image, false)) {
      yield put(updateTitleimage(catId, image))
    }
    if (checkNull(poster, false) && typeof poster === "object") {
      yield put(updateTitleimage(catId, poster, "poster"))
    }
    toast.success(response.data.message, {
      position: toast.POSITION.TOP_RIGHT,
    })
    history.push("/titles")
  } catch (error) {
    toast.error(error.message, {
      position: toast.POSITION.TOP_LEFT,
    })
  }
}

function* onUpdateTitleImage({ payload: { id, image, type } }) {
  try {
    const formData = new FormData()
    formData.append("image", image)
    const response =
      type === "banner"
        ? yield call(updateTitleImage, id, { image })
        : yield call(updateTitlePoster, id, { image })
    if (type === "banner") {
      yield put(updateTitleImageSuccess(response.data.data))
    } else {
      yield put(updateTitlePosterSuccess(response.data.data))
    }
  } catch (error) {
    yield put(updateTitleImageFail(error))
    toast.error(error.message, {
      position: toast.POSITION.TOP_LEFT,
    })
  }
}

function* onUpdateCategoryTitle({ payload: { categories, title } }) {
  let data = { cate_ids: categories, title_id: title }
  try {
    yield call(updateCategoryTitle, data)
  } catch (error) {
    // yield put(updateTitleImageFail(error))
    toast.error(error.message, {
      position: toast.POSITION.TOP_LEFT,
    })
  }
}

function* onUpdateTrailerVideo({
  payload: { id, data, history, trailerIndex },
}) {
  try {
    const titleSingle = yield select(state => state.title?.titleSingle)
    const trailerArr = checkArray(titleSingle?.trailer)
      ? titleSingle?.trailer
        .filter((p, pIdx) => {
          if (trailerIndex !== null) {
            return pIdx != trailerIndex
          } else {
            return true
          }
        })
        .map(trailer => ({
          name: trailer.name,
          thumbnail: trailer.thumbnail,
          file: trailer.file,
          duration: trailer.duration,
        }))
      : []
    if (trailerIndex === null && data?.file) {
      trailerArr.push({
        name: data.title_name,
        thumbnail: data.thumbnail,
        file: data.file,
        duration: data.duration,
      })
    }
    const response = yield call(updateTrailerVideo, id, { trailer: trailerArr })

    if (response.data) {
      if (titleSingle) {
        yield put(
          updateTrailerVideoSuccess({
            ...titleSingle,
            trailer: response?.data?.data?.trailer ?? [],
          })
        )
      }
      history.push(`/title/${id}/uploadTrailers`)
    } else {
      yield put(updateTrailerVideoFail(response))
      toast.error(response, {
        position: toast.POSITION.TOP_LEFT,
      })
    }
  } catch (error) {
    console.log("error :", error)
  }
}

function* onImportTitleVideo({ payload: { file, history } }) {
  try {
    const formData = new FormData()
    formData.append("file", file)
    const response = yield call(importTitleVideo, { file })
    if (response.data) {
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
      setTimeout(() => {
        history.go(0)
      }, 3000)
    } else {
      toast.error(response?.data?.message, {
        position: toast.POSITION.TOP_LEFT,
      })
    }
  } catch (error) {
    toast.error(error.getMessage(), {
      position: toast.POSITION.TOP_LEFT,
    })
  }
}
function* onUpdateTitleVideo({ payload: { id, data, history } }) {
  try {
    const { thumbnail, file, movieId } = data
    const titleSingle = yield select(state => state.title?.titleSingle)

    const response = movieId
      ? yield call(updateTitleVideo, id, {
        thumbnail,
        file,
        movieId,
      })
      : yield call(uploadTitleVideo, id, {
        thumbnail,
        file,
      })

    if (response.data) {
      if (titleSingle) {
        yield put(
          updateTitleVideoSuccess({
            ...titleSingle,
            movie: movieId ? response.data : response.data.data,
          })
        )
      }
      history.push(`/title/${id}/uploadMovie`)
    } else {
      yield put(updateTitleVideoFail(response))
      toast.error(response, {
        position: toast.POSITION.TOP_LEFT,
      })
    }
  } catch (error) {
  }
}

function* onUploadTitleVideo({ payload: { id, data } }) {
  try {
    const { thumbnail, file } = data

    const response = yield call(uploadTitleVideo, id, { thumbnail, file })
    if (response.data) {
      yield put(uploadTitleVideoSuccess(response.data.data))
    } else {
      yield put(uploadTitleVideoFail(response))
      toast.error(response, {
        position: toast.POSITION.TOP_LEFT,
      })
    }
  } catch (error) {
    console.log("error :", error)
  }
}

function* onUploadSeriesVideo({ payload: { id, data, history } }) {
  try {
    const { seasonId, name, episodes } = data

    const response = seasonId
      ? yield call(updateSeriesVideo, id, { seasonId, name, episodes })
      : yield call(uploadSeriesVideo, id, { name, episodes })

    if (response.data) {
      const newSeasonId = seasonId ? seasonId : response?.data?.data?.id
      history.push(`/title/${id}/uploadSeries`, { seasonId: newSeasonId })
      // yield put(updateTitleVideoSuccess(response.data.data))
    } else {
      yield put(updateTitleVideoFail(response))
      toast.error(response, {
        position: toast.POSITION.TOP_LEFT,
      })
    }
  } catch (error) {
    console.log("error :", error)
  }
}

function* TitleSaga() {
  yield takeEvery(GET_TITLE, fetchTitle)
  yield takeEvery(ADD_NEW_TITLE, onAddNewTitle)
  yield takeEvery(UPDATE_TITLE, onUpdateTitle)
  yield takeEvery(DELETE_TITLE, onDeleteTitle)
  yield takeEvery(EDIT_TITLE, onEditTitle)
  yield takeEvery(UPDATE_TITLE_IMAGE, onUpdateTitleImage)
  yield takeEvery(IMPORT_TITLE_VIDEO, onImportTitleVideo)
  yield takeEvery(UPDATE_TITLE_VIDEO, onUpdateTitleVideo)
  yield takeEvery(UPDATE_TRAILER_VIDEO, onUpdateTrailerVideo)
  yield takeEvery(UPLOAD_TITLE_VIDEO, onUploadTitleVideo)
  yield takeEvery(UPDATE_SERIES_VIDEO, onUploadSeriesVideo)
  yield takeEvery(UPDATE_CATEGORY_TITLE, onUpdateCategoryTitle)
}

export default TitleSaga
