import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
import {
    Card,
    CardBody,
    Col,
    DropdownMenu,
    DropdownToggle,
    Form,
    Row,
    Dropdown,
    DropdownItem,
    UncontrolledDropdown,
} from "reactstrap";
import { updateFiles } from "../../store/bucket/slice";
import FullPageLoader from "../../components/Common/FullPageLoader";
import { getBucketFilesData, updateSubtitle, upload } from "../../store/bucket/action";
import Button from 'react-bootstrap/Button';
import AWS from 'aws-sdk';
import { toast } from "react-toastify";
import { toastConfirm } from "../../common/toast";
import { deleteTitle } from "../../store/actions";
import { setTokenHeader } from "../../helpers/api_helper";
import { ModalHeader, ModalBody, Modal } from "react-bootstrap";
import { CCard, CCardBody, CCardGroup } from "@coreui/react";
import { FaTimes } from 'react-icons/fa';

const createS3Client = () => {
    AWS.config.update({
        accessKeyId: `${process.env.REACT_APP_S3_DOWNLOAD_ACCESS_KEY}`,
        secretAccessKey: `${process.env.REACT_APP_S3_DOWNLOAD_SECRET_ACCESS_KEY}`,
    });

    return new AWS.S3();
};

const FileList = React.memo((data) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const [objectList, setObjectList] = useState([]);
    const [bucketData, setBucketData] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [uploadtrue, setUploadtrue] = useState(false);
    const { filesData, BucketFiles, downloadStatus } = useSelector((state) => state.BucketList);
    const [loadingStates, setLoadingStates] = useState({});
    const [filterValue, setFilterValue] = useState('');
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isModelOpen, setModelOpen] = useState(false);
    const toggle = () => setModelOpen(!isModelOpen);

    const [formData, setFormData] = useState({
        notification: "",
        folderName: "",
        isRefresh: "",
        time: ""
    });

    useEffect(() => {
        dispatch(getBucketFilesData())
        const data = JSON.parse(sessionStorage.getItem("authUser"))
        setTokenHeader(data.access_token)
    }, [])

    useEffect(() => {
        if (BucketFiles) {
            setObjectList(BucketFiles);
            setBucketData(BucketFiles)
        }
    }, [BucketFiles])

    const handleDetails = useCallback((folder, files) => {
        dispatch(updateFiles(files));
        history.push(`/bucket-details/${folder.toString()}`);
    }, [dispatch, history]);

    const handleSearch = useCallback((event) => {
        const inputValue = event.target.value.toLowerCase();
        setInputValue(inputValue);
        const newArr = objectList.movienowzupload.filter((item) => {
            const folderName = item.folder.toLowerCase();
            return folderName.includes(inputValue);
        });
        setBucketData({ movienowzupload: newArr })
    }, [objectList]);

    const handleFilterChange = useCallback((value) => {
        setFilterValue(value);
        let filterData;
        if (value === 'Active') {
            filterData = BucketFiles && BucketFiles.movienowzupload.filter((v) => {
                return v.isExist;
            });
        } else if (value === 'Inactive') {
            filterData = BucketFiles && BucketFiles.movienowzupload.filter((v) => {
                return !v.isExist;
            });
        } else {
            filterData = BucketFiles && BucketFiles.movienowzupload;
        }

        setBucketData({
            ...bucketData,
            movienowzupload: filterData || [],
        });
    }, [BucketFiles]);

    const toggleDropdown = () => {
        setDropdownOpen(prevState => !prevState);
    };

    const handleDelete = async (id, folderName, index) => {
        const newarray = bucketData.movienowzupload.filter((v, i) => i !== index)
        const s3 = createS3Client();
        try {
            const response = await (
                await toastConfirm(`Are you sure you want to delete this from <b><i>App</i></b> and <b><i>Filmhub</i></b> bucket`)
            ).fire()

            if (response.isConfirmed) {
                const listParams = {
                    Bucket: process.env.REACT_APP_S3_DOWNLOAD_BUCKET_NAME,
                    Prefix: `filmhub/${folderName}/`
                };

                const listedObjects = await s3.listObjectsV2(listParams).promise();

                if (listedObjects.Contents.length === 0) {
                    toast.error("Folder is already empty")
                    return;
                }

                const deleteParams = {
                    Bucket: process.env.REACT_APP_S3_DOWNLOAD_BUCKET_NAME,
                    Delete: { Objects: [] }
                };

                listedObjects.Contents.forEach(({ Key }) => {
                    deleteParams.Delete.Objects.push({ Key });
                });

                await s3.deleteObjects(deleteParams).promise();

                if (id) {
                    dispatch(deleteTitle(id, history))
                }

                setBucketData({
                    ...bucketData,
                    movienowzupload: newarray || [],
                });
                console.log(`Folder '${folderName}' and its contents have been deleted successfully.`);
            }
        } catch (err) {
            console.error(`Error deleting folder '${folderName}' and its contents:`, err);
        }
    };

    const getFile = async (filePath, s3, isRefresh, isSubtitle = false) => {
        try {
            const listObjectsParams = {
                Bucket: `${process.env.REACT_APP_S3_DOWNLOAD_BUCKET_NAME}`,
                Prefix: `filmhub/${filePath}`,
            };

            const listObjectsResponse = await s3.listObjectsV2(listObjectsParams).promise();
            const yamlExtensions = ["yaml"];
            let destinationKey;
            if (isSubtitle !== true) {
                await invokeLambdaFunction(listObjectsResponse)
                // if (!result) {
                //     toast.error("Something Went Wrong !!");
                //     throw new Error("Something went wrong");
                // }
            }

            for (const object of listObjectsResponse.Contents) {
                const sourceKey = object.Key;
                const extension = sourceKey.split(".").pop();

                if (yamlExtensions.includes(extension)) {
                    destinationKey = sourceKey;
                    break;
                }
            }

            if (!destinationKey) {
                throw new Error("YAML file not found in the specified folder.");
            }

            const getObjectParams = {
                Bucket: `${process.env.REACT_APP_S3_DOWNLOAD_BUCKET_NAME}`,
                Key: destinationKey,
            };

            const getObjectResponse = await s3.getObject(getObjectParams).promise();
            const yamlData = getObjectResponse.Body.toString(); // Convert the data to a string
            return yamlData;
        } catch (error) {
            console.error('Error retrieving YAML file from S3:', error);
            throw error;
        }
    };

    const invokeLambdaFunction = async (folderName) => {
        try {
            AWS.config.update({
                accessKeyId: `${process.env.REACT_APP_S3_ACCESS_KEY}`,
                secretAccessKey: `${process.env.REACT_APP_S3_SECRET_ACCESS_KEY}`,

            });
            const lambda = new AWS.Lambda({ region: 'us-east-1' });
            const movieExtensions = ["mp4"];

            for (const object of folderName.Contents) {
                const sourceKey = object.Key;
                const extension = sourceKey.split(".").pop();
                if (movieExtensions.includes(extension)) {
                    const params = {
                        FunctionName: 'VODLambdaConvert',
                        InvocationType: 'RequestResponse',
                        Payload: JSON.stringify({ inputVideoKey: sourceKey }),
                    };
                    lambda.invoke(params, (err, data) => {
                        if (err) {
                            console.error("err", err);
                            setLoadingStates((prevLoadingStates) => ({
                                ...prevLoadingStates,
                                [folderName]: false,
                            }));
                            setUploadtrue((prevLoadingStates) => ({
                                ...prevLoadingStates,
                                [folderName]: false,
                            }))
                            return false
                        } else {
                            console.log("success", data.Payload);
                            return true
                        }
                    });
                }
            }
        } catch (error) {
            console.error(`Error invoking Lambda function: ${error}`);
            return false
        }
    };

    const handleUploadServer = async (folderName, isRefresh) => {
        try {
            setLoadingStates((prevLoadingStates) => ({
                ...prevLoadingStates,
                [folderName]: true,
            }));
            setUploadtrue((prevLoadingStates) => ({
                ...prevLoadingStates,
                [folderName]: false,
            }))
            const s3 = createS3Client();

            const yamlFile = await getFile(folderName, s3, isRefresh);

            if (!isRefresh) {
                if (yamlFile) {
                    console.log("GET YAML file")
                }
                const data = {
                    folderName: folderName,
                    yamlFile: yamlFile,
                    notification: formData.notification,
                    time:formData.time
                };

                const uploadYaml = await dispatch(upload(data)).then((v) => {
                    if (v.payload[0] === false) {
                        toast.error(`${JSON.parse(v.payload[1]).name},\n${JSON.parse(v.payload[1]).message}`);
                        return false
                    }
                });
                if (uploadYaml === false) {
                    throw new Error("YAML file not uploaded");
                }
            }

            setLoadingStates((prevLoadingStates) => ({
                ...prevLoadingStates,
                [folderName]: false,
            }));
            setUploadtrue((prevLoadingStates) => ({
                ...prevLoadingStates,
                [folderName]: true,
            }))
            toast.success("The file import and conversion has been initiated, it should be available as soon as completed. It may take upto an hour.")
        } catch (error) {
            setLoadingStates((prevLoadingStates) => ({
                ...prevLoadingStates,
                [folderName]: false,
            }));
            console.error('Error handling upload:', error);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault()
        if (formData.notification !== "") {
            setModelOpen(false),
                handleUploadServer(formData.folderName, formData.isRefresh)
        }
        setFormData({ ...formData, folderName: "", isRefresh: "", notification: "" })
    }

    const handleUpdateSubtitle = async (folderName, isRefresh) => {
        try {
            setLoadingStates((prevLoadingStates) => ({
                ...prevLoadingStates,
                [folderName]: true,
            }));
            setUploadtrue((prevLoadingStates) => ({
                ...prevLoadingStates,
                [folderName]: false,
            }))
            const s3 = createS3Client();

            const yamlFile = await getFile(folderName, s3, isRefresh, true);

            if (!isRefresh) {
                if (yamlFile) {
                    console.log("GET YAML file")
                }
                const data = {
                    folderName: folderName,
                    yamlFile: yamlFile,
                };

                const uploadYaml = await dispatch(updateSubtitle(data)).then((v) => {
                    if (v.payload[0] === false) {
                        toast.error(v.payload[1])
                        return false
                    }
                });
                if (uploadYaml === false) {
                    throw new Error("YAML file not uploaded");
                }
                console.log("Uploaded YAML File")
            }

            setLoadingStates((prevLoadingStates) => ({
                ...prevLoadingStates,
                [folderName]: false,
            }));
            setUploadtrue((prevLoadingStates) => ({
                ...prevLoadingStates,
                [folderName]: true,
            }))
            toast.success("Title Subtitles update Successfully")
        } catch (error) {
            setLoadingStates((prevLoadingStates) => ({
                ...prevLoadingStates,
                [folderName]: false,
            }));
            console.error('Error handling upload:', error);
        }
    }

    return (
        <>
            <div>
                <Row className="mb-3">
                    <Col xl={3} sm={6}>
                        <div className="mt-2">
                            <h5>Folders</h5>
                        </div>
                    </Col>
                    <Col xl={9} sm={6} className="d-flex align-items-center justify-content-end">
                        <div className="d-flex align-items-center">
                            <Form className="ms-3 d-flex align-items-center">
                                <div className="search-box mb-2 ">
                                    <div className="position-relative">
                                        <input
                                            type="text"
                                            value={inputValue}
                                            className="form-control bg-light border-light rounded"
                                            placeholder="Search..."
                                            onChange={handleSearch}
                                        />
                                        <i className="bx bx-search-alt search-icon"></i>
                                    </div>
                                </div>
                            </Form>
                            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} style={{ marginLeft: '5px', marginBottom: '7px' }}>
                                <DropdownToggle caret>
                                    {filterValue || 'Filter Title'}
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => handleFilterChange('Active')}>
                                        Added
                                    </DropdownItem>
                                    <DropdownItem onClick={() => handleFilterChange('Inactive')}>
                                        Not Added
                                    </DropdownItem>
                                    <DropdownItem onClick={() => handleFilterChange('')}>
                                        All
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    </Col>
                </Row>
            </div>
            <div>
                {bucketData.movienowzupload && bucketData.movienowzupload.length > 0 ? (
                    <Row>
                        {bucketData.movienowzupload.map((myFile, index) => (
                            <Col xl={4} sm={6} key={index + 1}>
                                <Card className="shadow-none border">
                                    <CardBody className="p-3">
                                        <div className="">
                                            <div className="d-flex avatar-xs me-3 mb-3">
                                                <div className="avatar-title bg-transparent rounded">
                                                    <span onClick={() => handleDetails(myFile.folder, myFile.files)}>
                                                        <i className="bx bxs-folder font-size-24 text-warning"></i>
                                                    </span>
                                                </div>
                                                {
                                                    myFile.isExist ?
                                                        < >
                                                            <span onClick={() => handleUploadServer(myFile.folder, true)}
                                                                className="align-self-end ms-2"
                                                                style={{ cursor: 'pointer' }}
                                                                aria-labelledby="Refresh"
                                                                title="Refresh">
                                                                <i className="bx bx-repeat font-size-24 text-success"></i>
                                                            </span>
                                                            <span onClick={() => handleUpdateSubtitle(myFile.folder, false)}
                                                                className="align-self-end ms-2"
                                                                style={{ cursor: 'pointer' }}
                                                                aria-labelledby="Update Subtitle"
                                                                title="Update Subtitle">
                                                                <i className="bx bx-video-plus font-size-24 text-info"></i>
                                                            </span>
                                                        </>
                                                        :
                                                        ""
                                                }

                                            </div>
                                            <div className="d-flex align-items-center justify-content-between cursor">
                                                <div className="overflow-hidden">
                                                    <h5 onClick={() => handleDetails(myFile.folder, myFile.files)} style={{ cursor: 'pointer' }} className="font-size-14 text-truncate mb-1">
                                                        <span className="text-body">
                                                            {myFile.folder}
                                                        </span>
                                                    </h5>
                                                    <p className="text-muted text-truncate mb-0">
                                                        <span onClick={() => handleDetails(myFile.folder, myFile.files)}>
                                                            {myFile.files.length} Files
                                                        </span>
                                                    </p>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    {myFile.isExist ? (
                                                        <Button disabled size="sm" variant="success">
                                                            <i className="bx bx-check font-size-10 p-0"></i>
                                                        </Button>
                                                    ) : uploadtrue[myFile.folder] ? (
                                                        <Button disabled size="sm" variant="success">
                                                            <i className="bx bx-check font-size-10"></i>
                                                        </Button>
                                                    ) : (
                                                        <Button onClick={() => {
                                                            setModelOpen(true),
                                                                setFormData({ ...formData, folderName: myFile.folder, isRefresh: false })
                                                            // handleUploadServer(myFile.folder, false)
                                                        }
                                                        } title="Add Movie" size="sm" variant="success">
                                                            {loadingStates[myFile.folder] ? (
                                                                <Spinner animation="border" size="sm" />
                                                            ) : (
                                                                <i className="bx bx-plus-medical font-size-10"></i>
                                                            )}
                                                        </Button>
                                                    )}
                                                    <Button onClick={() => handleDelete(myFile.id, myFile.folder, index)} title="Delete Movie" size="sm" variant="danger" className="ml-2">
                                                        <i className="bx bxs-trash-alt font-size-10"></i>
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                ) : (
                    <Row>
                        <Card className="shadow-none border">
                            <CardBody className="p-3 text-center">
                                <Spinner animation="grow" variant="primary" size="lg" />
                                <Spinner animation="grow" variant="secondary" size="lg" />
                                <Spinner animation="grow" variant="success" size="lg" />
                                <Spinner animation="grow" variant="dark" size="lg" />
                            </CardBody>
                        </Card>
                    </Row>
                )}

                <Modal show={isModelOpen}
                    onHide={() => setModelOpen(false)}
                    role="dialog"
                    autoFocus={true}
                    centered={true}>
                    <div className="modal bs-example-modal" role="dialog"></div>
                    <div className="modal-content">
                        <ModalHeader>
                            <h5 className="font-size-14">Send Notification</h5>
                            <Button variant="link" className="btn-close" onClick={() => setModelOpen(false)}>
                                {/* <FaTimes /> */}
                            </Button>
                        </ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col xl={12}>
                                    <CCardGroup>
                                        <CCard>
                                            <CCardBody>
                                                <form onSubmit={handleSubmit}>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <label className="form-label">Send notification to user ?</label>
                                                            <div className="d-flex">
                                                                <div className="form-check" style={{ marginRight: '10px' }}>
                                                                    <input className="form-check-input"
                                                                        type="radio"
                                                                        name="exampleRadios"
                                                                        onChange={(e) => setFormData({ ...formData, notification: e.target.value })}
                                                                        id="exampleRadios1"
                                                                        value="1"
                                                                        required />
                                                                    <label className="form-check-label" htmlFor="exampleRadios1">Yes</label>
                                                                </div>
                                                                <div className="form-check" style={{ marginRight: '10px' }}>
                                                                    <input className="form-check-input"
                                                                        type="radio"
                                                                        name="exampleRadios"
                                                                        onChange={(e) => setFormData({ ...formData, notification: e.target.value })}
                                                                        id="exampleRadios2"
                                                                        value="2"
                                                                        required />
                                                                    <label className="form-check-label" htmlFor="exampleRadios2">No</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {formData.notification === '1' && (
                                                        <>
                                                            <div className="row mt-4 mb-4">
                                                                <div className="col-md-12">
                                                                    <label className="form-label">Time</label>
                                                                    <div className="d-flex">
                                                                        <div className="form-check" style={{ marginRight: '10px' }}>
                                                                            <input className="form-check-input"
                                                                                type="radio"
                                                                                name="time"
                                                                                onChange={(e) => setFormData({ ...formData, time: e.target.value })}
                                                                                id="time"
                                                                                value="0"
                                                                                required />
                                                                            <label className="form-check-label" htmlFor="time">Now</label>
                                                                        </div>
                                                                        <div className="form-check" style={{ marginRight: '10px' }}>
                                                                            <input className="form-check-input"
                                                                                type="radio"
                                                                                name="time"
                                                                                onChange={(e) => setFormData({ ...formData, notification: e.target.value })}
                                                                                id="time1"
                                                                                value="1"
                                                                                required />
                                                                            <label className="form-check-label" htmlFor="time1">1 Hr later</label>
                                                                        </div>
                                                                        <div className="form-check" style={{ marginRight: '10px' }}>
                                                                            <input className="form-check-input"
                                                                                type="radio"
                                                                                name="time"
                                                                                onChange={(e) => setFormData({ ...formData, time: e.target.value })}
                                                                                id="time2"
                                                                                value="2"
                                                                                required />
                                                                            <label className="form-check-label" htmlFor="time2">2 Hr later</label>
                                                                        </div>
                                                                        <div className="form-check" style={{ marginRight: '10px' }}>
                                                                            <input className="form-check-input"
                                                                                type="radio"
                                                                                name="time"
                                                                                onChange={(e) => setFormData({ ...formData, time: e.target.value })}
                                                                                id="time3"
                                                                                value="3"
                                                                                required />
                                                                            <label className="form-check-label" htmlFor="time3">3 Hr later</label>
                                                                        </div>
                                                                        <div className="form-check" style={{ marginRight: '10px' }}>
                                                                            <input className="form-check-input"
                                                                                type="radio"
                                                                                name="time"
                                                                                onChange={(e) => setFormData({ ...formData, time: e.target.value })}
                                                                                id="time4"
                                                                                value="2"
                                                                                required />
                                                                            <label className="form-check-label" htmlFor="time4">4 Hr later</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}

                                                    <div className="float-end">
                                                        <button type="submit" className="btn btn-sm btn-primary w-md">Submit</button>
                                                    </div>
                                                </form>
                                            </CCardBody>
                                        </CCard>
                                    </CCardGroup>
                                </Col>
                            </Row>
                        </ModalBody>
                    </div>
                </Modal>
            </div>
        </>
    );
});
FileList.displayName = 'FileList';
export default FileList;