import React, { useEffect, useState } from "react";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import DatePicker from "react-datepicker";
import DataTable from "react-data-table-component";
import { Row, Col } from "reactstrap";
import axios from "axios";
import moment from "moment";
import { useHistory } from "react-router-dom";
import SelectAsyncPaginate from "../../ManageTitles/TitleAdd/SelectAsyncPaginate";
import { checkArray, checkNull } from "../../../helpers/basicFunctions";
import { useSelector, useDispatch } from "react-redux";
import {
  getTitleLogReport,
  TitleLogReportDetails,
} from "../../../store/title/toolkit/action";
import { CCard, CCardBody, CCardGroup } from "@coreui/react";
import FullPageLoader from "../../../components/Common/FullPageLoader";
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
import { setTokenHeader } from "../../../helpers/api_helper";
import { updateWatchedDurationType } from "../../../store/title/toolkit/slice";

const TitleLog = () => {
  const API_URL = `${process.env.REACT_APP_ZFLIX_URL}/v1`;
  document.title = "Log Report";
  const history = useHistory();
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(
    moment().startOf("month").toDate()
  );
  const [endDate, setEndDate] = useState(new Date());
  const { loadingState } = useSelector((state) => state.loadingState);
  const { logReport, logReportDetails, watchedDurationType } = useSelector(
    (state) => state.TitleSlice
  );
  const [isLoading, setIsLoading] = useState(true);
  const [totalWatchedTime, setTotalWatchedTime] = useState("0h 0m 0s");
  const [logData, setLogData] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [filterValue, setFilterValue] = useState(watchedDurationType);

  useEffect(() => {
    fetchData();
  }, [selectedUser, filterValue]);

  useEffect(() => {
    console.log("checkl something",logReportDetails)
    let totalHrs = logReportDetails?.reduce((total, item) => {
      return total + (Number(item?.watchTime )|| 0);
    }, 0);

    const hours = Math.floor(totalHrs/ 3600);
        const minutes = Math.floor((totalHrs% 3600) / 60);
        const seconds = totalHrs% 60;
    totalHrs = `${hours}h ${minutes}m ${seconds}s`;
    setTotalWatchedTime(totalHrs);
  }, [logReportDetails]);

  useEffect(() => {
    if (startDate && endDate) {
      fetchData();
    }
  }, [startDate, endDate]);

  function fetchData() {
    const data = JSON.parse(sessionStorage.getItem("authUser"));
    setTokenHeader(data.access_token);
    if (selectedUser.length > 0) {
      dispatch(
        TitleLogReportDetails({
          name: "",
          start: "",
          end: "",
          search: selectedUser[0]?.value,
          type: filterValue,
        })
      );
    }
    if (selectedUser[0] === null || selectedUser.length <= 0) {
      let starttime = new Date(startDate);
      let startmilliseconds = starttime.getTime();

      let endtime = new Date(endDate);
      let endmilliseconds = endtime.getTime();
      dispatch(
        getTitleLogReport({
          start: startmilliseconds,
          end: endmilliseconds,
          type: filterValue,
        })
      );
    }
  }

  useEffect(() => {
    setLogData(logReport?.data);
    setIsLoading(false);
    let totalHrs = logReport?.data?.reduce((total, item) => {
      return total + (item?.totalWatchTime || 0);
    }, 0);

    const hours = Math.floor(totalHrs / 3600);
    const minutes = Math.floor((totalHrs % 3600) / 60);
    const seconds = totalHrs % 60;
    totalHrs = `${hours}h ${minutes}m ${seconds}s`;
    setTotalWatchedTime(totalHrs);
  }, [logReport]);

  const toggleDropdown = () => {
    setDropdownOpen((prevState) => !prevState);
  };

  const handleFilterChange = (value) => {
    setFilterValue(value);
    dispatch(updateWatchedDurationType({ type: value }));
  };

  const handleViewDetails = (title) => {
    dispatch(
      TitleLogReportDetails({
        name: title,
        start: startDate,
        end: endDate,
        type: filterValue,
      })
    );
    history.push(`/title-log/details`);
  };

  const onChange = (dates) => {
    if (Array.isArray(dates) && dates.length === 2) {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    }
  };

  const loadUserOptions = async (search, loadedOptions, { page }) => {
    try {
      let optUrl = `${process.env.REACT_APP_ZFLIX_URL}/v1/log-report/users?page=${page}`;
      if (checkNull(search, false)) {
        optUrl = optUrl + "&search=" + search;
      }
      const response = await axios.get(optUrl, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("authUser")).access_token
          }`,
          "Content-Type": "application/json",
        },
      });
      const { data } = response.data;
      const newOptions = checkArray(data.results)
        ? data.results.map((result) => ({
            value: result.email,
            name: result.email,
          }))
        : [];
      const hasMore = page < data.totalPages;

      return {
        options: [...loadedOptions, ...newOptions],
        hasMore,
        additional: { page: page + 1 },
      };
    } catch (error) {
      console.error("Error fetching user options:", error);
      return { options: [], hasMore: false, additional: { page: 1 } };
    }
  };

  const exportReport = () => {
    const authUser = JSON.parse(sessionStorage.getItem("authUser"));

    axios
      .post(
        `${API_URL}/log-report/download-csv`,
        { start: startDate, end: endDate, type: filterValue },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authUser.access_token}`,
          },
        }
      )
      .then((response) => {
        const link = document.createElement("a");
        link.setAttribute("href", response.data.redirection);
        link.setAttribute("download", "report.csv");
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => console.error("Error exporting report:", error));
  };

  const columns = [
    {
      name: "Sr. no",
      selector: (row, index) => index + 1,
      sortable: true,
      maxWidth: "100px",
    },
    { name: "Title Name", selector: (row) => row.movieTitle, sortable: true },
    {
      name: "Watched Duration",
      selector: (row) => {
        const hours = Math.floor(row.totalWatchTime / 3600);
        const minutes = Math.floor((row.totalWatchTime % 3600) / 60);
        const seconds = row.totalWatchTime % 60;
        return `${hours}h ${minutes}m ${seconds}s`;
      },
      sortable: true,
    },
    {
      name: "Filmhub ID",
      selector: (row) => row.filmhubId || "",
      sortable: true,
    },
    { name: "Users Count", selector: (row) => row.count || "", sortable: true },
    {
      name: "Yesterday Watched Minutes",
      selector: (row) => {
        const hours = Math.floor(row.yesterday_watched_minutes / 3600);
        const minutes = Math.floor((row.yesterday_watched_minutes % 3600) / 60);
        const seconds = row.yesterday_watched_minutes % 60;
        return `${hours}h ${minutes}m ${seconds}s`;
      },
      sortable: true,
    },
    {
      name: "Today Watched Minutes",
      selector: (row) => {
        const hours = Math.floor(row.today_watched_minutes / 3600);
        const minutes = Math.floor((row.today_watched_minutes % 3600) / 60);
        const seconds = row.today_watched_minutes % 60;
        return `${hours}h ${minutes}m ${seconds}s`;
      },
      sortable: true,
    },
    {
      name: "Actions",
      selector: (row) => (
        <Button
          onClick={() => handleViewDetails(row.movieTitle)}
          className="btn btn-info btn-sm"
        >
          View
        </Button>
      ),
      sortable: true,
    },
  ];

  const Filtercolumns = [
    {
      name: "Sr. no",
      selector: (row, index) => index + 1,
      sortable: true,
      maxWidth: "100px",
    },
    {
      name: "Email",
      selector: (row) => (row.email ? row.email : "N/A"),
      sortable: true,
    },
    {
      name: "Title Name",
      selector: (row) => row.movieTitle,
      sortable: true,
    },
    {
      name: "Watched Duration",
      selector: (row) => {
        const hours = Math.floor(row.watchTime / 3600);
        const minutes = Math.floor((row.watchTime % 3600) / 60);
        const seconds = row.watchTime % 60;

        return `${hours}h ${minutes}m ${seconds}s`;
      },
      sortable: true,
    },
    {
      name: "Filmhub ID",
      selector: (row) => (row.filmhubId ? row.filmhubId : ""),
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) =>
        row.created_at ? new Date(row.created_at).toLocaleString() : "",
      sortable: true,
    },
  ];

  if (!isLoading && !logReport?.data) {
    return <FullPageLoader loading={true} />;
  }

  return (
    <div className="page-content genre">
      <div className="container-fluid">
        <Breadcrumbs title="Log Report" breadcrumbItem="Manage Log Report" />
        <Row>
          <Col xl={12}>
            <div className="d-flex align-items-start justify-content-between">
              <div className="select-user">
                <SelectAsyncPaginate
                  className="user"
                  loadOptions={loadUserOptions}
                  placeHolder="Select Users"
                  isMulti={false}
                  value={selectedUser}
                  onChange={(user) => setSelectedUser([user])}
                  isSearchable={true}
                  isClearable={true}
                />
              </div>
              <div className="d-flex align-items-center justify-content-between">
              <div className="total-hours">
                <span className="text-dark">Total Watched Hours: </span>
                <span className="">{totalWatchedTime}</span>
              </div>
              </div>

              <div className="d-flex align-items-center">
                <div className="ml-2">
                  <DatePicker
                    className="form-control p-1"
                    selected={startDate}
                    onChange={onChange}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                  />
                </div>

                <div className="ml-2">
                  <Button
                    className="btn btn-sm btn-info"
                    onClick={exportReport}
                  >
                    Export CSV
                  </Button>
                </div>
              </div>
            </div>

            <CCardGroup className="CCardGroup">
              <CCard>
                <CCardBody>
                  <Dropdown
                    isOpen={dropdownOpen}
                    toggle={toggleDropdown}
                    className="d-flex align-items-center justify-content-end mb-2"
                  >
                    <DropdownToggle caret>
                      {filterValue.toUpperCase() || "Filter"}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={() => handleFilterChange("title")}>
                        Title
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => handleFilterChange("trailer")}
                      >
                        Trailer
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                  {selectedUser.length > 0 && selectedUser[0] !== null ? (
                    <DataTable
                      striped
                      columns={Filtercolumns}
                      data={logReportDetails}
                      highlightOnHover
                    />
                  ) : (
                    <DataTable
                      striped
                      columns={columns}
                      data={logData}
                      highlightOnHover
                    />
                  )}
                </CCardBody>
              </CCard>
            </CCardGroup>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default TitleLog;
