import {
  GET_PLAN,
  GET_PLAN_FAIL,
  GET_PLAN_SUCCESS,
  ADD_NEW_PLAN,
  ADD_PLAN_SUCCESS,
  ADD_PLAN_FAIL,
  UPDATE_PLAN,
  UPDATE_PLAN_SUCCESS,
  UPDATE_PLAN_FAIL,
  EDIT_PLAN,
  EDIT_PLAN_SUCCESS,
  EDIT_PLAN_FAIL,
  DELETE_PLAN,
  DELETE_PLAN_SUCCESS,
  DELETE_PLAN_FAIL,
} from "./actionTypes"

export const getPlan = (page =1) => ({
  type: GET_PLAN,
  payload: {page},

})

export const getPlanSuccess = plan => ({
  type: GET_PLAN_SUCCESS,
  payload: plan,
})

export const addNewPlan = (plan,history) => ({
  type: ADD_NEW_PLAN,
  payload: {plan,history},
})

export const addPlanSuccess = plan => ({
  type: ADD_PLAN_SUCCESS,
  payload: plan,
})

export const addPlanFail = error => ({
  type: ADD_PLAN_FAIL,
  payload: error,
})

export const getPlanFail = error => ({
  type: GET_PLAN_FAIL,
  payload: error,
})

export const updatePlanSuccess = plan => ({
  type: UPDATE_PLAN_SUCCESS,
  payload: plan,
})

export const updatePlan = (id , plan,history) => ({
  type: UPDATE_PLAN,
  payload: {id , plan,history},
})
export const updatePlanFail = error => ({
  type: UPDATE_PLAN_FAIL,
  payload: error,
})
export const editPlanSuccess = plan => ({
  type: EDIT_PLAN_SUCCESS,
  payload: plan,
})

export const editPlan = (plan) => ({
  type: EDIT_PLAN,
  payload: plan,
})
export const editPlanFail = error => ({
  type: EDIT_PLAN_FAIL,
  payload: error,
})

export const deletePlan = (id,history) => ({
  type: DELETE_PLAN,
  payload: {id,history},
})

export const deletePlanSuccess = id => ({
  type: DELETE_PLAN_SUCCESS,
  payload: id,
})

export const deletePlanFail = error => ({
  type: DELETE_PLAN_FAIL,
  payload: error,
})
