import { deepCopy } from "../../helpers/basicFunctions"
import {
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAIL,
  ADD_CATEGORY_SUCCESS,
  ADD_CATEGORY_FAIL,
  UPDATE_CATEGORY_IMAGE_FAIL,
  UPDATE_CATEGORY_IMAGE_SUCCESS,
  UPDATE_CATEGORY_IMAGE,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAIL,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  categories: [],
  error: {},
}

const category = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload.data,
      }

    case GET_CATEGORIES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: {
          ...state.categories,
          results: [...state.categories.results, action.payload],
        },
      }

    case ADD_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_CATEGORY_SUCCESS:
      const resultCat = deepCopy(state.categories)
      resultCat.results = resultCat.results.map(category => {
        if (category.id.toString() === action.payload.id.toString()) {
          return { category, ...action.payload }
        } else {
          return category
        }
      })
      return {
        ...state,
        categories: resultCat,
      }

    case UPDATE_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: {
          ...state.categories,
          results: [
            ...state.categories.results.filter(
              item => item["id"] !== action.payload
            ),
          ],
        },
      }

    case DELETE_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_CATEGORY_IMAGE:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_CATEGORY_IMAGE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_CATEGORY_IMAGE_SUCCESS:
      return {
        ...state,
        categories: {
          ...state.categories,
          results: state.categories.results.map(item =>
            item?.id == action.payload.id
              ? { ...item, image: action.payload.image }
              : item
          ),
        },
      }

    default:
      return state
  }
}

export default category
