import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Table, Container, Card, CardBody } from "reactstrap";
import Button from 'react-bootstrap/Button';
import { useSelector } from "react-redux";
import AWS from 'aws-sdk';
import Spinner from 'react-bootstrap/Spinner';
import { toast } from "react-toastify";

const Details = () => {
  const history = useHistory();
  const { filesData } = useSelector(state => state.BucketList);
  const [loadingStates, setLoadingStates] = useState({}); // Keep track of loading state for each file

  const handleBack = () => {
    history.goBack()
  }

  const downloadFileFromS3 = (filePath) => {
    setLoadingStates(prevLoadingStates => ({
      ...prevLoadingStates,
      [filePath]: true,
    }));

    const s3 = new AWS.S3();
    AWS.config.update({
      accessKeyId: `${process.env.REACT_APP_S3_DOWNLOAD_ACCESS_KEY}`,
      secretAccessKey: `${process.env.REACT_APP_S3_DOWNLOAD_SECRET_ACCESS_KEY}`,
    });

    const params = {
      Bucket: `${process.env.REACT_APP_S3_DOWNLOAD_BUCKET_NAME}`,
      Key: filePath,
    };

    s3.getObject(params, (err, data) => {
      if (err) {
        console.error('Error downloading file from S3:', err);
        setLoadingStates(prevLoadingStates => ({
          ...prevLoadingStates,
          [filePath]: false,
        }));
        toast.error("Sorry!! Unable to download")
        return;
      }

      const blob = new Blob([data.Body]);
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = filePath.split('/').pop();
      link.click();

      setLoadingStates(prevLoadingStates => ({
        ...prevLoadingStates,
        [filePath]: false,
      }));
    });
  };

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="FilmHub Bucket" breadcrumbItem="Bucket Details" />
        <div className="d-xl-flex">
          <div className="w-100">
            <div className="d-md-flex">
              <div className="w-100">
                <Card>
                  <CardBody>
                    <Button onClick={handleBack} size="sm" variant="secondary">Back</Button>{' '}
                    <div className="mt-4">
                      <div className="table-responsive">
                        <Table className="table align-middle table-nowrap table-hover mb-0">
                          <thead>
                            <tr>
                              <th scope="col">Name</th>
                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filesData.payload && filesData.payload.map((recentFile, index) => (
                              <tr key={index}>
                                <td>
                                  <span className="text-dark fw-medium">
                                    <i className={"mdi mdi-file-document font-size-16 align-middle text-primary me-2"}></i> {recentFile}
                                  </span>
                                </td>
                                <td>
                                  <Button onClick={(e) => downloadFileFromS3(recentFile)} size="sm" variant="success" disabled={loadingStates[recentFile]}>
                                    {loadingStates[recentFile] ? (
                                      <Spinner animation="border" size="sm" />
                                    ) : (
                                      "Download"
                                    )}
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Details;
