import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap"
import Switch from "react-switch";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ErrorMessage, Field, Form, Formik } from "formik"
import * as Yup from "yup"
import { addNewCategory, getCategories } from "../../store/actions"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import SelectAsyncPaginate from "../../pages/ManageTitles/TitleAdd/SelectAsyncPaginate"
import { checkArray, checkIsNum, checkNull } from "../../helpers/basicFunctions"
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';


const EditCategory = props => {
  const dispatch = useDispatch()
  let history = useHistory()
  const { isOpen, toggle, editData } = props
  const [network, setNetwork] = useState({
    id: "",
    name: "",
    titles: [],
    description: "",
    isActive: "",
    sort: "",
    isFree: "",
    image: null,
  })
  const [close, setClose] = useState(true)
  const [isFree, setIsFree] = useState(false)
  useEffect(() => {
    setNetwork({
      id: checkNull(editData?.id),
      name: checkNull(editData?.name),
      sort: checkIsNum(editData?.sort),
      titles: checkArray(editData?.titles)
        ? editData.titles.map(title => ({
          value: title.id,
          name: `${title.title} (${title.year})`,
        }))
        : [],
      description: checkNull(editData?.description),
      isActive: editData?.isActive === true ? "1" : "0",
      image: null,
    })
    setIsFree(editData.isFree);
  }, [editData])

  const handleClose = () => {
    setClose(toggle)
    dispatch(getCategories())
  };

  useEffect(() => {
    getImageTag()
  }, [network])

  const stepAddSchema = Yup.object().shape({
    name: Yup.string().required("Enter title"),
    description: Yup.string().required("Enter description"),
  })

  const handleChange = (checked) => {
    checked == true ? setIsFree(true) : setIsFree(false);
  }

  const handleAddNetwork = async values => {
    const name = network.name
    const description = network.description
    const titles = network.titles.map(title => title.value)
    const image = network.image
    const sort = network.sort
    const isActive = network.isActive == 1 ? true : false
    const isfree = isFree
    dispatch(
      addNewCategory({ id: network.id, name, description, isActive, image, titles, sort, isFree }, history, handleClose)
    )
  }

  const loadTitleOptions = async (search, loadedOptions, { page }) => {
    try {
      let optUrl = `${process.env.REACT_APP_ZFLIX_URL}/v1/titles?page=${page}`
      if (checkNull(search, false)) {
        optUrl = optUrl + "&search=" + search
      }
      const response = await fetch(optUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("authUser")).access_token
            }`,
          "Content-Type": "application/json",
        },
      })
      const res = await response.json()
      let newOptions = [...loadedOptions]
      let totalPages = page
      if (res?.data) {
        const data = res.data
        const updatedOptions = checkArray(data.results)
          ? data.results.map(result => ({
            value: result.id,
            name: `${result.title} (${result.year})`,
          }))
          : []

        newOptions = [...newOptions, ...updatedOptions]
        totalPages = data.totalPages
      }

      return {
        options: newOptions,
        hasMore: page < totalPages ? true : false, // Whether there are more pages to load
        additional: {
          page: page + 1,
        },
      }
    } catch (error) {
      return {
        options: [],
        hasMore: false,
        additional: { page: 1 },
      }
    }
  }

  const getImageTag = () => {
    let res = null
    const img = document.getElementById('imageTag');
    const imgCon = document.getElementById('imageCon');
    if (imgCon && img) {
      imgCon.classList.add('d-none');
      if (network?.image) {
        const reader = new FileReader()
        reader.onload = function (e) {
          img.src = e.target.result;
          imgCon.classList.remove('d-none')
        }
        reader.readAsDataURL(network?.image)
      } else if (editData?.image) {
        img.src = editData?.image;
        imgCon.classList.remove('d-none')
      }
    }
    return res
  }

  const moveTitle = (dragIndex, hoverIndex) => {
    const draggedTitle = network.titles[dragIndex];
    const newTitles = [...network.titles];
    newTitles.splice(dragIndex, 1);
    newTitles.splice(hoverIndex, 0, draggedTitle);
    setNetwork({ ...network, titles: newTitles });
  };

  const Title = ({ title, index }) => {
    const [{ isDragging }, drag] = useDrag({
      type: 'title',
      item: { type: 'title', index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    const [, drop] = useDrop({
      accept: 'title',
      hover(item) {
        if (item.index !== index) {
          moveTitle(item.index, index);
          item.index = index;
        }
      },
    });

    const opacity = isDragging ? 0.5 : 1;
    const titleStyle = {
      display: 'inline-block',
      padding: '5px',
      margin: '5px',
      background: '#f0f0f0',
      borderRadius: '5px',
      boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)',
      opacity,
    };

    return (
      <div ref={(node) => drag(drop(node))} style={titleStyle}>
        {title.name}
      </div>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal bs-example-modal" role="dialog"></div>
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Edit Category</ModalHeader>
        <Formik
          initialValues={{
            name: "test",
            description: "",
          }}
          validationSchema={stepAddSchema}
          onSubmit={(values, actions) => {
            handleAddNetwork(values)
          }}
        >
          {({ values, setValues, setFieldValue, errors, touched }) => (
            <Form
              onSubmit={e => {
                e.preventDefault()
                handleAddNetwork()
                return false
              }}
            >
              <ModalBody>
                <Label className="my-2" name="networkName">
                  Name:
                </Label>
                <input
                  type="text"
                  className="form-control input-color "
                  placeholder="Enter name here"
                  name="name"
                  value={network.name}
                  onChange={e => {
                    setNetwork({ ...network, name: e.target.value })
                    setFieldValue("name", e.target.value)
                  }}
                />
                {errors.name && touched.name ? (
                  <div className="input-error text-danger">{errors.name}</div>
                ) : null}
                <Label className="my-2" name="networkDescription">
                  Description:
                </Label>
                <Field
                  as="textarea"
                  className="form-control input-color "
                  placeholder="Enter Description here"
                  name="description"
                  rows="5"
                  value={network.description}
                  onChange={e => {
                    setNetwork({ ...network, description: e.target.value })
                    setFieldValue("description", e.target.value)
                  }}
                />
                {errors.description && touched.description ? (
                  <div className="input-error text-danger">
                    {errors.description}
                  </div>
                ) : null}

                <Label className="my-2">Category Image:</Label>
                <div className="commonImgs" id="imageCon">
                  <img
                    id="imageTag"
                    alt=""
                    className="every-img"
                  />
                </div>
                <input
                  type="file"
                  className="form-control input-color"
                  onChange={e => {
                    setNetwork({ ...network, image: e.target.files[0] })
                  }}
                />
                <Label htmlFor="isActive" className="my-2">
                  Show on home page?
                </Label>
                <Field
                  name="isActive"
                  className="form-control"
                  onChange={e => {
                    setNetwork({ ...network, isActive: e.target.value })
                  }}
                >
                  {({ field, form }) => (
                    <div className="sub-category-select">
                      <select
                        id="isActive"
                        className="form-control input-color "
                        name="isActive"
                        value={network.isActive}
                        onChange={e => {
                          setNetwork({ ...network, isActive: e.target.value })
                        }}
                      >
                        <option value="">Choose</option>
                        <option value="1">yes</option>
                        <option value="0">No</option>
                      </select>
                      {form.touched.isActive && form.errors.isActive ? (
                        <div className="input-error text-danger">
                          {form.errors.isActive}
                        </div>
                      ) : null}
                    </div>
                  )}
                </Field>
                <Label htmlFor="formrow-firstname-Input" className="my-2">
                  Titles
                </Label>
                <SelectAsyncPaginate
                  // regionName={region.value}
                  loadOptions={loadTitleOptions}
                  placeHolder="Select Title"
                  isMulti={true}
                  value={network.titles}
                  onChange={titles => {
                    setNetwork({ ...network, titles })
                  }}
                  isSearchable={true}
                />
                {errors.titles && touched.titles ? (
                  <div className="text-danger input-error">{errors.titles}</div>
                ) : null}

                <Label htmlFor="formrow-firstname-Input" className="my-2">
                  Category Title index
                </Label>
                <DndProvider backend={HTML5Backend}>
                  <div>
                    {network.titles.map((title, index) => (
                      <Title key={title.value} title={title} index={index} />
                    ))}
                  </div>
                </DndProvider>
                <Row>
                  <Col>
                    <Label className="my-2" name="networkName">
                      Sort Index:
                    </Label>
                    <input
                      type="number"
                      className="form-control input-color "
                      placeholder="Enter sort index here"
                      name="sort"
                      step="1"
                      value={network.sort}
                      onChange={e => {
                        setNetwork({ ...network, sort: e.target.value })
                        setFieldValue("sort", e.target.value)
                      }}
                    />
                    {errors.sort && touched.sort ? (
                      <div className="input-error text-danger">{errors.sort}</div>
                    ) : null}
                  </Col>
                  <Col>
                    <Label className="my-2" name="networkName">
                      Make it Free:
                    </Label><br />
                    <Switch onChange={handleChange} checked={isFree} />
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button type="submit" color="primary">
                  Save Changes
                </Button>
                <Button type="button" color="secondary" onClick={toggle}>
                  Close
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  )
}

EditCategory.propTypes = {
  toggle: PropTypes.func,
  changeApiStatus: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default EditCategory
