import React, { useEffect, useRef, useState } from "react"
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Label,
} from "reactstrap"
import { Field, Formik, Form } from "formik"
import * as Yup from "yup"
import { toast } from "react-toastify"
import { useHistory } from "react-router-dom";
import { setTokenHeader } from "../../../helpers/api_helper";
import { addNewCast } from "../../../store/actions";
import { useDispatch } from "react-redux";
import { addFAQ } from "../../../store/faq/action"

const FAQAdd = props => {
  
  //meta title
  document.title = "Manage Customers"


  const history = useHistory();
  const uploadRef = useRef(null)
  const dispatch = useDispatch();

  const [data, setData] = useState({
    title: "",
    question: "",
    answer: "",
  })

  const AddFaqValidation = Yup.object().shape({
    title: Yup.string()
      .min(4, "Too short!!")
      .max(1000, "Too Long!!")
      .required("This field is required"),
    question: Yup.string()
      .min(4, "Too short!!")
      .max(500, "Too Long!!")
      .required("This field is required"),
    answer: Yup.string()
      .min(4, "Too short!!")
      .required("This field is required"),
  })

  const handleSubmit = (data) => {
    console.log("values", data);
    dispatch(addFAQ(data, history)).then((v)=>{
      toast.success("Add Successfully!!")
      history.push('/faq')
    }).catch((er)=>{
      toast.error("Something Wrong!! ", er)
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* ... other code */}
          <Formik
            initialValues={data}
            validationSchema={AddFaqValidation} // Uncommented the validation schema
            enableReinitialize
            onSubmit={(values) => {
              handleSubmit(values); // Pass values to handleSubmit
            }}
          >
            {({ errors, touched }) => (
              <Row>
                <Col xl={12}>
                  <Card>
                    <CardBody>
                      <Form>
                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">
                            FAQ TITLE
                          </Label>
                          <Field
                            type="text"
                            className="form-control"
                            id="title"
                            name="title" // Matched the name attribute
                          />
                          {errors.title && touched.title ? (
                            <div className="text-danger input-error">
                              {errors.title}
                            </div>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">
                            Question
                          </Label>
                          <Field
                            type="text"
                            className="form-control"
                            id="question" // Corrected the id
                            name="question" // Matched the name attribute
                          />
                          {errors.question && touched.question ? (
                            <div className="text-danger input-error">
                              {errors.question}
                            </div>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">
                            Answer
                          </Label>
                          <Field
                            as="textarea"
                            className="form-control"
                            id="answer"
                            name="answer" // Matched the name attribute
                            rows="5"
                          />
                          {errors.answer && touched.answer ? (
                            <div className="text-danger input-error">
                              {errors.answer}
                            </div>
                          ) : null}
                        </div>
                        <div>
                          <button
                            type="submit"
                            className="btn btn-primary w-md"
                          >
                            Submit
                          </button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
          </Formik>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FAQAdd
