import React, { useEffect, useRef, useState } from "react"
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Label,
} from "reactstrap"
import cloud from "../../../assets/flix-images/cloud-file-download.svg"
import "./genreEdit.css"
import * as Yup from "yup"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Dropzone from "react-dropzone"
import { Field, Formik, Form } from "formik"
import { fireToast } from "../../../common/toast"
import { useParams } from "react-router-dom";
import { setTokenHeader } from "../../../helpers/api_helper";
import { useDispatch, useSelector } from "react-redux";
import { editGenre, updateGenre } from "../../../store/actions";
import { useHistory } from "react-router-dom";

const GenreEdit = props => {
  document.title = "Genre Edit";
  const params = useParams()
  const history = useHistory();
  const dispatch = useDispatch();
  const { genreSingle } = useSelector(state => state.genre)
  const uploadRef = useRef(null)
  const [data, setData] = useState({ ...genreSingle })
  const [image, setImage] = useState({
    blob: null,
    src: "",
  })

  useEffect(() => {
    if (genreSingle) {
      setData({ ...genreSingle })
    }
  }, [genreSingle])


  const handleImageChange = files => {
    const extn = ["image/jpg", "image/png", "image/jpeg"]
    const [imageNew] = files
    setData({ ...data, imageNew })
    if (imageNew && extn.includes(imageNew.type)) {
      if (imageNew.size <= 5242880) {
        setImage({ blob: imageNew, src: window.URL.createObjectURL(imageNew) })
      } else {
        fireToast("error", "image too large")
      }
    } else {
      fireToast(
        "error",
        "Please select a valid image file(only jpg, png and jpeg images are allowed)"
      )
    }
  }

  const EditGenre = Yup.object().shape({
    name: Yup.string()
      .max(1000, "Too Long!!")
      .required("Enter the Genre Name"),
    description: Yup.string()
      .required("Enter description"),
    isActive: Yup.string()
      .required("Please select"),
  })


  const HandleSubmit = e => {
    data.isActive= data.isActive === "1" ? true : data.isActive === "0" ? false : data.isActive 
    dispatch(updateGenre(data, history))
  }


  useEffect(() => {
    const sessionsData = JSON.parse(sessionStorage.getItem("authUser"))

    setTokenHeader(sessionsData.access_token)
    dispatch(editGenre(params.id))
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Genre" breadcrumbItem="Edit Genre" />
          <Formik
            initialValues={{ ...data }}
            validationSchema={EditGenre}
            enableReinitialize
            onSubmit={(e) => {
              // alert("Submit")
              HandleSubmit();
              return false;
            }}
          >
            {({ values, setValues, setFieldValue, errors, touched, handleSubmit }) => (
              <Row>
                <Col xl={12}>
                  <Card>
                    <CardBody>
                      <Form>
                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">
                            Genre Name
                          </Label>
                          <Field
                            type="text"
                            className="form-control"
                            id="Name"
                            name="Name"
                            value={data.name}
                            onChange={e => {
                              setData({ ...data, name: e.target.value })
                              setFieldValue('name', e.target.value)
                            }}
                          />
                          {errors.name && touched.name ? (
                            <div className="text-danger input-error">
                              {errors.name}
                            </div>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">
                            Description
                          </Label>
                          <Field
                            as="textarea"
                            className="form-control"
                            id="description"
                            name="description"
                            rows="5"
                            value={data.description}
                            onChange={e => {
                              setData({ ...data, description: e.target.value })
                              setFieldValue('description', e.target.value)
                            }}
                          />
                          {errors.description && touched.description ? (
                            <div className="text-danger input-error">
                              {errors.description}
                            </div>
                          ) : null}
                        </div>
                        <div className="mb-2">
                          <Row >
                            <Col xl={6}>
                              <Label htmlFor="isActive">Sort Index</Label>
                              <div className="mb-3">
                                <Field
                                  type="number"
                                  className="form-control"
                                  id="sort"
                                  name="sort"
                                  value={data.sort}
                                  onChange={e => {
                                    setData({ ...data, sort: e.target.value })
                                    setFieldValue('sort', e.target.value)
                                  }}
                                />
                                {errors.sort && touched.sort ? (
                                  <div className="text-danger input-error">
                                    {errors.sort}
                                  </div>
                                ) : null}
                              </div>
                            </Col>
                            <Col xl={6}>
                              <Label htmlFor="isActive">isActive</Label>
                              <Field name="isActive" className="form-control"
                                onChange={e => {
                                  setData({ ...data, isActive: e.target.value })
                                }}>
                                {({ field, form }) => (
                                  <div className="sub-category-select">
                                    <select id="isActive"
                                      value={data.isActive == true ? '1' : '0'}
                                      className="form-control input-color " name="isActive" onChange={e => {
                                        setData({ ...data, isActive: e.target.value })
                                      }}>
                                      <option value="">Choose</option>
                                      <option value="1"  >yes</option>
                                      <option value="0">No</option>
                                    </select>
                                    {errors.isActive && touched.isActive ? (
                                      <div className="text-danger input-error">
                                        {errors.isActive}
                                      </div>
                                    ) : null}
                                  </div>
                                )}
                              </Field>
                            </Col>
                          </Row>


                        </div>
                        <div >
                          <Label htmlFor="formrow-firstname-Input">
                            Image <small>(Optional)</small>
                          </Label>
                          <div className="text-center">

                            <div className="text-center">
                              <div className="mb-3 dragdrop-container">
                                <input
                                  ref={uploadRef}
                                  id="upload"
                                  hidden
                                  accept="image/*"
                                  type="file"
                                  onChange={e =>
                                    handleImageChange(e.target.files)
                                  }
                                />
                                {image.src ? (
                                  <div className="commonImgs">
                                    <img
                                      className="every-img"
                                      src={image.src ? image.src : cloud}
                                      alt=""
                                      onClick={() => {
                                        uploadRef.current.click()
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <div className="drag-n-drop-container">
                                    <div>
                                      <Dropzone
                                        accept="image/*"
                                        multiple={false}
                                        onDrop={acceptedFiles => {
                                          handleImageChange(acceptedFiles)
                                        }}
                                      >
                                        {({
                                          getRootProps,
                                          getInputProps,
                                          isDragActive,
                                        }) => (
                                          <section>
                                            <div
                                              className="drop-area"
                                              {...getRootProps()}
                                            >
                                              <img
                                                width={60}
                                                src={cloud}
                                                alt=""
                                              />
                                              <input
                                                {...getInputProps()}
                                                accept="image/*"
                                                multiple={false}
                                              />

                                              {isDragActive ? (
                                                <div>
                                                  Drop your image file here
                                                </div>
                                              ) : (
                                                <p>
                                                  Drag n drop image file here,
                                                  or click to select <br />
                                                  <small className="text-center">
                                                    <strong>
                                                      Supported files:
                                                    </strong>{" "}
                                                    jpeg, jpg, png. | Will be
                                                    resized to: 1920x1080 px.
                                                  </small>
                                                </p>
                                              )}
                                            </div>
                                          </section>
                                        )}
                                      </Dropzone>
                                    </div>

                                  </div>
                                )}
                              </div>
                            </div>

                            <p className="fw-bold">
                              Note:
                              <span className="text-danger mx-2 text-align-left">
                                Supported image formats are:&nbsp;jpg, png and
                                jpeg only
                              </span>
                            </p>
                          </div>
                        </div>
                        {genreSingle.image ? (<div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">
                            Uploaded
                          </Label>
                          <div>
                            <img
                              className="previousImage"
                              src={genreSingle.image}
                              alt=""
                            /></div>
                        </div>) : ('')}
                        <div>
                          <button
                            type="submit"
                            className="btn btn-primary w-md">
                            Update
                          </button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
          </Formik>
        </Container>
        {/* container-fluid */}
      </div>
    </React.Fragment>
  )
}


export default GenreEdit
