import { createAsyncThunk } from "@reduxjs/toolkit";
const baseUrl = `${process.env.REACT_APP_ZFLIX_URL}/v1/`;


const getFAQ = createAsyncThunk(
    'get/faq',
    async (action) => {
        try {
            let url = `${baseUrl}cms/faq`;
            const token = JSON.parse(sessionStorage.getItem("authUser")).access_token
            let option = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':`Bearer ${token}`,
                },
            }
            const response = await (await fetch(url, option)).json();

            if (response.status === true) {
                return [true, response];
            } else if (response.status === false) {
                return [false, response.message];
            }
        } catch (error) {
            console.log(error)
            return [false];
        }
    }
)

const getFAQById = createAsyncThunk(
    'get/faq/id',
    async (action) => {
        try {
            let url = `${baseUrl}cms/faq/${action}`;
            const token = JSON.parse(sessionStorage.getItem("authUser")).access_token
            let option = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':`Bearer ${token}`,
                },
            }
            const response = await (await fetch(url, option)).json();

            if (response.status === true) {
                return [true, response];
            } else if (response.status === false) {
                return [false, response.message];
            }
        } catch (error) {
            console.log(error)
            return [false];
        }
    }
)

const addFAQ = createAsyncThunk(
    'faq/add',
    async (action) => {
        console.log("action",action);
        try {
            let url = `${baseUrl}cms/faq`;
            const token = JSON.parse(sessionStorage.getItem("authUser")).access_token
            let option = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    title: action.title,
                    faq_content:{
                        question:action.question,
                        answer:action.answer
                    }
                })
            }
            const response = await (await fetch(url, option)).json();

            if (response.status === true) {
                return [true, response];
                action.history.push('/faq')
            } else if (response.status === false) {
                return [false, response.message];
                action.history.push('/faq')
            }
        } catch (error) {
            console.log(error)
            return [false];
        }
    }
)

const updateFAQ = createAsyncThunk(
    'faq/add',
    async (action) => {
        console.log("action",action);
        try {
            let url = `${baseUrl}cms/faq/${action.id}`;
            const token = JSON.parse(sessionStorage.getItem("authUser")).access_token
            let option = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    title: action.title,
                    faq_content:action.faq_content,
                    onHomePage:action.onHomePage,
                })
            }
            const response = await (await fetch(url, option)).json();

            if (response.status === true) {
                return [true, response];
                action.history.push('/faq')
            } else if (response.status === false) {
                return [false, response.message];
                action.history.push('/faq')
            }
        } catch (error) {
            console.log(error)
            return [false];
        }
    }
)

const deleteFAQById = createAsyncThunk(
    'delete/faq/id',
    async (action) => {
        try {
            let url = `${baseUrl}cms/faq/${action}`;
            const token = JSON.parse(sessionStorage.getItem("authUser")).access_token
            let option = {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':`Bearer ${token}`,
                },
            }
            const response = await (await fetch(url, option)).json();
            console.log("response", response)
            if (response.status === true) {
                return [true, response];
            } else if (response.status === false) {
                return [false, response.message];
            }
        } catch (error) {
            console.log(error)
            return [false];
        }
    }
)



export { getFAQ ,addFAQ, getFAQById, updateFAQ, deleteFAQById}
