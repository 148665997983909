import React, { useEffect, useState } from "react"
import { cilPencil, cilTrash } from "@coreui/icons"
import { Button } from "reactstrap"
import Image from "react-bootstrap/Image"
import "./genre.css"

import { CCard, CCardBody, CCardGroup } from "@coreui/react"
import DataTable from "react-data-table-component"
import { Row, Col } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import CIcon from "@coreui/icons-react"
import { useHistory, Link } from "react-router-dom"
import { toastConfirm } from "../../../common/toast"
import { setTokenHeader } from "../../../helpers/api_helper"
import { useDispatch, useSelector } from "react-redux"
import { deleteGenre, getGenre } from "../../../store/actions"
import { startLoader } from "../../../store/loaderState/actions"
import FullPageLoader from "../../../components/Common/FullPageLoader"
import NoImgFound from "../../../assets/No-image-found.jpg"

const Genre = () => {
  document.title = "Genre"

  const { loadingState } = useSelector(state => state.loadingState)
  const [page, setPage] = useState(1)
  const history = useHistory()
  const dispatch = useDispatch()
  const { genre } = useSelector(state => state.genre)  
  const [genreData, setGenreData] = useState(genre);

  const deleteGenreWithId = async id => {
    const response = await (
      await toastConfirm("Are you sure you want to delete this?")
    ).fire()
    if (response.isConfirmed) {
      dispatch(deleteGenre(id, history))
    }
  }
  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem("authUser"))

    setTokenHeader(data.access_token)
    dispatch(startLoader())
    dispatch(getGenre())
  }, [])

  useEffect(() => {
    setGenreData(genre)
  }, [genre])

  useEffect(() => {
    dispatch(startLoader())
    dispatch(getGenre(page))
  }, [page])

  let rowCounter = 1
  const columns = [
    {
      name: "Sr. no",
      selector: row => rowCounter++,
      sortable: true,
      maxWidth: "100px",
    },
    {
      name: "Genre Name",
      selector: row => row.name,
      sortable: true,
    },
    {
      name: "Description",
      selector: row => row.description,
      sortable: true,
    },
    {
      name: "Status",
      selector: row => (row.isActive == true ? "Active" : "Inactive"),
      sortable: true,
      maxWidth: "100px",
    },
    {
      name: "Image",
      selector: row => (
        <Image
          style={{ width: "50px", padding: "10px 0px" }}
          src={row.image != "" ? row.image : NoImgFound}
        />
      ),
      sortable: true,
      maxWidth: "100px",
    },
    {
      name: "Actions",
      maxWidth: "100px",
      selector: row => (
        <>
          <Link to={`genre/${row.id}/edit`}>
            <CIcon
              icon={cilPencil}
              className="text-warning hand me-2"
              onClick={() => {
                // toggleViewModal()
                // setEdit(row);
              }}
            />
          </Link>
          <CIcon
            icon={cilTrash}
            className="text-danger hand"
            onClick={() => {
              deleteGenreWithId(row.id)
            }}
          />
        </>
      ),
    },
  ]

  if (!genreData?.results) {
    return <FullPageLoader />
  }

  return (
    <React.Fragment>
      <div className="page-content genre">
        <div className="container-fluid">
          <Breadcrumbs title="All Genre" breadcrumbItem="Manage Genre" />

          <Row>
            <Col xl={12}>
              <div className="mb-2">
                <Link to={"/genre/add"} className="btn btn-primary w-md">
                  Add Genre
                </Link>
              </div>
              <CCardGroup>
                <CCard>
                  <CCardBody>
                    <DataTable
                      striped
                      columns={columns}
                      data={genreData?.results}
                      highlightOnHover
                      pagination
                      paginationServer
                      paginationTotalRows={genreData?.totalResults}
                      paginationPerPage={genreData?.limit}
                      paginationComponentOptions={{
                        noRowsPerPage: true,
                      }}
                      onChangePage={(page, totalRows) => {
                        setPage(page)
                      }}
                    />
                  </CCardBody>
                </CCard>
              </CCardGroup>
              <FullPageLoader loading={loadingState} />
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Genre
