import React, { useEffect, useRef, useState } from "react"

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Input,
  InputGroup,
} from "reactstrap"
import { BsFillEyeFill,BsFillEyeSlashFill } from 'react-icons/bs';
import cloud from "../../../assets/flix-images/cloud-file-download.svg"
import "./castAdd.css"
import * as Yup from "yup"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Dropzone from "react-dropzone"
import { toast } from "react-toastify"
import { Field, Formik, Form } from "formik"
import { fireToast } from "../../../common/toast"
import { useHistory } from "react-router-dom";
import { setTokenHeader } from "../../../helpers/api_helper";
import { addNewCast } from "../../../store/actions";
import { useDispatch } from "react-redux";

const CastAdd = props => {

  //meta title
  document.title = "Manage Customers"


  const history = useHistory();
  const uploadRef = useRef(null)
  const dispatch = useDispatch();

  const [data, setData] = useState({
    name: "",
    title_name: "",
    bio: "",
    isActive: "",
  })
  const [image, setImage] = useState({
    blob: null,
    src: "",
  })


  // image validation
  const handleImageChange = files => {
    const extn = ["image/jpg", "image/png", "image/jpeg"]
    const [image] = files
    setData({ ...data, image })

    if (image && extn.includes(image.type)) {
      
      if (image.size <= 5242880) {
        setImage({ blob: image, src: window.URL.createObjectURL(image) })
      } else {
        fireToast("error", "image too large")
      }
    } else {

      fireToast(
        "error",
        "Please select a valid image file(only jpg, png and jpeg images are allowed)"
      )
    }
  }

  const addCast = Yup.object().shape({
    name: Yup.string()
      .min(4, "Too short!!")
      .max(1000, "Too Long!!")
      .required("This field is required"),
    title_name: Yup.string()
      .min(4, "Too short!!")
      .max(500, "Too Long!!")
      .required("This field is required"),
    bio: Yup.string()
      .min(4, "Too short!!")
      .required("This field is required"),
    isActive: Yup.string()
      .required("This field is required"),
   
  })

  const handleSubmit = (e) => {
    console.log(data);
    dispatch(addNewCast(
      data
    ,history))
  }
  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem("authUser"))

    setTokenHeader(data.access_token)
  }, [])


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Cast" breadcrumbItem="Add Cast" />
          <Formik
            initialValues={{ ...data }}
            validationSchema={addCast}
            enableReinitialize
            onSubmit={(e) => {
              handleSubmit();
              return false;
            }}
           
          >
            {({ values, setValues, setFieldValue, errors, touched }) => (
              <Row>
              <Col xl={8}>
                <Card>
                  <CardBody>
                    <Form>
                      <div className="mb-3">
                        <Label htmlFor="formrow-firstname-Input">
                          Actor Name
                        </Label>
                        <Field
                          type="text"
                          className="form-control"
                          id="name"
                          name="name"
                          onChange={e => {
                            setData({ ...data, name: e.target.value })
                            setFieldValue('name', e.target.value)
                          }}
                        />
                        {errors.name && touched.name ? (
                          <div className="text-danger input-error">
                            {errors.name}
                          </div>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label htmlFor="formrow-firstname-Input">
                        Title
                        </Label>
                        <Field
                          type="text"
                          className="form-control"
                          id="title_name"
                          name="title_name"
                          onChange={e => {
                            setData({ ...data, title_name: e.target.value })
                            setFieldValue('title_name', e.target.value)
                          }}
                        />
                        {errors.title_name && touched.title_name ? (
                          <div className="text-danger input-error">
                            {errors.title_name}
                          </div>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label htmlFor="formrow-firstname-Input">
                        Bio
                        </Label>
                        <Field
                          as="textarea"
                          className="form-control"
                          id="bio"
                          name="bio"
                          rows="5"
                          onChange={e => {
                            setData({ ...data, bio: e.target.value })
                            setFieldValue('bio', e.target.value)
                          }}
                        />
                        {errors.bio && touched.bio ? (
                          <div className="text-danger input-error">
                            {errors.bio}
                          </div>
                        ) : null}
                      </div>
                      <div>
                      <Label htmlFor="isActive">isActive</Label>
                <Field name="isActive" className="form-control" 
                onChange={e => {
                    setData({ ...data, isActive: e.target.value })
                  }}>
                  {({ field, form }) => (
                    <div className="sub-category-select">
                      <select id="isActive" className="form-control input-color " name="isActive"  onChange={e => {
                    setData({ ...data, isActive: e.target.value })
                  }}>
                        <option value="">Choose</option>
                        <option value="1">yes</option>
                        <option value="0">No</option>
                      </select>
                      {form.touched.isActive &&
                        form.errors.isActive ? (
                        <div className="input-error text-danger">
                          {form.errors.isActive}
                        </div>
                      ) : null}
                    </div>
                  )}
                </Field>

                      </div>
                      <div className="mt-3">
                          <Label htmlFor="formrow-firstname-Input">
                          Image <small>(Optional)</small>
                        </Label>
                        <div className="text-center">
                        
                          <div className="text-center">
                            <div className="mb-3 dragdrop-container">
                              <input
                                ref={uploadRef}
                                id="upload"
                                hidden
                                accept="image/*"
                                type="file"
                                onChange={e =>
                                  handleImageChange(e.target.files)
                                    
                                }
                              />
                              {image.src ? (
                                <div className="commonImgs">
                                  <img
                                    className="every-img"
                                    src={image.src ? image.src : cloud}
                                    alt=""
                                    onClick={() => {
                                      uploadRef.current.click()
                                    }}
                                  />
                                </div>
                              ) : (
                                <div className="drag-n-drop-container">
                                  <div>
                                    <Dropzone
                                      accept="image/*"
                                      multiple={false}
                                      onDrop={acceptedFiles => {
                                        handleImageChange(acceptedFiles)
                                      }}
                                    >
                                      {({
                                        getRootProps,
                                        getInputProps,
                                        isDragActive,
                                      }) => (
                                        <section>
                                          <div
                                            className="drop-area"
                                            {...getRootProps()}
                                          >
                                            <img
                                              width={60}
                                              src={cloud}
                                              alt=""
                                            />
                                            <input
                                              {...getInputProps()}
                                              accept="image/*"
                                              multiple={false}
                                            />

                                            {isDragActive ? (
                                              <div>
                                                Drop your image file here
                                              </div>
                                            ) : (
                                              <p>
                                                Drag n drop image file here,
                                                or click to select <br />
                                                <small className="text-center">
                                                  <strong>
                                                    Supported files:
                                                  </strong>{" "}
                                                  jpeg, jpg, png. | Will be
                                                  resized to: 1920x1080 px.
                                                </small>
                                              </p>
                                            )}
                                          </div>
                                        </section>
                                      )}
                                    </Dropzone>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <p className="fw-bold">
                            Note:
                            <span className="text-danger mx-2 text-align-left">
                              Supported image formats are:&nbsp;jpg, png and
                              jpeg only
                            </span>
                          </p>
                        </div>
                      </div>
                      <div>
                        <button
                          type="submit"
                          className="btn btn-primary w-md"
                        >
                          Submit
                        </button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            )}
          </Formik>
        </Container>
        {/* container-fluid */}
      </div>
    </React.Fragment>
  )
}


export default CastAdd
