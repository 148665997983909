import React, { useRef, useState, useEffect } from "react"
import axios from "axios"

import { Card, Col, Container, Row, CardBody, Label, Input } from "reactstrap"
import cloud from "../../../assets/flix-images/cloud-file-download.svg"
import "./subCategory.css"
import * as Yup from "yup"
import { fireToast } from "../../../common/toast"
import { ErrorMessage, Field, Form, Formik } from "formik"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Dropzone from "react-dropzone"
import { toast } from "react-toastify"
import romance from "../../../assets/flix-images/romance.jpg"
import { CButton } from "@coreui/react"
import CIcon from "@coreui/icons-react"
import { useParams } from "react-router-dom"

const SubCategoryDetail = props => {
  //meta title
  const uploadRef = useRef(null)
  const params = useParams()

  document.title = "Manage Customers"
  const [data, setData] = useState({
    movieName: "",
    releaseDate: "",
    rating: "",
    image: "",
    time: "",
  })
  const [image, setImage] = useState({
    blob: null,
    src: "",
  })

  useEffect(e => {
    const API_URL = `${process.env.REACT_APP_ZFLIX_URL}/v1`
    const sessionsData = JSON.parse(sessionStorage.getItem("authUser"))

    axios
      .get(API_URL + "/categorys/" + params.id, {
        headers: {
          Authorization: `Bearer ${sessionsData.access_token}`,
        },
      })
      .then(function (response) {
        setSubCategoryData(response?.data?.data?.titles)
        setSubCategoryHeading(response?.data?.data?.name)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])

  const dateSchema = Yup.string().matches(
    /^(\d{1,2})\s+(Jan|Feb|Mar|Apr|April|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s+(\d{4})$/,
    "Please enter a valid date (e.g. 27 May 2018)"
  )

  const timeSchema = Yup.string().matches(
    /^(\d+hrs)?\s*(\d+min)?$/,
    "Please enter a valid time (e.g. 2hrs 30min)"
  )

  const handleImageChange = files => {
    const extn = ["image/jpg", "image/png", "image/jpeg"]
    const [file] = files
    if (file && extn.includes(file.type)) {
      // changeApiStatus(false, "")
      if (file.size <= 5242880) {
        setImage({ blob: file, src: window.URL.createObjectURL(file) })
      } else {
        fireToast("error", "File too large")
      }
    } else {
      // changeApiStatus(false, 'Please select a valid image file')
      fireToast(
        "error",
        "Please select a valid image file(only jpg, png and jpeg images are allowed)"
      )
    }
  }

  // time schema
  // function isValidTime(value) {
  //   try {
  //     timeSchema.validateSync(value);
  //     const [hours, minutes] = value
  //       .split(' ')
  //       .map((part) => parseInt(part))
  //       .filter((value) => !isNaN(value));
  //     return hours > 0 || minutes > 0;
  //   } catch (error) {
  //     return false;
  //   }
  // }

  // date schema
  // function isValidDate(value) {
  //   try {
  //     dateSchema.validateSync(value);
  //     const [day, month, year] = value.split(' ');
  //     const date = new Date(`${month} ${day}, ${year}`);
  //     return !isNaN(date.getTime());
  //   } catch (error) {
  //     return false;
  //   }
  // }

  const categoryDetailSchema = Yup.object().shape({
    movieName: Yup.string()
      .min(4, "Too Short!")
      .max(1000, "Too Long!")
      .required("Please Enter Movie Name"),
    releaseDate: dateSchema.required("Date is required"),
    time: timeSchema.required("Time is required"),
    rating: Yup.number()
      .required("Please give the rating")
      .min(1, "Please give rating between 1 to 5")
      .max(5, "Rating cannot be greater than 5"),
  })

  const onSubmit = e => {
    toast.success("Updated Successfully")
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Sub Categories" breadcrumbItem="Movie Details" />

          <Formik
            initialValues={{ ...data }}
            validationSchema={categoryDetailSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({ values, setValues, setFieldValue, errors, touched }) => (
              <Form>
                <Row>
                  <Col xl={8}>
                    <Card>
                      <CardBody>
                        <div className="mb-3">
                          <Label htmlFor="formrow-movieName-Input">
                            Movie Name
                          </Label>
                          <Field
                            type="text"
                            className="form-control"
                            name="movieName"
                            id="movieName"
                            placeholder="The Angry Birds"
                          />
                          {errors.movieName && touched.movieName ? (
                            <div className="input-error text-danger">
                              {errors.movieName}
                            </div>
                          ) : null}
                        </div>

                        <Row>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-releaseDate-Input">
                                Release Date
                              </Label>
                              <Field
                                type="text"
                                className="form-control"
                                id="releaseDate"
                                placeholder="27 March 2018"
                                name="releaseDate"
                              />
                              {errors.releaseDate && touched.releaseDate ? (
                                <div className="input-error text-danger">
                                  {errors.releaseDate}
                                </div>
                              ) : null}
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-time-Input">
                                Movie Time
                              </Label>
                              <Field
                                type="text"
                                className="form-control"
                                id="time"
                                name="time"
                                placeholder="2hrs 30min"
                              />
                              {errors.time && touched.time ? (
                                <div className="input-error text-danger">
                                  {errors.time}
                                </div>
                              ) : null}
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col lg={4}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-rating-Input">
                                Rating
                              </Label>
                              <Field name="rating" className="form-control">
                                {({ field, form }) => (
                                  <div className="sub-category-select">
                                    <select id="rating" {...field}>
                                      <option value="">Choose Rating</option>
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                      <option value="4">4</option>
                                      <option value="5">5</option>
                                    </select>
                                    {form.touched.rating &&
                                    form.errors.rating ? (
                                      <div className="input-error text-danger">
                                        {form.errors.rating}
                                      </div>
                                    ) : null}
                                  </div>
                                )}
                              </Field>
                            </div>
                          </Col>
                          {/* <Col lg={4}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-InputZip">
                                Registered On
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id=""
                                placeholder="27 March 2018"
                              />
                            </div>
                          </Col>

                          <Col lg={4}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-InputZip">Age</Label>
                              <Input
                                type="text"
                                className="form-control"
                                // id="formrow-InputZip"
                                placeholder="15"
                              />
                            </div>
                          </Col> */}
                        </Row>

                        <div>
                          <button
                            type="submit"
                            className="btn btn-primary w-md"
                          >
                            Submit
                          </button>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col xl={4}>
                    <Card>
                      <CardBody>
                        <Label htmlFor="formrow-InputZip">Movie Image</Label>
                        <Row className="mb-4">
                          <div className="text-center">
                            <div>
                              <div className="text-center">
                                <div className="mb-3 dragdrop-container">
                                  <input
                                    ref={uploadRef}
                                    id="upload"
                                    hidden
                                    accept="image/*"
                                    type="file"
                                    onChange={e =>
                                      handleImageChange(e.target.files)
                                    }
                                  />
                                  {image.src ? (
                                    <div className="commonImgs">
                                      <img
                                        className="every-img"
                                        src={image.src ? image.src : cloud}
                                        alt=""
                                        onClick={() => {
                                          uploadRef.current.click()
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <div className="drag-n-drop-container">
                                      <div>
                                        <Dropzone
                                          accept="image/*"
                                          multiple={false}
                                          onDrop={acceptedFiles => {
                                            handleImageChange(acceptedFiles)
                                          }}
                                        >
                                          {({
                                            getRootProps,
                                            getInputProps,
                                            isDragActive,
                                          }) => (
                                            <section>
                                              <div
                                                className="drop-area"
                                                {...getRootProps()}
                                              >
                                                <img
                                                  width={60}
                                                  src={cloud}
                                                  alt=""
                                                />
                                                <input
                                                  {...getInputProps()}
                                                  accept="image/*"
                                                  multiple={false}
                                                />

                                                {isDragActive ? (
                                                  <div>
                                                    Drop your image file here
                                                  </div>
                                                ) : (
                                                  <p>
                                                    Drag n drop image file here,
                                                    or click to select <br />
                                                    <small className="text-center">
                                                      <strong>
                                                        Supported files:
                                                      </strong>{" "}
                                                      jpeg, jpg, png. | Will be
                                                      resized to: 1920x1080 px.
                                                    </small>
                                                  </p>
                                                )}
                                              </div>
                                            </section>
                                          )}
                                        </Dropzone>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <p className="fw-bold">
                                Note:
                                <span className="text-danger mx-2 text-align-left">
                                  Supported image formats are:&nbsp;jpg, png and
                                  jpeg only
                                </span>
                              </p>
                            </div>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default SubCategoryDetail
