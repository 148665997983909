import React, { useEffect, useState } from "react";
import { CCard, CCardBody, CCardGroup } from "@coreui/react";
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Label,
  Table,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button
} from "reactstrap";

import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import "./index.css";
import * as Yup from "yup";
import axios from "axios";

//Import Breadcrumb
import { Field, Formik, Form } from "formik";
import { useParams } from "react-router-dom";
import { setTokenHeader } from "../../../../helpers/api_helper";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { editUser, updateUser } from "../../../../store/actions";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import defaultAvatar from "../../../../assets/images/users/avatar.png";
import { map } from "lodash";
import { getTransactionData } from "../../../../store/transaction/toolkit/action";

const UserEdit = (props) => {
  const params = useParams();

  const transactionData = useSelector((state) => state.Transaction);
  const history = useHistory();
  const dispatch = useDispatch();
  const { userSingle } = useSelector((state) => state.user);
  document.title = "Customer Edit";
  const API_URL = `${process.env.REACT_APP_ZFLIX_URL}/v1`;
  const [isModelOpen, setModelOpen] = useState(false);
  const [data, setData] = useState("");
  const [deviceType, setDeviceType] = useState("");
  const [statusData, setStatusData] = useState("");
  const [originalTransactionId, setoriginalTransactionId] = useState("");
  const [subscription, setSubscriptions] = useState("");
  const [serverExtraInfo, setServerExtraInfo] = useState("");
  const [showMore, setShowMore] = useState(false);



  useEffect(() => {
    const sessionsData = JSON.parse(sessionStorage.getItem("authUser"));
    setTokenHeader(sessionsData.access_token);
    dispatch(editUser(params.id));

    dispatch(getTransactionData())
    fetchSubscriptions();
  }, [dispatch]);

  useEffect(() => {
    if (userSingle) {
      setData(userSingle.user);
    }
    if(transactionData?.transactionData?.length !== 0){
      const matchedItem = transactionData?.transactionData?.find(item => item.userId?._id === params.id);
      if(matchedItem){
        setDeviceType(matchedItem?.device_type)
        setoriginalTransactionId(matchedItem?.originalTransactionId)
      }
    }
   
  }, [userSingle,transactionData]);


  function formatDate(dateString) {
    if (!dateString) return "N/A"
    const date = new Date(dateString * 1000)
    return date.toLocaleString()
  }

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const EditUser = Yup.object().shape({
    name: Yup.string().max(1000, "Too Long!!").required("Enter the User Name"),
    email: Yup.string().required("Enter Email"),
  });

  const HandleSubmit = (e) => {
    let userData = [];
    userData["name"] = data.name;
    userData["email"] = data.email;
    if (data.password != "") {
      userData["password"] = data.password;
    }
    dispatch(updateUser(params.id, userData, history));
  };




  const handleServerStatus = (transactionId, type) => {
    const token = JSON.parse(sessionStorage.getItem("authUser"))
    const headers = {
      Authorization: `Bearer ${token.access_token}`,
    }

    axios
      .get(
        `${API_URL}/users/server/subscription-details?type=${type}&transactionId=${transactionId}`,
        { headers: headers }
      )
      .then((response) => {
        setStatusData(response.data?.data?.responseData);
        setServerExtraInfo(response.data?.data?.serverExtraDetails);
      })
      .catch((error) => {
        console.log(error);
        setStatusData("");
        setServerExtraInfo("");
      });
    setModelOpen(true);
  };


  const fetchSubscriptions = () => {
    try {
      axios
        .get(
          API_URL + "/users/subscription/transactions?userId=" + params.id,
          {}
        )
        .then(function (response) {
          setSubscriptions(response?.data?.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Customers" breadcrumbItem="Edit Profile" />
          <Formik
            initialValues={{ ...data }}
            validationSchema={EditUser}
            enableReinitialize
            onSubmit={(e) => {
              HandleSubmit();
              return false;
            }}
          >
            {({ values, setValues, setFieldValue, errors, touched }) => (
              <Card>
                <CardBody>
                  <Form>
                    <Row>
                      <Col md={5} className="text-center">
                        <img
                          src={data.image !== "" ? data.image : defaultAvatar}
                          width="200"
                          height="200"
                          style={{ borderRadius: "50%" }}
                        ></img>
                        <h4>{data.email}</h4>
                        <h5>
                          {data.isSubscribed ? "Subscribed" : "Not Subscribed"}
                        </h5>
                      </Col>
                      <Col md={7}>
                        <h5 className="">Personal Information</h5>
                        <div className="mb-3 mt-3">
                          <Label htmlFor="formrow-firstname-Input">Name</Label>
                          <Field
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            onChange={(e) => {
                              setData({ ...data, name: e.target.value });
                              setFieldValue("name", e.target.value);
                            }}
                          />
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">Email</Label>
                          <Field
                            type="text"
                            className="form-control"
                            id="email"
                            name="email"
                            onChange={(e) => {
                              setData({ ...data, email: e.target.value });
                              setFieldValue("email", e.target.value);
                            }}
                          />
                        </div>
                        <div>
                          <button
                            type="submit"
                            className="btn btn-primary w-md"
                          >
                            Update
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            )}
          </Formik>

          <div className="mt-4 p-4">
            {/* <h5>User Information</h5> */}
            <Breadcrumbs title="Customers" breadcrumbItem="User Information" />
            <Row>
              <Col md={6}>
                <div className="mb-1 mt-3 d-flex">
                  <Label
                    htmlFor="formrow-firstname-Input"
                    className="me-2 fs-5"
                  >
                    API Country Code :
                  </Label>
                  <div className="fs-5">{data?.api_country_code || "N/A"}</div>
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-1 mt-3 d-flex">
                  <Label
                    htmlFor="formrow-firstname-Input"
                    className="me-2 fs-5"
                  >
                    Country :
                  </Label>
                  <div className="fs-5">{data?.country || "N/A"}</div>
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-1 mt-3 d-flex">
                  <Label
                    htmlFor="formrow-firstname-Input"
                    className="me-2 fs-5"
                  >
                    Country Code :
                  </Label>
                  <div className="fs-5">{data?.country_code || "N/A"}</div>
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-1 mt-3 d-flex">
                  <Label
                    htmlFor="formrow-firstname-Input"
                    className="me-2 fs-5"
                  >
                    Device Id :
                  </Label>
                  <div className="fs-5">{data?.deviceId || "N/A"}</div>
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-1 mt-3 d-flex">
                  <Label
                    htmlFor="formrow-firstname-Input"
                    className="me-2 fs-5"
                  >
                    Gender :
                  </Label>
                  <div className="fs-5">{data?.gender || "N/A"}</div>
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-1 mt-3 d-flex">
                  <Label
                    htmlFor="formrow-firstname-Input"
                    className="me-2 fs-5"
                  >
                    Email Verified :
                  </Label>
                  <div className="fs-5">
                    {data?.isEmailVerified
                      ? "Verified"
                      : "Not Verified" || "N/A"}
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-1 mt-3 d-flex">
                  <Label
                    htmlFor="formrow-firstname-Input"
                    className="me-2 fs-5"
                  >
                    Subscribed :
                  </Label>
                  <div className="fs-5">
                    {data?.isSubscribed
                      ? "Subscribed"
                      : "Not Subscribed" || "N/A"}
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-1 mt-3 d-flex">
                  <Label
                    htmlFor="formrow-firstname-Input"
                    className="me-2 fs-5"
                  >
                    Provider :
                  </Label>
                  <div className="fs-5">{data?.provider || "N/A"}</div>
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-1 mt-3 d-flex">
                  <Label
                    htmlFor="formrow-firstname-Input"
                    className="me-2 fs-5"
                  >
                    Phone :
                  </Label>
                  <div className="fs-5">{data?.phone || "N/A"}</div>
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-1 mt-3 d-flex">
                  <Label
                    htmlFor="formrow-firstname-Input"
                    className="me-2 fs-5"
                  >
                    Unique Identifier :
                  </Label>
                  <div className="fs-5">{data?.uniqueIdentifier || "N/A"}</div>
                </div>
              </Col>
            </Row>
          </div>

          <Card>
            <CardBody>
              <div className="row mb-4">
                <div className="col-lg-12">
                  <div className="d-flex">
                    <h5>Selected Genres</h5>
                  </div>
                </div>
                {data && data.genres.length > 0 ? (
                  data.genres.map((genre, index) => (
                    <div className="col-lg-2 mt-2" key={index}>
                      <div className="text-center mb-3">
                        <img src={genre.image} alt="" className="avatar-sm" />
                        <a className="text-body" href="/job-details">
                          <h5 className="mt-4 mb-2 font-size-15">
                            {genre.name}
                          </h5>
                        </a>
                        <p className="mb-0 text-muted">{genre.description}</p>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="col-lg-12 mt-2 d-flex justify-content-center">
                    <span>Not Selected</span>
                  </div>
                )}
              </div>

              {/* Table to display device details */}

              <h5 className="mt-3">Device Details</h5>
              {data.deviceDetails.length > 0 ? (
                <Table bordered className="mt-3">
                  <thead>
                    <tr>
                      {/* <th>Blacklisted</th> */}
                      <th>S.No</th>
                      <th>Device ID</th>
                      <th>Device Name</th>
                      <th>Device Type</th>
                      <th>Expires</th>
                      <th
                        style={{
                          maxWidth: "350px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        FCM Token
                      </th>
                      {/* <th>Key</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {data.deviceDetails.map((device, index) => (
                      <tr key={index}>
                        {/* <td>{device.blacklisted ? "Yes" : "No"}</td> */}
                        <td>{index + 1}</td>
                        <td>{device.deviceId}</td>
                        <td>{device.deviceName}</td>
                        <td>{device.deviceType}</td>
                        <td>{new Date(device.expires).toLocaleString()}</td>
                        <td
                          style={{
                            maxWidth: "350px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {device.fcmToken}
                        </td>
                        {/* <td>{device.key}</td> */}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <div className="col-lg-12 mt-2 d-flex justify-content-center">
                  <span>No Records to display</span>
                </div>
              )}

              <div className="row">
                <div className="col-6">
                  <h5 className="mt-3">Subscription Details</h5>
                </div>
                <div className="col-6 d-flex justify-content-end align-items-center">
                  <button
                    onClick={() =>
                      handleServerStatus(originalTransactionId, deviceType)
                    }
                    className="form-control btn btn-sm btn-info"
                    style={{ maxWidth: "80px" }}
                  >
                    Get Status
                  </button>
                </div>
              </div>

              <Table bordered className="mt-3">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Date of Purchased</th>
                    <th>Valid Till</th>
                    <th>Transaction ID</th>
                    <th>Product ID</th>
                    {/* <th>Status</th> */}
                  </tr>
                </thead>
                <tbody>
                  {subscription &&
                    subscription.map((item, index) => (
                      <tr key={index}>
                        <th>{index + 1}</th>
                        <td>{new Date(item.datePurchased).toLocaleString()}</td>
                        <td>{new Date(item.validTill).toLocaleString()}</td>
                        <td>{item?.originalTransactionId}</td>
                        <td>{item?.productId}</td>
                        {/* <td>
                        <div className={item.status === 'active' ? 'font-size-12 text-uppercase badge badge-soft-success' : 'font-size-12 text-uppercase badge badge-soft-danger'}>
                          {item.status}</div>
                      </td> */}
                      </tr>
                    ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Container>
      </div>

      <Modal
            show={isModelOpen}
            onHide={() => {
              setStatusData("")
              setModelOpen(false)
            }
            }
            role="dialog"
            autoFocus={true}
            centered={true}>
            <div className="modal-content">
              <ModalHeader>
                <h5 className="font-size-14">Subscription Details From Server</h5>
                <Button variant="link" className="btn-close" onClick={() => {
                  setStatusData("")
                  setModelOpen(false)
                }}>
                </Button>
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col xl={12}>
                    <CCardGroup>
                      <CCard>
                        <CCardBody>
                          <ul>
                            <li className="mb-2">
                              <b>Plan Name :</b> {statusData?.plan_name}
                            </li>
                            <li className="mb-2">
                              <b>Subscription Date :</b>{" "}
                              {formatDate(statusData?.start_date)}
                            </li>
                            <li className="mb-2">
                              <b>Current Start Date :</b>{" "}
                              {formatDate(statusData?.start_date)}
                            </li>
                            <li className="mb-2">
                              <b>Current End Date :</b>{" "}
                              {formatDate(statusData?.current_period_end)}
                            </li>
                            <li className="mb-2">
                              <b>Trial Start Date :</b>{" "}
                              {formatDate(statusData?.trial_start)}
                            </li>
                            <li className="mb-2">
                              <b>Trial End Date :</b>{" "}
                              {formatDate(statusData?.trial_end)}
                            </li>
                            <li className="mb-2">
                              <b>Status :</b>{" "}
                              {statusData?.status?.toUpperCase()}
                            </li>
                          </ul>

                          {showMore && serverExtraInfo && (
                            <div
                              className=""
                              style={{
                                backgroundColor: "#e7f2f3",
                                maxHeight: "260px",
                                overflow: "auto",
                                padding: "4px",
                              }}
                            >
                              <pre>
                                {JSON.stringify(serverExtraInfo, null, 2)}
                              </pre>
                            </div>
                          )}
                          <div className="d-flex justify-content-end">
                            <button
                              className="btn btn-outline-secondary"
                              onClick={toggleShowMore}
                              style={{
                                outline: "none",
                                backgroundColor: "white",
                                color: "gray",
                                border: "none",
                              }}
                            >
                              {showMore && serverExtraInfo
                                ? "View Less"
                                : "View More"}
                            </button>
                          </div>
                          
                        </CCardBody>
                      </CCard>
                    </CCardGroup>
                  </Col>
                </Row>
              </ModalBody>
            </div>
          </Modal>
    </React.Fragment>
  );
};

export default UserEdit;
