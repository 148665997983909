import {
  GET_USER,
  GET_USER_FAIL,
  GET_USER_SUCCESS,
  ADD_NEW_USER,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  EDIT_USER,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAIL,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
} from "./actionTypes"

export const getUser = (page =1,userType,email,userId) => ({
  type: GET_USER,
  payload: {page,userType,email,userId},

})

export const getUserSuccess = User => ({
  type: GET_USER_SUCCESS,
  payload: User,
})

export const addNewUser = (user,history) => ({
  type: ADD_NEW_USER,
  payload: {user,history},
})

export const addUserSuccess = user => ({
  type: ADD_USER_SUCCESS,
  payload: user,
})

export const addUserFail = error => ({
  type: ADD_USER_FAIL,
  payload: error,
})

export const getUserFail = error => ({
  type: GET_USER_FAIL,
  payload: error,
})

export const updateUserSuccess = user => ({
  type: UPDATE_USER_SUCCESS,
  payload: user,
})

export const updateUser = (id , user,history) => ({
  type: UPDATE_USER,
  payload: {id , user,history},
})
export const updateUserFail = error => ({
  type: UPDATE_USER_FAIL,
  payload: error,
})
export const editUserSuccess = user => ({
  type: EDIT_USER_SUCCESS,
  payload: user,
})

export const editUser = (user) => ({
  type: EDIT_USER,
  payload: user,
})
export const editUserFail = error => ({
  type: EDIT_USER_FAIL,
  payload: error,
})

export const deleteUser = (id,history) => ({
  type: DELETE_USER,
  payload: {id,history},
})

export const deleteUserSuccess = id => ({
  type: DELETE_USER_SUCCESS,
  payload: id,
})

export const deleteUserFail = error => ({
  type: DELETE_USER_FAIL,
  payload: error,
})
