import { createSlice } from "@reduxjs/toolkit";
import { getBucketFilesData, downloadFile, upload ,updateSubtitle} from "./action";

const BucketSlice = createSlice({
    name: 'BucketSlice',
    initialState: {
        filesData: [],
        BucketFiles: [],
        downloadedFile: "",
        downloadStatus: false,
    },
    reducers: {
        updateFiles: (state, action) => {
            state.filesData = action
        },
        updateDownloadStatus: (state, action) => {
            state.downloadStatus = action
        }
    },
    extraReducers: (builder) => {

        // handle  get Files
        builder.addCase(getBucketFilesData.pending, (state) => {
            return { ...state, BucketFiles: [], };
        });
        builder.addCase(getBucketFilesData.fulfilled, (state, action) => {
            if (action.payload[0])
                return {
                    ...state,
                    BucketFiles: action.payload[1].data,
                }
            else
                return { ...state, BucketFiles: [], errors: action.payload[1] }
        });
        builder.addCase(getBucketFilesData.rejected, (state) => {
            return { ...state, BucketFiles: [] }
        });


        // handle download files
        builder.addCase(downloadFile.pending, (state) => {
            return { ...state, downloadedFile: "", };
        });
        builder.addCase(downloadFile.fulfilled, (state, action) => {
            if (action.payload[0])
                return {
                    ...state,
                    downloadedFile: action.payload[1].data,
                }
            else
                return { ...state, downloadedFile: "", errors: action.payload[1] }
        });
        builder.addCase(downloadFile.rejected, (state) => {
            return { ...state, downloadedFile: "" }
        });

        // handle upload files automaticly
        builder.addCase(upload.pending, (state) => {
            return { ...state, downloadStatus: false, };
        });
        builder.addCase(upload.fulfilled, (state, action) => {
            if (action.payload[0])
                return {
                    ...state,
                    downloadStatus: true,
                }
            else
                return { ...state, downloadStatus: false, errors: action.payload[1] }
        });
        builder.addCase(upload.rejected, (state) => {
            return { ...state, downloadStatus: false }
        });

        // handle upload files automaticly
        builder.addCase(updateSubtitle.pending, (state) => {
            return { ...state, downloadStatus: false, };
        });
        builder.addCase(updateSubtitle.fulfilled, (state, action) => {
            if (action.payload[0])
                return {
                    ...state,
                    downloadStatus: true,
                }
            else
                return { ...state, downloadStatus: false, errors: action.payload[1] }
        });
        builder.addCase(updateSubtitle.rejected, (state) => {
            return { ...state, downloadStatus: false }
        });

    }
})

export const { updateFiles, updateDownloadStatus } = BucketSlice.actions;


export default BucketSlice.reducer;