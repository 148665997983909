import React, { useEffect, useState } from "react"
import { cilPencil, cilTrash } from "@coreui/icons"
import Image from "react-bootstrap/Image"
import "./index.css"

import { CCard, CCardBody, CCardGroup } from "@coreui/react"
import DataTable from "react-data-table-component"
import { Row, Col } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import CIcon from "@coreui/icons-react"
import { useHistory, Link } from "react-router-dom"
import { toastConfirm } from "../../../common/toast"
import { deleteCast, getCast } from "../../../store/actions"
import { useDispatch, useSelector } from "react-redux"
import { setTokenHeader } from "../../../helpers/api_helper"
import NoImgFound from "../../../assets/No-image-found.jpg"
import { startLoader } from "../../../store/loaderState/actions"
import FullPageLoader from "../../../components/Common/FullPageLoader"
import { getFAQ ,deleteFAQById } from "../../../store/faq/action"

const FAQ = () => {
  document.title = "FAQ"
  const { loadingState, faqData } = useSelector(state => state.faq)
  const [page, setPage] = useState(1)
  const dispatch = useDispatch()

  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem("authUser"))
    setTokenHeader(data.access_token)
    dispatch(startLoader())
    dispatch(getFAQ())
  }, [])

  useEffect(() => {
    dispatch(startLoader())
    // dispatch(getFAQ(page))
  }, [page])

  const deleteFAQWithId = async id => {
    const response = await (
      await toastConfirm("Are you sure you want to delete this?")
    ).fire()
    if (response.isConfirmed) {
      dispatch(deleteFAQById(id))
    }
  }

  var rowCounter = 1
  const columns = [
    {
      name: "Sr. no",
      selector: row => rowCounter++,
      sortable: true,
      width: "80px",
    },
    {
      name: "FAQ Title",
      selector: row => row.title,
      sortable: true,
      width:"100px",
      maxWidth: "100px",
    },
    {
      name: "On Homepage",
      selector: row => row.onHomePage ? "Yes" : "No",
      sortable: true,
      width:"100px",
      maxWidth: "100px",
    },
    {
      name: "Question",
      selector: (row) => row.faq_content.map((element, index) => (
        <span key={index} className="mb-2">
          {element.question} <br />
        </span>
      )),
      maxWidth:"450px",
      sortable: true,
    },
    {
      name: "Answer",
      selector: (row) => row.faq_content.map((element, index) => (
        <span key={index} className="mb-2">
          {element.answer} <br />
        </span>)),
      maxWidth:"450px",
      sortable: true,
    },

    {
      name: "Actions",
      maxWidth: "100px",
      selector: row => (
        <>
          <Link to={`faq/${row._id}/edit`}>
            <CIcon
              icon={cilPencil}
              className="text-warning hand me-2"
            />
          </Link>
          <CIcon
            icon={cilTrash}
            className="text-danger hand"
            onClick={() => {
              deleteFAQWithId(row._id)
            }}
          />
        </>
      ),
    },
  ]
  if (!faqData) {
    return <FullPageLoader />
  }
  return (
    <React.Fragment>
      <div className="page-content cast ">
        <div className="container-fluid">
          <Breadcrumbs title="All Cast" breadcrumbItem="Manage FAQ" />

          <Row>
            <Col xl={12}>
              <div className="mb-2">
                <Link to={"/faq/add"} className="btn btn-primary w-md">
                  Add +
                </Link>
              </div>
              <CCardGroup>
                <CCard>
                  <CCardBody>
                    <DataTable
                      striped
                      columns={columns}
                      data={faqData ? faqData : ""}
                      highlightOnHover
                    />
                  </CCardBody>
                </CCard>
              </CCardGroup>
              <FullPageLoader loading={loadingState} />
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default FAQ
