/* CATEGORIES */
export const GET_PLAN = "GET_PLAN"
export const GET_PLAN_SUCCESS = "GET_PLAN_SUCCESS"
export const GET_PLAN_FAIL = "GET_PLAN_FAIL"

/**
 * add GENRE
 */
 export const ADD_NEW_PLAN = "ADD_NEW_PLAN"
 export const ADD_PLAN_SUCCESS = "ADD_PLAN_SUCCESS"
 export const ADD_PLAN_FAIL = "ADD_PLAN_FAIL"


  
/**
 * Edit GENRE
 */
export const EDIT_PLAN = "EDIT_PLAN"
export const EDIT_PLAN_SUCCESS = "EDIT_PLAN_SUCCESS"
export const EDIT_PLAN_FAIL = "EDIT_PLAN_FAIL"


/**
 * Edit GENRE
 */
 export const UPDATE_PLAN = "UPDATE_PLAN"
 export const UPDATE_PLAN_SUCCESS = "UPDATE_PLAN_SUCCESS"
 export const UPDATE_PLAN_FAIL = "UPDATE_PLAN_FAIL"

/**
 * Delete GENRE
 */
export const DELETE_PLAN = "DELETE_PLAN"
export const DELETE_PLAN_SUCCESS = "DELETE_PLAN_SUCCESS"
export const DELETE_PLAN_FAIL = "DELETE_PLAN_FAIL"
