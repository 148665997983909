import {
  GET_GENRE_SUCCESS,
  GET_GENRE_FAIL,
  ADD_GENRE_SUCCESS,
  ADD_GENRE_FAIL,
  UPDATE_GENRE_IMAGE_FAIL,
  UPDATE_GENRE_IMAGE_SUCCESS,
  UPDATE_GENRE_IMAGE,
  UPDATE_GENRE_SUCCESS,
  UPDATE_GENRE_FAIL,
  DELETE_GENRE_SUCCESS,
  DELETE_GENRE_FAIL,
  EDIT_GENRE,
  EDIT_GENRE_FAIL,
  EDIT_GENRE_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  genre: [],
  error: {},
  genreSingle: {},
}

const genre = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_GENRE_SUCCESS:
      return {
        ...state,
        genre: action.payload.data,
      }

    case GET_GENRE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_GENRE_SUCCESS:

      return {
        ...state,
        genre: {
          ...state.genre,
          results: [...state.genre.results, action.payload]
        },
      }


    case ADD_GENRE_FAIL:
      return {
        ...state,
        error: action.payload,
      }



    case UPDATE_GENRE_SUCCESS:

      return {
        ...state,
        genre: {
          ...state.genre,
          results: state.genre.results.map(item => item?.id == action.payload.id ? action.payload : item)
        },
      }

    case UPDATE_GENRE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_GENRE_SUCCESS:
      return {
        ...state,
        genre: {
          ...state.genre,
          results: [...state.genre.results.filter(item => item['id'] !== action.payload)]
        },
      }

    case DELETE_GENRE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_GENRE_IMAGE:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_GENRE_IMAGE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_GENRE_IMAGE_SUCCESS:
      return {
        ...state,
        genre: {
          ...state.genre,
          results: state.genre.results.map(item => item?.id == action.payload.id ? { ...item, image: action.payload.image } : item)
        },
      }

    case EDIT_GENRE:
      return {
        ...state,
        genreSingle: action.payload
      }

    case EDIT_GENRE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case EDIT_GENRE_SUCCESS:
      return {
        ...state,
        genreSingle: action.payload.data
      }



    default:
      return state
  }
}

export default genre
