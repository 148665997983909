import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap"

import { getChartsData as onGetChartsData } from "../../store/actions"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import { useSelector, useDispatch } from "react-redux"
import { getDashboardData } from "../../store/dashboard/toolkit/action"
import { Link } from "react-router-dom"
import StackedColumnChart from "./StackedColumnChart"
import classNames from "classnames"
import ReactGA from 'react-ga';


ReactGA.initialize('402451479');
const Dashboard = props => {
  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData,
    user: state.Login.user,
    auth_token: state.Login.auth_token,
  }))
  const dispatch = useDispatch()
  const dashboardData = useSelector(state => state.DashboardData)
  const reports = [
    {
      title: "Total Registerd Users",
      iconClass: "bxs-user-detail",
      description: dashboardData ? dashboardData.dashboardData.totalUsers : "00",
      link:"/registered-users"
    },
    {
      title: "Subscribed Users",
      iconClass: "bx-user",
      description: dashboardData ? dashboardData.dashboardData.subscribeUser : "00",
      link:"/registered-users"
    },
    {
      title: "Total Categories",
      iconClass: "bxs-widget",
      description: dashboardData ? dashboardData.dashboardData.totalCategories : "00",
      link:"/all-categories"
    },
    {
      title: "Total Titles",
      iconClass: "bx-tv",
      description: dashboardData ? dashboardData.dashboardData.totalTitles : "00",
      link:"/titles"
    },
    {
      title: "Total Cast",
      iconClass: "bxs-user-rectangle",
      description: dashboardData ? dashboardData.dashboardData.totalCast : "00",
      link:"/cast"
    },
    {
      title: "Total Genres",
      iconClass: "bxs-server",
      description: dashboardData ? dashboardData.dashboardData.totalGenres : "00",
      link:"/genre"
    },
    {
      title: "Total App Downloads",
      iconClass: "bxs-download",
      description: "20",
      link:"#"
    },
  ]


  const [periodData, setPeriodData] = useState([])
  const [periodType, setPeriodType] = useState("yearly")

  useEffect(() => {
    setPeriodData(chartsData)
  }, [chartsData])

  const onChangeChartPeriod = pType => {
    setPeriodType(pType)
    dispatch(onGetChartsData(pType))
  }

  useEffect(() => {
    dispatch(getDashboardData())
  }, [dispatch])

  useEffect(() => {
    ReactGA.initialize('402451479');
    document.title = "Dashboard"
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />
          <Row>
            {reports.map((report, key) => (
              <Col md="3" key={"_col_" + key} style={{ cursor: "pointer" }} >
                <Link to={report.link}>
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">
                            {report.title}
                          </p>
                          <h4 className="mb-0">{report.description}</h4>
                        </div>
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i
                              className={
                                "bx " + report.iconClass + " font-size-24"
                              }
                            ></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
            ))}
          </Row>
          <Row>

            <Col xl="8">

            </Col>
            <Col xl="4">

            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
