import {
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  UPDATE_USER_IMAGE_FAIL,
  UPDATE_USER_IMAGE_SUCCESS,
  UPDATE_USER_IMAGE,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  EDIT_USER,
  EDIT_USER_FAIL,
  EDIT_USER_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  user: [],
  error: {},
  userSingle: {},
}

const user = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USER_SUCCESS:
      return {
        ...state,
        user: action.payload.data,
      }

    case GET_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_USER_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          results: [...state.user.results, action.payload],
        },
      }

    case ADD_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          results: state.user.results.map(item =>
            item?.id == action.payload.id ? action.payload : item
          ),
        },
      }

    case UPDATE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_USER_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          results: [
            ...state.user.results.filter(item => item["id"] !== action.payload),
          ],
        },
      }

    case DELETE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case EDIT_USER:
      return {
        ...state,
        userSingle: action.payload,
      }

    case EDIT_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case EDIT_USER_SUCCESS:
      return {
        ...state,
        userSingle: action.payload.data,
      }

    default:
      return state
  }
}

export default user
