import React, { useEffect, useState } from 'react'
import Multiselect from 'multiselect-react-dropdown';
import { Button, Col, Row } from 'reactstrap';
import { Height } from '@mui/icons-material';
import { get, post, setTokenHeader } from '../../../helpers/api_helper';
import "./top10Titles.css"
import { toast } from 'react-toastify';
import { RiDragMove2Line } from 'react-icons/ri';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
const API_URL = `${process.env.REACT_APP_ZFLIX_URL}/v1`

const Top10 = () => {

    const location = useLocation()
    let type = ""
    if (location.pathname == "/top10/movies") {
        type = "movie"
    } else {
        type = "series"

    }

    const [moviesList, setMoviesList] = useState([])
    const [sortableMovies, setSortableMovies] = useState({
        items: [], draggingItem: null,
        newItemName: '',
        newItemImage: '',
    })

    // series




    useEffect(() => {
        const getTitles = async () => {
            const token = JSON.parse(sessionStorage.getItem("authUser")).access_token

            await setTokenHeader(token)
            let res = await get(`${API_URL}/titles?limit=10000`)
            console.log(res)
            setMoviesList(res.data.results)
            setSortableMovies((rest) => {
                return {
                    ...rest, items: res.data.results?.filter((fi) => fi?.rankTitle != "" && fi?.rankTitle != null && fi.type == type)?.sort((a, b) => parseInt(a.rankTitle) - parseInt(b.rankTitle))
                }
            })
        }
        getTitles()

    }, [])
    const list = {
        options: [{ name: 'Option 1', id: 1 }, { name: 'Option 2️', id: 2 }]
    };
    const onSelect = (selectedList, selectedItem) => {
        setSortableMovies((rest) => { return { ...rest, items: selectedList } })
    }
    const onRemove = (selectedList, removedItem) => {
        setSortableMovies((rest) => { return { ...rest, items: selectedList } })

    }

    const handleDragStart = (e, item) => {
        setSortableMovies((rest) => { return { ...rest, draggingItem: item } });
        e.dataTransfer.setData('text/plain', '');
    };

    const handleDragEnd = () => {
        setSortableMovies((rest) => { return { ...rest, draggingItem: null } });

    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e, targetItem) => {
        const { draggingItem, items } = sortableMovies;
        if (!draggingItem) return;

        const currentIndex = items.indexOf(draggingItem);
        const targetIndex = items.indexOf(targetItem);

        if (currentIndex !== -1 && targetIndex !== -1) {
            items.splice(currentIndex, 1);
            items.splice(targetIndex, 0, draggingItem);
            setSortableMovies((rest) => { return { ...rest, items: items } })

        }
    };


    const updateMovieRanks = async () => {
        let body = {
            titles: sortableMovies?.items?.map((item) => item.id),
            type: type

        }
        const res = await post(`${API_URL}/titles/updateTitleRank`, body)
        if (res.data.status) {
            toast.success(`${res.data.message}`)

        } else {
            toast.error(`${res.data.message}`)

        }
    }

    console.log('sortableMovies', sortableMovies)
    return (
        <div className='page-content'>
            <Row>
                <Col xs={6}>
                    <h2>
                        {type == "movie" ? "Manage Top Movies" : "Manage Top  Series"}
                    </h2>
                </Col >
                <Col xs={6}>
                    <h2>
                        Drag To Sort Movies
                    </h2>
                </Col>
            </Row>
            <Row>

                <Col xl={6} >
                    <Multiselect
                        options={moviesList} // Options to display in the dropdown
                        selectedValues={sortableMovies?.items} // Preselected value to persist in dropdown
                        onSelect={onSelect} // Function will trigger on select event
                        onRemove={onRemove} // Function will trigger on remove event
                        displayValue="title" // Property name to display in the dropdown options
                        style={{ multiselectContainer: { height: 200 } }}
                    />
                </Col>

                <Col xl={6} >
                    
                    <div className='mt-4'>
                        {sortableMovies?.items?.map((item, index) => (
                            <div
                                key={item.id}
                                className=
                                {`item ${item === sortableMovies?.draggingItem ?
                                    'dragging' : ''
                                    }`}
                                draggable="true"
                                onDragStart={(e) => handleDragStart(e, item)}
                                onDragEnd={handleDragEnd}
                                onDragOver={handleDragOver}
                                onDrop={(e) => handleDrop(e, item)}
                            >
                                <div className="details">
                                    <img src={item?.poster} alt={item?.title} />
                                    <span>{item?.title}</span>
                                    <span className='mx-5' style={{ fontSize: 15 }}> <RiDragMove2Line /></span>
                                </div>

                                {/* Use the React icon component */}
                            </div>
                        ))}
                    </div>
                </Col>

            </Row>
            <Row>
                <Col xs={6}>
                    <Button color="primary" onClick={updateMovieRanks}>
                        Submit
                    </Button>
                </Col>
            </Row>
        </div>
    )
}

export default Top10