import React, { useEffect, useRef, useState } from "react"

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Input,
  InputGroup,
} from "reactstrap"
import cloud from "../../../../assets/flix-images/cloud-file-download.svg"
import "./userAdd.css"
import * as Yup from "yup"

//Import Breadcrumb
import { toast } from "react-toastify"
import { Field, Formik, Form } from "formik"
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Breadcrumbs } from "@material-ui/core"
import { setTokenHeader } from "../../../../helpers/api_helper"
import { addNewUser } from "../../../../store/actions"

const AddUser = props => {


  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem("authUser"))

    setTokenHeader(data.access_token)
  }, [])


  const history = useHistory()
  const dispatch = useDispatch();
  //meta title
  const uploadRef = useRef(null)
  document.title = "Manage Customers"
  const [data, setData] = useState({
    name: "",
    email: "",
    password: "",
  })



  const AddUser = Yup.object().shape({
    name: Yup.string()
      .max(1000, "Too Long!!")
      .required("Enter the User Name"),
      email: Yup.string()
        .required("Enter Email"),
        password: Yup.string()
        .min(10)
        .required("Please enter password"),
        // role: Yup.string()
        // .required("Please select"),
  })

  const HandleSubmit = e => {
    dispatch(addNewUser(
      data
    ,history))
    
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="User" breadcrumbItem="Add User" />
          <Formik
            initialValues={{ ...data }}
            validationSchema={AddUser}
            enableReinitialize
            onSubmit={(e) => {
              HandleSubmit();
              return false;
            }}
          >
            {({ values, setValues, setFieldValue, errors, touched }) => (
              <Row>
                <Col xl={8}>
                  <Card>
                    <CardBody>
                      <Form
                        
                      >
                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">
                            Name
                          </Label>
                          <Field
                            type="text"
                            className="form-control"
                            id="name"
                            name="Name"
                            onChange={e => {
                              setData({ ...data, name: e.target.value })
                              setFieldValue('name', e.target.value)
                            }}
                          />
                          {errors.name && touched.name ? (
                            <div className="text-danger input-error">
                              {errors.name}
                            </div>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">
                            Email
                          </Label>
                          <Field
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            onChange={e => {
                              setData({ ...data, email: e.target.value })
                              setFieldValue('email', e.target.value)
                            }}
                          />
                          {errors.email && touched.email ? (
                            <div className="text-danger input-error">
                              {errors.email}
                            </div>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">
                            Password
                          </Label>
                          <Field
                            type="text"
                            className="form-control"
                            id="password"
                            name="password"
                            onChange={e => {
                              setData({ ...data, password: e.target.value })
                              setFieldValue('password', e.target.value)
                            }}
                          />
                          {errors.password && touched.password ? (
                            <div className="text-danger input-error">
                              {errors.password}
                            </div>
                          ) : null}
                        </div>
                      
                        <div>
                          <button
                            type="submit"
                            className="btn btn-primary w-md"
                          >
                            Add User
                          </button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
          </Formik>
        </Container>
        {/* container-fluid */}
      </div>
    </React.Fragment>
  )
}


export default AddUser
