import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_PLAN, ADD_NEW_PLAN, DELETE_PLAN, UPDATE_PLAN, UPDATE_PLAN_IMAGE, EDIT_PLAN } from "./actionTypes"

import {
  getPlanSuccess,
  getPlanFail,
  addPlanFail,
  addPlanSuccess,
  updatePlanSuccess,
  updatePlanFail,
  deletePlanSuccess,
  deletePlanFail,
  editPlanSuccess,
  editPlanFail,
} from "./actions"

//Include Both Helper File with needed methods
import { getPlan } from "../../helpers/fakebackend_helper"
import { stopLoader } from "../loaderState/actions"

function* fetchPlan({ payload: page }) {
  try {

    const response = yield call(getPlan,page.page)
    yield put(getPlanSuccess(response))
    yield put(stopLoader())
  } catch (error) {
    yield put(getPlanFail(error))
  }
}

function* onEditPlan({ payload: plan }) {
  try {
    const response = yield call(editPlan, plan)
    yield put(editPlanSuccess(response))
  } catch (error) {
    yield put(editPlanFail(error))
  }
}
function* onUpdatePlan({ payload: { id, plan, history } }) {
  try {
    const response = yield call(updatePlan, id, plan)
    yield put(updatePlanSuccess(response.data))


    history.push("/plans");

  } catch (error) {
    yield put(updatePlanFail(error))
  }
}

function* onDeletePlan({ payload: { id, history } }) {
  try {
    const response = yield call(deletePlan, id)

    yield put(deletePlanSuccess(id))
    history.push("/plans");

  } catch (error) {
    console.log(" onDeletePlan error ", error);
    yield put(deletePlanFail(error))
  }
}

function* onAddNewPlan({ payload: { plan, history } }) {

  try {
    const name = plan.name;
    const email = plan.email;
    const password = plan.password;
    const role = plan.role;

    const response = yield call(addNewPlan, { name, email, password, role })

    yield put(addPlanSuccess(response.data))
    history.push("/plans");

  } catch (error) {
    yield put(addPlanFail(error))

  }
}


function* planSaga() {
  yield takeEvery(GET_PLAN, fetchPlan)
  yield takeEvery(ADD_NEW_PLAN, onAddNewPlan)
  yield takeEvery(UPDATE_PLAN, onUpdatePlan)
  yield takeEvery(DELETE_PLAN, onDeletePlan)
  yield takeEvery(EDIT_PLAN, onEditPlan)
}

export default planSaga;
