import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap"
import Switch from "react-switch";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// import Spinner from "loader";
import { ErrorMessage, Field, Form, Formik } from "formik"
import * as Yup from "yup"
import { addNewCategory, getCategories } from "../../store/actions";
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import SelectAsyncPaginate from "../../pages/ManageTitles/TitleAdd/SelectAsyncPaginate"
import { checkArray, checkNull } from "../../helpers/basicFunctions"
import { get } from "../../helpers/api_helper";

const AddCategory = props => {
  const dispatch = useDispatch();
  let history = useHistory();
  const { isOpen, toggle } = props
  const [network, setNetwork] = useState({
    name: "",
    type: "",
    watchProvider: "",
    titleType: "",
    titles: [],
    description: "",
    isActive: "",
    image: null
  })
  const [close, setClose] = useState(true)
  const [isFree, setIsFree] = useState(false)
  const [watchProviders, setWatchProviders] = useState([])


  const handleClose = () => {
    setClose(toggle)
    dispatch(getCategories())
  }

  const handleChange = (checked) => {
    checked == true ? setIsFree(true) : setIsFree(false);
  }

  const stepAddSchema = Yup.object().shape({
    name: Yup.string().required("Enter title"),
    description: Yup.string().required("Enter description"),
  })

  const handleAddNetwork = async (values) => {
    const name = network.name;
    const type = network.type == 1 ? "Moviez-now" : "Tmdb";
    const watchProvider = parseInt(network.watchProvider);
    const titleType = network.titleType;

    const description = network.description;
    const titles = network.type == 1 ? network.titles.map((title) => title.value) : [];
    const image = network.image;

    const isActive = network.isActive == 1 ? true : false;
    dispatch(addNewCategory({ name, description, isActive, image, titles, isFree, type, watchProvider, titleType }, history, handleClose))
  }

  useEffect(() => {
    getImageTag()
  }, [network])

  useEffect(() => {
    const fetchProviders = async () => {
      const url = 'https://api.themoviedb.org/3/watch/providers/movie';
      const options = {
        headers: {
          accept: 'application/json',
          Authorization: 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIxNDE2YjYzYjZlYzAwNmQ3ZWQ3YWYwMzEwZTAxZGJlNiIsInN1YiI6IjY2NTk5Yjg1MTM1MDVkY2VjYzUwOTA2NCIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.Wh3iA-H2rK7HWnwTS6JNNhFPGMX3ICikp93WuE2qwX0'
        }
      };
      const res = await get(url, options)
      setWatchProviders([...res.results])

    }
    if (network.type == 2) {
      fetchProviders()
    }
  }, [network.type])



  const getImageTag = () => {
    let res = null
    const img = document.getElementById('imageTag');
    const imgCon = document.getElementById('imageCon');
    if (imgCon && img) {
      imgCon.classList.add('d-none');
      if (network?.image) {
        const reader = new FileReader()
        reader.onload = function (e) {
          img.src = e.target.result;
          imgCon.classList.remove('d-none')
        }
        reader.readAsDataURL(network?.image)
      }
    }
    return res
  }

  const loadTitleOptions = async (search, loadedOptions, { page }) => {
    try {
      let optUrl = `${process.env.REACT_APP_ZFLIX_URL}/v1/titles?page=${page}`
      if (checkNull(search, false)) {
        optUrl = optUrl + "&search=" + search
      }
      const response = await fetch(optUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("authUser")).access_token
            }`,
          "Content-Type": "application/json",
        },
      })
      const res = await response.json()
      let newOptions = [...loadedOptions]
      let totalPages = page
      if (res?.data) {
        const data = res.data
        const updatedOptions = checkArray(data.results)
          ? data.results.map(result => ({
            value: result.id,
            name: `${result.title} (${result.year})`,
          }))
          : []

        newOptions = [...newOptions, ...updatedOptions]
        totalPages = data.totalPages
      }

      return {
        options: newOptions,
        hasMore: page < totalPages ? true : false, // Whether there are more pages to load
        additional: {
          page: page + 1,
        },
      }
    } catch (error) {
      console.log("error : ", error)
      return {
        options: [],
        hasMore: false,
        additional: { page: 1 },
      }
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal bs-example-modal" role="dialog"></div>
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Add category</ModalHeader>
        <Formik
          initialValues={{
            name: "",
            description: "",
          }}
          validationSchema={stepAddSchema}
          onSubmit={(values, actions) => {
            handleAddNetwork(values)
          }}
        >
          {({ values, setValues, setFieldValue, errors, touched }) => (
            <Form

              onSubmit={(e) => {
                e.preventDefault();
                handleAddNetwork();
                return false;
              }}
            >
              <ModalBody>
                <Label className="my-2" name="networkName">
                  Name:
                </Label>
                <input
                  type="text"
                  className="form-control input-color "
                  placeholder="Enter name here"
                  name="name"
                  onChange={e => {
                    setNetwork({ ...network, name: e.target.value })
                    setFieldValue('name', e.target.value)
                  }}
                />
                {errors.name && touched.name ? (
                  <div className="input-error text-danger">{errors.name}</div>
                ) : null}

                <Row>

                  <Col>
                    <Label htmlFor="categoryType" className="my-2">Category Type</Label>
                    <Field name="categoryType" className="form-control"
                      onChange={e => {
                        setNetwork({ ...network, type: e.target.value })
                      }}>
                      {({ field, form }) => (
                        <div className="sub-category-select">
                          <select id="categoryType" className="form-control input-color " name="categoryType" onChange={e => {
                            setNetwork({ ...network, type: e.target.value })
                          }}>
                            <option value="">Choose</option>
                            <option value="1">Moviez-now</option>
                            <option value="2">Tmdb</option>
                          </select>
                          {form.touched.type &&
                            form.errors.type ? (
                            <div className="input-error text-danger">
                              {form.errors.type}
                            </div>
                          ) : null}
                        </div>
                      )}
                    </Field>
                  </Col>

                </Row>

                {
                  network.type == "1" ? (<>

                    <Label htmlFor="formrow-firstname-Input" className="my-2">Titles</Label>
                    <SelectAsyncPaginate
                      // regionName={region.value}
                      loadOptions={loadTitleOptions}
                      placeHolder="Select Title"
                      isMulti={true}
                      value={network.titles}
                      onChange={titles => {
                        setNetwork({ ...network, titles })
                      }}
                      isSearchable={true}
                    />
                    {errors.titles && touched.titles ? (
                      <div className="text-danger input-error">
                        {errors.titles}
                      </div>
                    ) : null}

                  </>) : <></>

                }

                {
                  network.type == "2" ? (

                    <Row>

                      <Col>
                        <Label htmlFor="watchProvider" className="my-2">Watch Provider</Label>
                        <Field name="watchProvider" className="form-control"
                          onChange={e => {
                            setNetwork({ ...network, type: e.target.value })
                          }}>
                          {({ field, form }) => (
                            <div className="sub-category-select">
                              <select id="watchProvider" className="form-control input-color " name="watchProvider" onChange={e => {
                                setNetwork({ ...network, watchProvider: e.target.value })
                              }}>
                                <option value="">Choose</option>
                                {
                                  watchProviders?.map((item, index) => {
                                    return (

                                      <option key={index} value={item.provider_id}>{item.provider_name}</option>
                                    )
                                  })
                                }

                              </select>
                              {form.touched.watchProvider &&
                                form.errors.watchProvider ? (
                                <div className="input-error text-danger">
                                  {form.errors.watchProvider}
                                </div>
                              ) : null}
                            </div>
                          )}
                        </Field>
                      </Col>

                    </Row>

                  ) : (<></>)


                }

                {
                  network.type == "2" ? (

                    <Row>

                      <Col>
                        <Label htmlFor="titleType" className="my-2">Title Type</Label>
                        <Field name="titleType" className="form-control"
                          onChange={e => {
                            setNetwork({ ...network, titleType: e.target.value })
                          }}>
                          {({ field, form }) => (
                            <div className="sub-category-select">
                              <select id="titleType" className="form-control input-color " name="titleType" onChange={e => {
                                setNetwork({ ...network, titleType: e.target.value })
                              }}>
                                <option value="">Choose</option>
                                <option value="movie">Movie</option>
                                <option value="tv">Tv</option>


                              </select>
                              {form.touched.titleType &&
                                form.errors.titleType ? (
                                <div className="input-error text-danger">
                                  {form.errors.titleType}
                                </div>
                              ) : null}
                            </div>
                          )}
                        </Field>
                      </Col>

                    </Row>

                  ) : (<></>)


                }

                <Label className="my-2" name="networkDescription">
                  Description:
                </Label>
                <Field
                  as="textarea"
                  className="form-control input-color "
                  placeholder="Enter Description here"
                  name="description"
                  rows="5"
                  onChange={e => {
                    setNetwork({ ...network, description: e.target.value })
                    setFieldValue('description', e.target.value)
                  }}
                />
                {errors.description && touched.description ? (
                  <div className="input-error text-danger">{errors.description}</div>
                ) : null}

                <Label className="my-2">Category Image:</Label>
                <div className="commonImgs d-none" id="imageCon">
                  <img
                    id="imageTag"
                    alt=""
                    className="every-img"
                  />
                </div>
                <input
                  type="file"
                  className="form-control input-color"
                  onChange={e => {
                    setNetwork({ ...network, image: e.target.files[0] })
                  }}
                />
                <Row>
                  <Col>
                    <Label htmlFor="isActive" className="my-2">Show on home page?</Label>
                    <Field name="isActive" className="form-control"
                      onChange={e => {
                        setNetwork({ ...network, isActive: e.target.value })
                      }}>
                      {({ field, form }) => (
                        <div className="sub-category-select">
                          <select id="isActive" className="form-control input-color " name="isActive" onChange={e => {
                            setNetwork({ ...network, isActive: e.target.value })
                          }}>
                            <option value="">Choose</option>
                            <option value="1">yes</option>
                            <option value="0">No</option>
                          </select>
                          {form.touched.isActive &&
                            form.errors.isActive ? (
                            <div className="input-error text-danger">
                              {form.errors.isActive}
                            </div>
                          ) : null}
                        </div>
                      )}
                    </Field>
                  </Col>
                  {
                    network.type == "1" ? (<Col>
                      <Label className="my-2" name="networkName">
                        Make it Free:
                      </Label><br />
                      <Switch onChange={handleChange} checked={isFree} />
                    </Col>) : (<></>)

                  }

                </Row>
              </ModalBody>
              <ModalFooter>
                <Button type="submit" color="primary" >
                  Save Changes
                </Button>
                <Button type="button" color="secondary" onClick={toggle}>
                  Close
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  )
}

AddCategory.propTypes = {
  toggle: PropTypes.func,
  changeApiStatus: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default AddCategory