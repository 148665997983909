import { createSlice } from "@reduxjs/toolkit";
import { getDashboardData } from "./action";

const dashboardSlice = createSlice({
    name: 'dashboardSlice',
    initialState: {
        dashboardData:[],
    },
    reducers: {

    },
    extraReducers: (builder) => {

        // handle  
        builder.addCase(getDashboardData.pending, (state) => {
            return { ...state, dashboardData: [],};
        });
        builder.addCase(getDashboardData.fulfilled, (state, action) => {
            if (action.payload[0])
                return {
                    ...state,
                    dashboardData: action.payload[1].data,
                }
            else
                return { ...state, dashboardData: [], errors: action.payload[1] }
        });
        builder.addCase(getDashboardData.rejected, (state) => {
            return { ...state, dashboardData: [] }
        });

    }
})

export default dashboardSlice.reducer;