import {
  GET_CATEGORIES,
  GET_CATEGORIES_PROFILE,
  GET_CATEGORIES_FAIL,
  GET_CATEGORIES_SUCCESS,
  ADD_NEW_CATEGORY,
  ADD_CATEGORY_SUCCESS,
  ADD_CATEGORY_FAIL,
  UPDATE_CATEGORY_IMAGE,
  UPDATE_CATEGORY_IMAGE_SUCCESS,
  UPDATE_CATEGORY_IMAGE_FAIL,
  UPDATE_CATEGORY,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAIL,
  DELETE_CATEGORY,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAIL,
} from "./actionTypes"

export const getCategories = () => ({
  type: GET_CATEGORIES,
})
export const getCategoriesProfile = id => ({
  type: GET_CATEGORIES_PROFILE,
  payload: id,
})

export const getCategoriesSuccess = categories => ({
  type: GET_CATEGORIES_SUCCESS,
  payload: categories,
})

export const addNewCategory = (category, history, handleClose) => ({
  type: ADD_NEW_CATEGORY,
  payload: { category, history, handleClose },
})

export const addCategorySuccess = category => ({
  type: ADD_CATEGORY_SUCCESS,
  payload: category,
})

export const addCategoryFail = error => ({
  type: ADD_CATEGORY_FAIL,
  payload: error,
})

export const getCategoriesFail = error => ({
  type: GET_CATEGORIES_FAIL,
  payload: error,
})

export const updateCategory = category => ({
  type: UPDATE_CATEGORY,
  payload: category,
})

export const updateCategoryimage = (id, image) => ({
  type: UPDATE_CATEGORY_IMAGE,
  payload: { id, image },
})

export const updateCategoryImageSuccess = category => ({
  type: UPDATE_CATEGORY_IMAGE_SUCCESS,
  payload: category,
})
export const updateCategoryImageFail = error => ({
  type: UPDATE_CATEGORY_IMAGE_FAIL,
  payload: error,
})

export const updateCategorySuccess = category => ({
  type: UPDATE_CATEGORY_SUCCESS,
  payload: category,
})

export const updateCategoryFail = error => ({
  type: UPDATE_CATEGORY_FAIL,
  payload: error,
})

export const deleteCategory = (category, history) => ({
  type: DELETE_CATEGORY,
  payload: { category, history },
})

export const deleteCategorySuccess = id => ({
  type: DELETE_CATEGORY_SUCCESS,
  payload: id,
})

export const deleteCategoryFail = error => ({
  type: DELETE_CATEGORY_FAIL,
  payload: error,
})
