import React, { useState, useEffect, useRef } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  Form,
} from "reactstrap"

import { withRouter } from "react-router-dom"
import Breadcrumb from "../../components/Common/Breadcrumb"
import avatar from "../../assets/images/users/avatar-1.jpg"
import { useDispatch,useSelector } from "react-redux"
import { getProfile } from "../../store/myAuth/toolkit/action"
import FullPageLoader from "../../components/Common/FullPageLoader"
import { fireToast } from "../../common/toast"

const UserProfile = () => {
  const dispatch = useDispatch();
  const { profile_data, loadingState } = useSelector(state => state.Auth);
  const [profile, setProfile ] = useState(profile_data);
  const [profileImg, setProfileImg ] = useState(avatar);

  useEffect(()=>{
    const id = JSON.parse(sessionStorage.getItem("authUser")).id
    dispatch(getProfile(id))
  },[])

  useEffect(()=>{
    if (profile_data) {
      setProfile(profile_data);
    }
  },[profile_data])

  const nameRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();
  const uploadRef = useRef()

  document.title = "Profile | Moviez - Admin Dashboard"
  const handleProfileSubmit = (e)=>{
    e.preventDefault();

    const updatedData = {
      name : nameRef.current.value,
      phone : phoneRef.current.value,
      email : emailRef.current.value
      // image : 
      // country_code
    }
  }

  const handleImageUpload = async (file)=>{
    const extn = ["image/jpg", "image/png", "image/jpeg"]
    const [image] = file

    if (image && extn.includes(image.type)) {
      if (image.size <= 5242880) {
        console.log("profileImg1")
        setProfileImg({ blob: image, src: window.URL.createObjectURL(image) })
      } else {
        console.log("profileImg2")
        fireToast("error", "image too large")
      }
    } else {
      console.log("profileImg3")
      fireToast(
        "error",
        "Please select a valid image file(only jpg, png and jpeg images are allowed)"
      )
    }
  }

  if (Object.keys(profile).length === 0) {
    return <FullPageLoader />
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Moviez" breadcrumbItem="Profile" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3">
                      <img
                        src={avatar}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5></h5>
                        <p className="mb-1"></p>
                        <p className="mb-0">Id no: #</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4">Update Profile</h4>
          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={handleProfileSubmit}
              >
                <Row>
                  <Col lg={6} sm={6} md={6}>
                    <div className="form-group">
                      <Label className="form-label">Name </Label>
                      <Input
                        ref={nameRef}
                        name="username"
                        className="form-control"
                        placeholder="Enter User Name"
                        type="text"
                        // value={profile.name}
                        defaultValue={profile.user.name}
                      />
                    </div>
                    <div className="form-group mt-3">
                      <Label className="form-label">Email</Label>
                      <Input
                        ref={emailRef}
                        name="email"
                        className="form-control"
                        placeholder="Enter User Name"
                        type="email"
                        defaultValue={profile.user.email}
                      />
                    </div>
                    <div className="form-group mt-3">
                      <Label className="form-label">Phone No. </Label>
                      <Input
                        ref={phoneRef}
                        defaultValue={profile.user.phone}
                        name="phone"
                        className="form-control"
                        placeholder="Enter User Name"
                        type="number"
                        minLength={10}
                        maxLength={10}
                      />
                    </div>
                  </Col>
                  <Col lg={6} sm={6} md={6}>
                    <div className="form-group text-center">
                      <img
                        src={avatar}
                        alt=""
                        className="avatar-lg rounded-circle"
                      />
                    </div>
                    <div className="form-group mt-3">
                      <Label className="form-label">Uplaod Image </Label>
                      <Input
                        ref={uploadRef}
                        accept="image/*"
                        name="file"
                        className="form-control"
                        placeholder="Enter User Name"
                        type="file"
                        onChange={e =>
                          handleImageUpload(e.target.files)
                        }
                      />
                    </div>
                  </Col>
                </Row>
                <div className="text-end mt-4">
                  <Button type="submit" color="success">
                    Update Profile
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>

          <h4 className="card-title mb-4">Change Password</h4>
          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
              >
                <Row>
                  <Col lg={6} sm={6} md={6}>
                    <div className="form-group">
                      <Label className="form-label">Old Password </Label>
                      <Input
                        name="username"
                        className="form-control"
                        placeholder="Enter User Name"
                        type="text"
                      />
                    </div>
                  </Col>
                  <Col lg={6} sm={6} md={6}>
                    <div className="form-group">
                      <Label className="form-label">New Password</Label>
                      <Input
                        name="username"
                        className="form-control"
                        placeholder="Enter User Name"
                        type="text"
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col lg={6} sm={6} md={6}>
                    <div className="form-group">
                      <Label className="form-label">Confirm Password</Label>
                      <Input
                        name="username"
                        className="form-control"
                        placeholder="Enter User Name"
                        type="text"
                      />
                    </div>
                  </Col>
                </Row>

                <div className="text-end mt-4">
                  <Button type="submit" color="success">
                    Update Password
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
        <FullPageLoader loading={loadingState} />
      </div>
    </React.Fragment>
  )
}

export default withRouter(UserProfile)
