import React, { useEffect, useState } from "react"
import { Button } from "reactstrap"
import "./index.css"
import { CCard, CCardBody, CCardGroup } from "@coreui/react"
import DataTable from "react-data-table-component"
import { Row, Col } from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useSelector } from "react-redux"
import FullPageLoader from "../../../components/Common/FullPageLoader"
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

const TitleLogDetails = () => {
  document.title = "Log Report Details"
  const history = useHistory();
  const { loadingState } = useSelector(state => state.loadingState)
  const { logReportDetails } = useSelector(state => state.TitleSlice)
  const [reportDetails, setReportDetails] = useState([]);

  useEffect(() => {
    setReportDetails(logReportDetails)
  }, [logReportDetails])


  let rowCounter = 1
  const columns = [
    {
      name: "Sr. no",
      selector: row => rowCounter++,
      sortable: true,
      maxWidth: "100px",
    },
    {
      name: "Title Name",
      selector: row => row.movieTitle,
      sortable: true,
    },
    {
      name: "Watched Duration",
      selector: row => {
        const hours = Math.floor(row.watchTime / 3600); 
        const minutes = Math.floor((row.watchTime % 3600) / 60); 
        const seconds = row.watchTime % 60;
        return `${hours}h ${minutes}m ${seconds}s`;

      },
      sortable: true,
    },
    {
      name: "Plateform",
      selector: row => row.plateform ? row.plateform : "",
      sortable: true,
    },
    {
      name: "Filmhub ID",
      selector: row => row.filmhubId ? row.filmhubId : "",
      sortable: true,
    },
    {
      name: "User",
      selector: row => row.userName ? row.userName : "",
      sortable: true,
    },
    {
      name: "Email",
      selector: row => row.email ? row.email : "N/A",
      sortable: true,
    },
    {
      name: "Date",
      selector: row => row.created_at ? new Date(row.created_at).toLocaleString(): "",
      sortable: true,
    },
  ]
  const handleBack = () => {
    history.goBack()
  }

  if(!reportDetails || reportDetails.length === 0){
    return <FullPageLoader/>
  }

  return (
    <React.Fragment>
      <div className="page-content genre">
        <div className="container-fluid">
          <Breadcrumbs title="Log Report" breadcrumbItem="Log Report Details" />
          <Row>
            <Col xl={12}>
              <div className="mb-2 d-flex align-items-center justify-content-end">

              </div>

              <CCardGroup>
                <CCard>
                  <CCardBody>
                  <Button onClick={handleBack} size="sm" variant="secondary">Back</Button>{' '}

                    {reportDetails && reportDetails.length > 0 ? (
                      <DataTable
                        striped
                        columns={columns}
                        data={reportDetails}
                        highlightOnHover
                      />
                    ) : (
                      <div>No result found</div>
                    )}
                  </CCardBody>
                </CCard>
              </CCardGroup>
              <FullPageLoader loading={loadingState} />
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default TitleLogDetails
