import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { AsyncPaginate } from "react-select-async-paginate";
import axios from "axios";
import { useDispatch } from "react-redux";
import { getGenre } from "../../../store/actions";

const SelectAsyncPaginate = (props) => {
  const dispatch = useDispatch()
  const [regionName, setRegionName] = useState(null);

  useEffect(() => {
    setRegionName(props.regionName);
  }, [props.regionName]);

  const loadOptions = async (searchQuery, loadedOptions, { page }) => {
    try {
      const callback = (data) => {
        return {
          options: responseJSON.data.data.results,
          // hasMore: responseJSON.length >= 1,
          hasMore: page < responseJSON.data.data.totalPages ? true : false,
          additional: {
            page: searchQuery ? 2 : page + 1,
          },
        }
      }

      dispatch(getGenre({page, callback }))
      // console.log('page : ' , page);
      // axios.defaults.headers.common['Authorization'] = `Bearer ${JSON.parse(sessionStorage.getItem("authUser")).access_token}`;
  
      // const responseJSON = await axios.get(`${process.env.REACT_APP_ZFLIX_URL}/v1/genres?page=${page}`);
  
      // console.log("responseJSON", responseJSON);

      // return {
      //   options: responseJSON.data.data.results,
      //   // hasMore: responseJSON.length >= 1,
      //   hasMore: page < responseJSON.data.data.totalPages ? true : false,
      //   additional: {
      //     page: searchQuery ? 2 : page + 1,
      //   },
      // };
    } catch (error) {
      console.log("err : ", error);
      return {
        options: [],
        hasMore: false,
        additional: { page: 1 },
      };
    }

  };

  const onChange = (option) => {
    if (typeof props.onChange === "function") {
      props.onChange(option);
    }
  };

  return (
    <AsyncPaginate   
      // key={JSON.stringify(regionName)}
      value={props.value || ""}
      // loadOptions={loadOptions}
      getOptionValue={(option) => option.name}
      getOptionLabel={(option) => option.name}
      onChange={onChange}
      isSearchable={false}
      additional={{
        page: 1,
      }}
      {...props}
    />
  );
};

SelectAsyncPaginate.propTypes = {
  // regionName: PropTypes.string.isRequired,
  value: PropTypes.array,
  onChange: PropTypes.func,
};

export default SelectAsyncPaginate;