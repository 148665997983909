import React, { useEffect, useState } from "react"
import { Button } from "reactstrap"
import "./index.css"
import { CCard, CCardBody, CCardGroup } from "@coreui/react"
import DataTable from "react-data-table-component"
import { Row, Col } from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useSelector } from "react-redux"
import FullPageLoader from "../../../components/Common/FullPageLoader"
import "react-datepicker/dist/react-datepicker.css";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min"

const SearchLogDetails = () => {
  document.title = "Search Report Details"
  const history = useHistory();
  const location = useLocation();
  const data = location.state.data;
  console.log("to check data===========>", location.state)

  const { loadingState } = useSelector(state => state.loadingState)
  const [reportDetails, setReportDetails] = useState([]);

  useEffect(() => {
    setReportDetails(data)
  }, [data])


  let rowCounter = 1
  const columns = [
    {
      name: "Sr. no",
      selector: row => rowCounter++,
      sortable: true,
      maxWidth: "100px",
    },
    {
      name: "Email",
      selector: row => row?.userId?.email || "N/A",
      sortable: true,
    },
    {
      name: "User",
      selector: row => row?.userId?.name || "Guest",
      sortable: true,
    },
    {
      name: "Filter",
      cell: row => (
        <div style={{
          // maxHeight: '100px', 
          // overflowY: 'auto',
           wordBreak: 'break-all' 
           }}>
          {row?.filters?.join(', ') || "N/A"}
        </div>
      ),
      sortable: true,
      minWidth: "400px",
    },
    
    {
      name: "Search Count",
      selector: row => row?.count ,
      sortable: true,
    },
  
    {
      name: "Search Date",
      selector: row => new Date(row?.updatedAt).toLocaleString() || "",
      sortable: true,
    },
   
  ]
  const handleBack = () => {
    history.goBack()
  }

  if(!reportDetails || reportDetails.length === 0){
    return <FullPageLoader/>
  }

  return (
    <React.Fragment>
      <div className="page-content genre">
        <div className="container-fluid">
          <Breadcrumbs title="Search Report" breadcrumbItem="Search Report Details" />
          <Row>
            <Col xl={12}>
              <div className="mb-2 d-flex align-items-center justify-content-end">

              </div>
              <div className="mb-2 d-flex justify-content-between">
    <h4> Searched Text: <strong>{`"${reportDetails?.keyword}"`}</strong> </h4>
    <h4>Total Searched Count: <strong>{reportDetails.count}</strong></h4>
  </div>

              <CCardGroup>
                <CCard>
                  <CCardBody>
                  <Button onClick={handleBack} size="sm" variant="secondary">Back</Button>{' '}

                    {reportDetails && reportDetails?.users.length > 0 ? (
                      <DataTable
                        striped
                        columns={columns}
                        data={reportDetails?.users}
                        highlightOnHover
                      />
                    ) : (
                      <div>No result found</div>
                    )}
                  </CCardBody>
                </CCard>
              </CCardGroup>
              <FullPageLoader loading={loadingState} />
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default SearchLogDetails
