import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { START_LOADER,
  STOP_LOADER } from "./actionTypes"

import {
  startLoaderSuccess,
  stopLoaderSuccess
} from "./actions"

//Include Both Helper File with needed methods



function* onStartLoader() {
  yield put(startLoaderSuccess())
}
function* onStopLoader() {
  yield put(stopLoaderSuccess())
}


function* LoaderSaga() {
  yield takeEvery(START_LOADER, onStartLoader)
  yield takeEvery(STOP_LOADER, onStopLoader)

}

export default LoaderSaga;
