import React, { useEffect, useState } from "react";
import { CCard, CCardBody, CCardGroup } from "@coreui/react";
import DataTable from "react-data-table-component";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useSelector, useDispatch } from "react-redux";
import FullPageLoader from "../../components/Common/FullPageLoader";
import {
    Col,
    Row,
} from "reactstrap";
import axios from "axios";
import CIcon from "@coreui/icons-react";
import { cilPencil, cilTrash } from "@coreui/icons";
import { updateHistory } from "../../store/title/toolkit/slice";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import { toastConfirm } from "../../common/toast";

const NotificationList = () => {
    const dispatch = useDispatch();
    const history = useHistory()
    const API_URL = `${process.env.REACT_APP_ZFLIX_URL}/v1`;
    const [data, setData] = useState("");
    const { titlePage } = useSelector(state => state.TitleSlice)
    const [page, setPage] = useState(titlePage || 1);

    useEffect(() => {
        document.title = "Notification List";
        fetchNotificationData();
    }, [dispatch, page]);

    const fetchNotificationData = (pageNumber = 1) => {
        const token = JSON.parse(sessionStorage.getItem("authUser"));
        const headers = {
            Authorization: `Bearer ${token.access_token}`,
        };

        axios
            .get(`${API_URL}/admin-notification?page=${pageNumber}`, { headers: headers })
            .then(response => {
                setData(response.data?.data);
            })
            .catch(error => console.log(error));
    };

    const deleteNotificationData = (id) => {
        const token = JSON.parse(sessionStorage.getItem("authUser"));
        const headers = {
            Authorization: `Bearer ${token.access_token}`,
        };
        axios
            .delete(`${API_URL}/admin-notification/${id}`, { headers: headers })
            .then(response => {
                toast.success(response.data?.message);
                const newArray = data && data.notification.filter((v)=> v._id != id)
                setData({...data,notification:newArray})
            })
            .catch(error => console.log(error));
    };

    const handleDeleteTitle = async id => {

        const response = await (
            await toastConfirm("Are you sure you want to delete this?")
        ).fire()
        if (response.isConfirmed) {
            deleteNotificationData(id)
        }
    }

    const handleTitlePageUpdateAndRedirect = (id, type) => {
        dispatch(updateHistory({page}));
        if (type === 'edit') {
            history.push(`/notification/${id}/edit`);
        }
    };

    const columns = [
        {
            name: "Sr. no",
            selector: (row, index) => {
                return index + 1
            },
            sortable: true,
            maxWidth: "10px"
        },
        {
            name: 'Title',
            selector: row => {
                return row?.title;
            },
            sortable: true,
            maxWidth: '150px',
        },
        {
            name: 'Message',
            selector: row => {
                return row?.message;
            },
            sortable: true,
            maxWidth: '500px',
        },
        {
            name: 'Users',
            selector: row => {
                let userLabel;
                switch (row?.userOption) {
                    case "1":
                        userLabel = 'Guest';
                        break;
                    case "2":
                        userLabel = 'Subscribe';
                        break;
                    case "3":
                        userLabel = 'All';
                        break;
                    default:
                        userLabel = '';
                        break;
                }
                return userLabel;
            },
            sortable: true,
            maxWidth: '150px',
        },
        {
            name: 'Send Options',
            selector: row => {
                let userLabel;
                switch (row?.sendOption) {
                    case "1":
                        userLabel = 'Now';
                        break;
                    case "2":
                        userLabel = 'Daily';
                        break;
                    case "3":
                        userLabel = 'Weekly';
                        break;
                    default:
                        userLabel = '';
                        break;
                }
                return userLabel;
            },
            sortable: true,
            maxWidth: '150px',
        },
        {
            name: "Time",
            selector: row => {
                return row?.frequency[0];
            },
            sortable: true,
            maxWidth: '150px',
        },
        {
            name: "Status",
            selector: row => {
                return <div className={row.status === 'active' ? 'font-size-12 text-uppercase badge badge-soft-success' : 'font-size-12 text-uppercase badge badge-soft-danger'}>
                    {row.status}</div>
            },
            sortable: true,
            maxWidth: "100px"
        },
        {
            name: "Actions",
            maxWidth: "150px",
            selector: row => (
                <>
                    <span onClick={() => handleTitlePageUpdateAndRedirect(row._id, 'edit')}>
                        <CIcon icon={cilPencil} className="text-warning hand me-2" />
                    </span>
                    <CIcon
                        icon={cilTrash}
                        className="text-danger hand"
                        onClick={() => {
                            handleDeleteTitle(row._id)
                        }}
                    />
                </>
            ),
        },
    ];

    if (!data) {
        return <FullPageLoader loading={'loading'} />;
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Notification" breadcrumbItem="Notification List" />
                    <Row>

                        <Col xl={12}>

                            <CCardGroup>
                                <CCard>
                                    <CCardBody>
                                        <DataTable
                                            striped
                                            columns={columns}
                                            data={data?.notification}
                                            highlightOnHover
                                            defaultSortField="Sr. no"
                                            pagination
                                            paginationServer
                                            paginationTotalRows={data?.pagination?.totalPages}
                                            paginationPerPage={data?.pagination?.limit}
                                            paginationDefaultPage={titlePage}
                                            paginationComponentOptions={{
                                                noRowsPerPage: true,
                                            }}
                                            onChangePage={(page, totalRows) => {
                                                setPage(page)
                                            }}
                                        />
                                    </CCardBody>
                                </CCard>
                            </CCardGroup>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    );
};

export default NotificationList;
