import React, { useEffect, useState } from "react"
import "./allCategories.css"
import { cilPencil, cilTrash } from "@coreui/icons"
import { Card, CardImg, CardText, Container } from "reactstrap"
import { CButton } from "@coreui/react"

import { Row, Col } from "reactstrap"
import plus from "../../../assets/flix-images/plus.png"
import NoImgFound from "../../../assets/No-image-found.jpg"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import CIcon from "@coreui/icons-react"
import { toastConfirm, toastConfirmDelete } from "../../../common/toast"
import EditCategory from "../../../modals/EditCategoryList"
import AddCategory from "../../../modals/AddcategoriesList"
import { Link, useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { getCategories, deleteCategory } from "../../../store/actions"
import { setTokenHeader } from "../../../helpers/api_helper"
import { startLoader } from "../../../store/loaderState/actions"
import FullPageLoader from "../../../components/Common/FullPageLoader"

import Swal from "sweetalert2"
import { checkNull } from "../../../helpers/basicFunctions"
const AllCategories = () => {
  const { loadingState } = useSelector(state => state.loadingState)
  const dispatch = useDispatch()
  let history = useHistory()
  //meta title
  document.title = "Categories"
  // const [category, setCategory] = useState([])
  const [addModal, setAddModal] = useState(false)
  const toggleAddModal = () => setAddModal(!addModal)
  const { categories } = useSelector(state => state.category)

  const [editModal, setEditModal] = useState(false)
  const [editData, setEditData] = useState(null)
  const toggleEditModal = (existData = null) => {
    setEditModal(!editModal)
    setEditData(existData)
  }

  const deleteCategoryWithId = async id => {
    const response = await (
      await toastConfirm("Are you sure you want to delete this?")
    ).fire()
    if (response.isConfirmed) {
      dispatch(deleteCategory({ id }, history))
    }
  }

  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem("authUser"))

    setTokenHeader(data.access_token)
    dispatch(startLoader())
    dispatch(getCategories())
  }, [])

  return (
    <React.Fragment>
      <AddCategory isOpen={addModal} toggle={toggleAddModal} />
      {editData !== null && (
        <EditCategory
          isOpen={editModal}
          toggle={toggleEditModal}
          editData={editData}
        />
      )}
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Manage Categories" breadcrumbItem="Categories" />
          <div className="checkout-tabs">
            <Row>
              {categories && categories?.map((item, index) => {
                return (
                  <>
                    <Col
                      xs={6}
                      sm={4}
                      md={3}
                      key={index + 1}
                      className="active"
                    >
                      <Link to={"/sub-category/" + item.id}>
                        <Card className="allCategory-card">
                          <CardImg
                            top
                            className="img-fluid img"
                            src={
                              checkNull(item.image, false)
                                ? item.image
                                : NoImgFound
                            }
                            alt={item.name}
                          />
                          <div className="p-2">
                            <CardText className="text-center title">
                              <p className="m-0">{item.name}</p>
                            </CardText>
                          </div>
                        </Card>
                      </Link>

                      <div className="edit">
                        <Link to={"/category/" + item.id}>
                          <CButton
                            variant="text"
                            className="newEditIcon p-0"
                          >
                            <CIcon icon={cilPencil} className="text-warning" />
                          </CButton>
                        </Link>

                        <CButton
                          variant="text"
                          className="newDeleteIcon p-0"
                          onClick={() => deleteCategoryWithId(item["id"])}
                        >
                          <CIcon icon={cilTrash} className="text-danger" />
                        </CButton>
                      </div>
                    </Col>
                  </>
                )
              })}
              <Col xs={6} sm={4} md={3} className="add">
                <Card
                  className="allCategory-card d-flex align-items-center justify-content-center"
                  onClick={() => {
                    toggleAddModal()
                    // setEdit(row)
                  }}
                >
                  <CardImg
                    top
                    className="img-fluid addImg"
                    src={plus}
                    alt="Moviez"
                  />
                </Card>
              </Col>
            </Row>
          </div>
          <FullPageLoader loading={loadingState} />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AllCategories
