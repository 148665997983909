import React, { useEffect, useState } from "react"
import "./analytics.css"
import { cilPencil, cilTrash } from "@coreui/icons"
import { Card, CardImg, CardText, Container } from "reactstrap"
import { CButton, CCard, CCardBody, CCardGroup } from "@coreui/react"
import { Row, Col, FormGroup, Label, Input, FormText } from "reactstrap"
import plus from "../../../assets/flix-images/plus.png"
import NoImgFound from "../../../assets/No-image-found.jpg"
import DataTable from "react-data-table-component"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import CIcon from "@coreui/icons-react"
import { toastConfirm, toastConfirmDelete } from "../../../common/toast"
import EditCategory from "../../../modals/EditCategoryList"
import AddCategory from "../../../modals/AddcategoriesList"
import { Link, useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { getCategories, deleteCategory } from "../../../store/actions"
import { setTokenHeader } from "../../../helpers/api_helper"
import { startLoader } from "../../../store/loaderState/actions"
import FullPageLoader from "../../../components/Common/FullPageLoader"

import { checkNull } from "../../../helpers/basicFunctions"

const Analytics = () => {
  const { loadingState } = useSelector(state => state.loadingState)
  const dispatch = useDispatch()
  let history = useHistory()

  document.title = "Categories"
  const [addModal, setAddModal] = useState(false)
  const toggleAddModal = () => setAddModal(!addModal)
  const { categories } = useSelector(state => state.category)

  const [editModal, setEditModal] = useState(false)
  const [editData, setEditData] = useState(null)
  const toggleEditModal = (existData = null) => {
    setEditModal(!editModal)
    setEditData(existData)
  }

  const deleteCategoryWithId = async id => {
    const response = await (
      await toastConfirm("Are you sure you want to delete this?")
    ).fire()
    if (response.isConfirmed) {
      dispatch(deleteCategory({ id }, history))
    }
  }

  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem("authUser"))

    setTokenHeader(data.access_token)
    dispatch(startLoader())
    dispatch(getCategories())
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid titlelisting">
          <Breadcrumbs title="Analytics" breadcrumbItem="View Analytics" />
          <Row>
            <Col xl={12}>
              <CCardGroup>
                <CCard>
                  <FullPageLoader />
                  <CCardBody style={{padding:'5px'}}>
                    <iframe

                      width="1200"
                      height="800"
                      src="https://lookerstudio.google.com/embed/reporting/55720318-de39-4d7f-9b2b-088a0a62c1b4/page/tWDGB"
                      frameBorder="0"
                      style={{ border: 0 ,marginTop:'15px'}}
                      allowFullScreen
                    ></iframe>
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Analytics
