import {
  GET_PLAN_SUCCESS,
  GET_PLAN_FAIL,
  ADD_PLAN_SUCCESS,
  ADD_PLAN_FAIL,
  UPDATE_PLAN_IMAGE_FAIL,
  UPDATE_PLAN_IMAGE_SUCCESS,
  UPDATE_PLAN_IMAGE,
  UPDATE_PLAN_SUCCESS,
  UPDATE_PLAN_FAIL,
  DELETE_PLAN_SUCCESS,
  DELETE_PLAN_FAIL,
  EDIT_PLAN,
  EDIT_PLAN_FAIL,
  EDIT_PLAN_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  plan: [],
  error: {},
  planSingle: {},
}

const plan = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PLAN_SUCCESS:
      return {
        ...state,
        plan: action.payload.data,
      }

    case GET_PLAN_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_PLAN_SUCCESS:

      return {
        ...state,
        plan: {
          ...state.plan,
          results: [...state.plan.results, action.payload]
        },
      }
      
        
    case ADD_PLAN_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    

      case UPDATE_PLAN_SUCCESS:

        return {
          ...state,
          plan: {
            ...state.plan,
            results: state.plan.results.map(item => item?.id == action.payload.id ? action.payload : item)
          },
        }
  
      case UPDATE_PLAN_FAIL:
        return {
          ...state,
          error: action.payload,
        }
  
      case DELETE_PLAN_SUCCESS:
        return {
          ...state,
          plan: {
            ...state.plan,
            results: [...state.plan.results.filter(item => item['id'] !== action.payload)]
          },
        }
  
      case DELETE_PLAN_FAIL:
        return {
          ...state,
          error: action.payload,
        }

      case EDIT_PLAN:
          return {
            ...state,
            planSingle: action.payload
          }
  
      case EDIT_PLAN_FAIL:
        return {
          ...state,
          error: action.payload,
        }
  
      case EDIT_PLAN_SUCCESS:
        return {
          ...state,
          planSingle: action.payload.data
        }

   

    default:
      return state
  }
}

export default plan
