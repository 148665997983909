import React, { useEffect, useRef, useState } from "react"
import {
  Row,
  Col,
  Label,
  FormGroup,
  Input,
  Progress,
  Button,
  InputGroup,
} from "reactstrap"
import cloud from "../../../assets/flix-images/cloud-file-download.svg"
import videoPlayerIcon from "../../../assets/flix-images/video_player.jpg"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import FullPageLoader from "../../../components/Common/FullPageLoader"
import { useHistory, } from "react-router-dom"
import { fireToast } from "../../../common/toast"
import { useDispatch, useSelector } from "react-redux"
import { setTokenHeader } from "../../../helpers/api_helper"
import { editTitle, updateTrailervideo, } from "../../../store/actions"
import { startLoader } from "../../../store/loaderState/actions"
import { useParams } from "react-router-dom"
import AWS from "aws-sdk"
import Dropzone from "react-dropzone"
import { checkNull } from "../../../helpers/basicFunctions"

const S3_BUCKET = `${process.env.REACT_APP_S3_BUCKET_NAME}`
const REGION = `${process.env.REACT_APP_S3_DESIRED_REGION}`

AWS.config.update({
  accessKeyId: `${process.env.REACT_APP_S3_ACCESS_KEY}`,
  secretAccessKey: `${process.env.REACT_APP_S3_SECRET_ACCESS_KEY}`,
})

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: "us-east-1",
  httpOptions: {
    timeout: 3600000, // Timeout set to 10 minutes (600,000 milliseconds)
  },
})

const UpdateTrailers = () => {
  document.title = "Manage Trailers"

  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()
  const [progress, setProgress] = useState(0)
  const [imageProgress, setImageProgress] = useState(0)
  const [selectedVideoFile, setSelectedVideoFile] = useState(null)
  const [selectedImageFlie, setSelectedImageFlie] = useState(null)
  const uploadRef = useRef(null)
  const [image, setImage] = useState({
    blob: null,
    src: "",
  })
  const [video, setVideo] = useState()
  const { loadingState } = useSelector(state => state.loadingState)
  const [errors, setErrors] = useState({
    titleName: "",
    duration: "",
  })
  const [data, setData] = useState({
    title_name: "",
    file: "",
    thumbnail: "",
    duration: "",
  })
  const [isUploading, setIsUploading] = useState(false)

  const errorChangehandler = (input, value) => {
    setErrors(prevState => ({ ...prevState, [input]: value }))
  }
  const inputChangehandler = (input, value) => {
    setData(prevState => ({ ...prevState, [input]: value }))
  }

  const handleVideoChange = files => {
    const extn = ["video/mp4"]
    const [videoFile] = files
    if (videoFile && extn.includes(videoFile.type)) {
      const videoElement = document.createElement('video');
      videoElement.preload = 'metadata';

      videoElement.onloadedmetadata = function () {
        window.URL.revokeObjectURL(videoElement.src);
        let duration = videoElement.duration;
        let vidDuration = duration.toString();
        setData({ ...data, duration:vidDuration })
      }
      videoElement.src = URL.createObjectURL(files[0]);;

      setVideo(files[0])
      setSelectedVideoFile(files[0])
    } else {
      fireToast("error", "Please select a valid video file")
    }
  }

  const handleImageChange = files => {
    const extn = ["image/jpg", "image/png", "image/jpeg"]
    const [imageNew] = files

    if (imageNew && extn.includes(imageNew.type)) {
      if (imageNew.size <= 5242880) {
        setImage({ blob: imageNew, src: window.URL.createObjectURL(imageNew) })
        setSelectedImageFlie(files[0])
      } else {
        fireToast("error", "image too large")
      }
    } else {
      fireToast(
        "error",
        "Please select a valid image file(only jpg, png and jpeg images are allowed)"
      )
    }
  }

  const UploadBothFiles = async () => {
    if (data.title_name === "") {
      errorChangehandler("titleName", "Please enter a valid name")
      return
    }
    if (!selectedImageFlie) {
      toast.error("Please select a Image", {
        position: toast.POSITION.TOP_LEFT,
      })
      return
    }
    if (!selectedVideoFile) {
      toast.error("Please select a video", {
        position: toast.POSITION.TOP_LEFT,
      })
      return
    }
    setIsUploading(true)
    try {
      const thumbnail = selectedImageFlie
        ? await uploadImageFile(selectedImageFlie)
        : trailerObj.thumbnail
      const file = selectedVideoFile
        ? await uploadFile(selectedVideoFile)
        : trailerObj.thumbnail
      setData({ ...data, file, thumbnail })
      const { title_name, duration } = data;
      dispatch(
        updateTrailervideo(params.id, { thumbnail, file, title_name, duration }, history)
      )
    } catch (error) {
      console.log(error)
    }
    setIsUploading(false)
  }

  const uploadFile = file => {
    if (file == "undefined") {
      toast.error("Please select a valid File", {
        position: toast.POSITION.TOP_LEFT,
      })
    }
    return new Promise((resolve, reject) => {
      const params = {
        ACL: "public-read",
        Body: file,
        Bucket: S3_BUCKET,
        Key: `Inputs/videos/${file.name.replace(/\s/g, "")}`,
      }

      myBucket
        .putObject(params)
        .on("httpUploadProgress", evt => {
          setProgress(Math.round((evt.loaded / evt.total) * 100))
        })
        .send(function (err) {
          if (err) {
            console.log(err)
          } else {
            const videoPath =
              process.env.REACT_APP_S3_DEFAULT_URL +
              "Outputs/" +
              file.name.split(".")[0].replace(/\s/g, "") +
              "/HLS/" +
              file.name.split(".")[0].replace(/\s/g, "") +
              ".m3u8"
            resolve(videoPath)
            // setData({...data , file: videoPath})
          }
        })
    })
  }
  const uploadImageFile = file => {
    return new Promise((resolve, reject) => {
      const params = {
        ACL: "public-read",
        Body: file,
        Bucket: S3_BUCKET,
        Key: `Inputs/images/${file.name.trim()}`,
      }

      myBucket
        .putObject(params)
        .on("httpUploadProgress", evt => {
          setImageProgress(Math.round((evt.loaded / evt.total) * 100))
        })
        .send(function (err) {
          if (err) {
            console.log(err)
          } else {
            // const imageThumbnail = process.env.REACT_APP_S3_DEFAULT_URL+file.name.split('.')[0].trim()+ '/' + file.name.trim();
            const imageThumbnail =
              process.env.REACT_APP_S3_DEFAULT_URL +
              "Inputs/images/" +
              file.name.trim()
            resolve(imageThumbnail)
          }
        })
    })
  }

  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem("authUser"))
    setTokenHeader(data.access_token)
    dispatch(startLoader())
    dispatch(editTitle(params.id))
  }, [])

  useEffect(() => {
    if (progress == 100) {
      fireToast("success", "Video Uploaded successfully")
      // dispatch(startLoader());
    }
  }, [progress])

  useEffect(() => {
    if (imageProgress == 100) {
      fireToast("success", "Image Uploaded successfully")
      // dispatch(startLoader());
    }
  }, [imageProgress])

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid titlelisting">
          <Breadcrumbs title="Manage Trailers" breadcrumbItem="Trailers" />
          <FullPageLoader loading={loadingState} />
          <Row>
            <Col xl={12}>
              <div className="mb-3">
                <FormGroup>
                  <Label for="trailerName">Trailer Name</Label>
                  <InputGroup>
                    <Input
                      type="text"
                      className="form-control"
                      id="trailerName"
                      name="trailerName"
                      value={data.title_name}
                      onChange={e => {
                        inputChangehandler("title_name", e.target.value)
                        errorChangehandler("titleName", "")
                      }}
                    />
                  </InputGroup>
                  {checkNull(errors.titleName, false) ? (
                    <div className="text-danger input-error">
                      {errors.titleName}
                    </div>
                  ) : null}
                </FormGroup>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={6}>
              <div className="py-3 text-center h4">
                <label>Upload Image</label>
              </div>
              <div className="mb-3 dragdrop-container text-center p-4">
                <input
                  ref={uploadRef}
                  id="uploadImage"
                  hidden
                  accept="image/*"
                  type="file"
                  onChange={e => handleImageChange(e.target.files)}
                />
                {image.src ? (
                  <div className="commonImgs">
                    <img
                      className="every-img"
                      src={image.src ? image.src : cloud}
                      alt=""
                      onClick={() => {
                        uploadRef.current.click()
                      }}
                    />
                  </div>
                ) : (
                  <div className="drag-n-drop-container">
                    <div>
                      <Dropzone
                        accept="image/*"
                        multiple={false}
                        onDrop={acceptedFiles => {
                          handleImageChange(acceptedFiles)
                        }}
                      >
                        {({ getRootProps, getInputProps, isDragActive }) => (
                          <section>
                            <div className="drop-area" {...getRootProps()}>
                              <img width={60} src={cloud} alt="" />
                              <input
                                {...getInputProps()}
                                accept="image/*"
                                multiple={false}
                              />

                              {isDragActive ? (
                                <div>Drop your image file here</div>
                              ) : (
                                <p>
                                  Drag n drop image file here, or click to
                                  select <br />
                                  <small className="text-center">
                                    <strong>Supported files:</strong> jpeg, jpg,
                                    png. | Will be resized to: 1920x1080 px.
                                  </small>
                                </p>
                              )}
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </div>
                  </div>
                )}
              </div>
              <p className="fw-bold">
                Note:
                <span className="text-danger mx-2 text-align-left">
                  Supported image formats are:&nbsp;jpg, png and jpeg only
                </span>
              </p>
              {data.banner ? (
                <div className="mb-3">
                  <Label htmlFor="formrow-firstname-Input">Uploaded</Label>
                  <div>
                    <img className="previousImage" src={data.banner} alt="" />
                  </div>
                </div>
              ) : (
                ""
              )}
            </Col>
            <Col xl={6}>
              <div className="py-3 text-center h4">
                <label>Upload Video</label>
              </div>

              <div className="mb-3 dragdrop-container text-center p-4">
                <input
                  ref={uploadRef}
                  id="uploadFile"
                  hidden
                  accept="video/mp4"
                  type="file"
                  onChange={e => handleVideoChange(e.target.files)}
                />
                {video ? (
                  <div className="commonImgs">
                    <img
                      className="every-img"
                      src={video ? videoPlayerIcon : cloud}
                      alt=""
                      onClick={() => {
                        uploadRef.current.click()
                      }}
                    />
                  </div>
                ) : (
                  <div className="drag-n-drop-container">
                    <div>
                      <Dropzone
                        accept="image/*"
                        multiple={false}
                        onDrop={acceptedFiles => {
                          handleVideoChange(acceptedFiles)
                        }}
                      >
                        {({ getRootProps, getInputProps, isDragActive }) => (
                          <section>
                            <div className="drop-area" {...getRootProps()}>
                              <img width={60} src={cloud} alt="" />
                              <input
                                {...getInputProps()}
                                accept="video/mp4"
                                multiple={false}
                              />

                              {isDragActive ? (
                                <div>Drop your video file here</div>
                              ) : (
                                <p>
                                  Drag n drop Video file here, or click to
                                  select <br />
                                  <small className="text-center">
                                    <strong>Supported files:</strong> MP4
                                  </small>
                                </p>
                              )}
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </div>
                  </div>
                )}
              </div>
              <p className="fw-bold">
                Note:
                <span className="text-danger mx-2 text-align-left">
                  Supported Video formats are:&nbsp;MP4
                </span>
              </p>
            </Col>

            <Col xl={12}>
              <div className="p-4">
                <p>Thumbnail Uploaded - {imageProgress}% </p>
                <Progress color="success" value={imageProgress} />
              </div>
              <div className="p-4">
                <p>Video Uploaded - {progress}% </p>
                <Progress color="success" value={progress} />
              </div>
              <Button
                disabled={isUploading}
                color="success"
                outline
                className="mt-4"
                onClick={() => (isUploading ? null : UploadBothFiles())}
              >
                Upload
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default UpdateTrailers
