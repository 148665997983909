import React, { useCallback, useEffect, useState } from "react";
import { CCard, CCardBody, CCardGroup } from "@coreui/react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import axios from "axios"
import {
    Col,
    Row,
} from "reactstrap";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import ReactDatePicker from "react-datepicker";
import { CountryDropdown } from "react-country-region-selector"
import moment from 'moment-timezone';
import { countries } from 'country-data';

const API_URL = `${process.env.REACT_APP_ZFLIX_URL}/v1`

const ManageEditNotifications = React.memo(() => {
    const params = useParams()
    const history = useHistory()
    const [sendOption, setSendOption] = useState('');
    const [userOption, setUserOption] = useState('');
    const [startDate, setStartDate] = useState(new Date());

    const [formData, setFormData] = useState({
        title: "",
        message: "",
        userOption: "",
        sendOption: "",
        time: [],
        utcTime: [],
        days: [],
        status: "",
        timeZoneCountry: ""
    });

    useEffect(() => {
        document.title = "Edit Notification";
        fetchNotificationData(params.id);

        return () => {
            setFormData({
                title: "",
                message: "",
                userOption: "",
                sendOption: "",
                time: [],
                utcTime: [],
                days: [],
                status: ""
            });
        };
    }, []);

    const handleChange = useCallback((e) => {
        const { name, value, type, checked } = e.target;
        console.log("e.target", e.target.value)

        if (type === "checkbox") {
            if (checked) {
                if (!formData[name].includes(value)) {
                    setFormData((prevData) => ({
                        ...prevData,
                        [name]: [...prevData[name], value],
                    }));
                }
                if (formData[name].includes(value)) {
                    setFormData((prevData) => ({
                        ...prevData,
                        [name]: prevData[name].filter(item => item !== value),
                    }));
                }
            } else {

                setFormData((prevData) => ({
                    ...prevData,
                    [name]: [...prevData[name], value],
                }));
            }
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    }, [formData]);

    const handleSendOptionChange = useCallback((e) => {
        setSendOption(e.target.value);
        if (e.target.value === "1" || e.target.value === "2") {
            setFormData({ ...formData, days: [], sendOption: e.target.value })
        } else {
            setFormData({ ...formData, sendOption: e.target.value })
        }
    }, [formData]);

    const fetchNotificationData = (id) => {
        const token = JSON.parse(sessionStorage.getItem("authUser"));
        const headers = {
            Authorization: `Bearer ${token.access_token}`,
        };

        axios
            .get(`${API_URL}/admin-notification/${id}`, { headers: headers })
            .then(response => {
                setFormData({
                    title: response.data?.data?.title,
                    message: response.data?.data?.message,
                    userOption: response.data?.data?.userOption,
                    sendOption: response.data?.data?.sendOption,
                    days: response.data?.data?.days,
                    status: response.data?.data?.status,
                    time: response.data?.data?.frequency,
                    utcTime: response.data?.data?.scheduledFrequencies,
                    timeZoneCountry: response.data?.data?.timeZoneCountry
                })

                setSendOption(response.data?.data?.sendOption)
                setUserOption(response.data?.data?.userOption)

                const receivedTime = response.data?.data?.frequency[0]
                const [hours, minutes] = receivedTime.split(':').map(Number);
                const defaultDate = new Date();
                defaultDate.setHours(hours);
                defaultDate.setMinutes(minutes);
                setStartDate(defaultDate)
            })
            .catch(error => console.log(error));
    };

    const handleFrequency = useCallback((date) => {
        const frequencyDate = new Date(date);
        const hour = frequencyDate.getHours();
        const minutes = frequencyDate.getMinutes();
        const formattedHour = hour.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');

        const UTCTime = getUTCTime(formattedHour, formattedMinutes, formData.timeZoneCountry)
        const time = `${formattedHour}:${formattedMinutes}`;

        setStartDate(date)
        setFormData((prevData) => ({
            ...prevData,
            time: [time],
            utcTime: [UTCTime],
        }));
    }, [formData, formData.timeZoneCountry]);

    function getUTCTime(hours, minutes, country) {
        const countryCode = countries.all.find((c) => c.name === country);
        const timeZones = moment.tz.zonesForCountry(countryCode.alpha2);
        const localTime = moment.tz({ hours, minutes }, timeZones[0]);
        const utcTime = localTime.clone().utc();
        return utcTime.format('HH:mm');
    }

    const handleSubmit = useCallback((e) => {
        e.preventDefault();
        const token = JSON.parse(sessionStorage.getItem("authUser"))

        if (formData.sendOption === "1") {
            formData.time = []
            formData.utcTime = []
        }
        axios
            .patch(
                API_URL + "/admin-notification/" + params.id,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token.access_token}`,
                    },
                }
            )
            .then(function (response) {
                if (response.data?.status) {
                    toast.success("Update Successfully!!")
                    history.push('/notification-list')
                } else {
                    toast.error(response.data?.message)
                }
            })
            .catch(function (error) {
                console.log(error)
                toast.error("Something went wrong")
            })
        }, [formData, params.id, history]);

    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs title="Notifications" breadcrumbItem="Edit Notification" />
                <Row>
                    <Col xl={12}>
                        <CCardGroup>
                            <CCard>
                                <CCardBody>
                                    <form className="" onSubmit={handleSubmit}>
                                        <div className="mb-3">
                                            <label htmlFor="formrow-firstname-Input" className="form-label">Title</label>
                                            <input
                                                name="title"
                                                id="formrow-firstname-Input"
                                                placeholder="Enter notification title"
                                                type="text"
                                                className="form-control form-control"
                                                aria-invalid="false"
                                                defaultValue={formData.title}
                                                onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                                                required />
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="mb-3">
                                                    <label htmlFor="formrow-email-Input" className="form-label">Message</label>
                                                    <textarea id="textarea"
                                                        rows="3"
                                                        placeholder="Your Message"
                                                        defaultValue={formData.message}
                                                        onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                                                        required
                                                        className="form-control">
                                                    </textarea>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="mb-3">
                                                        <h5 className="font-size-14 mb-4">User Type</h5>
                                                        <div className="mt-4 d-flex">
                                                            <div className="form-check ml-2" style={{ marginRight: '10px' }}>
                                                                <input className="form-check-input"
                                                                    type="radio"
                                                                    name="exampleRadios"
                                                                    onClick={(e) => {
                                                                        setUserOption(e.target.value),
                                                                            setFormData({ ...formData, userOption: e.target.value })
                                                                    }} id="exampleRadios1"
                                                                    value="1"
                                                                    checked={userOption === "1"}
                                                                    required />
                                                                <label className="form-check-label" htmlFor="exampleRadios1">Guest</label>
                                                            </div>
                                                            <div className="form-check" style={{ marginRight: '10px' }}>
                                                                <input className="form-check-input"
                                                                    type="radio"
                                                                    name="exampleRadios"
                                                                    onClick={(e) => {
                                                                        setUserOption(e.target.value),
                                                                            setFormData({ ...formData, userOption: e.target.value })
                                                                    }} id="exampleRadios2"
                                                                    value="2"
                                                                    checked={userOption === "2"}
                                                                    required />
                                                                <label className="form-check-label" htmlFor="exampleRadios2">Subscribed</label>
                                                            </div>
                                                            <div className="form-check" style={{ marginRight: '10px' }}>
                                                                <input className="form-check-input"
                                                                    type="radio"
                                                                    name="exampleRadios"
                                                                    onClick={(e) => {
                                                                        setUserOption(e.target.value),
                                                                            setFormData({ ...formData, userOption: e.target.value })
                                                                    }}
                                                                    id="exampleRadios3"
                                                                    value="3"
                                                                    checked={userOption === "3"}
                                                                    required />
                                                                <label className="form-check-label" htmlFor="exampleRadios3">All</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <p>Select Country Timezone</p>
                                                    <CountryDropdown
                                                        value={formData.timeZoneCountry || ""}
                                                        onChange={(val) => setFormData({ ...formData, timeZoneCountry: val })}
                                                        className="form-control w-100"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="mb-3">
                                                        <h5 className="font-size-14 mb-4">Send Options</h5>
                                                        <div className="mt-4 d-flex">
                                                            <div className="form-check" style={{ marginRight: '10px' }}>
                                                                <label className="form-check-label" htmlFor="sendOption1">Now</label>
                                                                <input className="form-check-input"
                                                                    type="radio"
                                                                    name="sendOption"
                                                                    id="sendOption1"
                                                                    checked={sendOption == '1'}
                                                                    onClick={handleSendOptionChange}
                                                                    required
                                                                    value="1" />
                                                            </div>
                                                            <div className="form-check" style={{ marginRight: '10px' }}>
                                                                <label className="form-check-label" htmlFor="sendOption2">Daily</label>
                                                                <input className="form-check-input"
                                                                    type="radio" name="sendOption"
                                                                    id="sendOption2"
                                                                    checked={sendOption == '2'}
                                                                    onClick={handleSendOptionChange}
                                                                    required
                                                                    value="2" />
                                                            </div>
                                                            <div className="form-check" style={{ marginRight: '10px' }}>
                                                                <label className="form-check-label" htmlFor="sendOption3">Weekly</label>
                                                                <input className="form-check-input"
                                                                    type="radio"
                                                                    name="sendOption"
                                                                    id="sendOption3"
                                                                    required
                                                                    onClick={handleSendOptionChange}
                                                                    checked={sendOption == '3'}
                                                                    value="3" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                {sendOption === '2' && (
                                                    <>
                                                        <div className="col-md-3">
                                                            <p>Select time for Daily</p>
                                                            <ReactDatePicker
                                                                className="form-control"
                                                                selected={startDate}
                                                                onChange={(date) => handleFrequency(date)}
                                                                showTimeSelect
                                                                showTimeSelectOnly
                                                                timeIntervals={15}
                                                                timeCaption="Time"
                                                                dateFormat="h:mm aa"
                                                            />

                                                        </div>
                                                    </>
                                                )}
                                                {sendOption === '3' && (
                                                    <>
                                                        <div className="col-md-3">
                                                            <p>Select time for Weekly</p>
                                                            <ReactDatePicker
                                                                className="form-control"
                                                                selected={startDate}
                                                                onChange={(date) => handleFrequency(date)}
                                                                showTimeSelect
                                                                showTimeSelectOnly
                                                                timeIntervals={15}
                                                                timeCaption="Time"
                                                                dateFormat="h:mm aa"
                                                            />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <p>Days</p>
                                                            {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day, index) => (
                                                                <div key={day} className="form-check form-check-inline">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        id={day}
                                                                        name="days"
                                                                        // defaultValue={formData.days}
                                                                        value={day}
                                                                        onClick={handleChange}
                                                                        checked={formData.days.includes(day)}
                                                                    />
                                                                    <label className="form-check-label" htmlFor={day}>
                                                                        {day.charAt(0).toUpperCase() + day.slice(1)}
                                                                    </label>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col-md-3">
                                                    <div className="mb-3">
                                                        <label htmlFor="formrow-email-Input" className="form-label">Status</label>
                                                        <select
                                                            name="status"
                                                            className="form-control"
                                                            value={formData.status}
                                                            onChange={handleChange}
                                                        >
                                                            <option value={'active'}>Active</option>
                                                            <option value={'inactive'}>Inactive</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="float-end">
                                            <button type="submit" className="btn btn-primary w-md">Submit</button>
                                        </div>
                                    </form>
                                </CCardBody>
                            </CCard>
                        </CCardGroup>
                    </Col>
                </Row>
            </div>
        </div>
    );
});
ManageEditNotifications.displayName = 'ManageEditNotifications';

export default ManageEditNotifications;
