/* CATEGORIES */
export const GET_GENRE = "GET_GENRE"
export const GET_GENRE_SUCCESS = "GET_GENRE_SUCCESS"
export const GET_GENRE_FAIL = "GET_GENRE_FAIL"

/**
 * add GENRE
 */
 export const ADD_NEW_GENRE = "ADD_NEW_GENRE"
 export const ADD_GENRE_SUCCESS = "ADD_GENRE_SUCCESS"
 export const ADD_GENRE_FAIL = "ADD_GENRE_FAIL"

/**
 * UPDATE GENRE IMAGE
 */
 export const UPDATE_GENRE_IMAGE = "UPDATE_GENRE_IMAGE"
 export const UPDATE_GENRE_IMAGE_SUCCESS = "UPDATE_GENRE_IMAGE_SUCCESS"
 export const UPDATE_GENRE_IMAGE_FAIL = "UPDATE_GENRE_IMAGE_FAIL"
  
/**
 * Edit GENRE
 */
export const EDIT_GENRE = "EDIT_GENRE"
export const EDIT_GENRE_SUCCESS = "EDIT_GENRE_SUCCESS"
export const EDIT_GENRE_FAIL = "EDIT_GENRE_FAIL"


/**
 * Edit GENRE
 */
 export const UPDATE_GENRE = "UPDATE_GENRE"
 export const UPDATE_GENRE_SUCCESS = "UPDATE_GENRE_SUCCESS"
 export const UPDATE_GENRE_FAIL = "UPDATE_GENRE_FAIL"

/**
 * Delete GENRE
 */
export const DELETE_GENRE = "DELETE_GENRE"
export const DELETE_GENRE_SUCCESS = "DELETE_GENRE_SUCCESS"
export const DELETE_GENRE_FAIL = "DELETE_GENRE_FAIL"
