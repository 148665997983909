import React, { useState } from 'react';
import AWS from 'aws-sdk'
import { Breadcrumbs } from '@material-ui/core';

const S3_BUCKET = `${process.env.REACT_APP_S3_BUCKET_NAME}`;
const REGION = `${process.env.REACT_APP_S3_DESIRED_REGION}`;


AWS.config.update({
    accessKeyId: `${process.env.REACT_APP_S3_ACCESS_KEY}`,
    secretAccessKey: `${process.env.REACT_APP_S3_SECRET_ACCESS_KEY}`
})

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
})

const UploadImageToS3WithNativeSdk = () => {

    const [progress, setProgress] = useState(0);
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileInput = (e) => {
        setSelectedFile(e.target.files[0]);
    }

    const uploadFile = (file) => {

        const params = {
            ACL: 'public-read',
            Body: file,
            Bucket: S3_BUCKET,
            Key: file.name
        };

        myBucket.putObject(params)
            .on('httpUploadProgress', (evt) => {
                setProgress(Math.round((evt.loaded / evt.total) * 100))
            })
            .send((err) => {
                if (err) console.log(err)
                else return true
            })
    }


    return (<React.Fragment>
                <div className="page-content">
                    <div className="container-fluid titlelisting">
                        <Breadcrumbs title="Manage Titles" breadcrumbItem="Titles" />

                            <div>Native SDK File Upload Progress is {progress}%</div>
                            <input type="file" onChange={handleFileInput} />
                                <button onClick={() => uploadFile(selectedFile)}> Upload to S3</button>
                            </div>
                    </div>
            </React.Fragment>)
}

export default UploadImageToS3WithNativeSdk;