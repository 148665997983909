import React, { useEffect, useState } from "react"
import { cilPencil, cilTrash } from "@coreui/icons"
import { Button } from "reactstrap"

import { CCard, CCardBody, CCardGroup } from "@coreui/react"
import DataTable from "react-data-table-component"
import { Row, Col } from "reactstrap"
import angryBirds from "../../../assets/flix-images/angryBirds.jpg"
import horror from "../../../assets/flix-images/horror.jpg"
import romance from "../../../assets/flix-images/romance.jpg"
import thriller from "../../../assets/flix-images/thriller.jpg"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import CIcon from "@coreui/icons-react"
import { useHistory } from "react-router-dom"
import { toastConfirm } from "../../../common/toast"

const AllContent = () => {
  const history = useHistory()
  //meta title
  document.title = "Regisered Users"
  const [userData, setUserData] = useState([])
  const deleteUser = id => {
    toastConfirm("Are you sure you want to delete this?").fire()
  }

  const users = [
    {
      id: 1,
      genre:"Comedy",
      name: "The Angry Birds",
      releaseDate: "27 March 2018",
      postedOn:"27 March 2018",
      rating: 4,
      img: angryBirds,
      time: "2hrs 30min",
    },
    {
      id: 2,
      genre:"Horror",
      name: "Conjuring",
      releaseDate: "27 March 2018",
      rating: 4,
      img: horror,
      time: "2hrs 30min",
    },
    {
      id: 3,
      genre:"Romantic",
      name: "Titanic",
      releaseDate: "27 March 2018",
      rating: 4,
      img: romance,
      time: "2hrs 30min",
    },
    {
        id: 4,
        genre:"Thriller",
        name: "The Offering",
        releaseDate: "27 March 2018",
        postedOn:"27 March 2018",
        rating: 4,
        img: thriller,
        time: "2hrs 30min",
      },
      {
        id: 5,
        genre:"Horror",
        name: "Conjuring",
        releaseDate: "27 March 2018",
        rating: 4,
        img: horror,
        time: "2hrs 30min",
      },
      {
        id: 6,
        genre:"Romantic",
        name: "Titanic",
        releaseDate: "27 March 2018",
        rating: 4,
        img: romance,
        time: "2hrs 30min",
      },
      {
        id: 7,
        genre:"Comedy",
        name: "The Angry Birds",
        releaseDate: "27 March 2018",
        postedOn:"27 March 2018",
        rating: 4,
        img: angryBirds,
        time: "2hrs 30min",
      },
      {
        id: 8,
        genre:"Horror",
        name: "Conjuring",
        releaseDate: "27 March 2018",
        rating: 4,
        img: horror,
        time: "2hrs 30min",
      },
      {
        id: 9,
        genre:"Romantic",
        name: "Titanic",
        releaseDate: "27 March 2018",
        rating: 4,
        img: romance,
        time: "2hrs 30min",
      },
  ]

  useEffect(() => {
    setUserData(users)
  }, [])

  const columns = [
    {
      name: "Sr. no",
      selector: row => {
        return row.id
      },
      sortable: true,
    },
    {
      name: "Movie  Name",
      selector: row => row.name,
      sortable: true,
    },
    {
      name: "Release Date",
      selector: row => row.releaseDate,
      sortable: true,
    },
    {
      name: "Image",
      selector: row => {
        return (
          <div className="categoryDetail-img">
            <img src={row.img} style={{height:"68px", padding:"10px"}}/>
          </div>
        )
      },
      sortable: true,
    },
    {
      name: "Rating",
      selector: row => {
        return <div className="mx-3">{row.rating}</div>
      },
      sortable: true,
    },
    {
      name: "Time",
      selector: row => row.time,
      sortable: true,
    },
    {
      name: "Actions",
      selector: row => (
        <>
          <CIcon
            icon={cilPencil}
            className="text-warning hand me-2"
            onClick={() => {
              // toggleViewModal()
              history.push("/sub-category-details")
              // setEdit(row);
            }}
          />
          <CIcon
            icon={cilTrash}
            className="text-danger hand"
            onClick={() => {
              deleteUser()
            }}
          />
        </>
      ),
    },
    {
      name: "View Details",
      selector: row => (
        <>
          <a target="_blank" rel="noreferrer">
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={() => {
                history.push("/sub-category-details")
              }}
            >
              View Details
            </Button>
          </a>
        </>
      ),
    },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Category Details" breadcrumbItem="Comedy" />

          <Row>
            <Col xl={12}>
              <CCardGroup>
                <CCard>
                  <CCardBody>
                    <DataTable
                      striped
                      columns={columns}
                      data={userData}
                      pagination={true}
                      paginationServer
                      paginationRowsPerPageOptions={[8, 16, 24, 32]}
                    />
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AllContent
