import React, { useEffect, useState } from "react"
import "./categoryDetails.css"
import { cilPencil, cilTrash } from "@coreui/icons"
import { useParams } from "react-router-dom"
import axios from "axios"
import { CCard, CCardBody, CCardGroup } from "@coreui/react"
import DataTable from "react-data-table-component"
import { Row, Col } from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import CIcon from "@coreui/icons-react"
import { useHistory } from "react-router-dom"
import { toastConfirm } from "../../../common/toast"

const SubCategory = () => {
  const history = useHistory()
  const params = useParams()
  //meta title
  document.title = "Sub-Category"
  const [subCategoryData, setSubCategoryData] = useState([])
  const [subCategoryHeading, setSubCategoryHeading] = useState("")

  useEffect(() => {
    const API_URL = `${process.env.REACT_APP_ZFLIX_URL}/v1`
    const sessionsData = JSON.parse(sessionStorage.getItem("authUser"))

    axios
      .get(API_URL + "/categorys/" + params.id, {
        headers: {
          Authorization: `Bearer ${sessionsData.access_token}`,
        },
      })
      .then(function (response) {
        setSubCategoryData(response?.data?.data?.titles)
        setSubCategoryHeading(response?.data?.data?.name)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])

  let rowCounter = 1
  const columns = [
    {
      name: "Sr. no",
      maxWidth: "80px",
      selector: row => rowCounter++,
      sortable: true,
    },
    {
      name: "Image",
      maxWidth: "120px",
      selector: row => {
        return (
          <div className="categoryDetail-img">
            <img src={row.banner} />
          </div>
        )
      },
      sortable: true,
    },
    {
      name: "Movie  Name",
      selector: row => row.title,
      sortable: true,
    },
    {
      name: "Release Date",
      selector: row => row.year,
      sortable: true,
      maxWidth: "150px",
    },
    {
      name: "Type",
      selector: row => row.type,
      sortable: true,
      maxWidth: "150px",
    },

    {
      name: "Time",
      selector: row => row.running_time,
      sortable: true,
      maxWidth: "150px",
    },
    {
      name: "Actions",
      maxWidth: "100px",
      selector: row => (
        <>
          <CIcon
            icon={cilPencil}
            className="text-warning hand me-2"
            onClick={() => {
              history.push(`/title/${row.id}/edit`)
            }}
          />
        </>
      ),
    },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Category Details"
            breadcrumbItem={subCategoryHeading}
          />

          <Row>
            <Col xl={12}>
              <CCardGroup>
                <CCard>
                  <CCardBody>
                    <DataTable
                      striped
                      columns={columns}
                      data={subCategoryData}
                      // pagination={true}
                      paginationServer
                      // paginationRowsPerPageOptions={[8, 16, 24, 32]}
                    />
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default SubCategory
