import {
  GET_SEARCHDATA_SUCCESS,
  GET_SEARCHDATA_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  searchData: [],
  error: {},
  searchDataSingle: {},
}

const searchData = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SEARCHDATA_SUCCESS:
      return {
        ...state,
        searchData: action.payload.data,
      }

    case GET_SEARCHDATA_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default searchData
