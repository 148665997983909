import React, { useEffect, useState } from "react"
import { Col, Container, Row, Label, Button } from "reactstrap"
import * as Yup from "yup"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Field, Formik, Form } from "formik"

import {
    addNewCategory,
} from "../../../store/actions"
import { useDispatch } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import "react-languages-select/scss/react-languages-select.scss"
import { ToastContainer } from "react-toastify"
import { checkArray, checkNull } from "../../../helpers/basicFunctions"
import FullPageLoader from "../../../components/Common/FullPageLoader"
import SelectAsyncPaginate from "../../ManageTitles/TitleAdd/SelectAsyncPaginate"
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Switch from "react-switch";
import NoImgFound from "../../../assets/No-image-found.jpg"
import { get } from "../../../helpers/api_helper"

const EditCategoryPage = props => {
    document.title = "Edit Category"
    const dispatch = useDispatch()
    const { id } = useParams()
    let history = useHistory()
    const [isFree, setIsFree] = useState(false)
    const [watchProviders, setWatchProviders] = useState([])

    const [network, setNetwork] = useState({
        id: "",
        name: "",
        type: "",
        watchProvider: "",
        titleType: "",
        titles: [],
        description: "",
        isActive: "",
        sort: "",
        isFree: "",
        image: null,
    })


    const fetchProviders = async () => {
        const url = 'https://api.themoviedb.org/3/watch/providers/movie';
        const options = {
            headers: {
                accept: 'application/json',
                Authorization: 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIxNDE2YjYzYjZlYzAwNmQ3ZWQ3YWYwMzEwZTAxZGJlNiIsInN1YiI6IjY2NTk5Yjg1MTM1MDVkY2VjYzUwOTA2NCIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.Wh3iA-H2rK7HWnwTS6JNNhFPGMX3ICikp93WuE2qwX0'
            }
        };
        const res = await get(url, options)
        setWatchProviders([...res.results])

    }

    useEffect(() => {
        const fetchCategoryData = async () => {
            try {
                let optUrl = `${process.env.REACT_APP_ZFLIX_URL}/v1/categorys/${id}`
                const response = await fetch(optUrl, {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("authUser")).access_token
                            }`,
                        "Content-Type": "application/json",
                    },
                })

                const res = await response.json();
                const data = res?.data;
                console.log("hh", data)
                setNetwork({
                    id: data?.id || "",
                    name: data?.name || "",
                    type: data?.type == "Tmdb" ? "2" : "1" || "",
                    watchProvider: data?.watchProvider || "",
                    titleType: data?.titleType || "",

                    sort: data?.sort || "",
                    titles: checkArray(data?.titles)
                        ? data.titles.map((title) => ({
                            value: title.id,
                            name: `${title.title} (${title.year})`,
                        }))
                        : [],
                    description: data?.description || "",
                    isActive: data?.isActive ? "1" : "0",
                    image: data?.image || null,
                    isFree: data?.isFree || "",
                });
                setIsFree(data?.isFree)
            } catch (error) {
                console.error("Error fetching category data:", error);
            }
        };
        fetchCategoryData();
        fetchProviders()
    }, []);

    const stepAddSchema = Yup.object().shape({
        name: Yup.string().required("Enter title"),
        description: Yup.string().required("Enter description"),
    })

    const moveTitle = (dragIndex, hoverIndex) => {
        const draggedTitle = network.titles[dragIndex];
        const newTitles = [...network.titles];
        newTitles.splice(dragIndex, 1);
        newTitles.splice(hoverIndex, 0, draggedTitle);
        setNetwork({ ...network, titles: newTitles });
    };

    const Title = ({ title, index }) => {
        const [{ isDragging }, drag] = useDrag({
            type: 'title',
            item: { type: 'title', index },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        });

        const [, drop] = useDrop({
            accept: 'title',
            hover(item) {
                if (item.index !== index) {
                    moveTitle(item.index, index);
                    item.index = index;
                }
            },
        });

        const opacity = isDragging ? 5 : 1;
        const background = isDragging ? '#FF0000' : '#f0f0f0';
        const color = isDragging ? '#FFFFFF' : '';
        const titleStyle = {
            display: 'inline-block',
            padding: '3px',
            margin: '3px',
            borderRadius: '5px',
            boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)',
            opacity,
            cursor: 'pointer',
            background,
            color
        };

        return (
            <div ref={(node) => drag(drop(node))} style={titleStyle}>
                {title.name}
            </div>
        );
    };

    const handleChange = (checked) => {
        checked == true ? setIsFree(true) : setIsFree(false);
    }

    const handleAddNetwork = async values => {
        const name = network.name
        const type = network.type == 1 ? "Moviez-now" : "Tmdb";
        const watchProvider = parseInt(network.watchProvider);
        const titleType = network.titleType;
        const description = network.description
        const titles = network.type == 1 ? network.titles.map(title => title.value) : []
        const image = network.image
        const sort = network.sort
        const isActive = network.isActive == 1 ? true : false
        const isfree = isFree;
        dispatch(
            addNewCategory({ id: network.id, name, description, isActive, image, titles, sort, isFree, type, watchProvider, titleType }, history)
        )
    }

    const loadTitleOptions = async (search, loadedOptions, { page }) => {
        try {
            let optUrl = `${process.env.REACT_APP_ZFLIX_URL}/v1/titles?page=${page}`
            if (checkNull(search, false)) {
                optUrl = optUrl + "&search=" + search
            }
            const response = await fetch(optUrl, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("authUser")).access_token
                        }`,
                    "Content-Type": "application/json",
                },
            })
            const res = await response.json()
            let newOptions = [...loadedOptions]
            let totalPages = page
            if (res?.data) {
                const data = res.data
                const updatedOptions = checkArray(data.results)
                    ? data.results.map(result => ({
                        value: result.id,
                        name: `${result.title} (${result.year})`,
                    }))
                    : []

                newOptions = [...newOptions, ...updatedOptions]
                totalPages = data.totalPages
            }

            return {
                options: newOptions,
                hasMore: page < totalPages ? true : false, // Whether there are more pages to load
                additional: {
                    page: page + 1,
                },
            }
        } catch (error) {
            return {
                options: [],
                hasMore: false,
                additional: { page: 1 },
            }
        }
    }

    if (!network) {
        return <FullPageLoader />
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="CAtegory" breadcrumbItem="Edit Category" />
                    <Formik
                        initialValues={{
                            name: network.name || "",
                            description: "",
                        }}
                        validationSchema={stepAddSchema}
                        onSubmit={(values, actions) => {
                            handleAddNetwork(values)
                        }}
                    >
                        {({ values, setValues, setFieldValue, errors, touched }) => (
                            <Form
                                onSubmit={e => {
                                    e.preventDefault()
                                    handleAddNetwork()
                                    return false
                                }}
                            >
                                <Label className="my-2" name="networkName">
                                    Name:
                                </Label>
                                <input
                                    type="text"
                                    className="form-control input-color "
                                    placeholder="Enter name here"
                                    name="name"
                                    value={network.name}
                                    onChange={e => {
                                        setNetwork({ ...network, name: e.target.value })
                                        setFieldValue("name", e.target.value)
                                    }}
                                />
                                {errors.name && touched.name ? (
                                    <div className="input-error text-danger">{errors.name}</div>
                                ) : null}


                                <Row>

                                    <Col>
                                        <Label htmlFor="categoryType" className="my-2">Category Type</Label>
                                        <Field name="categoryType" className="form-control"
                                            onChange={e => {
                                                setNetwork({ ...network, type: e.target.value })
                                            }} >
                                            {({ field, form }) => (
                                                <div className="sub-category-select">
                                                    <select id="categoryType" className="form-control input-color " name="categoryType" onChange={e => {
                                                        setNetwork({ ...network, type: e.target.value })
                                                    }} value={network.type}>
                                                        <option value="">Choose</option>
                                                        <option value="1">Moviez-now</option>
                                                        <option value="2">Tmdb</option>
                                                    </select>
                                                    {form.touched.type &&
                                                        form.errors.type ? (
                                                        <div className="input-error text-danger">
                                                            {form.errors.type}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            )}
                                        </Field>
                                    </Col>

                                </Row>



                                {
                                    network.type == "2" ? (

                                        <Row>

                                            <Col>
                                                <Label htmlFor="watchProvider" className="my-2">Watch Provider</Label>
                                                <Field name="watchProvider" className="form-control"
                                                    onChange={e => {
                                                        setNetwork({ ...network, type: e.target.value })
                                                    }}>
                                                    {({ field, form }) => (
                                                        <div className="sub-category-select">
                                                            <select id="watchProvider" className="form-control input-color " name="watchProvider" onChange={e => {
                                                                setNetwork({ ...network, watchProvider: e.target.value })
                                                            }} value={network.watchProvider} >
                                                                <option value="">Choose</option>
                                                                {
                                                                    watchProviders?.map((item, index) => {
                                                                        return (

                                                                            <option key={index} value={item.provider_id}>{item.provider_name}</option>
                                                                        )
                                                                    })
                                                                }

                                                            </select>
                                                            {form.touched.watchProvider &&
                                                                form.errors.watchProvider ? (
                                                                <div className="input-error text-danger">
                                                                    {form.errors.watchProvider}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    )}
                                                </Field>
                                            </Col>

                                        </Row>

                                    ) : (<></>)


                                }

                                {
                                    network.type == "2" ? (

                                        <Row>

                                            <Col>
                                                <Label htmlFor="titleType" className="my-2">Title Type</Label>
                                                <Field name="titleType" className="form-control"
                                                    onChange={e => {
                                                        setNetwork({ ...network, titleType: e.target.value })
                                                    }}>
                                                    {({ field, form }) => (
                                                        <div className="sub-category-select">
                                                            <select id="titleType" className="form-control input-color " name="titleType" onChange={e => {
                                                                setNetwork({ ...network, titleType: e.target.value })
                                                            }} value={network.titleType} >
                                                                <option value="">Choose</option>
                                                                <option value="movie">Movie</option>
                                                                <option value="tv">Tv</option>


                                                            </select>
                                                            {form.touched.titleType &&
                                                                form.errors.titleType ? (
                                                                <div className="input-error text-danger">
                                                                    {form.errors.titleType}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    )}
                                                </Field>
                                            </Col>

                                        </Row>

                                    ) : (<></>)


                                }


                                <Label className="my-2" name="networkDescription">
                                    Description:
                                </Label>
                                <Field
                                    as="textarea"
                                    className="form-control input-color "
                                    placeholder="Enter Description here"
                                    name="description"
                                    rows="5"
                                    value={network.description}
                                    onChange={e => {
                                        setNetwork({ ...network, description: e.target.value })
                                        setFieldValue("description", e.target.value)
                                    }}
                                />
                                {errors.description && touched.description ? (
                                    <div className="input-error text-danger">
                                        {errors.description}
                                    </div>
                                ) : null}

                                <Label className="my-2">Category Image:</Label>
                                <div className="commonImgs" id="imageCon">
                                    <img
                                        src={network?.image || NoImgFound}
                                        id="imageTag"
                                        alt=""
                                        className="every-img"
                                    />
                                </div>
                                <input
                                    type="file"
                                    className="form-control input-color"
                                    onChange={e => {
                                        setNetwork({ ...network, image: e.target.files[0] })
                                    }}
                                />
                                <Label htmlFor="isActive" className="my-2">
                                    Show on home page?
                                </Label>
                                <Field
                                    name="isActive"
                                    className="form-control"
                                    onChange={e => {
                                        setNetwork({ ...network, isActive: e.target.value })
                                    }}
                                >
                                    {({ field, form }) => (
                                        <div className="sub-category-select">
                                            <select
                                                id="isActive"
                                                className="form-control input-color "
                                                name="isActive"
                                                value={network.isActive}
                                                onChange={e => {
                                                    setNetwork({ ...network, isActive: e.target.value })
                                                }}
                                            >
                                                <option value="">Choose</option>
                                                <option value="1">yes</option>
                                                <option value="0">No</option>
                                            </select>
                                            {form.touched.isActive && form.errors.isActive ? (
                                                <div className="input-error text-danger">
                                                    {form.errors.isActive}
                                                </div>
                                            ) : null}
                                        </div>
                                    )}
                                </Field>
                                {
                                    network.type == "1" ? (<>
                                        <Label htmlFor="formrow-firstname-Input" className="my-2">
                                            Titles
                                        </Label>
                                        <SelectAsyncPaginate
                                            // regionName={region.value}
                                            loadOptions={loadTitleOptions}
                                            placeHolder="Select Title"
                                            isMulti={true}
                                            value={network.titles}
                                            onChange={titles => {
                                                setNetwork({ ...network, titles })
                                            }}
                                            isSearchable={true}
                                        />
                                        {errors.titles && touched.titles ? (
                                            <div className="text-danger input-error">{errors.titles}</div>
                                        ) : null}

                                        <Label htmlFor="formrow-firstname-Input" className="my-2">
                                            Category Title index
                                        </Label>
                                        <DndProvider backend={HTML5Backend}>
                                            <div>
                                                {network.titles.map((title, index) => (
                                                    <Title key={title.value} title={title} index={index} />
                                                ))}
                                            </div>
                                        </DndProvider>

                                    </>) : (<></>)


                                }

                                <Row>
                                    <Col>
                                        <Label className="my-2" name="networkName">
                                            Sort Index:
                                        </Label>
                                        <input
                                            type="number"
                                            className="form-control input-color "
                                            placeholder="Enter sort index here"
                                            name="sort"
                                            step="1"
                                            value={network.sort}
                                            onChange={e => {
                                                setNetwork({ ...network, sort: e.target.value })
                                                setFieldValue("sort", e.target.value)
                                            }}
                                        />
                                        {errors.sort && touched.sort ? (
                                            <div className="input-error text-danger">{errors.sort}</div>
                                        ) : null}
                                    </Col>
                                    <Col>
                                        <Label className="my-2" name="networkName">
                                            Make it Free:
                                        </Label><br />
                                        <Switch onChange={handleChange} checked={isFree} />
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col sm={4}>
                                        <Button className="btn btn-sm " type="submit" color="primary">
                                            Save Changes
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                    <ToastContainer />
                </Container>
                {/* container-fluid */}
            </div >
        </React.Fragment >
    )
}


export default EditCategoryPage;
