import React from "react"
import { Redirect } from "react-router-dom"
import UserProfile from "../pages/Authentication/user-profile"
import PrivacyPolicy from "../pages/PrivacyPolicy/index"
import TermConditions from "../pages/TermConditions/index"
import FAQ from "../pages/ManageFAQ/FAQ/index"
import FAQAdd from "../pages/ManageFAQ/FAQAdd/index"
import EditFAQ from "../pages/ManageFAQ/FAQEdit/index"
import Login from "../pages/Authentication/Login"
import Privacy from "../pages/Authentication/Privacy"
import Logout from "../pages/Authentication/Logout"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import RegisteredUsers from "../pages/ManageCustomers/RegisteredUsers"
import AddUser from "../pages/ManageCustomers/RegisteredUsers/AddUser"
import EditUser from "../pages/ManageCustomers/RegisteredUsers/UserEdit"
import Analytics from "../pages/Manage Analytics/Analytics"
import TitleLog from "../pages/ManageTitleLog/TitleLog"
import TitleLogDetails from "../pages/ManageTitleLog/TitleLogView"
import AllCategories from "../pages/ManageCategories/AllCategories"
import AllTitles from "../pages/ManageTitles/AllTitles"
import NewRelease from "../pages/ManageTitles/NewRelease"
import TitleEdit from "../pages/ManageTitles/TitleEdit"
import TitleAdd from "../pages/ManageTitles/TitleAdd"
import Genre from "../pages/ManageGenre/Genre"
import EditGenre from "../pages/ManageGenre/GenreEdit"
import Movies from "../pages/ManageTitles/UploadSeries/Movies"
import UpdateMovies from "../pages/ManageTitles/UploadSeries/UpdateMovies"
import Series from "../pages/ManageTitles/UploadSeries/Series"
import UpdateSeries from "../pages/ManageTitles/UploadSeries/UpdateSeries"
import Trailers from "../pages/ManageTitles/UploadSeries/Trailers"
import UpdateTrailers from "../pages/ManageTitles/UploadSeries/UpdateTrailers"
import GenreAdd from "../pages/ManageGenre/GenreAdd"
import Cast from "../pages/ManageCast/Cast"
import EditCast from "../pages/ManageCast/CastEdit"
import CastAdd from "../pages/ManageCast/CastAdd"
import SubCategoryDetail from "../pages/ManageCategories/SubCategoryDetail"
import SubCategory from "../pages/ManageCategories/SubCategory"
import EditCategory from "../pages/ManageCategories/EditCategory"
import AllContent from "../pages/ManageContent/AllContent"
import UploadImageToS3WithNativeSdk from "../pages/ManageTitles/UploadSeries/UploadImageToS3WithNativeSdk"
import MangeTransactions from "../pages/ManageTransaction/Transactions"
import MangeBuckets from "../pages/BucketDetails/index"
import MangeBucketsDetails from "../pages/BucketDetails/Details"
import ManagePlans from "../pages/ManagePlans"
import TermsConditions from "../pages/Authentication/TermsConditions"
import Notifications from "../pages/Notifications/index"
import NotificationsList from "../pages/Notifications/list"
import NotificationsEdit from "../pages/Notifications/edit"
import SearchLog from "../pages/ManageSearchLog/SearchLog"
import SearchLogDetails from "../pages/ManageSearchLog/SearchLogView"
import AppSetting from "../pages/setting/AppSetting"
// import NewScreenOrderSequence from "../pages/setting/newScreenOrderSequence"
// import AppSetting from "../pages/setting/AppSetting"
import Top10 from "../pages/ManageTitles/top10Titles"
import NewScreenOrderSequence from "../pages/setting/newScreenOrderSequence"

const authProtectedRoutes = [
  { path: "/privacy-policy", component: PrivacyPolicy },
  { path: "/notifications", component: Notifications },
  { path: "/notification-list", component: NotificationsList },
  { path: "/notification/:id/edit", component: NotificationsEdit },
  { path: "/terms-conditions", component: TermConditions },
  { path: "/faq", component: FAQ },
  { path: "/faq/add", component: FAQAdd },
  { path: "/faq/:id/edit", component: EditFAQ },
  { path: "/registered-users", component: RegisteredUsers },
  { path: "/user/:id/edit", component: EditUser },
  { path: "/registered-users/add", component: AddUser },
  { path: "/sub-category-details/:id", component: SubCategoryDetail },
  { path: "/sub-category/:id", component: SubCategory },
  { path: "/category/:id", component: EditCategory },
  { path: "/analytics", component: Analytics },
  { path: "/title-log", component: TitleLog },
  { path: "/search-log", component: SearchLog },
  { path: "/title-log/details", component: TitleLogDetails },
  { path: "/search-log/details", component: SearchLogDetails },
  { path: "/all-categories", component: AllCategories },
  { path: "/titles", component: AllTitles },
  { path: "/new-release", component: NewRelease },
  { path: "/title/:id/edit", component: TitleEdit },
  { path: "/title/:id/uploadMovie", component: Movies },
  { path: "/title/:id/UpdateMovie", component: UpdateMovies },
  { path: "/title/:id/UpdateTrailers", component: UpdateTrailers },
  { path: "/title/:id/uploadTrailers", component: Trailers },
  { path: "/title/:id/uploadSeries", component: Series },
  { path: "/title/:id/UpdateSeries", component: UpdateSeries },
  { path: "/title/add", component: TitleAdd },
  { path: "/top10/movies", component: Top10 },
  { path: "/top10/series", component: Top10 },
  { path: "/genre", component: Genre },
  { path: "/genre/add", component: GenreAdd },
  { path: "/genre/:id/edit", component: EditGenre },
  { path: "/cast", component: Cast },
  { path: "/cast/add", component: CastAdd },
  { path: "/cast/:id/edit", component: EditCast },
  { path: "/dashboard", component: Dashboard },
  { path: "/all-content", component: AllContent },
  { path: "/uploadS3", component: UploadImageToS3WithNativeSdk },
  { path: "/transactions", component: MangeTransactions },
  { path: "/bucket-details", component: MangeBuckets },
  { path: "/bucket-details/:foldername", component: MangeBucketsDetails },
  { path: "/profile", component: UserProfile },
  { path: "/plans", component: ManagePlans },
  { path: "/app-setting", component: AppSetting },
  { path: "/new-screen-orderseq", component: NewScreenOrderSequence },
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },

]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/privacy", component: Privacy },
  { path: "/user/terms-conditions", component: TermsConditions },
  { path: "/forgot-password", component: ForgetPwd },
]

export { authProtectedRoutes, publicRoutes }
