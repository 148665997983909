/* CATEGORIES */
export const GET_USER = "GET_USER"
export const GET_USER_SUCCESS = "GET_USER_SUCCESS"
export const GET_USER_FAIL = "GET_USER_FAIL"

/**
 * add GENRE
 */
 export const ADD_NEW_USER = "ADD_NEW_USER"
 export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS"
 export const ADD_USER_FAIL = "ADD_USER_FAIL"


  
/**
 * Edit GENRE
 */
export const EDIT_USER = "EDIT_USER"
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS"
export const EDIT_USER_FAIL = "EDIT_USER_FAIL"


/**
 * Edit GENRE
 */
 export const UPDATE_USER = "UPDATE_USER"
 export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
 export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL"

/**
 * Delete GENRE
 */
export const DELETE_USER = "DELETE_USER"
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS"
export const DELETE_USER_FAIL = "DELETE_USER_FAIL"
