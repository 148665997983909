import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_CAST, ADD_NEW_CAST, DELETE_CAST, UPDATE_CAST, UPDATE_CAST_IMAGE, EDIT_CAST } from "./actionTypes"

import {
  getCastSuccess,
  getCastFail,
  addCastFail,
  addCastSuccess,
  updateCastimage,
  updateCastImageSuccess,
  updateCastImageFail,
  updateCastSuccess,
  updateCastFail,
  deleteCastSuccess,
  deleteCastFail,
  editCastSuccess,
  editCastFail,
} from "./actions"

//Include Both Helper File with needed methods
import { getCast, addNewCast, updateCast, deleteCast, updateCastImage, editCast } from "../../helpers/fakebackend_helper"
import { stopLoader } from "../loaderState/actions"

function* fetchCast({ payload:page }) {
  try {

    const response = yield call(getCast,page.page)
    yield put(getCastSuccess(response))
    yield put(stopLoader())
  } catch (error) {
    yield put(getCastFail(error))
  }
}

function* onEditCast({ payload:cast }) {
  try {

    const response = yield call(editCast,cast)
    yield put(editCastSuccess(response))
  } catch (error) {
    yield put(editCastFail(error))
  }
}
function* onUpdateCast({ payload: {cast, history } }) {
  try {
    const id =cast.id;
    const name =cast.name;
    const title_name =cast.title_name;
    const bio =cast.bio;
    const image =cast.image;
    const isActive = cast.isActive;
    const response = yield call(updateCast, id,{ name, title_name, bio , isActive })
    yield put(updateCastSuccess(response.data))
    const catId = response.data.id;
    if(image != undefined){
      yield put(updateCastimage(catId, image))
    }
    history.push("/cast");
    
  } catch (error) {
    yield put(updateCastFail(error))
  }
}

function* onDeleteCast({ payload: {cast, history } }) {
  try {
    const response = yield call(deleteCast,cast)

    yield put(deleteCastSuccess(cast))
    history.push("/cast");

  } catch (error) {
    yield put(deleteCastFail(error))
  }
}

function* onAddNewCast({ payload: {cast, history } }) {

  try {
    const name =cast.name;
    const title_name =cast.title_name;
    const bio =cast.bio;
    const image =cast.image;
    const isActive =cast.isActive == 1 ? true : false
    const response = yield call(addNewCast, { name, title_name, bio, isActive })
    yield put(addCastSuccess(response.data))
    const catId = response.data.id;
    if(image != undefined){
      yield put(updateCastimage(catId, image))
    }
    history.push("/cast");
  } catch (error) {

    yield put(addCastFail(error))
  }
}

function* onUpdateCastImage({ payload: { id, image } }) {
  try {
    const formData = new FormData()
    formData.append('image', image)
    const response = yield call(updateCastImage, id, { image })
    yield put(updateCastImageSuccess(response.data))
  } catch (error) {

    yield put(updateCastImageFail(error))
  }
}

function* CastSaga() {
  yield takeEvery(GET_CAST, fetchCast)
  yield takeEvery(ADD_NEW_CAST, onAddNewCast)
  yield takeEvery(UPDATE_CAST, onUpdateCast)
  yield takeEvery(DELETE_CAST, onDeleteCast)
  yield takeEvery(EDIT_CAST, onEditCast)
  yield takeEvery(UPDATE_CAST_IMAGE, onUpdateCastImage)
}

export default CastSaga;
