import React, { useEffect, useState } from "react";
import { CCard, CCardBody, CCardGroup } from "@coreui/react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import axios from "axios"
import {
  Col,
  Row,
} from "reactstrap";
import { toast } from "react-toastify";
import ReactDatePicker from "react-datepicker";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { CountryDropdown } from "react-country-region-selector"
import moment from 'moment-timezone';
import { countries } from 'country-data';


const ManageNotifications = () => {
  const [sendOption, setSendOption] = useState('');
  const history = useHistory()
  const API_URL = `${process.env.REACT_APP_ZFLIX_URL}/v1`
  const [startDate, setStartDate] = useState();

  const [formData, setFormData] = useState({
    title: "",
    message: "",
    userOption: "",
    sendOption: "",
    time: [],
    utcTime: [],
    days: [],
    timeZoneCountry: ""
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      if (checked) {
        if (!formData[name].includes(value)) {
          setFormData((prevData) => ({
            ...prevData,
            [name]: [...prevData[name], value],
          }));
        }
      } else {

        setFormData((prevData) => ({
          ...prevData,
          [name]: [...prevData[name], value],
        }));
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSendOptionChange = (e) => {
    setSendOption(e.target.value);
    if (e.target.value === "1" || e.target.value === "2") {
      setFormData({ ...formData, days: [], sendOption: e.target.value })
    } else {
      setFormData({ ...formData, sendOption: e.target.value })
    }
  };


  function getTime(date) {
    const frequencyDate = new Date(date);
    const hour = frequencyDate.getHours();
    const minutes = frequencyDate.getMinutes();
    const formattedHour = hour.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const time = `${formattedHour}:${formattedMinutes}`;

    const countryCode = countries.all.find((c) => c.name === formData.timeZoneCountry);
    const timeZones = moment.tz.zonesForCountry(countryCode.alpha2);
    const localTime = moment.tz({ formattedHour, formattedMinutes }, timeZones[0]);
    const utcTime = localTime.clone().utc();
    console.log("time",time)

    return {
      "time":time,
      "utcTime":utcTime.format('HH:mm')
    };
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const token = JSON.parse(sessionStorage.getItem("authUser"))
    const time = getTime(startDate)
    formData.time = time.time
    formData.utcTime = time.utcTime

    if (formData.sendOption === "1") {
      formData.time = []
      formData.utcTime = []
    }

    axios
      .post(
        API_URL + "/admin-notification",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token.access_token}`,
          },
        }
      )
      .then(function (response) {
        toast.success("Create Successfully!!")
        setFormData({
          title: "",
          message: "",
          userOption: "",
          sendOption: "",
          frequency: "",
          days: [],
        });
        history.push('/notification-list')
      })
      .catch(function (error) {
        console.log(error)
        toast.error("Something went wrong" + error)
      })
  };


  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Notifications" breadcrumbItem="Create Notification" />
          <Row>
            <Col xl={12}>
              <CCardGroup>
                <CCard>
                  <CCardBody>
                    <form className="" onSubmit={handleSubmit}>
                      <div className="mb-3">
                        <label htmlFor="formrow-firstname-Input" className="form-label">Title</label>
                        <input
                          name="title"
                          id="formrow-firstname-Input"
                          placeholder="Enter notification title"
                          type="text"
                          className="form-control form-control"
                          aria-invalid="false"
                          onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                          required />
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label htmlFor="formrow-email-Input" className="form-label">Message</label>
                            <textarea id="textarea"
                              rows="3"
                              placeholder="Your Message"
                              onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                              required
                              className="form-control">
                            </textarea>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="mb-3">
                              <h5 className="font-size-14 mb-4">User Type</h5>
                              <div className="mt-4 d-flex">
                                <div className="form-check ml-2" style={{ marginRight: '10px' }}>
                                  <input className="form-check-input"
                                    type="radio"
                                    name="exampleRadios"
                                    onChange={(e) => setFormData({ ...formData, userOption: e.target.value })}
                                    id="exampleRadios1"
                                    value="1"
                                    required />
                                  <label className="form-check-label" htmlFor="exampleRadios1">Guest</label>
                                </div>
                                <div className="form-check" style={{ marginRight: '10px' }}>
                                  <input className="form-check-input"
                                    type="radio"
                                    name="exampleRadios"
                                    onChange={(e) => setFormData({ ...formData, userOption: e.target.value })}
                                    id="exampleRadios2"
                                    value="2"
                                    required />
                                  <label className="form-check-label" htmlFor="exampleRadios2">Subscribed</label>
                                </div>
                                <div className="form-check" style={{ marginRight: '10px' }}>
                                  <input className="form-check-input"
                                    type="radio"
                                    name="exampleRadios"
                                    onChange={(e) => setFormData({ ...formData, userOption: e.target.value })}
                                    id="exampleRadios3"
                                    value="3"
                                    required />
                                  <label className="form-check-label" htmlFor="exampleRadios3">All</label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                                <label className="form-check-label" >Select Country Timezone</label>
                                <CountryDropdown
                                  value={formData.timeZoneCountry || ""}
                                  onChange={(val) => setFormData({ ...formData, timeZoneCountry: val })}
                                  className="form-control w-100"
                                  required
                                />
                            </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="mb-3">
                              <h5 className="font-size-14 mb-4">Send Options</h5>
                              <div className="mt-4 d-flex">
                                <div className="form-check" style={{ marginRight: '10px' }}>
                                  <label className="form-check-label" htmlFor="sendOption1">Now</label>
                                  <input className="form-check-input"
                                    type="radio"
                                    name="sendOption"
                                    id="sendOption1"
                                    checked={sendOption === '1'}
                                    onClick={handleSendOptionChange}
                                    required
                                    value="1" />
                                </div>
                                <div className="form-check" style={{ marginRight: '10px' }}>
                                  <label className="form-check-label" htmlFor="sendOption2">Daily</label>
                                  <input className="form-check-input"
                                    type="radio" name="sendOption"
                                    id="sendOption2"
                                    checked={sendOption === '2'}
                                    onClick={handleSendOptionChange}
                                    required
                                    value="2" />
                                </div>
                                <div className="form-check" style={{ marginRight: '10px' }}>
                                  <label className="form-check-label" htmlFor="sendOption3">Weekly</label>
                                  <input className="form-check-input"
                                    type="radio"
                                    name="sendOption"
                                    id="sendOption3"
                                    required
                                    onClick={handleSendOptionChange}
                                    checked={sendOption === '3'}
                                    value="3" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          {sendOption === '2' && (
                            <>
                              <div className="col-md-3">
                                <label className="form-check-label" >Select time for daily</label>
                                <ReactDatePicker
                                  className="form-control"
                                  selected={startDate}
                                  onChange={(date) => setStartDate(date)}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={15}
                                  timeCaption="Time"
                                  dateFormat="h:mm aa"
                                />
                              </div>

                            </>
                          )}
                          {sendOption === '3' && (
                            <>
                              <div className="col-md-3">
                                <label className="form-check-label" >Select time for weekly</label>
                                <ReactDatePicker
                                  className="form-control"
                                  selected={startDate}
                                  onChange={(date) => setStartDate(date)}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={15}
                                  timeCaption="Time"
                                  dateFormat="h:mm aa"
                                />
                              </div>
                              <div className="col-md-6">
                                <label className="form-check-label" >Days</label>
                                {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day, index) => (
                                  <div key={day} className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id={day}
                                      name="days"
                                      value={day}
                                      onClick={handleChange}
                                      checked={formData.days.includes(day)}
                                    />
                                    <label className="form-check-label" htmlFor={day}>
                                      {day.charAt(0).toUpperCase() + day.slice(1)}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="float-end">
                        <button type="submit" className="btn btn-primary w-md">Submit</button>
                      </div>
                    </form>
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ManageNotifications;
