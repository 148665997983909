import React, { useEffect, useRef, useState } from "react"
import ReactPlayer from "react-player"
import { Row, Col, Label, Button, Badge } from "reactstrap"
import { useHistory, Link } from "react-router-dom"

import Breadcrumbs from "../../../components/Common/Breadcrumb"
import FullPageLoader from "../../../components/Common/FullPageLoader"
import { useDispatch, useSelector } from "react-redux"
import { setTokenHeader } from "../../../helpers/api_helper"
import { editTitle, updateTrailervideo } from "../../../store/actions"
import { startLoader } from "../../../store/loaderState/actions"
import { useParams } from "react-router-dom"
import { checkArray } from "../../../helpers/basicFunctions"
import DeleteModal from "../../../components/Common/DeleteModal"
import { updateTrailerDuration } from "../../../store/title/toolkit/action"
import { toast } from "react-toastify"


const Trailers = () => {
  document.title = "Manage Trailers"
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState(-1)

  const onClickDelete = trailerIndex => {
    setDeleteId(trailerIndex)
    setDeleteModal(true)
  }
  const closeDeleteModel = () => {
    setDeleteId(-1)
    setDeleteModal(false)
  }

  const [image, setImage] = useState({
    blob: null,
    src: "",
  })
  const [video, setVideo] = useState()

  const { titleSingle } = useSelector(state => state.title)
  const { loadingState } = useSelector(state => state.loadingState)
  const [videoDurations, setVideoDurations] = useState([]);

  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem("authUser"))
    setTokenHeader(data.access_token)
    dispatch(startLoader())
    dispatch(editTitle(params.id))
  }, [])

  useEffect(() => {
    if (titleSingle?.id == params.id && !checkArray(titleSingle?.trailer)) {
      history.push(`/title/${params.id}/UpdateTrailers`)
    }
  }, [titleSingle])

  const deleteTrailerHandler = () => {
    if (deleteId > -1) {
      dispatch(
        updateTrailervideo(params.id, {}, history, deleteId)
      )
      closeDeleteModel();
    }
  }

  const handleUpdateDuration = (trailerIndex, id) => {
    const trailer = titleSingle?.trailer[trailerIndex];
    const currentVideoDuration = videoDurations[trailerIndex];

    dispatch(updateTrailerDuration({id:id,duration:currentVideoDuration.toString()})).then((v)=>{
        toast.success("Trailer Duration Update Successfully")
    }).catch((er)=>{
      toast.error("Something went wrong!!")
    })

      console.log(`Updating duration for trailer ${trailerIndex}: ${currentVideoDuration} and id${id}`);
    
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={deleteTrailerHandler}
        onCloseClick={() => closeDeleteModel()}
      />
      <div className="page-content">
        <div className="container-fluid titlelisting">
          <Breadcrumbs title="Manage Trailers" breadcrumbItem="Trailers" />
          <FullPageLoader loading={loadingState} />
          <Row>
            <Col xl={12} className="w-100 mb-2">
              <Link
                to={`/title/${params.id}/UpdateTrailers`}
                className="btn btn-primary w-md"
              >
                Upload Trailer
              </Link>
            </Col>
          </Row>
          {checkArray(titleSingle?.trailer) &&
            titleSingle?.trailer.map((trailerObj, trailerIndex) => (
              <Row key={trailerObj?._id}>
                <Col xl={12} className="w-100 my-3">
                  <h4>
                    <Badge>{trailerObj?.name}</Badge>
                  </h4>
                  <div className="d-flex justify-content-end">
                    {videoDurations.length > 0 && videoDurations[trailerIndex] !== null && (
                      <Button
                        className="mt-2 btn-sm btn-info"
                        onClick={() => handleUpdateDuration(trailerIndex, trailerObj?._id)}
                      >
                        Update Duration
                      </Button>
                    )}
                    <Button className="mt-2 ml-4 btn-sm btn-danger" style={{marginLeft:'10px'}} onClick={() => onClickDelete(trailerIndex)} >
                      Delete
                    </Button>
                  </div>
                </Col>
                <Col sm={6} xl={6} className="">
                  <Label htmlFor="formrow-firstname-Input">
                    Uploaded Trailer Thumbnail
                  </Label>
                  <div className="w-100">
                    <img src={trailerObj?.thumbnail} alt="" />
                  </div>
                </Col>
                <Col sm={6} xl={6} className="">
                  <Label htmlFor="formrow-firstname-Input">
                    Uploaded Trailer file
                  </Label>
                  <div style={{ maxHeight: "400px" }}>
                    <ReactPlayer id="video-player"
                      controls={true} url={trailerObj?.file}
                      onDuration={(duration) => {
                        const updatedDurations = [...videoDurations];
                        updatedDurations[trailerIndex] = duration;
                        setVideoDurations(updatedDurations);
                      }}
                    />
                  </div>
                </Col>
              </Row>
            ))}
        </div>
      </div>
    </React.Fragment>
  )
}

export default Trailers
