import React, { useEffect, useRef, useState } from "react"

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Input,
  InputGroup,
} from "reactstrap"
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';
import cloud from "../../../assets/flix-images/cloud-file-download.svg"
import "./genreAdd.css"
import * as Yup from "yup"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Dropzone from "react-dropzone"
import { toast } from "react-toastify"
import { Field, Formik, Form } from "formik"
import { fireToast } from "../../../common/toast"
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addNewGenre } from "../../../store/actions";
import { setTokenHeader } from "../../../helpers/api_helper";

const GenreeAdd = props => {


  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem("authUser"))

    setTokenHeader(data.access_token)
  }, [])


  const history = useHistory()
  const dispatch = useDispatch();
  //meta title
  const uploadRef = useRef(null)
  document.title = "Manage Customers"
  const [data, setData] = useState({
    name: "",
    description: "",
    isActive: "",
    image:""
  })
  const [image, setImage] = useState({
    blob: null,
    src: "",
  })


  // image validation
  const handleImageChange = files => {
    const extn = ["image/jpg", "image/png", "image/jpeg"]
    const [image] = files
    setData({ ...data, image })

    if (image && extn.includes(image.type)) {
      // changeApiStatus(false, "")
      if (image.size <= 5242880) {
        setImage({ blob: image, src: window.URL.createObjectURL(image) })
      } else {
        fireToast("error", "image too large")
      }
    } else {
      // changeApiStatus(false, 'Please select a valid image image')
      fireToast(
        "error",
        "Please select a valid image file(only jpg, png and jpeg images are allowed)"
      )
    }
  }

  const EditGenre = Yup.object().shape({
    name: Yup.string()
      .max(1000, "Too Long!!")
      .required("Enter the Genre Name"),
      description: Yup.string()
        .required("Enter description"),
        isActive: Yup.string()
          .required("Please select"),
  })

  const HandleSubmit = e => {
    dispatch(addNewGenre(
      data
    ,history))
    toast.success("Updated Successfully!!")
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Genre" breadcrumbItem="Add Genre" />
          <Formik
            initialValues={{ ...data }}
            validationSchema={EditGenre}
            enableReinitialize
            onSubmit={(e) => {
              HandleSubmit();
              return false;
            }}
          >
            {({ values, setValues, setFieldValue, errors, touched }) => (
              <Row>
                <Col xl={8}>
                  <Card>
                    <CardBody>
                      <Form
                        
                      >
                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">
                            Genre Name
                          </Label>
                          <Field
                            type="text"
                            className="form-control"
                            id="Name"
                            name="Name"
                            onChange={e => {
                              setData({ ...data, name: e.target.value })
                              setFieldValue('name', e.target.value)
                            }}
                          />
                          {errors.name && touched.name ? (
                            <div className="text-danger input-error">
                              {errors.name}
                            </div>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">
                            description
                          </Label>
                          <Field
                            as="textarea"
                            className="form-control"
                            id="description"
                            name="description"
                            rows="5"
                            onChange={e => {
                              setData({ ...data, description: e.target.value })
                              setFieldValue('description', e.target.value)
                            }}
                          />
                          {errors.description && touched.description ? (
                            <div className="text-danger input-error">
                              {errors.description}
                            </div>
                          ) : null}
                        </div>
                        <div>
                          <Label htmlFor="isActive">isActive</Label>
                          <Field name="isActive" className="form-control"
                            onChange={e => {
                              setData({ ...data, isActive: e.target.value })
                            }}>
                            {({ field, form }) => (
                              <div className="sub-category-select">
                                <select id="isActive" className="form-control input-color " name="isActive" onChange={e => {
                                  setData({ ...data, isActive: e.target.value })
                                }}>
                                  <option value="">Choose</option>
                                  <option value="1">yes</option>
                                  <option value="0">No</option>
                                </select>
                                {errors.isActive && touched.isActive ? (
                            <div className="text-danger input-error">
                              {errors.isActive}
                            </div>
                          ) : null}
                              </div>
                            )}
                          </Field>
                        </div>
                        <div >
                          <Label htmlFor="formrow-firstname-Input">
                            Image <small>(Optional)</small>
                          </Label>
                          <div className="text-center">

                            <div className="text-center">
                              <div className="mb-3 dragdrop-container">
                                <input
                                  ref={uploadRef}
                                  id="upload"
                                  hidden
                                  accept="image/*"
                                  type="file"
                                  onChange={e =>
                                    handleImageChange(e.target.files)
                                      
                                  }
                                />
                                {image.src ? (
                                  <div className="commonImgs">
                                    <img
                                      className="every-img"
                                      src={image.src ? image.src : cloud}
                                      alt=""
                                      onClick={() => {
                                        uploadRef.current.click()
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <div className="drag-n-drop-container">
                                    <div>
                                      <Dropzone
                                        accept="image/*"
                                        multiple={false}
                                        onDrop={acceptedFiles => {
                                          handleImageChange(acceptedFiles)
                                        }}
                                      >
                                        {({
                                          getRootProps,
                                          getInputProps,
                                          isDragActive,
                                        }) => (
                                          <section>
                                            <div
                                              className="drop-area"
                                              {...getRootProps()}
                                            >
                                              <img
                                                width={60}
                                                src={cloud}
                                                alt=""
                                              />
                                              <input
                                                {...getInputProps()}
                                                accept="image/*"
                                                multiple={false}
                                              />

                                              {isDragActive ? (
                                                <div>
                                                  Drop your image file here
                                                </div>
                                              ) : (
                                                <p>
                                                  Drag n drop image file here,
                                                  or click to select <br />
                                                  <small className="text-center">
                                                    <strong>
                                                      Supported files:
                                                    </strong>{" "}
                                                    jpeg, jpg, png. | Will be
                                                    resized to: 1920x1080 px.
                                                  </small>
                                                </p>
                                              )}
                                            </div>
                                          </section>
                                        )}
                                      </Dropzone>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            <p className="fw-bold">
                              Note:
                              <span className="text-danger mx-2 text-align-left">
                                Supported image formats are:&nbsp;jpg, png and
                                jpeg only
                              </span>
                            </p>
                          </div>
                        </div>
                        <div>
                          <button
                            type="submit"
                            className="btn btn-primary w-md"
                          >
                            Submit
                          </button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
          </Formik>
        </Container>
        {/* container-fluid */}
      </div>
    </React.Fragment>
  )
}


export default GenreeAdd
