import { createStore, applyMiddleware, compose } from "redux"
import createSagaMiddleware from "redux-saga"
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducers"
import rootSaga from "./sagas"
import thunk from "redux-thunk";

const sagaMiddleware = createSagaMiddleware()
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk,sagaMiddleware],
})
sagaMiddleware.run(rootSaga)

export default store
