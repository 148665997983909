import { createSlice } from "@reduxjs/toolkit";

const UserSlice = createSlice({
    name: 'userSlice',
    initialState: {
        type : "Live"
    },
    reducers: {
        updateUserType: (state = '', action) => {
            state.type = action.payload.type;
        }
    },
    extraReducers: (builder) => {

    }
})

export const { updateUserType } = UserSlice.actions;

export default UserSlice.reducer;