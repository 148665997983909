import React from "react"

import {useSelector } from "react-redux";
import Loading from 'react-fullscreen-loading';

const FullPageLoader = props => {
  
    return(
      <Loading {...props} 
      background="#fff" 
      loaderColor="#3498db" />
    )
}


export default FullPageLoader
