import { createSlice } from "@reduxjs/toolkit";
import { updateTrailerDuration, updateMovieDuration, getTitleLogReport, TitleLogReportDetails } from "./action";

const TitleSlice = createSlice({
    name: 'titleSlice',
    initialState: {
        trailerUpdate: false,
        logReport: [],
        logReportDetails: [],
        titlePage: 1,
        titleCountries : "",
        watchedDurationType:'title'
    },
    reducers: {
        updateHistory: (state = '', action) => {
            state.titlePage = action.payload.page;
            state.titleCountries = action.payload.countries;
        },
        updateWatchedDurationType: (state = '', action) => {
            state.watchedDurationType = action.payload.type;
        }
    },
    extraReducers: (builder) => {

        // handle  get Files
        builder.addCase(updateTrailerDuration.pending, (state) => {
            return { ...state, trailerUpdate: false };
        });
        builder.addCase(updateTrailerDuration.fulfilled, (state, action) => {
            if (action.payload[0])
                return {
                    ...state,
                    trailerUpdate: true,
                }
            else
                return { ...state, trailerUpdate: false }
        });
        builder.addCase(updateTrailerDuration.rejected, (state) => {
            return { ...state, trailerUpdate: false }
        });

        // Get All title log report
        builder.addCase(getTitleLogReport.pending, (state) => {
            return { ...state, logReport: [] };
        });
        builder.addCase(getTitleLogReport.fulfilled, (state, action) => {
            if (action.payload[0])
                return {
                    ...state,
                    logReport: action.payload[1],
                }
            else
                return { ...state, trailerUpdate: false }
        });
        builder.addCase(getTitleLogReport.rejected, (state) => {
            return { ...state, logReport: [] }
        });

        // Get All title log report details
        builder.addCase(TitleLogReportDetails.pending, (state) => {
            return { ...state, logReportDetails: [] };
        });
        builder.addCase(TitleLogReportDetails.fulfilled, (state, action) => {
            if (action.payload[0])
                return {
                    ...state,
                    logReportDetails: action.payload[1].data,
                }
            else
                return { ...state, trailerUpdate: false }
        });
        builder.addCase(TitleLogReportDetails.rejected, (state) => {
            return { ...state, logReportDetails: [] }
        });
    }
})

export const { updateHistory , updateWatchedDurationType} = TitleSlice.actions;

export default TitleSlice.reducer;