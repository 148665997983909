export const checkArray = (arr, res = true, val = false) => {
  return arr && Array.isArray(arr) && arr.length > 0 ? res : val
}

export const checkNull = (string, val = "") => {
  if (typeof string === "number") {
    string = string.toString()
  }

  return string && string !== "" && string !== null ? string : val
}

export const checkIsNum = (num, val = 0) => {
  try {
    if (typeof num === "string") {
      num = parseFloat(num)
    }
  } catch (error) {}

  return num && typeof num === "number" ? num : val
}

export const removeDuplicates = arr => {
  return arr.filter((item, index) => arr.indexOf(item) === index)
}

export const randomNumber = (min, max) =>
  Math.floor(Math.random() * (max - min)) + min

export const deepCopy = obj => {
  return JSON.parse(JSON.stringify(obj))
}

export const groupByArray = (arr, groupBy) => {
  return arr.reduce((acc, obj) => {
    const key = obj[groupBy]
    if (!acc[key]) {
      acc[key] = []
    }
    acc[key].push(obj)
    return acc
  }, {})
}

export const removeExtention = (string, preFix = "") => {
  let res = ""
  if (typeof string === "string") {
    const explodedArray = string.split(".")
    if (checkArray(explodedArray) && explodedArray.length > 1) {
      explodedArray.pop()
      res = explodedArray.join(".")
    } else {
      res = explodedArray.join("")
    }
    res = `${res}${preFix}`
  }
  return res
}

export const generateRandomString = length => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
  let result = ""

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length)
    result += characters.charAt(randomIndex)
  }

  return result
}
