import React, { useEffect, useState } from "react";
import { Card, CardBody, Container } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import FileList from "./FileList";

const series = [76];
const options = {
  chart: {
    height: 150,
    type: "radialBar",
    sparkline: {
      enabled: true,
    },
  },
  colors: ["#556ee6"],
  plotOptions: {
    radialBar: {
      startAngle: -90,
      endAngle: 90,
      track: {
        background: "#e7e7e7",
        strokeWidth: "97%",
        margin: 5, // margin is in pixels
      },
      hollow: {
        size: "60%",
      },
      dataLabels: {
        name: {
          show: false,
        },
        value: {
          offsetY: -2,
          fontSize: "16px",
        },
      },
    },
  },
  grid: {
    padding: {
      top: -10,
    },
  },
  stroke: {
    dashArray: 3,
  },
  labels: ["Storage"],
};

const FileManager = () => {
  useEffect(() => {
    document.title = "File Manager | ZFlix - Admin Dashboard";
  }, []);

  const [filemanager] = useState({
    options: options,
    series: series,
  });

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Bucket" breadcrumbItem="FilmHub Bucket" />
        <div className="d-xl-flex">
          <div className="w-100">
            <div className="d-md-flex">
              <div className="w-100">
                <Card>
                  <CardBody>
                    <FileList />
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default FileManager;
