import {
  GET_GENRE,
  GET_GENRE_FAIL,
  GET_GENRE_SUCCESS,
  ADD_NEW_GENRE,
  ADD_GENRE_SUCCESS,
  ADD_GENRE_FAIL,
  UPDATE_GENRE_IMAGE,
  UPDATE_GENRE_IMAGE_SUCCESS,
  UPDATE_GENRE_IMAGE_FAIL,
  UPDATE_GENRE,
  UPDATE_GENRE_SUCCESS,
  UPDATE_GENRE_FAIL,
  EDIT_GENRE,
  EDIT_GENRE_SUCCESS,
  EDIT_GENRE_FAIL,
  DELETE_GENRE,
  DELETE_GENRE_SUCCESS,
  DELETE_GENRE_FAIL,
} from "./actionTypes"

export const getGenre = (page =1,isActive= "all") =>({
    type: GET_GENRE,
    payload: {page,isActive},
  })


export const getGenreSuccess = Genre => ({
  type: GET_GENRE_SUCCESS,
  payload: Genre,
})

export const addNewGenre = (genre,history) => ({
  type: ADD_NEW_GENRE,
  payload: {genre,history},
})

export const addGenreSuccess = genre => ({
  type: ADD_GENRE_SUCCESS,
  payload: genre,
})

export const addGenreFail = error => ({
  type: ADD_GENRE_FAIL,
  payload: error,
})

export const getGenreFail = error => ({
  type: GET_GENRE_FAIL,
  payload: error,
})




export const updateGenreimage = (id,image) => ({
  type: UPDATE_GENRE_IMAGE,
  payload: {id,image},
})

export const updateGenreImageSuccess = genre => ({
  type: UPDATE_GENRE_IMAGE_SUCCESS,
  payload: genre,
})
export const updateGenreImageFail = error => ({
  type: UPDATE_GENRE_IMAGE_FAIL,
  payload: error,
})

export const updateGenreSuccess = genre => ({
  type: UPDATE_GENRE_SUCCESS,
  payload: genre,
})

export const updateGenre = (genre,history) => ({
  type: UPDATE_GENRE,
  payload: {genre,history},
})
export const updateGenreFail = error => ({
  type: UPDATE_GENRE_FAIL,
  payload: error,
})
export const editGenreSuccess = genre => ({
  type: EDIT_GENRE_SUCCESS,
  payload: genre,
})

export const editGenre = (genre) => ({
  type: EDIT_GENRE,
  payload: genre,
})
export const editGenreFail = error => ({
  type: EDIT_GENRE_FAIL,
  payload: error,
})

export const deleteGenre = (genre,history) => ({
  type: DELETE_GENRE,
  payload: {genre,history},
})

export const deleteGenreSuccess = id => ({
  type: DELETE_GENRE_SUCCESS,
  payload: id,
})

export const deleteGenreFail = error => ({
  type: DELETE_GENRE_FAIL,
  payload: error,
})

