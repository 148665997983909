import React, { useEffect, useState } from "react"
import { cilPencil, cilTrash } from "@coreui/icons"
import Image from "react-bootstrap/Image"
import "./searchData.css"

import { CCard, CCardBody, CCardGroup } from "@coreui/react"
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"
import DatePicker from "react-datepicker"

import SelectAsyncPaginate from "../../ManageTitles/TitleAdd/SelectAsyncPaginate"
import DataTable from "react-data-table-component"
import { Row, Col } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import axios from "axios"
import moment from "moment"
import { useHistory, Link } from "react-router-dom"
import { setTokenHeader } from "../../../helpers/api_helper"
import { useDispatch, useSelector } from "react-redux"
import { getSearchData } from "../../../store/actions"
import { startLoader } from "../../../store/loaderState/actions"
import FullPageLoader from "../../../components/Common/FullPageLoader"
import NoImgFound from "../../../assets/No-image-found.jpg"

const SearchData = () => {
  document.title = "Search-Report"

  const [isLoading, setIsLoading] = useState(true)
  const [filterValue, setFilterValue] = useState("")
  // const [startDate, setStartDate] = useState(moment().startOf("month").toDate())
  // const [endDate, setEndDate] = useState(new Date())
  const { loadingState } = useSelector(state => state.loadingState)
  const [page, setPage] = useState(1)
  const history = useHistory()
  const dispatch = useDispatch() 
  const {searchData} =useSelector(state => state.searchData)
  const [searchDataData, setSearchDataData] = useState(searchData);
  let limit = 10

  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem("authUser"))
    setTokenHeader(data.access_token)
    dispatch(startLoader())
    dispatch(getSearchData())
  }, [page])

  useEffect(() => {
    setSearchDataData(searchData)
    setIsLoading(false)
  }, [searchData])

  useEffect(() => {
    dispatch(startLoader(page))
    dispatch(getSearchData(page))
  }, [page])


   const toggleDropdown = () => {
    setDropdownOpen(prevState => !prevState)
  }

  const handleFilterChange = value => {
    setFilterValue(value)
    dispatch(updateWatchedDurationType({ type: value }))
  }

  const handleViewDetails = data => {
    history.push({
      pathname: '/search-log/details',
      state: {  data }
    });
  }

  // const onChange = dates => {
  //   if (Array.isArray(dates) && dates.length === 2) {
  //     const [start, end] = dates
  //     setStartDate(start)
  //     setEndDate(end)
  //   }
  // }

  const columns = [
    {
      name: "Sr. no",
      selector: (row, index) => index + 1,
      sortable: true,
      maxWidth: "100px",
    },
    { 
      name: "Searched Text",
       selector: row => row?.keyword, sortable: true 
      },
   
    {
      name: "Searched Count",
      selector: row => row?.count || "",
      sortable: true,
    },
    {
      name: "Searched Date",
      selector: row =>
        row.users ? new Date(row?.users?.[row.users.length -1]?.updatedAt).toLocaleString() : "",
      sortable: true,
    },
    
    {
      name: "Actions",
      selector: row => (
        <Button
          onClick={() => handleViewDetails(row)}
          className="btn btn-info btn-sm"
        >
          View
        </Button>
      ),
      // sortable: true,
    },
  ]

  // const Filtercolumns = [
  //   {
  //     name: "Sr. no",
  //     selector: (row, index) => index + 1,
  //     sortable: true,
  //     maxWidth: "100px",
  //   },
  
  //   {
  //     name: "Searched Text",
  //     selector: row => row?.keyword,
  //     sortable: true,
  //   },
  //   {
  //     name: "Searched Count",
  //     selector: row => row?.count || "",
  //     sortable: true,
  //   },
  //   // {
  //   //   name: "Date",
  //   //   selector: row =>
  //   //     row?.created_at ? new Date(row?.created_at).toLocaleString() : "",
  //   //   sortable: true,
  //   // },
  // ]
  if (!isLoading && !searchData) {
    return <FullPageLoader loading={true} />
  }

  return (
    <div className="page-content genre">
      <div className="container-fluid">
        <Breadcrumbs title="Search Report" breadcrumbItem="Manage Search Report" />
        <Row>
          <Col xl={12}>
            <div className="d-flex align-items-start justify-content-between">
              <div className="select-user">
          
              </div>

              <div className="d-flex align-items-center">
                <div className="ml-2">
                  {/* <DatePicker
                    className="form-control p-1"
                    selected={startDate}
                    onChange={onChange}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                  /> */}
                </div>

                <div className="ml-2">
                 
                </div>
              </div>
            </div>

            <CCardGroup className="CCardGroup">
              <CCard>
                <CCardBody>
                    {/* <Dropdown
                      isOpen={dropdownOpen}
                      toggle={toggleDropdown}
                      className="d-flex align-items-center justify-content-end mb-2"
                    >
                      <DropdownToggle caret>
                        {filterValue.toUpperCase() || "Filter"}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() => handleFilterChange("title")}
                        >
                          Title
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => handleFilterChange("trailer")}
                        >
                          Trailer
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown> */}
                  {searchDataData && (
                    <DataTable
                      striped
                      columns={columns}
                      data={searchDataData}
                      highlightOnHover
                      // pagination
                      // paginationServer
                      // paginationTotalRows={searchData?.totalResults}
                      // paginationPerPage={searchData?.limit}
                      // paginationComponentOptions={{
                      //   noRowsPerPage: true,
                      // }}
                      // onChangePage={(page, totalRows) => {
                      //   setPage(page)
                      // }}
                    />
                  ) 
                  }
                </CCardBody>
              </CCard>
            </CCardGroup>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default SearchData
