import React, { useEffect, useRef, useState } from "react"
import ReactPlayer from 'react-player';
import {
  Row,
  Col,
  Label,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap"
import { useHistory, Link, useLocation } from "react-router-dom"

import Breadcrumbs from "../../../components/Common/Breadcrumb"
import FullPageLoader from "../../../components/Common/FullPageLoader"
import { useDispatch, useSelector } from "react-redux"
import { setTokenHeader } from "../../../helpers/api_helper"
import { editTitle } from "../../../store/actions"
import { startLoader } from "../../../store/loaderState/actions"
import { useParams } from "react-router-dom"
import { checkArray, checkNull } from "../../../helpers/basicFunctions"

const Series = () => {
  document.title = "Manage Series"
  const location = useLocation()
  const seasonId = location?.state?.seasonId
  const { titleSingle } = useSelector(state => state.title)
  const { loadingState } = useSelector(state => state.loadingState)
  const dispatch = useDispatch()
  const params = useParams()
  const [open, setOpen] = useState(seasonId ?? "")
  const toggle = id => {
    if (open === id) {
      setOpen()
    } else {
      setOpen(id)
    }
  }

  useEffect(() => {
    if (titleSingle?.seasons?.[0]?.id && !checkNull(open, false)) {
      setOpen(titleSingle?.seasons?.[0]?.id)
    }
  }, [titleSingle])

  const [image, setImage] = useState({
    blob: null,
    src: "",
  })
  const [video, setVideo] = useState()

  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem("authUser"))
    setTokenHeader(data.access_token)
    dispatch(startLoader())
    dispatch(editTitle(params.id))
    console.log("final :", data)
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid titlelisting">
          <Breadcrumbs title="Manage Video" breadcrumbItem="Video" />
          <FullPageLoader loading={loadingState} />
          <Row>
            <Col xl={12} className="w-100" style={{ textAlign: "end" }}>
              <Link
                to={{
                  pathname: `/title/${params.id}/UpdateSeries`,
                  state: {
                    seasonId: null,
                    episodeId: null,
                  },
                }}
                className="btn btn-primary w-md my-3"
              >
                Add Season
              </Link>
            </Col>
          </Row>
          <Row>
            <Col xl={12} className="w-100">
              <Accordion flush open={open} toggle={toggle}>
                {checkArray(titleSingle.seasons) &&
                  titleSingle.seasons.map(season => {
                    return (
                      <AccordionItem key={season.id}>
                        <AccordionHeader targetId={season.id}>
                          {season.name}
                        </AccordionHeader>
                        <AccordionBody accordionId={season.id}>
                          {checkArray(season.episodes) &&
                            season.episodes.map(episode => {
                              return (
                                <Row key={episode._id} className="mb-3">
                                  <Col xl={12} className="w-100">
                                    <div className="mb-2">
                                      <Link
                                        to={{
                                          pathname: `/title/${params.id}/UpdateSeries`,
                                          state: {
                                            seasonId: season.id,
                                            episodeId: episode._id,
                                          },
                                        }}
                                        className="btn btn-primary w-md"
                                      >
                                        Update {episode.title_name}
                                      </Link>
                                    </div>
                                  </Col>
                                  <Col sm={6} xl={6} className="">
                                    <Label htmlFor="formrow-firstname-Input">
                                      Uploaded Thumbnail
                                    </Label>
                                    <div className="w-100">
                                      <img src={episode?.thumbnail} alt="" />
                                    </div>
                                  </Col>
                                  <Col sm={6} xl={6} className="">
                                    <Label htmlFor="formrow-firstname-Input">
                                      Uploaded Video file
                                    </Label>
                                    <div style={{ maxHeight: "400px" }}>
                                      <ReactPlayer controls url={episode?.file} />
                                    </div>
                                  </Col>
                                </Row>
                              )
                            })}
                          <Row>
                            <Col
                              xl={12}
                              className="w-100 mt-5"
                              style={{ textAlign: "center" }}
                            >
                              <Link
                                to={{
                                  pathname: `/title/${params.id}/UpdateSeries`,
                                  state: {
                                    seasonId: season.id,
                                    episodeId: null,
                                  },
                                }}
                                className="btn btn-primary w-md"
                              >
                                Add Episode
                              </Link>
                            </Col>
                          </Row>
                        </AccordionBody>
                      </AccordionItem>
                    )
                  })}
              </Accordion>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Series
