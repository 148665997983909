import {
  GET_CAST_SUCCESS,
  GET_CAST_FAIL,
  ADD_CAST_SUCCESS,
  ADD_CAST_FAIL,
  UPDATE_CAST_IMAGE_FAIL,
  UPDATE_CAST_IMAGE_SUCCESS,
  UPDATE_CAST_IMAGE,
  UPDATE_CAST_SUCCESS,
  UPDATE_CAST_FAIL,
  DELETE_CAST_SUCCESS,
  DELETE_CAST_FAIL,
  EDIT_CAST,
  EDIT_CAST_FAIL,
  EDIT_CAST_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  cast: [],
  error: {},
  castSingle: {},
}

const genre = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CAST_SUCCESS:
      return {
        ...state,
        cast: action.payload.data,
      }

    case GET_CAST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_CAST_SUCCESS:
      return {
        ...state,
        cast: {
          ...state.cast,
          results: [...state.cast.results, action.payload]
        },
      }


    case ADD_CAST_FAIL:
      return {
        ...state,
        error: action.payload,
      }



    case UPDATE_CAST_SUCCESS:
      return {
        ...state,
        cast: {
          ...state.cast,
          results: state.cast.results.map(item => item?.id == action.payload.id ? action.payload : item)
        },
      }

    case UPDATE_CAST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_CAST_SUCCESS:
      return {
        ...state,
        cast: {
          ...state.cast,
          results: [...state.cast.results.filter(item => item['id'] !== action.payload)]
        },
      }

    case DELETE_CAST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_CAST_IMAGE:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_CAST_IMAGE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_CAST_IMAGE_SUCCESS:
      return {
        ...state,
        cast: {
          ...state.cast,
          results: state.cast.results.map(item => item?.id == action.payload.id ? { ...item, image: action.payload.image } : item)
        },
      }

    case EDIT_CAST:
      return {
        ...state,
        castSingle: action.payload
      }

    case EDIT_CAST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case EDIT_CAST_SUCCESS:
      return {
        ...state,
        castSingle: action.payload.data
      }



    default:
      return state
  }
}

export default genre
