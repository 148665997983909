import React, { useEffect, useState } from "react"
import { Col, Container, Row, Label, Button } from "reactstrap"
import * as Yup from "yup"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Formik, Form } from "formik"
import "react-languages-select/scss/react-languages-select.scss"
import { ToastContainer, toast } from "react-toastify"
import { checkArray, checkNull } from "../../../helpers/basicFunctions"
import FullPageLoader from "../../../components/Common/FullPageLoader"
import SelectAsyncPaginate from "../../ManageTitles/TitleAdd/SelectAsyncPaginate"
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { CCard, CCardBody, CCardGroup } from "@coreui/react"
const baseUrl = `${process.env.REACT_APP_ZFLIX_URL}/v1/`;

const EditCategoryPage = props => {
    document.title = "New Release Title"
    const [newRelease, setNewRelease] = useState({
        titles: [],
    })

    useEffect(() => {
        fetchData()
    }, [])

    async function fetchData() {
        try {
            let url = `${baseUrl}cms/new-release`;
            const token = JSON.parse(sessionStorage.getItem("authUser")).access_token
            let option = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            }
            const response = await (await fetch(url, option)).json();

            if (response.status === true) {
                console.log("response", response)
                setNewRelease({ ...newRelease, titles: response?.data?.titles })
            }
        } catch (error) {
            console.log(error)
            return [false];
        }
    }

    const moveTitle = (dragIndex, hoverIndex) => {
        const draggedTitle = newRelease.titles[dragIndex];
        const newTitles = [...newRelease.titles];
        newTitles.splice(dragIndex, 1);
        newTitles.splice(hoverIndex, 0, draggedTitle);
        setNewRelease({ ...newRelease, titles: newTitles });
    };

    const Title = ({ title, index }) => {
        const [{ isDragging }, drag] = useDrag({
            type: 'title',
            item: { type: 'title', index },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        });

        const [, drop] = useDrop({
            accept: 'title',
            hover(item) {
                if (item.index !== index) {
                    moveTitle(item.index, index);
                    item.index = index;
                }
            },
        });

        const opacity = isDragging ? 5 : 1;
        const background = isDragging ? '#FF0000' : '#f0f0f0';
        const color = isDragging ? '#FFFFFF' : '';
        const titleStyle = {
            display: 'inline-block',
            width: '30%',
            padding: '5px',
            margin: '10px',
            borderRadius: '5px',
            boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)',
            opacity,
            cursor: 'pointer',
            background,
            color
        };

        return (
            <div ref={(node) => drag(drop(node))} style={titleStyle}>
                {title.name}
            </div>
        );
    };

    const loadTitleOptions = async (search, loadedOptions, { page }) => {
        try {
            let optUrl = `${process.env.REACT_APP_ZFLIX_URL}/v1/titles?page=${page}`
            if (checkNull(search, false)) {
                optUrl = optUrl + "&search=" + search
            }
            const response = await fetch(optUrl, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("authUser")).access_token
                        }`,
                    "Content-Type": "application/json",
                },
            })
            const res = await response.json()
            let newOptions = [];
            let totalPages = page
            if (res?.data) {
                const data = res.data
                const updatedOptions = checkArray(data.results)
                    ? data.results.map(result => ({
                        value: result.id,
                        name: `${result.title} (${result.year})`,
                    }))
                    : []

                newOptions = [...newOptions, ...updatedOptions]
                totalPages = data.totalPages
            }
            return {
                options: newOptions,
                hasMore: page < totalPages ? true : false,
                additional: {
                    page: page + 1,
                },
            }
        } catch (error) {
            return {
                options: [],
                hasMore: false,
                additional: { page: 1 },
            }
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        try {
            let url = `${baseUrl}cms/new-release`;
            const token = JSON.parse(sessionStorage.getItem("authUser")).access_token
            let option = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    type: "new-release",
                    title: newRelease?.titles
                })
            }
            const response = await (await fetch(url, option)).json();

            if (response.status === true) {
                toast.success(response?.message || "Successfully")
            } else if (response.status === false) {
                toast.error("Something went wrong !!")
            }
        } catch (error) {
            console.log(error)
            return [false];
        }
    }

    if (!newRelease) {
        return <FullPageLoader />
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid titlelisting">
                    <Breadcrumbs title="Title" breadcrumbItem="New Release" />
                    <Row>
                        <Col xl={12}>
                            <CCardGroup style={{ height: '100%', overflowY: 'auto' }}>
                                <CCard style={{ minHeight: "80vh" }}>
                                    <CCardBody >
                                        <Formik >
                                            {({ values, setValues, setFieldValue, errors, touched }) => (
                                                <Form onSubmit={handleSubmit} >
                                                    <Label htmlFor="formrow-firstname-Input" className="my-2">
                                                        Titles
                                                    </Label>
                                                    <SelectAsyncPaginate
                                                        // regionName={region.value}
                                                        loadOptions={loadTitleOptions}
                                                        placeHolder="Select Title"
                                                        isMulti={true}
                                                        value={newRelease.titles}
                                                        onChange={titles => {
                                                            setNewRelease({ ...newRelease, titles })
                                                        }}
                                                        required
                                                        isSearchable={true}
                                                    />
                                                    {errors.titles && touched.titles ? (
                                                        <div className="text-danger input-error">{errors.titles}</div>
                                                    ) : null}

                                                    <Label htmlFor="formrow-firstname-Input" className="my-2">
                                                        New release index
                                                    </Label>
                                                    <DndProvider backend={HTML5Backend}>
                                                        <div>
                                                            {newRelease.titles.map((title, index) => (
                                                                <Title key={title.value} title={title} index={index} />
                                                            ))}
                                                        </div>
                                                    </DndProvider>

                                                    <Row className="mt-2">
                                                        <Col sm={4}>
                                                            <Button className="btn btn-sm " type="submit" color="primary">
                                                                Save Changes
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            )}
                                        </Formik>
                                    </CCardBody>
                                    <ToastContainer />
                                </CCard>
                            </CCardGroup>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}


export default EditCategoryPage;
