import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

const SidebarContent = props => {
  const ref = useRef()
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <Link to="/#">
                <i className="bx bx-grid-alt"></i>
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li>

            <li className="menu-title">{props.t("Manage Content")}</li>
            <li>
              <Link to="/#" className="has-arrow" >
                <i className="bx bx-pie-chart-alt-2"></i>
                <span>Analytics</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/analytics">Overview</Link>
                </li>
                <li>
                  <Link to="/title-log">Log Report</Link>
                </li>
                <li>
                  <Link to="/search-log">Search Report</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/all-categories">
                <i className="bx bx-category-alt"></i>
                <span>{props.t("All Categories")}</span>
              </Link>
            </li>
            <li>
              <Link to="/genre">
                <i className="bx bx-cube"></i>
                <span>{props.t("All Genre")}</span>
              </Link>
            </li>

            <li>
              <Link to="/registered-users">
                <i className="bx bxs-user"></i>
                <span>{props.t("Customers")}</span>
              </Link>
            </li>
            <li>
              <Link to="/#" className="has-arrow" >
                <i className="bx bx-movie" />
                <span>Titles</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/titles">All Titles</Link>
                </li>
                <li>
                  <Link to="/new-release">New Release</Link>
                </li>
                <li>
                  <Link to="/top10/movies">Manage Top Movies</Link>
                </li>
                <li>
                  <Link to="/top10/series">Manage Top Series</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/cast">
                <i className="bx bx-spreadsheet"></i>
                <span>{props.t("All Cast")}</span>
              </Link>
            </li>

            <li>
              <Link to="/transactions">
                <i className="bx bx-wallet"></i>
                <span>{props.t("Transactions")}</span>
              </Link>
            </li>
            <li>
              <Link to="/bucket-details">
                <i className="bx bxs-store"></i>
                <span>{props.t("FilmHub Bucket")}</span>
              </Link>
            </li>

            <li>
              <Link to="/#" className="has-arrow" >
                <i className="bx bx-notification"></i>
                <span>{props.t("Notifications")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/notifications">Create</Link>
                </li>
                <li>
                  <Link to="/notification-list">List</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow" >
                <i className="bx bx-book-content"></i>
                <span>{props.t("Static Content")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/faq">FAQ's</Link>
                </li>
                <li>
                  <Link to="/terms-conditions">Term's & Conditions</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow" >
                <i className="bx bxs-detail" />
                <span>Setting</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/app-setting">App Setting</Link>
                </li>
                <li>
                  <Link to="/new-screen-orderseq">New Screen Order Sequence</Link>
                </li>
                <li>
                  <Link to="/profile">Profile</Link>
                </li>
                <li>
                  <Link to="/logout">Logout</Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
