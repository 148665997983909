import { createSlice } from "@reduxjs/toolkit";
import { getProfile } from "./action";

const AuthSlice = createSlice({
    name: 'AuthSlice',
    initialState: {
        loadingState: true,
        profile_data: {}
    },
    reducers: {
    },
    extraReducers: (builder) => {

        // handle  get Files
        builder.addCase(getProfile.pending, (state) => {
            return { ...state, profile_data: {}, loadingState: false };
        });
        builder.addCase(getProfile.fulfilled, (state, action) => {
            if (action.payload[0])
                return {
                    ...state,
                    profile_data: action.payload[1].data,
                    loadingState: false
                }
            else
                return { ...state, profile_data: {}, loadingState: false }
        });
        builder.addCase(getProfile.rejected, (state) => {
            return { ...state, profile_data: {}, loadingState: false }
        });
    }
})


export default AuthSlice.reducer;