import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import {
  postLogin,
  postJwtLogin,
  postSocialLogin,
} from "../../../helpers/fakebackend_helper";
import { ADMIN_LOGIN_URL } from "../../../helpers/AuthApiUrl";
import { toast } from "react-toastify";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import { setTokenHeader } from "../../../helpers/api_helper";

const fireBaseBackend = getFirebaseBackend();

function* loginUser({ payload: { user: data, history } }) {
  try {
    const min = 1000000000; // Minimum 10-digit number
    const max = 9999999999; // Maximum 10-digit number
    const random =  (Math.floor(Math.random() * (max - min + 1)) + min)+ "utbdbgskdh";

    data.deviceId =  random;
    data.deviceName =  "chrome";
    data.fcmToken =  random
    data.deviceType =  "web";
    const response = yield call(postLogin, data);
    if (response.data.status) {
      toast.success(response.message, {
        position: toast.POSITION.BOTTOM_RIGHT
      });
      const { data } = response.data
      // accessToken = data.tokens.access.token
      const user = {};
      user['access_token'] = data.tokens.access.token;
      user['refresh_token'] = data.tokens.refresh.token;
      user['id'] = data.user.id;
      user['email'] = data.user.email;
      user['name'] = data.user.name;
      user['role'] = data.user.role;
      yield put(loginSuccess(data))
      setTokenHeader(data.tokens.access.token)
      sessionStorage.setItem('authUser', JSON.stringify(user));
      history.push("/dashboard");
    } else {
      console.log(error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_LEFT
      });
      yield put(apiError(error));
    }

    // const response = fetch(ADMIN_LOGIN_URL, {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify(data)
    // })
    //   .then(data => data.json())
    // const user = {};
    // if (response.status) {
    //   user['access_token'] = response.data.tokens.access.token;
    //   user['refresh_token'] = response.data.tokens.refresh.token;
    //   user['email'] = response.data.user.email;
    //   user['name'] = response.data.user.name;
    //   user['role'] = response.data.user.role;
    //   console.log(user);
    //   sessionStorage.setItem('authUser', JSON.stringify(user));
    // }
    // history.push("/dashboard");
  } catch (error) {
    console.log("error : ", error);
    toast.error(error.message, {
      position: toast.POSITION.TOP_LEFT
    });
    yield put(apiError(error));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser");

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout);
      yield put(logoutUserSuccess(response));
    }
    history.push("/login");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend();
      const response = yield call(
        fireBaseBackend.socialLoginUser,
        data,
        type,
      );
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    } else {
      const response = yield call(postSocialLogin, data);
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    }
    history.push("/dashboard");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeLatest(SOCIAL_LOGIN, socialLogin);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
