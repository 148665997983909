import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { AsyncPaginate } from "react-select-async-paginate";

const SelectAsyncPaginate = (props) => {

  const onChange = (option) => {
    if (typeof props.onChange === "function") {
      props.onChange(option);
    }
  };

  return (
    <AsyncPaginate   
      value={props.value || ""}
      getOptionValue={(option) => option.value}
      getOptionLabel={(option) => option.name}
      onChange={onChange}
      additional={{
        page: 1,
      }}
      {...props}
    />
  );
};

SelectAsyncPaginate.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
};

export default SelectAsyncPaginate;