import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
// import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import calendarSaga from "./calendar/saga"
import chatSaga from "./chat/saga"
// import contactsSaga from "./contacts/saga";
import categoriesSaga from "./category/saga";
import LoaderSaga from "./loaderState/saga";
import cast from "./cast/saga";
import genreSaga from "./genre/saga";
import userSaga from "./user/saga";
import planSaga from "./plan/saga";
import TitleSaga from "./title/saga";
import dashboardSaga from "./dashboard/saga";
import dashboardSaasSaga from "./dashboard-saas/saga";
import searchDataSaga from "./searchData/saga";

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(LayoutSaga),
    fork(calendarSaga),
    fork(chatSaga),
    fork(LoaderSaga),
    
    fork(categoriesSaga),
    fork(genreSaga),
    fork(userSaga),
    fork(planSaga),
    fork(TitleSaga),
    fork(cast),
    fork(dashboardSaga),
    fork(dashboardSaasSaga),
    fork(searchDataSaga),
  ])
}
