import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CCard, CCardBody, CCardGroup } from "@coreui/react";
import DataTable from "react-data-table-component";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { useSelector, useDispatch } from "react-redux";
import { getTransactionData } from "../../../store/transaction/toolkit/action";
import FullPageLoader from "../../../components/Common/FullPageLoader";
import { Link } from "react-router-dom";
import defaultAvatar from "../../../assets/images/users/avatar.png"
import { countries } from 'country-data';
import {
  Card,
  CardBody,
  Col,
  DropdownMenu,
  DropdownToggle,
  Form,
  Row,
  Dropdown,
  DropdownItem,
  UncontrolledDropdown,
  Button,
} from "reactstrap";
import axios from "axios";
import { Modal, ModalBody, ModalHeader, ModalTitle } from "react-bootstrap";
const API_URL = `${process.env.REACT_APP_ZFLIX_URL}/v1`

const ManageTransactions = () => {
  const renderObject = (obj) => {
    return (
      <div className="bg" style={{ backgroundColor: "lightblue" }}>
        {Object.entries(obj).map(([key, value]) => (
          <div key={key}>
            <p>
              <b>{key}:</b>{" "}
              {typeof value === "object" ? renderObject(value) : value}
            </p>
          </div>
        ))}
      </div>
    );
  };

  const dispatch = useDispatch();
  const transactionData = useSelector((state) => state.Transaction);
  const [userData, setUserData] = useState();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [isModelOpen, setModelOpen] = useState(false);
  const [statusData, setStatusData] = useState("");
  const [serverExtraInfo, setServerExtraInfo] = useState("");
  const [monthlySubscription, setMonthlySubscription] = useState(0);
  const [yearlySubscription, setYearlySubscription] = useState(0);
  const [trialPeriod, setTrialPeriod] = useState(0);
  const [expiredSubscription, setExpiredSubscription] = useState(0);
  const [showMore, setShowMore] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const handlePageChange = page => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = rowsPerPage => {
    setRowsPerPage(rowsPerPage);
    setCurrentPage(1); // Reset page to 1 when changing rows per page
  };

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  useEffect(() => {
    document.title = "Transaction"
    dispatch(getTransactionData())
  }, [dispatch])

  useEffect(() => {
    const cleanup = () => {
      setMonthlySubscription(0)
      setYearlySubscription(0)
      setTrialPeriod(0)
      setExpiredSubscription(0)
    }

    if (transactionData?.transactionData) {
      setUserData(transactionData?.transactionData)
      const data = transactionData.transactionData
      let monthlySubscription = 0,
        yearlySubscription = 0,
        trialPeriod = 0,
        expiredSubscription = 0

      data.forEach(element => {
        const datePurchased = new Date(element.datePurchased)
        const validTill = new Date(element.validTill)
        const differenceInMs = validTill - datePurchased
        const differenceInDays = differenceInMs / (1000 * 60 * 60 * 24)

        if (element.status === "inactive") {
          expiredSubscription++
        } else if (differenceInDays <= 3) {
          trialPeriod++
        } else {
          if (element.productId === "com.zflix.app.1month") {
            monthlySubscription++
          } else {
            yearlySubscription++
          }
        }
      })

      setMonthlySubscription(monthlySubscription)
      setYearlySubscription(yearlySubscription)
      setTrialPeriod(trialPeriod)
      setExpiredSubscription(expiredSubscription)
    }

    return cleanup
  }, [transactionData])

  const toggleDropdown = () => {
    setDropdownOpen(prevState => !prevState)
  }

  const handleFilterChange = value => {
    setFilterValue(value)
    console.log(value);
    
    let filterData
    if (value === "Active") {
      filterData =
        transactionData &&
        transactionData?.transactionData.filter(v => {
          return v.status === "active"
        })
        console.log("filterData",filterData);
        
    } else if (value === "Inactive") {
      filterData =
        transactionData &&
        transactionData?.transactionData.filter(v => {
          return v.status === "inactive"
        })
    } else {
      filterData = transactionData && transactionData?.transactionData
    }

    setUserData(filterData)
  }

  const handleServerStatus = (transactionId, type) => {
    const token = JSON.parse(sessionStorage.getItem("authUser"))
    const headers = {
      Authorization: `Bearer ${token.access_token}`,
    }

    axios
      .get(
        `${API_URL}/users/server/subscription-details?type=${type}&transactionId=${transactionId}`,
        { headers: headers }
      )
      .then((response) => {
        setStatusData(response.data?.data?.responseData);
        setServerExtraInfo(response.data?.data?.serverExtraDetails);
      })
      .catch((error) => {
        console.log(error);
        setStatusData("");
        setServerExtraInfo("");
      });
    setModelOpen(true);
  };

  // const formatCountdown = milliseconds => {
  //   const seconds = Math.floor(milliseconds / 1000);
  //   const days = Math.floor(seconds / (24 * 60 * 60));
  //   const hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60));
  //   const minutes = Math.floor((seconds % (60 * 60)) / 60);
  //   const remainingSeconds = seconds % 60;

  //   const formattedDays = days >= 0 ? `${days}` : `00`;
  //   const formattedHours = hours >= 0 ? `${hours}` : `00`;
  //   const formattedMinutes = minutes >= 0 ? `${minutes}` : `00`;
  //   const formattedSeconds =
  //     remainingSeconds >= 0 ? `${remainingSeconds}` : `00`;

  //   return `${formattedDays}d ${formattedHours}h ${formattedMinutes}m ${formattedSeconds}s`;
  // };

  const formatCountdown = useCallback((milliseconds) => {
    const seconds = Math.floor(milliseconds / 1000)
    const days = Math.floor(seconds / (24 * 60 * 60))
    const hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60))
    const minutes = Math.floor((seconds % (60 * 60)) / 60)
    const remainingSeconds = seconds % 60

    const formattedDays = days >= 0 ? `${days}` : `00`;
    const formattedHours = hours >= 0 ? `${hours}` : `00`;
    const formattedMinutes = minutes >= 0 ? `${minutes}` : `00`;
    const formattedSeconds =
      remainingSeconds >= 0 ? `${remainingSeconds}` : `00`;

    return `${formattedDays}d ${formattedHours}h ${formattedMinutes}m ${formattedSeconds}s`;
  }, []);

  const columns = useMemo(() => {
    return [
      {
        name: "Sr. no",
        selector: (row, index) => {
          return index + 1;
        },
        sortable: true,
        maxWidth: "10px",
      },
      {
        name: "Profile Pic",
        selector: (row) => {
          return (
            <div className="categoryDetail-img ">
              <Link
                to={row.userId ? "user/" + row.userId._id + "/edit" : "#"}
                title="view user"
              >
                <img
                  src={
                    row.userId.image !== "" ? row.userId.image : defaultAvatar
                  }
                  style={{ height: "60px", width: "60px", borderRadius: "50%" }}
                />
              </Link>
            </div>
          );
        },
        sortable: true,
        maxWidth: "10px",
      },
      {
        name: "Name",
        selector: (row) => {
          return (
            <Link
              to={row.userId ? "user/" + row.userId._id + "/edit" : "#"}
              title="view user"
            >
              <span style={{ color: "#000000de" }}>
                {row.userId.name !== "" ? row.userId?.name : "N/A"}
              </span>
            </Link>
          );
        },
        sortable: true,
        sortFunction: (a, b) => {
          const dateA = a.userId.name;
          const dateB = b.userId.name;

          if (dateA < dateB) {
            return -1;
          }
          if (dateA > dateB) {
            return 1;
          }
        },
        maxWidth: "300px",
      },
      {
        name: "Email",
        selector: (row) => {
          return (
            <Link
              to={row.userId ? "user/" + row.userId._id + "/edit" : "#"}
              title="view user"
            >
              <span style={{ color: "#000000de", textDecoration: "none" }}>
                {row.userId.email !== "" ? row.userId?.email : "N/A"}
              </span>
            </Link>
          );
        },
        sortable: true,
        sortFunction: (a, b) => {
          const dateA = a.userId.email;
          const dateB = b.userId.email;

          if (dateA < dateB) {
            return -1;
          }
          if (dateA > dateB) {
            return 1;
          }
        },
        width: "250px",
        maxWidth: "250px",
      },
      {
        name: "Country",
        selector: (row) => {
          return countries[row.userId.country]?.name || row.userId.country;
        },
        sortable: true,
        maxWidth: "150px",
      },
      {
        name: "Device Type",
        selector: (row) => {
          return row.device_type;
        },
        sortable: true,
        maxWidth: "150px", // Adjust width based on device type lengths
      },
      {
        name: "Product Plan",
        selector: (row) => {
          // Using regular expressions to determine the subscription type
          switch (true) {
            case /1month/.test(row.productId):
              return "Monthly";
            case /1year/.test(row.productId):
              return "Yearly";
            case /yearly/.test(row.productId):
              return "Yearly";
            case /month/.test(row.productId):
              return "Monthly";
            default:
              return "Unknown"; // Optional: handle cases that don't match any known patterns
          }
        },
        sortable: true,
        maxWidth: "150px",
      },
      {
        name: "Subscription Start",
        selector: (row) => {
          const timestamp = row?.datePurchased;
          if (timestamp) {
            const date = new Date(timestamp);
            const formattedDate = date.toLocaleDateString();
            const formattedTime = date.toLocaleTimeString();
            return formattedDate + " " + formattedTime;
          }
          return null;
        },
        sortable: true,
        sortFunction: (a, b) => {
          return -1;
        },
        width: "200px",
        maxWidth: "200px",
      },
      {
        name: "Subscription End",
        selector: (row) => {
          const timestamp = row?.validTill;
          if (timestamp) {
            const date = new Date(timestamp);
            const formattedDate = date.toLocaleDateString();
            const formattedTime = date.toLocaleTimeString();
            return formattedDate + " " + formattedTime;
          }
          return null;
        },
        sortable: true,
        sortFunction: (a, b) => {
          const dateA = new Date(a.validTill);
          const dateB = new Date(b.validTill);

          if (dateA < dateB) {
            return -1;
          }
          if (dateA > dateB) {
            return 1;
          }
        },
        width: "200px",
        maxWidth: "200px",
      },
      {
        name: "Countdown to expiry",
        selector: (row) => {
          const countdown = row?.validTill
            ? formatCountdown(row.validTill - Date.now())
            : "";
          return countdown;
        },
        sortable: true,
        maxWidth: "700px",
        cell: (row) => {
          const [countdown, setCountdown] = useState(
            formatCountdown(row.validTill - Date.now())
          );
          useEffect(() => {
            const intervalId = setInterval(() => {
              setCountdown(formatCountdown(row.validTill - Date.now()));
            }, 1000);
            return () => clearInterval(intervalId);
          }, [row.validTill]);
          return countdown;
        },
      },
      {
        name: "Trail",
        selector: (row, index) => {
          return (
            <div
              key={index}
              className={
                row?.isTrial === true
                  ? "font-size-12 text-uppercase badge badge-soft-success"
                  : "font-size-12 text-uppercase badge badge-soft-danger"
              }
            >
              {row?.isTrial === true ? "Active" : "Inactive"}
            </div>
          );
        },
        sortable: true,
        sortFunction: (a, b) => {
          const dateA = a.isTrial;
          const dateB = b.isTrial;

          if (dateA < dateB) {
            return -1;
          }
          if (dateA > dateB) {
            return 1;
          }
        },
        maxWidth: "100px",
      },
      {
        name: "Status",
        selector: (row, index) => {
          return (
            <div
              key={index}
              // className={
              //   row.status === "active"
              //     ? "font-size-12 text-uppercase badge badge-soft-success"
              //     : "font-size-12 text-uppercase badge badge-soft-danger"
              // }
              className={
                row.isRefund === 1 
                  ? "font-size-12 text-uppercase badge badge-soft-danger" 
                  : row.status === "active" 
                    ? "font-size-12 text-uppercase badge badge-soft-success"
                    : "font-size-12 text-uppercase badge badge-soft-danger"
              }
            >
              {/* {row.status} */}
              {row?.isRefund === 1 ? "Refunded" : row?.status}
            </div>
          );
        },
        sortable: true,
        sortFunction: (a, b) => {
          const dateA = a.status;
          const dateB = b.status;

          if (dateA < dateB) {
            return -1;
          }
          if (dateA > dateB) {
            return 1;
          }
        },
        maxWidth: "100px",
      },
      {
        name: "Action",
        selector: (row, index) => {
          return (
            <button
              key={index}
              onClick={() =>
                handleServerStatus(row.originalTransactionId, row.device_type)
              }
              className="form-control btn btn-sm btn-info"
            >
              Get Status
            </button>
          );
        },
        sortable: true,
        maxWidth: "300px",
      },
    ];
  }, [formatCountdown]);
  useEffect(() => {
    dispatch(getTransactionData());
  }, [dispatch]);

  useEffect(() => {
    const data = transactionData?.transactionData || [];
    setUserData(data);
  }, [transactionData]);

  const filteredData =userData && userData.filter(user =>
    user.status.toLowerCase().includes(filterValue.toLowerCase()) 
  );

  const paginatedData =filteredData && filteredData.length>0 && filteredData.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

  function formatDate(dateString) {
    if (!dateString) return "N/A"
    const date = new Date(dateString * 1000)
    return date.toLocaleString()
  }

  if (!userData) {
    return <FullPageLoader loading={"loading"} />;
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Transactions Details"
            breadcrumbItem="Transactions"
          />
          <div className="row">
            <div className="col-lg-3">
              <div className="blog-stats-wid card">
                <div className="card-body">
                  <div className="d-flex flex-wrap">
                    <div className="me-3">
                      <p className="text-muted mb-2">
                        Monthly Active Subscription
                      </p>
                      <h5 className="mb-0">{monthlySubscription}</h5>
                    </div>
                    <div className="avatar-sm ms-auto">
                      <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                        <i className="bx bx-calendar"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="blog-stats-wid card">
                <div className="card-body">
                  <div className="d-flex flex-wrap">
                    <div className="me-3">
                      <p className="text-muted mb-2">
                        Yearly Active Subscription
                      </p>
                      <h5 className="mb-0">{yearlySubscription}</h5>
                    </div>
                    <div className="avatar-sm ms-auto">
                      <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                        <i className="bx bxs-book-bookmark"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="blog-stats-wid card">
                <div className="card-body">
                  <div className="d-flex flex-wrap">
                    <div className="me-3">
                      <p className="text-muted mb-2">Total In Trial Period</p>
                      <h5 className="mb-0">{trialPeriod}</h5>
                    </div>
                    <div className="avatar-sm ms-auto">
                      <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                        <i className="bx bx-calendar-check"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="blog-stats-wid card">
                <div className="card-body">
                  <div className="d-flex flex-wrap">
                    <div className="me-3">
                      <p className="text-muted mb-2">
                        Total Expired Subscription
                      </p>
                      <h5 className="mb-0">{expiredSubscription}</h5>
                    </div>
                    <div className="avatar-sm ms-auto">
                      <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                        <i className="bx bx-calendar-x"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Row>
        <Col sm="12">
          <Card>
            <CardBody>
            <div className="d-flex align-items-center justify-content-end">
                      <Dropdown
                        isOpen={dropdownOpen}
                        toggle={toggleDropdown}
                        style={{ marginLeft: "5px", marginBottom: "7px" }}
                      >
                        <DropdownToggle caret>
                          {filterValue || "Filter"}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={() => handleFilterChange("Active")}
                          >
                            Active
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => handleFilterChange("Inactive")}
                          >
                            Inactive
                          </DropdownItem>
                          <DropdownItem onClick={() => handleFilterChange("")}>
                            All
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                    
              <DataTable
                columns={columns}
                data={paginatedData}
                pagination
                paginationServer
                paginationTotalRows={filteredData.length}
                onChangeRowsPerPage={handleRowsPerPageChange}
                onChangePage={handlePageChange}
                paginationRowsPerPageOptions={[50, 100, 150]}
                progressPending={transactionData.loading}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal
            show={isModelOpen}
            onHide={() => {
              setStatusData("")
              setModelOpen(false)
            }
            }
            role="dialog"
            autoFocus={true}
            centered={true}>
            <div className="modal-content">
              <ModalHeader>
                <h5 className="font-size-14">Subscription Details From Server</h5>
                <Button variant="link" className="btn-close" onClick={() => {
                  setStatusData("")
                  setModelOpen(false)
                }}>
                </Button>
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col xl={12}>
                    <CCardGroup>
                      <CCard>
                        <CCardBody>
                          <ul>
                            <li className="mb-2">
                              <b>Plan Name :</b> {statusData?.plan_name}
                            </li>
                            <li className="mb-2">
                              <b>Subscription Date :</b>{" "}
                              {formatDate(statusData?.start_date)}
                            </li>
                            <li className="mb-2">
                              <b>Current Start Date :</b>{" "}
                              {formatDate(statusData?.start_date)}
                            </li>
                            <li className="mb-2">
                              <b>Current End Date :</b>{" "}
                              {formatDate(statusData?.current_period_end)}
                            </li>
                            <li className="mb-2">
                              <b>Trial Start Date :</b>{" "}
                              {formatDate(statusData?.trial_start)}
                            </li>
                            <li className="mb-2">
                              <b>Trial End Date :</b>{" "}
                              {formatDate(statusData?.trial_end)}
                            </li>
                            <li className="mb-2">
                              <b>Status :</b>{" "}
                              {statusData?.status?.toUpperCase()}
                            </li>
                          </ul>

                          {/* <div className="d-flex justify-content-end">
                            <button
                              className="btn btn-outline-secondary"
                              onClick={toggleShowMore}
                              style={{
                                outline: "none",
                                backgroundColor: "white",
                                color: "gray",
                                border: "none",
                              }}
                            >
                              {showMore && serverExtraInfo
                                ? "View Less"
                                : "View More"}
                            </button>
                          </div> */}
                          
                        </CCardBody>
                      </CCard>
                    </CCardGroup>
                  </Col>
                </Row>
              </ModalBody>
            </div>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ManageTransactions;