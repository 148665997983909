import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_SEARCHDATA } from "./actionTypes"

import {
  getSearchDataSuccess,
  getSearchDataFail,
} from "./actions"

//Include Both Helper File with needed methods
import { getSearchData } from "../../helpers/fakebackend_helper"
import { stopLoader } from "../loaderState/actions"

function* fetchSearchData({ payload: page}) {
  try {

    const response = yield call(getSearchData,page.page)
    yield put(getSearchDataSuccess(response))
    yield put(stopLoader())
  } catch (error) {
    yield put(getSearchDataFail(error))
  }
}

function* searchDataSaga() {
  yield takeEvery(GET_SEARCHDATA, fetchSearchData)
}

export default searchDataSaga;
