import { combineReducers } from "redux"
import Layout from "./layout/reducer"
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"
import calendar from "./calendar/reducer"
import chat from "./chat/reducer"
import category from "./category/reducer"
import cast from "./cast/reducer"
import genre from "./genre/reducer"
import user from "./user/reducer"
import plan from "./plan/reducer"
import title from "./title/reducer"
import loadingState from "./loaderState/reducer"
import Dashboard from "./dashboard/reducer";
import DashboardSaas from "./dashboard-saas/reducer";
import DashboardData from "./dashboard/toolkit/slice";
import Transaction from "./transaction/toolkit/slice";
import BucketList from "./bucket/slice";
import TitleSlice from "./title/toolkit/slice";
import UserSlice from "./user/toolkit/slice";
import faq from "./faq/slice";
import Auth from "./myAuth/toolkit/slice";
import searchData from "./searchData/reducer"

const rootReducer = combineReducers({
  cast,
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  calendar,
  chat,
  title,
  category,
  genre,
  loadingState,
  user,
  plan,
  Dashboard,
  DashboardSaas,
  DashboardData,
  Transaction,
  BucketList,
  TitleSlice,
  faq,
  Auth,
  UserSlice,
  searchData,
})

export default rootReducer
