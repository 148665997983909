import { createSlice } from "@reduxjs/toolkit";
import { getTransactionData } from "./action";

const TransactionSlice = createSlice({
    name: 'TransactionSlice',
    initialState: {
        transactionData:[],
    },
    reducers: {

    },
    extraReducers: (builder) => {

        // handle  
        builder.addCase(getTransactionData.pending, (state) => {
            return { ...state, transactionData: [],};
        });
        builder.addCase(getTransactionData.fulfilled, (state, action) => {
            if (action.payload[0])
                return {
                    ...state,
                    transactionData: action.payload[1].data,
                }
            else
                return { ...state, transactionData: [], errors: action.payload[1] }
        });
        builder.addCase(getTransactionData.rejected, (state) => {
            return { ...state, transactionData: [] }
        });

    }
})

export default TransactionSlice.reducer;