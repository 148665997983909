import React, { useEffect, useMemo, useState } from "react"
import { cilPencil, cilTrash } from "@coreui/icons"
import Image from "react-bootstrap/Image"
import "./Cast.css"

import { CCard, CCardBody, CCardGroup } from "@coreui/react"
import DataTable from "react-data-table-component"
import { Row, Col, FormGroup, Form } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import CIcon from "@coreui/icons-react"
import { useHistory, Link } from "react-router-dom"
import { toastConfirm } from "../../../common/toast"
import { deleteCast, getCast } from "../../../store/actions"
import { useDispatch, useSelector } from "react-redux"
import { setTokenHeader } from "../../../helpers/api_helper"
import NoImgFound from "../../../assets/No-image-found.jpg"
import { startLoader } from "../../../store/loaderState/actions"
import FullPageLoader from "../../../components/Common/FullPageLoader"
import axios from "axios"
import { debounce } from "lodash"

const Cast = () => {
  document.title = "Cast"

  const { loadingState } = useSelector(state => state.loadingState)
  const [page, setPage] = useState(1)
  const dispatch = useDispatch()
  const history = useHistory()
  const [castData, setCastData] = useState([])
  const [inputValue, setInputValue] = useState("");
  const { cast } = useSelector(state => state.cast)
  const deleteCastWithId = async id => {
    const response = await (
      await toastConfirm("Are you sure you want to delete this?")
    ).fire()
    if (response.isConfirmed) {
      dispatch(deleteCast(id, history))
    }
  }
  useEffect(() => {
    setCastData(cast)
  }, [cast])

  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem("authUser"))
    setTokenHeader(data.access_token)
    dispatch(startLoader())
    dispatch(getCast())
  }, [])

  useEffect(() => {
    dispatch(startLoader())
    dispatch(getCast(page))
  }, [page])

  function handleSearch(event) {
    const debouncedSearch = debounce(handleDebouncedSearch, 300);
    let cancelTokenSource;

    const inputValue = event.target.value.toLowerCase();
    setInputValue(inputValue);

    if (cancelTokenSource) {
      cancelTokenSource.cancel('Operation canceled due to new request.');
    }
    cancelTokenSource = axios.CancelToken.source();

    debouncedSearch(inputValue, cancelTokenSource.token);
  }

  async function handleDebouncedSearch(inputValue, cancelToken) {
    if (inputValue.length > 0) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ZFLIX_URL}/v1/casts?search=${inputValue}`,
          {
            headers: {
              Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("authUser")).access_token}`,
              'Content-Type': 'application/json',
            },
            cancelToken,
          }
        );

        const res = response.data;
        setCastData(res.data);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled:', error.message);
        } else {
          console.error('Error fetching data:', error);
        }
      }
    } else {
      setCastData(cast);
    }
  }


  let rowCounter = 1
  const columns = useMemo(() => [
    {
      name: "Sr. no",
      selector: row => rowCounter++,
      sortable: true,
      maxWidth: "80px",
    },
    {
      name: "Image",
      selector: (row, i) => (
        <Image key={i} fluid={true} src={row.image ? row.image : NoImgFound} />
      ),
      sortable: true,
      maxWidth: "100px",
    },
    {
      name: "Actor Name",
      selector: row => row.name,
      sortable: true,
      maxWidth: "300px",
    },
    {
      name: "In Title Name",
      selector: row => row.title_name,
      sortable: true,
      maxWidth: "200px",
    },

    {
      name: "Movies/Series Name",
      selector: row => row?.title,
      sortable: true,
      maxWidth: "400px",
    },

    {
      name: "Actions",
      maxWidth: "100px",
      selector: (row, i) => (
        <>
          <Link to={`cast/${row.id}/edit`} key={i}>
            <CIcon key={i + i + row.id}
              icon={cilPencil}
              className="text-warning hand me-2"
              onClick={() => {
                // toggleViewModal()
                // setEdit(row);
              }}
            />
          </Link>
          <CIcon key={i + i + i + row.id}
            icon={cilTrash}
            className="text-danger hand"
            onClick={() => {
              deleteCastWithId(row.id)
            }}
          />
        </>
      ),
    },
  ])

  if (!castData?.results) {
    return <FullPageLoader />
  }

  return (
    <React.Fragment>
      <div className="page-content cast ">
        <div className="container-fluid">
          <Breadcrumbs title="All Cast" breadcrumbItem="Manage Cast" />

          <Row>
            <Col xl={12}>
              <div className="d-flex justify-content-between">
                <div className="mb-2">
                  <Link to={"/cast/add"} className="btn btn-primary w-md">
                    Add +
                  </Link>
                </div>
                <FormGroup>
                  <Form className="mt-4 mt-sm-0 float-sm-end d-flex align-items-center">
                    <div className="search-box mb-2 me-2">
                      <div className="position-relative">
                        <input
                          type="text"
                          value={inputValue}
                          className="form-control bg-light border-light rounded"
                          placeholder="Search..."
                          onChange={handleSearch}
                        />
                        <i className="bx bx-search-alt search-icon"></i>
                      </div>
                    </div>
                  </Form>
                </FormGroup>
              </div>
              <CCardGroup>
                <CCard>

                  <CCardBody>
                    <DataTable
                      striped
                      columns={columns}
                      data={castData?.results}
                      highlightOnHover
                      pagination
                      paginationServer
                      paginationTotalRows={castData?.totalResults}
                      paginationPerPage={castData?.limit}
                      paginationComponentOptions={{
                        noRowsPerPage: true,
                      }}
                      onChangePage={(page, totalRows) => {
                        setPage(page)
                      }}
                    />
                  </CCardBody>
                </CCard>
              </CCardGroup>
              <FullPageLoader loading={loadingState} />
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Cast
