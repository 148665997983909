import React, { useEffect, useRef, useState } from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import axios from "axios"
import './appSetting.css'
import {
    Button,
    Card,
    Col,
    Row,
} from "reactstrap";
import { toast } from "react-toastify";
import ReactDatePicker from "react-datepicker";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { countries } from 'country-data';
import { Draggable } from "react-drag-reorder";
import sc1 from "../../../assets/images/sc-1.png"
import sc2 from "../../../assets/images/sc-2.png"
import sc3 from "../../../assets/images/sc-3.png"
import sc4 from "../../../assets/images/sc-4.png"
import sc5 from "../../../assets/images/sc-5.png"
import { get, post } from "../../../helpers/api_helper";
const initialWords = [{ name: "screen-1", image: sc1, appName: 'sc-1' }, { name: "screen-2", image: sc2, appName: 'sc-2' }, { name: "screen-3", image: sc3, appName: 'sc-3' }, { name: "screen-4", image: sc4, appName: 'sc-4' }, { name: "screen-5", image: sc5, appName: 'sc-5' }]

const AppSetting = () => {
    const [sendOption, setSendOption] = useState('');
    const history = useHistory()
    const API_URL = `${process.env.REACT_APP_ZFLIX_URL}/v1`
    const [startDate, setStartDate] = useState();
    const exploreRef = useRef(null)
    const [isExplore, setIsExplore] = useState(false);

    const [formData, setFormData] = useState({
        title: "",
        message: "",
        userOption: "",
        sendOption: "",
        time: [],
        utcTime: [],
        days: [],
        timeZoneCountry: ""
    });
    const [words, setWords] = useState([])






    function getTime(date) {
        const frequencyDate = new Date(date);
        const hour = frequencyDate.getHours();
        const minutes = frequencyDate.getMinutes();
        const formattedHour = hour.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const time = `${formattedHour}:${formattedMinutes}`;

        const countryCode = countries.all.find((c) => c.name === formData.timeZoneCountry);
        const timeZones = moment.tz.zonesForCountry(countryCode.alpha2);
        const localTime = moment.tz({ formattedHour, formattedMinutes }, timeZones[0]);
        const utcTime = localTime.clone().utc();
        console.log("time", time)

        return {
            "time": time,
            "utcTime": utcTime.format('HH:mm')
        };
    }


    useEffect(() => {

        try {

            const fetchExplore = async () => {
                let url = `${API_URL}/auth/explore-toggle`;
                const token = JSON.parse(sessionStorage.getItem("authUser")).access_token
                let option = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                }
                const response = await (await fetch(url, option)).json();

                if (response.status === true) {
                    setIsExplore(response?.data?.isExploreShow)
                    if (exploreRef.current) {

                        exploreRef.current.checked = response?.data?.isExploreShow
                    }
                    console.log(response)

                } else if (response.status === false) {
                    console.log(response)

                }


            }
            fetchExplore()

        } catch (error) {
            console.log(error)
            return [false];
        }


    }, [])



    useEffect(() => {

        try {

            const fetchScreenOrder = async () => {
                let url = `${API_URL}/auth/screen-onboarding-seq`;

                const response = await get(url)

                if (response.status === true) {
                    let screenOrder = response.data.screenOrder

                    let tempArr = []
                    for (const item of screenOrder) {
                        let findObjInWords = initialWords?.find((fi) => fi.appName == item)
                        tempArr.push(findObjInWords)
                    }
                    console.log(tempArr)
                    setWords([...tempArr])
                    if (tempArr?.length == 0) {
                        setWords([...initialWords])
                    }
                } else if (response.status === false) {
                    console.log(response)

                }


            }
            fetchScreenOrder()

        } catch (error) {
            console.log(error)
            return [false];
        }


    }, [])

    const handleSync = async () => {
        toast.success('Dashboard Data Syncing In Progress')

        const res = await post(`${API_URL}/users/sync-cached-dashboard`)
        if (res.data.status) {
            toast.success('Dashboard Data Synced')

        }
    }





    const getChangedPos = (currentPos, newPos) => {
        let tempArr = [...words]
        if (currentPos < newPos) {

            let oldItem = tempArr[currentPos]
            for (let i = currentPos; i < newPos; i++) {
                tempArr[i] = tempArr[i + 1]
            }
            tempArr[newPos] = oldItem
            setWords([...tempArr])
            console.log(currentPos, newPos, tempArr)
        } else {
            let oldItem = tempArr[currentPos]
            for (let i = currentPos; i > newPos; i--) {
                tempArr[i] = tempArr[i - 1]
            }
            tempArr[newPos] = oldItem
            setWords([...tempArr])
            console.log(currentPos, newPos, tempArr)

        }



    }


    const handleSubmit = async () => {
        let screenOrder = words?.map((item) => item.appName)
        let body = {
            screenOrder,
            isExplore: exploreRef.current.checked
        }
        let url = `${API_URL}/auth/change-screen-reorder`;
        const response = await post(url, body)
        console.log(response)
        if (response.status) {
            toast.success('Screen Orders Changed')
        }

    }


    console.log(words)

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Setting" breadcrumbItem="App Setting" />

                    <Row>
                        <div className="dropdown d-none d-lg-inline-block ms-1 mt-4">
                            <h5>Seach Toggle</h5>
                            <span>Use this setting to show or hide the search and explore sections in the mobile app</span>
                            <div className="form-check form-switch p-4">
                                <input className="form-check-input explore_checkbox w-45" type="checkbox" role="switch" id="flexSwitchCheckChecked" ref={exploreRef} onClick={async (e) => {
                                    let url = `${API_URL}/auth/change-explore-toggle`;
                                    await post(url, { isExplore: e.target.checked, screenOrder: words?.map((item) => item.appName) })
                                }} />
                                <label className="form-check-label mt-1" style={{ marginLeft: 20 }} htmlFor="flexSwitchCheckChecked"><b>To Show Or Hide Explore Section On Mobile</b></label>
                            </div>
                        </div>


                    </Row>

                    <Row>
                        <h5>Dashboard movie sync</h5>
                        <span>You can use the sync now button to sync the movies for the app and website dashboard screens</span>
                        <Col className="mt-4">

                            <button className="btn btn-primary w-md" onClick={handleSync}>Sync Now</button>
                        </Col>



                    </Row>





                </div>

                <div className="container-fluid mt-4">

                    <h5 className="text-setting">
                        REORDER ONBOARDING SCREENS

                    </h5>
                    <span>You can move these screens to set the order in the mobile app for the onboarding screens.</span>
                    <Row className="mt-5">
                        {words?.length > 0 && <Draggable onPosChange={getChangedPos}>

                            {words?.length > 0 && words?.map((word, idx) => {
                                return (
                                    <Col key={idx}  >

                                        <img src={word?.image} width={200} style={{ objectFit: 'fill' }} />
                                        <div className="p-1 mx-3 fw-bolder">
                                            {word?.name}
                                        </div>
                                    </Col>
                                );
                            })}

                        </Draggable>
                        }
                        <div className="text-center mt-5">
                            <button className="btn btn-primary w-md" onClick={handleSubmit}>Submit</button>
                        </div>
                    </Row>
                </div>



            </div>
        </React.Fragment>
    );
};

export default AppSetting;
