import React, { useEffect, useRef, useState } from "react"
import ReactPlayer from "react-player"
import { Row, Col, Label, Button } from "reactstrap"
import { useHistory, Link } from "react-router-dom"

import Breadcrumbs from "../../../components/Common/Breadcrumb"
import FullPageLoader from "../../../components/Common/FullPageLoader"
import { useDispatch, useSelector } from "react-redux"
import { setTokenHeader } from "../../../helpers/api_helper"
import { editTitle } from "../../../store/actions"
import { startLoader } from "../../../store/loaderState/actions"
import { useParams } from "react-router-dom"
import { updateMovieDuration } from "../../../store/title/toolkit/action"
import { toast } from "react-toastify"

const Movies = () => {
  document.title = "Manage Movie"
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()

  const [image, setImage] = useState({
    blob: null,
    src: "",
  })
  const [video, setVideo] = useState()

  const { titleSingle } = useSelector(state => state.title)
  const { loadingState } = useSelector(state => state.loadingState)
  const [videoDurations, setVideoDurations] = useState(null);

  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem("authUser"))
    setTokenHeader(data.access_token)
    dispatch(startLoader())
    dispatch(editTitle(params.id))
  }, [])


  const handleUpdateDuration = ( id) => {
    const currentVideoDuration = videoDurations;

    dispatch(updateMovieDuration({ id: id, duration: currentVideoDuration.toString() })).then((v) => {
      toast.success("Movie Duration Update Successfully")
    }).catch((er) => {
      toast.error("Something went wrong!!")
    })

    console.log(`Updating duration  ${currentVideoDuration} and id ${id}`);

  };

  useEffect(() => {
    if (titleSingle?.id == params.id && !titleSingle?.movie?.id) {
      history.push(`/title/${params.id}/UpdateMovie`)
    }
  }, [titleSingle])

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid titlelisting">
          <Breadcrumbs title="Manage Video" breadcrumbItem="Video" />
          <FullPageLoader loading={loadingState} />

          <Row>
            <Col xl={12} className="w-100">
              <div className="mb-2">
                <Link
                  to={`/title/${params.id}/UpdateMovie`}
                  className="btn btn-primary w-md"
                >
                  Update Movie
                </Link>
              </div>
            </Col>
            <Col xl={12} className="w-100 my-3">
              <div className="d-flex justify-content-end">
                {videoDurations !== null && (
                  <Button
                    className="mt-2 btn-sm btn-info"
                    onClick={() => handleUpdateDuration(titleSingle?.movie?.id)}
                  >
                    Update Duration
                  </Button>
                )}

              </div>
            </Col>
            <Col sm={6} xl={6} className="">
              <Label htmlFor="formrow-firstname-Input">
                Uploaded Thumbnail
              </Label>
              <div className="w-100">
                <img src={titleSingle?.movie?.thumbnail} alt="" />
              </div>
            </Col>
            <Col sm={6} xl={6} className="">
              <Label htmlFor="formrow-firstname-Input">
                Uploaded Video file
              </Label>
              <div style={{ maxHeight: "400px" }}>
                <ReactPlayer controls={true} url={titleSingle?.movie?.file}
                  onDuration={(duration) => {
                    setVideoDurations(duration);
                  }} />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Movies
