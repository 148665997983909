import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Container, Row, CardBody, Label, } from "reactstrap"
import "./titleAdd.css"
import mycountriesdata from "./../TitleEdit/countries.json"
import * as Yup from "yup"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Dropzone from "react-dropzone"
import { Field, Formik, Form } from "formik"
import { fireToast } from "../../../common/toast"
import { setTokenHeader } from "../../../helpers/api_helper"
import { addNewTitle, getCategories } from "../../../store/actions"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import cloud from "../../../assets/flix-images/cloud-file-download.svg"
import SelectAsyncPaginate, { loadOptions } from "./SelectAsyncPaginate"
import { MuiChipsInput } from "mui-chips-input"
import ReactLanguageSelect from "react-languages-select"
import { CountryDropdown } from "react-country-region-selector"
import { ToastContainer, toast } from "react-toastify"
import { checkArray, checkNull } from "../../../helpers/basicFunctions"
import "react-languages-select/scss/react-languages-select.scss"


const TitleAdd = props => {
  //meta title
  const [chips, setChips] = useState([])
  const uploadRef = useRef(null)
  const posterRef = useRef(null)
  const languageSelectRef = useRef(null)
  const dispatch = useDispatch()
  const history = useHistory()

  document.title = "Add Title"
  const [data, setData] = useState({
    title: "",
    type: "",
    genres: [],
    tags: [],
    year: "",
    description: "",
    casts: [],
    tagline: "",
    running_time: "",
    country_of_origin: "",
    language: "",
    copyright: "",
    studio: "",
    sku: "",
    imdb: "",
    included_countries: [],
    excluded_countries: [],
    isActive: "1",
    categories: [],
    isAgeRestriction: "",
    addToDashboard: false,
    isDefaultSearchInclude: false
  })
  const [image, setImage] = useState({
    blob: null,
    src: "",
  })
  const [poster, setPoster] = useState({
    blob: null,
    src: "",
  })

  const [selectItems, onChange] = useState([])
  const AddTitleValidation = Yup.object().shape({
    title: Yup.string()
      .min(4, "Too short!!")
      .max(1000, "Too Long!!")
      .required("This feild is required"),
    type: Yup.string().required("This feild is required"),
    genres: Yup.array().required("This feild is required"),
    tags: Yup.array().required("This feild is required"),
    year: Yup.string().required("This feild is required"),
    description: Yup.string().required("This feild is required"),
    casts: Yup.array().required("This feild is required"),
    tagline: Yup.string().required("This feild is required"),
    running_time: Yup.number().integer().required("This feild is required"),
    country_of_origin: Yup.string().required("This feild is required"),
    language: Yup.string().required("This feild is required"),
    copyright: Yup.string().required("This feild is required"),
    studio: Yup.string().required("This feild is required"),
    sku: Yup.string().required("This feild is required"),
    // imdb: Yup.string().required("This feild is required"),
    included_countries: Yup.array().required("This feild is required"),
    excluded_countries: Yup.array().required("This feild is required"),
    isActive: Yup.string().required("This feild is required"),
    isAgeRestriction: Yup.string().required("This feild is required"),
  })

  const onSubmit = e => {
    toast.success("Updated Successfully!!")
  }

  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem("authUser"))
    setTokenHeader(data.access_token)
    dispatch(getCategories())
  }, [])

  useEffect(() => {
    if (languageSelectRef.current) {
      const divElement = document.querySelector("#languageSelectInput")
      const buttons = divElement.querySelectorAll("button")
      buttons.forEach(button => {
        button.type = "button" // Set the desired button type here
      })
    }
  }, [languageSelectRef])

  useEffect(() => {
  }, [data])

  const loadGenreOptions = async (search, loadedOptions, { page }) => {
    try {
      let optUrl = `${process.env.REACT_APP_ZFLIX_URL}/v1/genres?page=${page}`
      if (checkNull(search, false)) {
        optUrl = optUrl + "&search=" + search
      }
      const response = await fetch(optUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("authUser")).access_token
            }`,
          "Content-Type": "application/json",
        },
      })
      const res = await response.json()
      let newOptions = [...loadedOptions]
      let totalPages = page
      if (res?.data) {
        const data = res.data
        const updatedOptions = checkArray(data.results)
          ? data.results.map(result => ({
            value: result.id,
            name: result.name,
          }))
          : []

        newOptions = [...newOptions, ...updatedOptions]
        totalPages = data.totalPages
      }

      return {
        options: newOptions,
        hasMore: page < totalPages ? true : false, // Whether there are more pages to load
        additional: {
          page: page + 1,
        },
      }
    } catch (error) {
      console.log("errorrrssss : ", error)
      return {
        options: [],
        hasMore: false,
        additional: { page: 1 },
      }
    }
  }
  const LoadIncludedCoutriesOptions = async (
    search,
    loadedOptions,
    { page }
  ) => {
    return {
      options: mycountriesdata
        .filter(p => {
          if (checkNull(search, false)) {
            const itemNameLower = p.name.toLowerCase()
            const filterStringLower = search.toLowerCase()
            return itemNameLower.includes(filterStringLower)
          }
          return true
        })
        .map(result => ({
          value: result.id,
          name: result.name,
        })),
      hasMore: false,
    }
  }
  const LoadExcludedCoutriesOptions = async (
    search,
    loadedOptions,
    { page }
  ) => {
    return {
      options: mycountriesdata
        .filter(p => {
          if (checkNull(search, false)) {
            const itemNameLower = p.name.toLowerCase()
            const filterStringLower = search.toLowerCase()
            return itemNameLower.includes(filterStringLower)
          }
          return true
        })
        .map(result => ({
          value: result.id,
          name: result.name,
        })),
      hasMore: false,
    }
  }
  const loadCastOptions = async (search, loadedOptions, { page }) => {
    try {
      let optUrl = `${process.env.REACT_APP_ZFLIX_URL}/v1/casts?page=${page}`
      if (checkNull(search, false)) {
        optUrl = optUrl + "&search=" + search
      }
      const response = await fetch(optUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("authUser")).access_token
            }`,
          "Content-Type": "application/json",
        },
      })
      const res = await response.json()
      let newOptions = [...loadedOptions]
      let totalPages = page
      if (res?.data) {
        const data = res.data
        const updatedOptions = checkArray(data.results)
          ? data.results.map(result => ({
            value: result.id,
            name: `${result.name}(${result?.title_name})`,
          }))
          : []

        newOptions = [...newOptions, ...updatedOptions]
        totalPages = data.totalPages
      }

      return {
        options: newOptions,
        hasMore: page < totalPages ? true : false, // Whether there are more pages to load
        additional: {
          page: page + 1,
        },
      }
    } catch (error) {
      console.log("errorrrssss : ", error)
      return {
        options: [],
        hasMore: false,
        additional: { page: 1 },
      }
    }
  }
  const loadCategoriesOptions = async (search, loadedOptions, { page }) => {
    try {
      let optUrl = `${process.env.REACT_APP_ZFLIX_URL}/v1/categorys?page=${page}`
      if (checkNull(search, false)) {
        optUrl = optUrl + "&search=" + search
      }
      const response = await fetch(optUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("authUser")).access_token
            }`,
          "Content-Type": "application/json",
        },
      })
      const res = await response.json()
      let newOptions = [...loadedOptions]
      let totalPages = page
      if (res?.data) {
        const data = res.data
        const updatedOptions = checkArray(data.results)
          ? data.results.map(result => ({
            value: result.id,
            name: `${result.name}`,
          }))
          : []

        newOptions = [...newOptions, ...updatedOptions]
        totalPages = data.totalPages
      }

      return {
        options: newOptions,
        hasMore: page < totalPages ? true : false,
        additional: {
          page: page + 1,
        },
      }
    } catch (error) {
      console.log("errorrrssss : ", error)
      return {
        options: [],
        hasMore: false,
        additional: { page: 1 },
      }
    }
  }

  const handleAgeRestrictionChange = (value) => {
    console.log("value", value)
    setData({
      ...data,
      isAgeRestriction: value === 'true' ? true : false
    });
  };

  const handleAddToDashboardChange = (value) => {
    console.log("value", value)
    setData({
      ...data,
      addToDashboard: value === 'true' ? true : false
    });
  };


  const handleAddToTopTen = (value) => {
    const newValue = value === "true" ? true : false;
    setData({ ...data, isDefaultSearchInclude: newValue });
  };

  const handleImageChange = files => {
    const extn = ["image/jpg", "image/png", "image/jpeg"]
    const [imageNew] = files
    setData({ ...data, imageNew })
    if (imageNew && extn.includes(imageNew.type)) {
      if (imageNew.size <= 5242880) {
        setImage({ blob: imageNew, src: window.URL.createObjectURL(imageNew) })
      } else {
        fireToast("error", "image too large")
      }
    } else {
      fireToast(
        "error",
        "Please select a valid image file(only jpg, png and jpeg images are allowed)"
      )
    }
  }

  const handlePosterChange = files => {
    const extn = ["image/jpg", "image/png", "image/jpeg"]
    const [imageNew] = files
    setData({ ...data, poster: imageNew })

    if (imageNew && extn.includes(imageNew.type)) {
      // changeApiStatus(false, "")
      if (imageNew.size <= 5242880) {
        setPoster({ blob: imageNew, src: window.URL.createObjectURL(imageNew) })
      } else {
        fireToast("error", "image too large")
      }
    } else {
      fireToast(
        "error",
        "Please select a valid image file(only jpg, png and jpeg images are allowed)"
      )
    }
  }

  const handleSubmit = () => {
    let categories = data.categories.map((v) => v.value)
    data.categories = categories;
    console.log("submit data", data)
    dispatch(addNewTitle(data, history))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Title" breadcrumbItem=" Add Title" />
          <Formik
            initialValues={{ ...data }}
            validationSchema={AddTitleValidation}
            enableReinitialize
            onSubmit={handleSubmit}
          >
            {({ values, setValues, setFieldValue, errors, touched }) => (
              <Row>
                <Col xl={12}>
                  <Card>
                    <CardBody>
                      <Form>
                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">Title</Label>
                          <Field
                            type="text"
                            className="form-control"
                            id="title"
                            name="title"
                            value={data.title}
                            onChange={e => {
                              setData({ ...data, title: e.target.value })
                            }}
                          />
                          {errors.title && touched.title ? (
                            <div className="text-danger input-error">
                              {errors.title}
                            </div>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Row>
                            <Col xl={6} sm={6}>
                              <Label htmlFor="type">Type</Label>
                              <Field
                                name="type"
                                className="form-control"
                                onChange={e => {
                                  setData({ ...data, type: e.target.value })
                                }}
                              >
                                {({ field, form }) => (
                                  <div className="sub-category-select">
                                    <select
                                      id="type"
                                      value={data.type}
                                      className="form-control input-color "
                                      name="type"
                                      onChange={e => {
                                        setData({ ...data, type: e.target.value })
                                      }}
                                    >
                                      <option value="">Choose</option>
                                      <option value="series">Series</option>
                                      <option value="movie">Movie</option>
                                    </select>
                                    {errors.type && touched.type ? (
                                      <div className="text-danger input-error">
                                        {errors.type}
                                      </div>
                                    ) : null}
                                  </div>
                                )}
                              </Field>
                            </Col>
                            <Col xl={6} sm={6}>
                              <Label htmlFor="formrow-firstname-Input">Select Categories</Label>
                              <SelectAsyncPaginate
                                loadOptions={loadCategoriesOptions}
                                placeHolder="Select Categories"
                                isMulti={true}
                                value={data.categories}
                                onChange={categories => {
                                  setData({ ...data, categories })
                                }}
                                isSearchable={true}
                              />
                              {errors.categories && touched.categories ? (
                                <div className="text-danger input-error">
                                  {errors.categories}
                                </div>
                              ) : null}
                            </Col>
                          </Row>
                        </div>

                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">Genre</Label>
                          <SelectAsyncPaginate
                            // regionName={region.value}
                            loadOptions={loadGenreOptions}
                            placeHolder="Select Genre"
                            isMulti={true}
                            value={data.genres}
                            onChange={genres => {
                              setData({ ...data, genres })
                            }}
                            isSearchable={true}
                          />
                          {errors.genres && touched.genres ? (
                            <div className="text-danger input-error">
                              {errors.genres}
                            </div>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">Cast</Label>
                          <SelectAsyncPaginate
                            // regionName={region.value}
                            loadOptions={loadCastOptions}
                            placeHolder="Select Cast"
                            isMulti={true}
                            value={data.casts}
                            onChange={casts => {
                              setData({ ...data, casts })
                            }}
                            isSearchable={true}
                          />
                          {errors.casts && touched.casts ? (
                            <div className="text-danger input-error">
                              {errors.casts}
                            </div>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">
                            Description
                          </Label>
                          <Field
                            as="textarea"
                            className="form-control"
                            id="description"
                            name="description"
                            rows="5"
                            value={data.description}
                            onChange={e => {
                              setData({ ...data, description: e.target.value })
                            }}
                          />
                          {errors.description && touched.description ? (
                            <div className="text-danger input-error">
                              {errors.description}
                            </div>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <label htmlFor="isAgeRestriction">Age Restriction:</label>
                          <div>
                            <input
                              type="radio"
                              id="true"
                              name="isAgeRestriction"
                              value="true"
                              onChange={(e) => handleAgeRestrictionChange(e.target.value)}
                            />{' '}
                            <label htmlFor="true"> True</label>
                          </div>
                          <div>
                            <input
                              type="radio"
                              id="false"
                              name="isAgeRestriction"
                              value="false"
                              onChange={(e) => handleAgeRestrictionChange(e.target.value)}
                            />{' '}
                            <label htmlFor="false"> False</label>
                          </div>
                          {errors.isAgeRestriction && touched.isAgeRestriction ? (
                            <div className="text-danger input-error">
                              {errors.isAgeRestriction}
                            </div>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <label htmlFor="addToDashboard">Add To Dashboard Banner:</label>
                          <div>
                            <input
                              type="radio"
                              id="addToDashboardTrue"
                              name="addToDashboard"
                              value="true"
                              onChange={(e) => handleAddToDashboardChange(e.target.value)}
                            />{' '}
                            <label htmlFor="addToDashboardTrue" className="me-4"> Yes</label>
                            <input
                              type="radio"
                              id="addToDashboardFalse"
                              name="addToDashboard"
                              value="false"
                              onChange={(e) => handleAddToDashboardChange(e.target.value)}
                              defaultChecked
                            />{' '}
                            <label htmlFor="addToDashboardFalse"> No</label>
                          </div>

                          {errors.isAgeRestriction && touched.isAgeRestriction ? (
                            <div className="text-danger input-error">
                              {errors.isAgeRestriction}
                            </div>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <label htmlFor="defaultResult">Add to default search results:</label>
                          <div>
                            <input
                              type="radio"
                              id="true"
                              name="defaultResult"
                              checked={data.isDefaultSearchInclude === true}
                              value="true"
                              onClick={(e) => handleAddToTopTen(e.target.value)}
                            />{' '}
                            <label htmlFor="true" className="me-4"> Yes</label>
                            <input
                              type="radio"
                              id="false"
                              checked={data.isDefaultSearchInclude === false}
                              name="defaultResult"
                              value="false"
                              onClick={(e) => handleAddToTopTen(e.target.value)}
                            />{' '}
                            <label htmlFor="false"> No</label>
                          </div>

                        </div>



                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">Tags</Label>
                          <div>
                            <MuiChipsInput
                              value={data.tags}
                              onChange={tags => {
                                setData({ ...data, tags })
                              }}
                            />
                          </div>
                          {errors.tags && touched.tags ? (
                            <div className="text-danger input-error">
                              {errors.tags}
                            </div>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">Year</Label>
                          <Field
                            type="text"
                            className="form-control"
                            id="year"
                            name="year"
                            value={data.year}
                            onChange={e => {
                              setData({ ...data, year: e.target.value })
                            }}
                          />
                          {errors.year && touched.year ? (
                            <div className="text-danger input-error">
                              {errors.year}
                            </div>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">
                            Tagline
                          </Label>
                          <Field
                            type="text"
                            className="form-control"
                            id="tagline"
                            name="tagline"
                            value={data.tagline}
                            onChange={e => {
                              setData({ ...data, tagline: e.target.value })
                            }}
                          />
                          {errors.tagline && touched.tagline ? (
                            <div className="text-danger input-error">
                              {errors.tagline}
                            </div>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">
                            Running Time
                          </Label>
                          <Field
                            type="text"
                            className="form-control"
                            id="running_time"
                            name="running_time"
                            value={data.running_time}
                            onChange={e => {
                              setData({
                                ...data,
                                running_time: e.target.value
                              })
                            }}
                          />
                          {errors.running_time && touched.running_time ? (
                            <div className="text-danger input-error">
                              {errors.running_time}
                            </div>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">
                            Country Of Origin
                          </Label>
                          <CountryDropdown
                            value={data.country_of_origin}
                            onChange={val => {
                              setData({ ...data, country_of_origin: val })
                            }}
                            className="form-control w-100"
                          />
                          {errors.country_of_origin &&
                            touched.country_of_origin ? (
                            <div className="text-danger input-error">
                              {errors.country_of_origin}
                            </div>
                          ) : null}
                        </div>
                        <div className="mb-3" id="languageSelectInput">
                          <Label htmlFor="formrow-firstname-Input">
                            Language
                          </Label>
                          <ReactLanguageSelect
                            ref={languageSelectRef}
                            names={"international"}
                            value={data.language}
                            onSelect={languageCode => {
                              setData({ ...data, language: languageCode })
                            }}
                            className="form-control w-100"
                          />
                          {errors.language && touched.language ? (
                            <div className="text-danger input-error">
                              {errors.language}
                            </div>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">
                            Copyright
                          </Label>
                          <Field
                            type="text"
                            className="form-control"
                            id="copyright"
                            name="copyright"
                            value={data.copyright}
                            onChange={e => {
                              setData({ ...data, copyright: e.target.value })
                            }}
                          />
                          {errors.copyright && touched.copyright ? (
                            <div className="text-danger input-error">
                              {errors.copyright}
                            </div>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">
                            Studio
                          </Label>
                          <Field
                            type="text"
                            className="form-control"
                            id="studio"
                            name="studio"
                            value={data.studio}
                            onChange={e => {
                              setData({ ...data, studio: e.target.value })
                            }}
                          />
                          {errors.studio && touched.studio ? (
                            <div className="text-danger input-error">
                              {errors.studio}
                            </div>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">SKU</Label>
                          <Field
                            type="text"
                            className="form-control"
                            id="sku"
                            name="sku"
                            value={data.sku}
                            onChange={e => {
                              setData({ ...data, sku: e.target.value })
                            }}
                          />
                          {errors.sku && touched.sku ? (
                            <div className="text-danger input-error">
                              {errors.sku}
                            </div>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">IMDB</Label>
                          <Field
                            type="text"
                            className="form-control"
                            id="imdb"
                            name="imdb"
                            value={data.imdb}
                            onChange={e => {
                              setData({ ...data, imdb: e.target.value })
                            }}
                          />
                          {errors.imdb && touched.imdb ? (
                            <div className="text-danger input-error">
                              {errors.imdb}
                            </div>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">
                            Included Countries
                          </Label>
                          <SelectAsyncPaginate
                            loadOptions={LoadIncludedCoutriesOptions}
                            placeHolder="Select Included Countries"
                            isMulti={true}
                            value={data.included_countries}
                            onChange={included_countries => {
                              setData({ ...data, included_countries })
                            }}
                            isSearchable={true}
                          />
                          {errors.included_countries &&
                            touched.included_countries ? (
                            <div className="text-danger input-error">
                              {errors.included_countries}
                            </div>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">
                            Excluded Countries
                          </Label>
                          <SelectAsyncPaginate
                            loadOptions={LoadExcludedCoutriesOptions}
                            placeHolder="Select Excluded Countries"
                            isMulti={true}
                            value={data.excluded_countries}
                            onChange={excluded_countries => {
                              setData({ ...data, excluded_countries })
                            }}
                            isSearchable={true}
                          />
                          {errors.excluded_countries &&
                            touched.excluded_countries ? (
                            <div className="text-danger input-error">
                              {errors.excluded_countries}
                            </div>
                          ) : null}
                        </div>
                        <div>
                          <Label htmlFor="isActive">isActive</Label>
                          <Field
                            name="isActive"
                            className="form-control"
                            onChange={e => {
                              setData({ ...data, isActive: e.target.value })
                            }}
                          >
                            {({ field, form }) => (
                              <div className="sub-category-select">
                                <select
                                  id="isActive"
                                  value={data.isActive === "1" ? "1" : "0"}
                                  className="form-control input-color "
                                  name="isActive"
                                  onChange={e => {
                                    setData({
                                      ...data,
                                      isActive: e.target.value,
                                    })
                                  }}
                                >
                                  <option value="">Choose</option>
                                  <option value="1">yes</option>
                                  <option value="0">No</option>
                                </select>
                                {errors.isActive && touched.isActive ? (
                                  <div className="text-danger input-error">
                                    {errors.isActive}
                                  </div>
                                ) : null}
                              </div>
                            )}
                          </Field>
                        </div>

                        <div>
                          <Label htmlFor="formrow-firstname-Input">
                            Image <small>(Optional)</small>
                          </Label>
                          <div className="text-center">
                            <div className="text-center">
                              <div className="mb-3 dragdrop-container">
                                <input
                                  ref={uploadRef}
                                  id="upload"
                                  hidden
                                  accept="image/*"
                                  type="file"
                                  onChange={e =>
                                    handleImageChange(e.target.files)
                                  }
                                />
                                {image.src ? (
                                  <div className="commonImgs">
                                    <img
                                      className="every-img"
                                      src={image.src ? image.src : cloud}
                                      alt=""
                                      onClick={() => {
                                        uploadRef.current.click()
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <div className="drag-n-drop-container">
                                    <div>
                                      <Dropzone
                                        accept="image/*"
                                        multiple={false}
                                        onDrop={acceptedFiles => {
                                          handleImageChange(acceptedFiles)
                                        }}
                                      >
                                        {({
                                          getRootProps,
                                          getInputProps,
                                          isDragActive,
                                        }) => (
                                          <section>
                                            <div
                                              className="drop-area"
                                              {...getRootProps()}
                                            >
                                              <img
                                                width={60}
                                                src={cloud}
                                                alt=""
                                              />
                                              <input
                                                {...getInputProps()}
                                                accept="image/*"
                                                multiple={false}
                                              />

                                              {isDragActive ? (
                                                <div>
                                                  Drop your image file here
                                                </div>
                                              ) : (
                                                <p>
                                                  Drag n drop image file here,
                                                  or click to select <br />
                                                  <small className="text-center">
                                                    <strong>
                                                      Supported files:
                                                    </strong>{" "}
                                                    jpeg, jpg, png. | Will be
                                                    resized to: 1920x1080 px.
                                                  </small>
                                                </p>
                                              )}
                                            </div>
                                          </section>
                                        )}
                                      </Dropzone>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            <p className="fw-bold">
                              Note:
                              <span className="text-danger mx-2 text-align-left">
                                Supported image formats are:&nbsp;jpg, png and
                                jpeg only
                              </span>
                            </p>
                          </div>
                        </div>
                        {data.banner ? (
                          <div className="mb-3">
                            <Label htmlFor="formrow-firstname-Input">
                              Uploaded
                            </Label>
                            <div>
                              <img
                                className="previousImage"
                                src={data.banner}
                                alt=""
                              />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <div>
                          <Label htmlFor="formrow-firstname-Input">
                            Poster <small>(Optional)</small>
                          </Label>
                          <div className="text-center">
                            <div className="text-center">
                              <div className="mb-3 dragdrop-container">
                                <input
                                  ref={posterRef}
                                  id="upload"
                                  hidden
                                  accept="image/*"
                                  type="file"
                                  onChange={e =>
                                    handlePosterChange(e.target.files)
                                  }
                                />
                                {poster.src ? (
                                  <div className="commonImgs">
                                    <img
                                      className="every-img"
                                      src={poster.src ? poster.src : cloud}
                                      alt=""
                                      onClick={() => {
                                        posterRef.current.click()
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <div className="drag-n-drop-container">
                                    <div>
                                      <Dropzone
                                        accept="image/*"
                                        multiple={false}
                                        onDrop={acceptedFiles => {
                                          handlePosterChange(acceptedFiles)
                                        }}
                                      >
                                        {({
                                          getRootProps,
                                          getInputProps,
                                          isDragActive,
                                        }) => (
                                          <section>
                                            <div
                                              className="drop-area"
                                              {...getRootProps()}
                                            >
                                              <img
                                                width={60}
                                                src={cloud}
                                                alt=""
                                              />
                                              <input
                                                {...getInputProps()}
                                                accept="image/*"
                                                multiple={false}
                                              />

                                              {isDragActive ? (
                                                <div>
                                                  Drop your image file here
                                                </div>
                                              ) : (
                                                <p>
                                                  Drag n drop image file here,
                                                  or click to select <br />
                                                  <small className="text-center">
                                                    <strong>
                                                      Supported files:
                                                    </strong>{" "}
                                                    jpeg, jpg, png. | Will be
                                                    resized to: 1920x1080 px.
                                                  </small>
                                                </p>
                                              )}
                                            </div>
                                          </section>
                                        )}
                                      </Dropzone>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            <p className="fw-bold">
                              Note:
                              <span className="text-danger mx-2 text-align-left">
                                Supported image formats are:&nbsp;jpg, png and
                                jpeg only
                              </span>
                            </p>
                          </div>
                        </div>
                        {data.poster ? (
                          <div className="mb-3">
                            <Label htmlFor="formrow-firstname-Input">
                              Uploaded
                            </Label>
                            <div>
                              <img
                                className="previousImage"
                                src={data.poster}
                                alt=""
                              />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <div>
                          <button
                            type="submit"
                            className="btn btn-primary w-md"
                          >
                            Submit
                          </button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
          </Formik>
          <ToastContainer />
        </Container>
        {/* container-fluid */}
      </div>
    </React.Fragment>
  )
}

export default TitleAdd
