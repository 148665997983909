import React, { useEffect, useMemo, useState } from "react"
import {
  cilPencil,
  cilTrash,
  cilMovie,
  cilFeaturedPlaylist,
} from "@coreui/icons"
import Image from "react-bootstrap/Image"
import { CCard, CCardBody, CCardGroup } from "@coreui/react"
import DataTable from "react-data-table-component"
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Form,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import "./title.css"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import FullPageLoader from "../../../components/Common/FullPageLoader"
import CIcon from "@coreui/icons-react"
import { useHistory, Link } from "react-router-dom"
import { toastConfirm } from "../../../common/toast"
import { useDispatch, useSelector } from "react-redux"
import { setTokenHeader } from "../../../helpers/api_helper"
import { deleteTitle, getTitle, importTitleVideo } from "../../../store/actions"
import NoImgFound from "../../../assets/No-image-found.jpg"
import { startLoader } from "../../../store/loaderState/actions"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { updateHistory } from "../../../store/title/toolkit/slice"
import countriesData from "../TitleEdit/countries.json"
import languages from "../../../assets/lang/language.json"
import axios, { CancelToken } from "axios"
import { debounce } from "lodash"
import { Spinner } from "react-bootstrap"

const TitleListing = () => {
  document.title = "All Titles"
  const dispatch = useDispatch()
  const history = useHistory()
  const [modal, setModal] = useState(false)
  const [inputValue, setInputValue] = useState("")
  const [exportLoading, setExportLoading] = useState(false)
  const { loadingState } = useSelector(state => state.loadingState)
  const { titlePage, titleCountries } = useSelector(state => state.TitleSlice)
  const [page, setPage] = useState(titlePage || 1)
  const toggle = () => setModal(!modal)
  let { title } = useSelector(state => state.title)
  const [titleData, setTitleData] = useState(title)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [languagedropdownOpen, setLanguagedropdownOpen] = useState(false)
  const historyCountry = countriesData.filter(v => v?.id === titleCountries)
  const [countries, setCountries] = useState(
    titleCountries
      ? { id: historyCountry[0]?.id, name: historyCountry[0]?.name }
      : { id: "all", name: "Countries (All)" }
  )
  const [selectedLanguage, setSelectedLanguage] = useState({
    id: "all",
    name: "Language (All)",
  })

  const [sortKey, setSortKey] = useState("");
  const [sortType, setSortType] = useState("");
  const handleSort = (column, sortDirection) => {
    setSortKey(column.name);
    setSortType(sortDirection);
  };


  const handleDeleteTitle = async id => {
    const response = await (
      await toastConfirm("Are you sure you want to delete this?")
    ).fire()
    if (response.isConfirmed) {
      dispatch(deleteTitle(id, history))
    }
  }

  const handleFilter = async (id, name, type) => {
    setPage(1)
    if (type === "country") {
      setCountries({ ...countries, id, name })
    } else if (type === "language") {
      setSelectedLanguage({ ...selectedLanguage, id, name })
    }
  }

  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem("authUser"))
    setTokenHeader(data.access_token)
    dispatch(startLoader())
    dispatch(getTitle(page, countries?.id, selectedLanguage?.id))
  }, [])

  useEffect(() => {
    setTitleData(title)
  }, [title])

  useEffect(() => {
    console.log("check page my page====", page)
    dispatch(startLoader());
    dispatch(getTitle(page, countries?.id, selectedLanguage?.id, sortKey, sortType));
  }, [page, countries, selectedLanguage, sortKey, sortType]);

  let rowCounter = 1
  const columns = useMemo(() => [
    {
      name: "Sr. no",
      selector: (row, index) => ((page - 1) * 200) + index + 1,
      sortable: true,
      maxWidth: "80px",
    },
    {
      name: "Banner",
      selector: (row, index) => (
        <Image
          key={index}
          fluid={true}
          src={row.banner ? row.banner : NoImgFound}
          style={{ width: "80px", height: "60px", padding: "10px 0px" }}
        />
      ),
      // sortable: true,
      width: "100px",
    },
    {
      name: "Title",
      selector: row => row.title,
      sortable: true,
      maxWidth: "300px",
    },
    {
      name: "Source",
      selector: row => row.source,
      sortable: true,
      maxWidth: "100px",
    },
    {
      name: "Type",
      selector: row =>
        row.type ? row.type.charAt(0).toUpperCase() + row.type.slice(1) : "",
      sortable: true,
      maxWidth: "100px",
    },
    {
      name: "Origin Country",
      selector: row => {
        const country = countriesData.find(v => v?.id === row.country_of_origin)
        return country ? country.name : "N/A"
      },
      sortable: true,
      maxWidth: "300px",
    },
    {
      name: "Language",
      selector: row => languages[row?.language] || row?.language,
      sortable: true,
      maxWidth: "200px",
    },
    // {
    //   name: "Genre",
    //   selector: (row,index) =>
    //     row.genres.map((genre,i) => {
    //       return (
    //         <Badge key={genre.id+i+index} pill bg="primary" className="p-1 me-1 mb-1">
    //           {genre.name}
    //         </Badge>
    //       )
    //     }),
    //   sortable: true,
    // },
    {
      name: "Year",
      selector: row => row.year,
      sortable: true,
      maxWidth: "100px",
    },
    // {
    //   name: "Categories",
    //   maxWidth: "150px",

    //   selector: (row,index) =>
    //     row.categories.map((category,i) => {
    //       return (
    //         <Badge key={category.id+index+i} pill bg="primary" className="p-2">
    //           {category.name}
    //         </Badge>
    //       )
    //     }),
    //   sortable: true,
    // },
    {
      name: "Actions",
      maxWidth: "150px",
      selector: row => (
        <>
          <span
            onClick={() => handleTitlePageUpdateAndRedirect(row.id, "edit")}
          >
            <CIcon icon={cilPencil} className="text-warning hand me-2" />
          </span>
          <span
            onClick={() =>
              handleTitlePageUpdateAndRedirect(row.id, "uploadTrailers")
            }
          >
            <CIcon
              icon={cilFeaturedPlaylist}
              className="text-warning hand me-2"
            />
          </span>
          {row.type == "movie" ? (
            <span
              onClick={() =>
                handleTitlePageUpdateAndRedirect(row.id, "uploadMovie")
              }
            >
              <CIcon icon={cilMovie} className="text-warning hand me-2" />
            </span>
          ) : (
            <span
              onClick={() =>
                handleTitlePageUpdateAndRedirect(row.id, "uploadSeries")
              }
            >
              <CIcon icon={cilMovie} className="text-warning hand me-2" />
            </span>
          )}
          <CIcon
            icon={cilTrash}
            className="text-danger hand"
            onClick={() => {
              handleDeleteTitle(row.id)
            }}
          />
        </>
      ),
    },
  ])

  const toggleDropdown = dropdown => {
    if (dropdown === "country") {
      setDropdownOpen(prevState => !prevState)
    } else if (dropdown === "language") {
      setLanguagedropdownOpen(prevState => !prevState)
    }
  }

  const handleTitlePageUpdateAndRedirect = (id, type) => {
    dispatch(updateHistory({ page, countries: countries?.id }))
    if (type === "edit") {
      history.push(`/title/${id}/edit`)
    } else if (type === "uploadTrailers") {
      history.push(`/title/${id}/uploadTrailers`)
    } else if (type === "uploadMovie") {
      history.push(`/title/${id}/uploadMovie`)
    } else if (type === "uploadSeries") {
      history.push(`/title/${id}/uploadSeries`)
    }
  }

  const importChangeHandler = event => {
    if (event?.target?.files[0]) {
      dispatch(importTitleVideo(event.target.files[0], history))
      setModal(false)
    }
  }

  function handleSearch(event) {
    const debouncedSearch = debounce(handleDebouncedSearch, 300)
    let cancelTokenSource

    const inputValue = event.target.value.toLowerCase()
    setInputValue(inputValue)

    if (cancelTokenSource) {
      cancelTokenSource.cancel("Operation canceled due to new request.")
    }
    cancelTokenSource = axios.CancelToken.source()

    debouncedSearch(inputValue, cancelTokenSource.token)
  }

  const handleExport = async () => {
    setExportLoading(true)
    console.log("set true")
    const authUser = JSON.parse(sessionStorage.getItem("authUser"))

    axios({
      method: "post",
      url: `${process.env.REACT_APP_ZFLIX_URL}/v1/titles/export-title`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authUser.access_token}`,
      },
    })
      .then(response => {
        const link = document.createElement("a")
        link.setAttribute("href", response.data.redirection)
        link.setAttribute("download", "title-report.csv")
        link.style.display = "none"
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        setExportLoading(false)
      })
      .catch(error => {
        console.log(error)
        setExportLoading(false)
      })

    console.log("set false")
  }

  async function handleDebouncedSearch(inputValue, cancelToken) {
    if (inputValue.length > 0) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ZFLIX_URL}/v1/titles?search=${inputValue}`,
          {
            headers: {
              Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("authUser")).access_token
                }`,
              "Content-Type": "application/json",
            },
            cancelToken,
          }
        )

        const res = response.data
        setTitleData(res.data)
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled:", error.message)
        } else {
          console.error("Error fetching data:", error)
        }
      }
    } else {
      setTitleData(title)
    }
  }
  // console.log('titleData?.results',titleData?.results)


  if (!titleData?.results) {
    return <FullPageLoader />
  }

  const ImportModel = (
    <Modal isOpen={modal} toggle={toggle} centered={true}>
      <ModalHeader toggle={toggle}>Import titles from yaml file</ModalHeader>
      <ModalBody>
        <Label for="importFile">Import</Label>
        <Input
          id="importFile"
          name="file"
          onChange={importChangeHandler}
          type="file"
          accept=".yaml"
        />
      </ModalBody>
    </Modal>
  )

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid titlelisting">
          <Breadcrumbs title="Manage Titles" breadcrumbItem="Titles" />
          <div className="d-flex justify-content-between">
            <div className="mb-2">
              <Link to={"/title/add"} className="btn btn-primary ">
                Add Title
              </Link>{" "}
              <button onClick={toggle} className="btn btn-primary ">
                Import
              </button>{" "}
              <button onClick={handleExport} className="btn btn-primary ">
                {exportLoading ? <Spinner animation="border" size="sm" /> : "Export"}
              </button>
            </div>
            <FormGroup>
              <Form className="mt-4 mt-sm-0 float-sm-end d-flex align-items-center">
                <div className="search-box mb-2 me-2">
                  <div className="position-relative">
                    <input
                      type="text"
                      value={inputValue}
                      className="form-control bg-light border-light rounded"
                      placeholder="Search..."
                      onChange={handleSearch}
                    />
                    <i className="bx bx-search-alt search-icon"></i>
                  </div>
                </div>

                <div
                  className="d-flex align-items-center justify-content-end  me-2"
                  style={{ marginTop: "-7px" }}
                >
                  <Dropdown
                    isOpen={languagedropdownOpen}
                    toggle={() => toggleDropdown("language")}
                    style={{ marginRight: "10px" }}
                  >
                    <DropdownToggle caret>
                      {selectedLanguage?.name || "Language"}
                    </DropdownToggle>
                    <DropdownMenu
                      style={{ maxHeight: "400px", overflowY: "auto" }}
                      direction="down"
                    >
                      <DropdownItem
                        onClick={() =>
                          handleFilter("all", "Language (All)", "language")
                        }
                        key={"all"}
                      >
                        {"All"}
                      </DropdownItem>
                      {Object.entries(languages).map(([code, name], i) => (
                        <DropdownItem
                          onClick={() => handleFilter(code, name, "language")}
                          key={i}
                        >
                          {name}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>

                  <Dropdown
                    isOpen={dropdownOpen}
                    toggle={() => toggleDropdown("country")}
                    style={{ marginRight: "10px" }}
                  >
                    <DropdownToggle caret>
                      {countries?.name || "Countries (All)"}
                    </DropdownToggle>
                    <DropdownMenu
                      style={{ maxHeight: "400px", overflowY: "auto" }}
                    >
                      <DropdownItem
                        onClick={() => handleFilter("all", "all", "country")}
                        key={"all"}
                      >
                        {"All"}
                      </DropdownItem>
                      {countriesData.map((v, i) => (
                        <DropdownItem
                          onClick={() =>
                            handleFilter(v?.id, v?.name, "country")
                          }
                          key={v?.id}
                        >
                          {v?.name}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </Form>
            </FormGroup>
          </div>
          <Row>
            <Col xl={12}>
              <CCardGroup>
                <CCard>
                  <FullPageLoader loading={loadingState} />
                  <CCardBody>
                    <DataTable
                      striped
                      columns={columns}
                      data={titleData?.results}
                      highlightOnHover
                      pagination
                      paginationServer
                      paginationTotalRows={titleData?.totalResults}
                      paginationPerPage={titleData?.limit}
                      paginationDefaultPage={page}
                      paginationComponentOptions={{
                        noRowsPerPage: true,
                      }}
                      onChangePage={(page, totalRows) => {
                        setPage(page)
                      }}
                      onSort={handleSort}
                      sortServer
                    />
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </Col>
          </Row>
        </div>
      </div>
      {ImportModel}
    </React.Fragment>
  )
}

export default TitleListing
