import React, { useState, useEffect } from "react"
import { ToastContainer, toast } from "react-toastify"
import axios from "axios"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

const TermConditions = () => {
  document.title = "Term & Conditions | Moviez : Stream Movie Channel"

  const [activeTab, setActiveTab] = useState("1")
  const [terms, setTerms] = useState("")
  const [text, setText] = useState("")
  const [id, setID] = useState("")
  const API_URL = `${process.env.REACT_APP_ZFLIX_URL}/v1`
  const sessionsData = JSON.parse(sessionStorage.getItem("authUser"))

  useEffect(() => {
    getCms()
  }, [])

  const getCms = () => {
    axios
      .get(API_URL + "/cms/privacy-policy?type=terms-conditions")
      .then(response => {
        if (response.data?.data[0]?.Content) {
          setText(response.data?.data[0]?.Content )
          setID(response.data?.data[0]?.id)
        }
      })
      .catch(error => console.log(error))
  }

  const handleSubmit = () => {

    let bodyData;
    if (id === "") {
      bodyData = {
        type: 'terms-conditions',
        content: terms
      }
    } else {
      bodyData = {
        _id: id,
        type: 'terms-conditions',
        content: terms
      }
    }

    axios
      .post(
        API_URL + "/cms/privacy-policy",
        bodyData,
        {
          headers: {
            Authorization: `Bearer ${sessionsData.access_token}`,
          },
        }
      )
      .then(function (response) {
        getCms()
        toast.success("Updated Successfully!!")
      })
      .catch(function (error) {
        console.log(error)
        toast.error("Something went wrong")
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Manage Content" breadcrumbItem="Static Content" />

          <div className="checkout-tabs">
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1">
                        <CardTitle className="mb-3">Terms & Condtions</CardTitle>
                        <CKEditor
                          editor={ClassicEditor}
                          data={text}
                          onReady={editor => {
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData()
                            setTerms(data)
                          }}
                        />
                        <button
                          className="btn btn-outline-dark mt-2"
                          onClick={() => handleSubmit()}
                        >
                          Update
                        </button>
                        <ToastContainer />
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default TermConditions
