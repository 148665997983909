import { call, put, takeEvery } from "redux-saga/effects"
import { toast } from "react-toastify"

// Crypto Redux States
import {
  GET_CATEGORIES,
  GET_CATEGORIES_PROFILE,
  ADD_NEW_CATEGORY,
  DELETE_CATEGORY,
  UPDATE_CATEGORY,
  UPDATE_CATEGORY_IMAGE,
} from "./actionTypes"

import {
  getCategoriesSuccess,
  getCategoriesFail,
  addCategoryFail,
  addCategorySuccess,
  updateCategoryimage,
  updateCategoryImageSuccess,
  updateCategoryImageFail,
  updateCategorySuccess,
  updateCategoryFail,
  deleteCategorySuccess,
  deleteCategoryFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getCategories,
  getCategoriesProfile,
  addNewCategory,
  updateCategory,
  deleteCategory,
  updateCategoryImage,
} from "../../helpers/fakebackend_helper"
import { stopLoader } from "../loaderState/actions"
import { checkNull } from "../../helpers/basicFunctions"

function* fetchCategories() {
  try {
    const response = yield call(getCategories)

    yield put(getCategoriesSuccess(response))
    yield put(stopLoader())
  } catch (error) {
    yield put(getCategoriesFail(error))
  }
}
function* fetchCategoriesProfile() {
  try {
    const response = yield call(getCategoriesProfile)

    yield put(getCategoriesSuccess(response))
    yield put(stopLoader())
  } catch (error) {
    yield put(getCategoriesFail(error))
  }
}
function* onUpdateCategory({ payload: category }) {
  try {
    const response = yield call(updateUser, category)
    yield put(updateCategorySuccess(response))
  } catch (error) {
    yield put(updateCategoryFail(error))
  }
}

function* onDeleteCategory({ payload: { category, history } }) {
  try {
    const response = yield call(deleteCategory, category.id)

    yield put(deleteCategorySuccess(category.id))
    history.push("/all-categories")
  } catch (error) {
    yield put(deleteCategoryFail(error))
  }
}

function* onAddNewCategory({ payload: { category, history, handleClose } }) {
  try {
    const {
      id = null,
      name,
      type,
      watchProvider,
      titleType,
      description,
      image,
      isActive,
      titles,
      isFree,
      sort = null,
    } = category

    const response = checkNull(id, false)
      ? yield call(updateCategory, id, {
        name,
        type,
        watchProvider,
        titleType,
        description,
        isActive,
        titles,
        isFree,
        sort: parseInt(sort),
      })
      : yield call(addNewCategory, { name, type, watchProvider, titleType, description, isActive, titles, isFree })
    if (response.status && id) {
      toast.success("Update Successfully");
      history.push("/all-categories")
    } else {
      toast.success("Addedd Successfully");
      history.push("/all-categories")
    }

    // if (response?.data && checkNull(id, false)) {
    //   yield put(updateCategorySuccess(response.data))
    // } else {
    //   yield put(addCategorySuccess(response.data.data))
    // }
    // if (response?.data &&checkNull(image, false) &&typeof image === "object") {
    //   const catId = checkNull(id, false)
    //     ? response?.data?.id
    //     : response?.data?.data?.id
    //   if (catId) yield put(updateCategoryimage(catId, image))
    // }
    // if (response?.data) {
    //   if (checkNull(id, false)) {
    //     if (response.status) {
    //       handleClose()
    //     } else {
    //       toast.error(response.message, {
    //         position: toast.POSITION.TOP_LEFT,
    //       })
    //     }
    //   } else {
    //     if (response.data.status) {
    //       handleClose()
    //     } else {
    //       toast.error(response.data.message, {
    //         position: toast.POSITION.TOP_LEFT,
    //       })
    //     }
    //   }
    // } else {
    //   toast.error(response.message || response.data.message, {
    //     position: toast.POSITION.TOP_LEFT,
    //   })
    // }
  } catch (error) {
    yield put(addCategoryFail(error))
  }
}

function* onUpdateCategoryImage({ payload: { id, image } }) {
  try {
    const formData = new FormData()
    formData.append("image", image)
    const response = yield call(updateCategoryImage, id, { image })
    yield put(updateCategoryImageSuccess(response.data.data))
  } catch (error) {
    yield put(updateCategoryImageFail(error))
  }
}

function* categoriesSaga() {
  yield takeEvery(GET_CATEGORIES, fetchCategories)
  yield takeEvery(GET_CATEGORIES_PROFILE, fetchCategoriesProfile)
  yield takeEvery(ADD_NEW_CATEGORY, onAddNewCategory)
  yield takeEvery(UPDATE_CATEGORY, onUpdateCategory)
  yield takeEvery(DELETE_CATEGORY, onDeleteCategory)
  yield takeEvery(UPDATE_CATEGORY_IMAGE, onUpdateCategoryImage)
}

export default categoriesSaga
