import {
  GET_TITLE,
  GET_TITLE_FAIL,
  GET_TITLE_SUCCESS,
  ADD_NEW_TITLE,
  ADD_TITLE_SUCCESS,
  ADD_TITLE_FAIL,
  UPDATE_TITLE_IMAGE,
  UPDATE_TITLE_IMAGE_SUCCESS,
  UPDATE_TITLE_IMAGE_FAIL,
  UPDATE_TITLE_VIDEO,
  UPDATE_TITLE_VIDEO_SUCCESS,
  UPDATE_TITLE_VIDEO_FAIL,
  UPDATE_TITLE,
  UPDATE_TITLE_SUCCESS,
  UPDATE_TITLE_FAIL,
  EDIT_TITLE,
  EDIT_TITLE_SUCCESS,
  EDIT_TITLE_FAIL,
  DELETE_TITLE,
  DELETE_TITLE_SUCCESS,
  DELETE_TITLE_FAIL,
  UPDATE_TITLE_POSTER_SUCCESS,
  UPDATE_SERIES_VIDEO,
  UPDATE_SERIES_VIDEO_SUCCESS,
  UPDATE_SERIES_VIDEO_FAIL,
  UPDATE_TRAILER_VIDEO,
  UPDATE_TRAILER_VIDEO_SUCCESS,
  UPDATE_TRAILER_VIDEO_FAIL,
  IMPORT_TITLE_VIDEO,
  IMPORT_TITLE_VIDEO_FAIL,
  UPDATE_CATEGORY_TITLE,
} from "./actionTypes"

export const getTitle = (page = 1,countries="all",language="all",sortKey="",sortType="") =>{
  console.log("1",sortKey,sortType)
  return ({
    type: GET_TITLE,
    payload: { page,countries,language ,sortKey,sortType},
  })
}

export const getTitleSuccess = Title => ({
  type: GET_TITLE_SUCCESS,
  payload: Title,
})

export const addNewTitle = (titles, history) => ({
  type: ADD_NEW_TITLE,
  payload: { titles, history },
})

export const addTitleSuccess = title => ({
  type: ADD_TITLE_SUCCESS,
  payload: title,
})

export const addTitleFail = error => ({
  type: ADD_TITLE_FAIL,
  payload: error,
})

export const getTitleFail = error => ({
  type: GET_TITLE_FAIL,
  payload: error,
})

export const updateTitleimage = (id, image, type = "banner") => ({
  type: UPDATE_TITLE_IMAGE,
  payload: { id, image, type },
})

export const updateCategoryTitles = (categories, title ) => ({
  type: UPDATE_CATEGORY_TITLE,
  payload: { categories,title },
})

export const updateTitleImageSuccess = title => ({
  type: UPDATE_TITLE_IMAGE_SUCCESS,
  payload: title,
})
export const updateTitlePosterSuccess = title => ({
  type: UPDATE_TITLE_POSTER_SUCCESS,
  payload: title,
})
export const updateTitleImageFail = error => ({
  type: UPDATE_TITLE_IMAGE_FAIL,
  payload: error,
})

export const updateTrailervideo = (id, data, history, trailerIndex = null) => ({
  type: UPDATE_TRAILER_VIDEO,
  payload: { id, data, history, trailerIndex },
})

export const updateTrailerVideoSuccess = title => ({
  type: UPDATE_TRAILER_VIDEO_SUCCESS,
  payload: title,
})
export const updateTrailerVideoFail = error => ({
  type: UPDATE_TRAILER_VIDEO_FAIL,
  payload: error,
})

export const updateTitlevideo = (id, data, history) => ({
  type: UPDATE_TITLE_VIDEO,
  payload: { id, data, history },
})

export const updateTitleVideoSuccess = title => ({
  type: UPDATE_TITLE_VIDEO_SUCCESS,
  payload: title,
})
export const updateTitleVideoFail = error => ({
  type: UPDATE_TITLE_VIDEO_FAIL,
  payload: error,
})
export const importTitleVideo = (file, history) => ({
  type: IMPORT_TITLE_VIDEO,
  payload: { file, history },
})

export const uploadTitlevideo = (id, data, history) => ({
  type: UPDATE_TITLE_VIDEO,
  payload: { id, data, history },
})

export const uploadTitleVideoSuccess = title => ({
  type: UPLOAD_TITLE_VIDEO_SUCCESS,
  payload: title,
})
export const uploadTitleVideoFail = error => ({
  type: UPLOAD_TITLE_VIDEO_FAIL,
  payload: error,
})

// Series
export const updateSeriesvideo = (id, data, history) => ({
  type: UPDATE_SERIES_VIDEO,
  payload: { id, data, history },
})

export const updateSeriesVideoSuccess = title => ({
  type: UPDATE_SERIES_VIDEO_SUCCESS,
  payload: title,
})
export const updateSeriesVideoFail = error => ({
  type: UPDATE_SERIES_VIDEO_FAIL,
  payload: error,
})

export const updateTitle = (titles, history) => ({
  type: UPDATE_TITLE,
  payload: { titles, history },
})
export const updateTitleFail = error => ({
  type: UPDATE_TITLE_FAIL,
  payload: error,
})
export const updateTitleSuccess = title => ({
  type: UPDATE_TITLE_SUCCESS,
  payload: title,
})
export const editTitleSuccess = title => ({
  type: EDIT_TITLE_SUCCESS,
  payload: title,
})

export const editTitle = title => ({
  type: EDIT_TITLE,
  payload: title,
})
export const editTitleFail = error => ({
  type: EDIT_TITLE_FAIL,
  payload: error,
})

export const deleteTitle = (title, history) => ({
  type: DELETE_TITLE,
  payload: { title, history },
})

export const deleteTitleSuccess = id => ({
  type: DELETE_TITLE_SUCCESS,
  payload: id,
})

export const deleteTitleFail = error => ({
  type: DELETE_TITLE_FAIL,
  payload: error,
})
