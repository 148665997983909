import { createAsyncThunk } from "@reduxjs/toolkit";
const baseUrl = `${process.env.REACT_APP_ZFLIX_URL}/v1/`;


const getBucketFilesData = createAsyncThunk(
    'buckets',
    async (action) => {
        try {
            let url = `${baseUrl}bucket`;
            const token = JSON.parse(sessionStorage.getItem("authUser")).access_token
            let option = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':`Bearer ${token}`,
                },
            }
            const response = await (await fetch(url, option)).json();

            if (response.status === true) {
                return [true, response];
            } else if (response.status === false) {
                return [false, response.message];
            }
        } catch (error) {
            console.log(error)
            return [false];
        }
    }
)

const downloadFile = createAsyncThunk(
    'buckets/download',
    async (action) => {
        try {
            let url = `${baseUrl}bucket/file-download`;
            const token = JSON.parse(sessionStorage.getItem("authUser")).access_token
            let option = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    filePath: action
                })
            }
            const response = await (await fetch(url, option)).json();

            if (response.status === true) {
                return [true, response];
            } else if (response.status === false) {
                return [false, response.message];
            }
        } catch (error) {
            console.log(error)
            return [false];
        }
    }
)

const upload = createAsyncThunk(
    'buckets/upload',
    async (action) => {
        try {
            let url = `${baseUrl}bucket/upload`;
            const token = JSON.parse(sessionStorage.getItem("authUser")).access_token
            let option = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    folderName: action.folderName,
                    yamlFile:action.yamlFile,
                    notification:action.notification || "",
                    time:action.time || ""
                })
            }
            const response = await (await fetch(url, option)).json();

            if (response.status === true) {
                return [true, response];
            } else if (response.status === false) {
                return [false, response.message];
            }
        } catch (error) {
            console.log(error)
            return [false];
        }
    }
)

const updateSubtitle = createAsyncThunk(
    'buckets/updateSubtitles',
    async (action) => {
        try {
            let url = `${baseUrl}titles/update-subtitle`;
            const token = JSON.parse(sessionStorage.getItem("authUser")).access_token
            let option = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    folderName:action.folderName,
                    file:action.yamlFile
                })
            }
            const response = await (await fetch(url, option)).json();

            if (response.status === true) {
                return [true, response];
            } else if (response.status === false) {
                return [false, response.message];
            }
        } catch (error) {
            console.log(error)
            return [false];
        }
    }
)



export { getBucketFilesData, downloadFile, upload ,updateSubtitle}
