import {
  START_LOADER_SUCCESS,
  STOP_LOADER_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
 
  loadingState:'',
}

const loadingState = (state = INIT_STATE, action) => {
  switch (action.type) {
    case START_LOADER_SUCCESS:
      return {
        ...state,
        loadingState: true
      }

    case STOP_LOADER_SUCCESS:
      return {
        ...state,
        loadingState: false
      }

    default:
      return state
  }
}

export default loadingState
