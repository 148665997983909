import {
  GET_CAST,
  GET_CAST_FAIL,
  GET_CAST_SUCCESS,
  ADD_NEW_CAST,
  ADD_CAST_SUCCESS,
  ADD_CAST_FAIL,
  UPDATE_CAST_IMAGE,
  UPDATE_CAST_IMAGE_SUCCESS,
  UPDATE_CAST_IMAGE_FAIL,
  UPDATE_CAST,
  UPDATE_CAST_SUCCESS,
  UPDATE_CAST_FAIL,
  EDIT_CAST,
  EDIT_CAST_SUCCESS,
  EDIT_CAST_FAIL,
  DELETE_CAST,
  DELETE_CAST_SUCCESS,
  DELETE_CAST_FAIL,
} from "./actionTypes"

export const getCast = (page =1) => ({
  type: GET_CAST,
  payload: {page},

})

export const getCastSuccess = Cast => ({
  type: GET_CAST_SUCCESS,
  payload: Cast,
})

export const addNewCast = (cast,history) => ({
  type: ADD_NEW_CAST,
  payload: {cast,history},
})

export const addCastSuccess = cast => ({
  type: ADD_CAST_SUCCESS,
  payload: cast,
})

export const addCastFail = error => ({
  type: ADD_CAST_FAIL,
  payload: error,
})

export const getCastFail = error => ({
  type: GET_CAST_FAIL,
  payload: error,
})




export const updateCastimage = (id,image) => ({
  type: UPDATE_CAST_IMAGE,
  payload: {id,image},
})

export const updateCastImageSuccess = cast => ({
  type: UPDATE_CAST_IMAGE_SUCCESS,
  payload: cast,
})
export const updateCastImageFail = error => ({
  type: UPDATE_CAST_IMAGE_FAIL,
  payload: error,
})

export const updateCastSuccess = cast => ({
  type: UPDATE_CAST_SUCCESS,
  payload: cast,
})

export const updateCast = (cast,history) => ({
  type: UPDATE_CAST,
  payload: {cast,history},
})
export const updateCastFail = error => ({
  type: UPDATE_CAST_FAIL,
  payload: error,
})
export const editCastSuccess = cast => ({
  type: EDIT_CAST_SUCCESS,
  payload: cast,
})

export const editCast = (cast) => ({
  type: EDIT_CAST,
  payload: cast,
})
export const editCastFail = error => ({
  type: EDIT_CAST_FAIL,
  payload: error,
})

export const deleteCast = (cast,history) => ({
  type: DELETE_CAST,
  payload: {cast,history},
})

export const deleteCastSuccess = id => ({
  type: DELETE_CAST_SUCCESS,
  payload: id,
})

export const deleteCastFail = error => ({
  type: DELETE_CAST_FAIL,
  payload: error,
})


