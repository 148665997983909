import { createAsyncThunk } from "@reduxjs/toolkit";
const baseUrl = `${process.env.REACT_APP_ZFLIX_URL}/v1/`;



const updateTrailerDuration = createAsyncThunk(
    'buckets/download',
    async (action) => {
        try {
            let url = `${baseUrl}titles/trailer-duration/${action.id}`;
            const token = JSON.parse(sessionStorage.getItem("authUser")).access_token
            let option = {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    duration: action.duration
                })
            }
            const response = await (await fetch(url, option)).json();

            if (response.status === true) {
                return [true, response];
            } else if (response.status === false) {
                return [false, response.message];
            }
        } catch (error) {
            console.log(error)
            return [false];
        }
    }
)

const updateMovieDuration = createAsyncThunk(
    'Movie/duration',
    async (action) => {
        try {
            let url = `${baseUrl}titles/movie-duration/${action.id}`;
            const token = JSON.parse(sessionStorage.getItem("authUser")).access_token
            let option = {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    duration: action.duration
                })
            }
            const response = await (await fetch(url, option)).json();

            if (response.status === true) {
                return [true, response];
            } else if (response.status === false) {
                return [false, response.message];
            }
        } catch (error) {
            console.log(error)
            return [false];
        }
    }
)

const getTitleLogReport = createAsyncThunk(
    'report/get',
    async (action) => {
        try {
            let url = `${baseUrl}log-report?start=${action.start}&end=${action.end}&type=${action.type}`;
            let option = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            }
            const response = await (await fetch(url, option)).json();
            if (response.status === true) {
                return [true, response];
            } else if (response.status === false) {
                return [false, response.message];
            }
        } catch (error) {
            console.log(error)
            return [false];
        }
    }
)

const TitleLogReportDetails = createAsyncThunk(
    'report/details',
    async (action) => {
        try {
            let url = `${baseUrl}log-report/details`;
            const token = JSON.parse(sessionStorage.getItem("authUser")).access_token
            let option = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    name: action.name,
                    start: action.start,
                    end: action.end,
                    type: action.type,
                    search:action.search
                })
            }
            const response = await (await fetch(url, option)).json();
            if (response.status === true) {
                return [true, response];
            } else if (response.status === false) {
                return [false, response.message];
            }
        } catch (error) {
            console.log(error)
            return [false];
        }
    }
)



export { updateTrailerDuration,updateMovieDuration ,getTitleLogReport, TitleLogReportDetails}
