import {
  GET_TITLE_SUCCESS,
  GET_TITLE_FAIL,
  ADD_TITLE_SUCCESS,
  ADD_TITLE_FAIL,
  UPDATE_TITLE_IMAGE_FAIL,
  UPDATE_TITLE_IMAGE_SUCCESS,
  UPDATE_TITLE_IMAGE,
  UPDATE_TITLE_VIDEO_FAIL,
  UPDATE_TITLE_VIDEO_SUCCESS,
  UPDATE_TITLE_VIDEO,
  UPLOAD_TITLE_VIDEO_FAIL,
  UPLOAD_TITLE_VIDEO_SUCCESS,
  UPLOAD_TITLE_VIDEO,
  DELETE_TITLE_SUCCESS,
  DELETE_TITLE_FAIL,
  EDIT_TITLE,
  EDIT_TITLE_FAIL,
  EDIT_TITLE_SUCCESS,
  UPDATE_TITLE_POSTER_SUCCESS,
  UPDATE_TRAILER_VIDEO,
  UPDATE_TRAILER_VIDEO_FAIL,
  UPDATE_TRAILER_VIDEO_SUCCESS,
  UPDATE_CATEGORY_TITLE,
} from "./actionTypes"

const INIT_STATE = {
  title: [],
  error: {},
  titleSingle: {},
  isUpdateCategoryTitle: {}
}


const title = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TITLE_SUCCESS:
      return {
        ...state,
        title: action.payload.data,
      }

    case GET_TITLE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_TITLE_SUCCESS:
      return {
        ...state,
        title: {
          ...state.title,
          results: [...state.title.results, action.payload]
        },
      }


    case ADD_TITLE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_TITLE_SUCCESS:
      return {
        ...state,
        title: {
          ...state.title,
          results: [...state.title.results.filter(item => item['id'] !== action.payload)]
        },
      }

    case DELETE_TITLE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_TITLE_IMAGE:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_TITLE_IMAGE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_TITLE_IMAGE_SUCCESS:
      return {
        ...state,
        title: {
          ...state.title,
          results: state.title.results.map(item => item?.id == action.payload.id ? { ...item, banner: action.payload.banner } : item)
        },
      }
    case UPDATE_TITLE_POSTER_SUCCESS:
      return {
        ...state,
        title: {
          ...state.title,
          results: state.title.results.map(item => item?.id == action.payload.id ? { ...item, poster: action.payload.poster } : item)
        },
      }

    case UPDATE_TITLE_VIDEO:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_TITLE_VIDEO_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_TITLE_VIDEO_SUCCESS:
      return {
        ...state,
        titleSingle: action.payload,
      }
    case UPDATE_TRAILER_VIDEO:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_TRAILER_VIDEO_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_TRAILER_VIDEO_SUCCESS:
      return {
        ...state,
        titleSingle: action.payload,
      }


    case UPLOAD_TITLE_VIDEO:
      return {
        ...state,
        error: action.payload,
      }

    case UPLOAD_TITLE_VIDEO_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPLOAD_TITLE_VIDEO_SUCCESS:
      return {
        ...state,
        title: {
          ...state.title,
          results: state.title.results.map(item => item?.id == action.payload.id ? { ...item, video: action.payload.video } : item)
        },
      }

    case EDIT_TITLE:
      return {
        ...state,
        titleSingle: {}
      }

    case EDIT_TITLE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case EDIT_TITLE_SUCCESS:
      return {
        ...state,
        titleSingle: action.payload.data
      }

    case UPDATE_CATEGORY_TITLE:
      return {
        ...state,
        isUpdateCategoryTitle: action.payload,
      }


    default:
      return state
  }
}

export default title
