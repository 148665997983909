import {
  GET_SEARCHDATA,
  GET_SEARCHDATA_FAIL,
  GET_SEARCHDATA_SUCCESS,
} from "./actionTypes"

export const getSearchData = (page =1) => ({
  type: GET_SEARCHDATA,
  payload: {page},
})


export const getSearchDataSuccess = SearchData => ({
  type: GET_SEARCHDATA_SUCCESS,
  payload: SearchData,
})


export const getSearchDataFail = error => ({
  type: GET_SEARCHDATA_FAIL,
  payload: error,
})


