import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_USER, ADD_NEW_USER, DELETE_USER, UPDATE_USER, UPDATE_USER_IMAGE, EDIT_USER } from "./actionTypes"

import {
  getUserSuccess,
  getUserFail,
  addUserFail,
  addUserSuccess,
  updateUserSuccess,
  updateUserFail,
  deleteUserSuccess,
  deleteUserFail,
  editUserSuccess,
  editUserFail,
} from "./actions"

//Include Both Helper File with needed methods
import { getUser, addNewUser, updateUser, deleteUser, editUser } from "../../helpers/fakebackend_helper"
import { stopLoader } from "../loaderState/actions"

function* fetchUser({ payload }) {
  try {
    const response = yield call(getUser,payload.page,payload.userType,payload.email,payload.userId)
    yield put(getUserSuccess(response))
    yield put(stopLoader())
  } catch (error) {
    yield put(getUserFail(error))
  }
}

function* onEditUser({ payload: user }) {
  try {
    const response = yield call(editUser, user)
    console.log("check my resposne ====>",response)
    yield put(editUserSuccess(response))
  } catch (error) {
    yield put(editUserFail(error))
  }
}
function* onUpdateUser({ payload: { id, user, history } }) {
  try {
    const response = yield call(updateUser, id, user)
    yield put(updateUserSuccess(response.data))


    history.push("/registered-users");

  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onDeleteUser({ payload: { id, history } }) {
  try {
    const response = yield call(deleteUser, id)

    yield put(deleteUserSuccess(id))
    history.push("/registered-users");

  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onAddNewUser({ payload: { user, history } }) {

  try {
    const name = user.name;
    const email = user.email;
    const password = user.password;
    const role = user.role;

    const response = yield call(addNewUser, { name, email, password, role })

    yield put(addUserSuccess(response.data))
    history.push("/registered-users");

  } catch (error) {
    yield put(addUserFail(error))

  }
}


function* userSaga() {
  yield takeEvery(GET_USER, fetchUser)
  yield takeEvery(ADD_NEW_USER, onAddNewUser)
  yield takeEvery(UPDATE_USER, onUpdateUser)
  yield takeEvery(DELETE_USER, onDeleteUser)
  yield takeEvery(EDIT_USER, onEditUser)
}

export default userSaga;
