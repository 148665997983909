import React, { useEffect, useState } from "react"
import { cilPencil, cilTrash } from "@coreui/icons"
import { Button } from "reactstrap"

import { CCard, CCardBody, CCardGroup } from "@coreui/react"
import DataTable from "react-data-table-component"
import { Row, Col } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import CIcon from "@coreui/icons-react"
import { useHistory, Link } from "react-router-dom"
import { toastConfirm } from "../../common/toast"
import { useDispatch, useSelector } from "react-redux"
import { deleteUser, getUser } from "../../store/actions"
import { setTokenHeader } from "../../helpers/api_helper"
import FullPageLoader from "../../components/Common/FullPageLoader"
import { startLoader } from "../../store/loaderState/actions"
import NoImgFound from "../../assets/No-image-found.jpg"
import { getPlan } from "../../store/plan/actions"

const ManagePlans = () => {
  document.title = "Plan Details"

  const { loadingState } = useSelector(state => state.loadingState)

  const history = useHistory()
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const countPerPage = 10
  const dddd = useSelector(state => state.plans)

  const deleteUserRecord = async id => {
    const response = await (
      await toastConfirm("Are you sure you want to delete this?")
    ).fire()
    if (response.isConfirmed) {
      dispatch(deleteUser(id, history))
    }
  }

  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem("authUser"))
    setTokenHeader(data.access_token)
    dispatch(startLoader())
    dispatch(getPlan())
  }, [])

  useEffect(() => {
    dispatch(startLoader())
    dispatch(getUser(page))
  }, [page])

  var rowCounter = 1
  const columns = [
    {
      name: "Sr. no",
      selector: row => rowCounter++,
      sortable: true,
      maxWidth: "80px",
    },
    {
      name: "Email",
      selector: row => row.email,
      sortable: true,
    },
    {
      name: "User Name",
      selector: row => row.name,
      sortable: true,
    },
    {
      name: "Country",
      selector: row => row.country,
      sortable: true,
    },
    {
      name: "Verified",
      selector: row => (row.isEmailVerified == true ? "Active" : "Inactive"),
      sortable: true,
      maxWidth: "120px",
    },
    {
      name: "Subscription",
      selector: row => (row.isSubscribed == true ? "Active" : "Inactive"),
      sortable: true,
      maxWidth: "150px",
    },
    {
      name: "Actions",
      maxWidth: "100px",
      selector: row => (
        <>
          <Link to={`user/${row.id}/edit`}>
            <CIcon
              icon={cilPencil}
              className="text-warning hand me-2"
              onClick={() => {
                // toggleViewModal()
                // setEdit(row);
              }}
            />
          </Link>
          <CIcon
            icon={cilTrash}
            className="text-danger hand"
            onClick={() => {
              deleteUserRecord(row.id)
            }}
          />
        </>
      ),
    },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Manage Plans"
            breadcrumbItem="Plans list"
          />

          <Row>
            <Col xl={12}>
              <div className="mb-2">
                <Link
                  to={"/registered-users/add"}
                  className="btn btn-primary w-md"
                >
                  Add Plan
                </Link>
              </div>
            </Col>
            <Col xl={12}>
              <CCardGroup>
                <CCard>
                  <CCardBody>
                    <DataTable
                      striped
                      columns={columns}
                      data={user?.results}
                      highlightOnHover
                      pagination
                      paginationServer
                      paginationTotalRows={user?.totalResults}
                      paginationPerPage={countPerPage}
                      paginationComponentOptions={{
                        noRowsPerPage: true,
                      }}
                      onChangePage={(page, totalRows) => {
                        setPage(page)
                      }}
                    />
                  </CCardBody>
                </CCard>
              </CCardGroup>
              <FullPageLoader loading={loadingState} />
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ManagePlans
