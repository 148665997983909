/* CATEGORIES */
export const GET_CAST = "GET_CAST"
export const GET_CAST_SUCCESS = "GET_CAST_SUCCESS"
export const GET_CAST_FAIL = "GET_CAST_FAIL"

/**
 * add GENRE
 */
 export const ADD_NEW_CAST = "ADD_NEW_CAST"
 export const ADD_CAST_SUCCESS = "ADD_CAST_SUCCESS"
 export const ADD_CAST_FAIL = "ADD_CAST_FAIL"

/**
 * UPDATE GENRE IMAGE
 */
 export const UPDATE_CAST_IMAGE = "UPDATE_CAST_IMAGE"
 export const UPDATE_CAST_IMAGE_SUCCESS = "UPDATE_CAST_IMAGE_SUCCESS"
 export const UPDATE_CAST_IMAGE_FAIL = "UPDATE_CAST_IMAGE_FAIL"
  
/**
 * Edit GENRE
 */
export const EDIT_CAST = "EDIT_CAST"
export const EDIT_CAST_SUCCESS = "EDIT_CAST_SUCCESS"
export const EDIT_CAST_FAIL = "EDIT_CAST_FAIL"


/**
 * Edit GENRE
 */
 export const UPDATE_CAST = "UPDATE_CAST"
 export const UPDATE_CAST_SUCCESS = "UPDATE_CAST_SUCCESS"
 export const UPDATE_CAST_FAIL = "UPDATE_CAST_FAIL"

/**
 * Delete GENRE
 */
export const DELETE_CAST = "DELETE_CAST"
export const DELETE_CAST_SUCCESS = "DELETE_CAST_SUCCESS"
export const DELETE_CAST_FAIL = "DELETE_CAST_FAIL"
